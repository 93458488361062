import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  HostListener
} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material';
import {Overlay} from '@angular/cdk/overlay';
import {
  PostModel,
  UserModel,
  CampusPostModel,
  CampusCourseFeedPostModel,
  CampusClassPostModel
} from '../../models';
import {
  PostApiService,
  CampusApiService,
  UserApiService
} from '../../../../services/api';
import {PostEmitter} from '../../emitter';
import {CryptoUtilities} from '../../../shared/utilities';
import {UserService} from '../../../../services';
import {
  SharedImagePreviewComponent,
  SharedPostDetailModalComponent
} from '../../modals';

@Component({
  selector: 'shared-post-component',
  template: require('./post.component.html'),
  styles: [require('./post.component.scss')]
})
export class SharedPostComponent {
  constructor(
    private postApiService: PostApiService,
    private campusApiService: CampusApiService,
    private userApiService: UserApiService,
    private router: Router,
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  @Input() protected posts: Array<
    | PostModel
    | CampusPostModel
    | CampusCourseFeedPostModel
    | CampusClassPostModel
  > = [];
  @Input() protected noPosts;
  @Input() protected route: {
    name: string;
    campusId?: number;
    campusFreshersFeedId?: number;
    campusCourseFeedId?: number;
    campusClassId?: number;
    userId?: number;
  } = {name: 'home'};
  @Input() protected user: UserModel;
  @Output() protected loadRecord = new EventEmitter();
  protected btnLoadMoreText = 'Load More';
  protected notPostMessage: string;
  private dialogRef: MatDialogRef<SharedImagePreviewComponent>;
  private limit = 20;
  private offset = 0;
  protected userJustVoted: boolean = false;
  protected isLoadingMorePosts: boolean = false;
  private loading = true;

  public ngOnInit(): void {
    this.getSharedPostSubscriber();
    this.postSavedSubcribers();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.posts?.previousValue !== undefined) {
      if (this.posts.length === 0 && changes.posts?.previousValue) {
        this.notPostMessage = 'No Post Yet.';
        this.noPosts = true;
        this.isLoadingMorePosts = false;
        this.loading = false;
      }
    }
  }

  private postSavedSubcribers(): void {
    PostEmitter.postSave().subscribe((post: PostModel | CampusPostModel) => {
      this.posts.unshift(post);
    });
  }

  private getSharedPostSubscriber(): void {
    // console.log('enter getSharedPostSubscriber');
    PostEmitter.postShare().subscribe((post: PostModel | CampusPostModel) => {
      this.posts.unshift(post);
    });
  }

  protected onClickUserProfile(user): Promise<boolean> {
    // console.log('user id', user, user.id);
    let userId = CryptoUtilities.cipher(user.id);
    let currentLoginUser = UserService.getUser();

    if (user.id === currentLoginUser.id) {
      return this.router.navigate([`/profile`]);
    } else {
      return this.router.navigate([`/profile/${userId}`]);
    }
  }

  protected onDeletePost(postId: number): void {
    // delete here the post
    this.postApiService
      .promiseRemovePost(postId)
      .then(() => {
        let index = this.posts.findIndex((filter) => filter.id === postId);
        this.posts.splice(index, 1);
      })
      .catch(() => {});
  }

  @HostListener('window:scroll', [])
  protected onScroll(): void {
    // let divElemHeight = document.getElementById("shared-post-component").offsetHeight;
    let homeElement = document.getElementById('home-component');

    if (homeElement) {
      let elemBountRect = homeElement.getBoundingClientRect();

      if (window.innerHeight + window.scrollY >= elemBountRect.height - 100) {
        // you're at the bottom of the page
        if (this.posts.length !== 0 && !this.isLoadingMorePosts) {
          this.onLoadMorePost();
        }
      }
    }
  }

  protected onLoadMorePost(): void {
    // console.log('enter onLoadMorePost');
    this.isLoadingMorePosts = true;
    this.loading = true;
    this.offset = this.posts.length;
    let campusId: any;

    switch (this.route.name) {
      case 'home':
        this.postApiService
          .promiseGetAllPost(this.limit, this.offset)
          .then((posts: PostModel[]) => {
            this.posts = this.posts.concat(posts);
            this.checkIfThereAreStillPostAvailable(posts);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
      case 'campus':
        campusId = parseInt(CryptoUtilities.decipher(this.route.campusId), 10);
        this.campusApiService
          .promiseGetAllPost(campusId, this.limit, this.offset)
          .then((campusPost: CampusPostModel[]) => {
            this.posts = this.posts.concat(campusPost);
            // console.log('posts', this.posts);
            this.checkIfThereAreStillPostAvailable(campusPost);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
      case 'campusFreshersFeed':
        campusId = parseInt(CryptoUtilities.decipher(this.route.campusId), 10);
        let campusFreshersFeedId = parseInt(
          CryptoUtilities.decipher(this.route.campusCourseFeedId),
          10
        );
        this.campusApiService
          .promiseGetAllFreshersFeedPost(
            campusId,
            campusFreshersFeedId,
            this.limit,
            this.offset
          )
          .then((campusPost: CampusPostModel[]) => {
            this.posts = this.posts.concat(campusPost);
            this.checkIfThereAreStillPostAvailable(campusPost);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
      case 'campusCourseFeed':
        campusId = parseInt(CryptoUtilities.decipher(this.route.campusId), 10);
        let campusCourseFeedId = parseInt(
          CryptoUtilities.decipher(this.route.campusCourseFeedId),
          10
        );
        this.campusApiService
          .promiseGetAllCoursePost(campusId, campusCourseFeedId)
          .then((campusPost: CampusPostModel[]) => {
            this.posts = this.posts.concat(campusPost);
            this.checkIfThereAreStillPostAvailable(campusPost);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
      case 'campusClasses':
        campusId = parseInt(CryptoUtilities.decipher(this.route.campusId), 10);
        let campusClassId = parseInt(
          CryptoUtilities.decipher(this.route.campusClassId),
          10
        );
        this.campusApiService
          .promiseGetAllClassPost(
            campusId,
            campusClassId,
            true,
            this.limit,
            this.offset
          )
          .then((campusPost: CampusPostModel[]) => {
            this.posts = this.posts.concat(campusPost);
            this.checkIfThereAreStillPostAvailable(campusPost);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
      case 'profile':
        let userId = this.route.userId;
        this.userApiService
          .promiseGetTimeline(userId, this.limit, this.offset)
          .then((timelinePost: PostModel[]) => {
            this.posts = this.posts.concat(timelinePost);
            this.checkIfThereAreStillPostAvailable(timelinePost);
            this.isLoadingMorePosts = false;
            this.loading = false;
          });
        break;
    }
  }

  protected onClickPhoto(postAttachments, imageIndex): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'image-preview-modal';
    dialogConfig.id = 'ImagePreviewModal';
    dialogConfig.disableClose = true;
    dialogConfig.scrollStrategy = this.overlay.scrollStrategies.block();
    dialogConfig.data = {
      images: postAttachments,
      clickIndex: imageIndex,
      source: 'post'
    };
    this.dialogRef = this.dialog.open(
      SharedImagePreviewComponent,
      dialogConfig
    );
  }

  protected getPollPercentage(pollOptions): any {
    pollOptions.map((optionData) => {
      optionData.sum = parseFloat(optionData.sum) + 1;
      optionData.average = (optionData.count / optionData.sum) * 100;
      optionData.average = optionData.average.toFixed(2);

      return optionData;
    });
  }

  protected onPollVote(index, option, pollOptions): void {
    switch (this.route.name) {
      case 'home':
        this.postApiService
          .promiseVotePoll(option.id)
          .then(() => {
            pollOptions[index].count += 1;
            this.getPollPercentage(pollOptions);
            this.userJustVoted = true;
          })
          .catch(() => {});
        break;
      case 'campus':
      case 'campusFreshersFeed':
      case 'campusCourseFeed':
      case 'campusClasses':
        this.campusApiService
          .promiseVotePoll(option.id)
          .then(() => {
            pollOptions[index].count += 1;
            this.getPollPercentage(pollOptions);
          })
          .catch(() => {});
        break;
    }
  }

  private checkIfThereAreStillPostAvailable(
    posts: PostModel[] | CampusPostModel[]
  ): void {
    if (posts.length === 0) {
      this.btnLoadMoreText = 'No More Posts To Show';
      this.loading = false;
    }
  }

  protected trimStory(message, maxCharacters): string {
    let trimmedString = message.substr(0, maxCharacters);
    return (trimmedString =
      trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
      ) + '...');
  }

  protected onClickCommentDetail(post): void {
    let dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'post-comment-detail-modal';
    dialogConfig.id = 'SharedPostDetailModalComponent';
    dialogConfig.disableClose = true;
    dialogConfig.scrollStrategy = this.overlay.scrollStrategies.block();
    dialogConfig.data = {post: post, route: this.route, user: this.user};
    this.dialog.open(SharedPostDetailModalComponent, dialogConfig);
  }

  public ngOnDestroy(): void {
    PostEmitter.removeSubscriber(PostEmitter.getPostSaveName());
    PostEmitter.removeSubscriber(PostEmitter.getPostSaveName());
  }
  protected loadPost(): void {
    // console.log('enter loadPost');
    this.loadRecord.emit();
    this.loading = false;
  }

  protected updatePost(data: any): void {
    // console.log(data);
    // let index = this.posts.indexOf(data.originPost);
    for (let i = 0; i < this.posts.length; i++) {
      if (this.posts[i].id === data.updatedPost.id) {
        this.posts[i] = data.updatedPost;
      }
    }
    // this.posts[index] = data.updatedPost;
  }
}
