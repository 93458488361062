export class SkillService {
  public static getskill(): any {
    return [
      {id: 272, name: 'SubmittalsConstruction}', complete: 'Architecture'},
      {id: 273, name: 'GradingLandscape}', complete: 'Architecture'},
      {id: 274, name: 'HVAC', complete: 'Architecture'},
      {id: 275, name: 'Construction', complete: 'Architecture'},
      {id: 276, name: 'SketchUpDModelingSoftware}', complete: 'Architecture'},
      {id: 277, name: 'QualityControl', complete: 'Architecture'},
      {id: 278, name: 'ProjectCoordination', complete: 'Architecture'},
      {
        id: 279,
        name: 'GeographicInformationSystems',
        complete: 'Architecture'
      },
      {id: 280, name: 'MechanicalSystems', complete: 'Architecture'},
      {id: 281, name: 'AcademicAchievement', complete: 'Architecture'},
      {
        id: 282,
        name: 'Autodesk3DSMax3DGraphicsSoftware}',
        complete: 'Architecture'
      },
      {id: 283, name: 'BusinessDevelopment', complete: 'Architecture'},
      {id: 284, name: 'BuildingDesign', complete: 'Architecture'},
      {
        id: 285,
        name: 'IrrigationLandscapingAndAgriculture}',
        complete: 'Architecture'
      },
      {id: 286, name: 'Propulsion', complete: 'Architecture'},
      {id: 287, name: 'FinancialManagement', complete: 'Architecture'},
      {id: 288, name: 'Rendering', complete: 'Architecture'},
      {id: 289, name: 'InformationModel', complete: 'Architecture'},
      {id: 290, name: 'ConstructionManagement', complete: 'Architecture'},
      {id: 291, name: 'Land-UsePlanning', complete: 'Architecture'},
      {id: 292, name: 'Shipyard', complete: 'Architecture'},
      {id: 293, name: 'Budgeting', complete: 'Architecture'},
      {id: 294, name: 'Sketching', complete: 'Architecture'},
      {id: 295, name: 'IntegratedDesign', complete: 'Architecture'},

      {id: 1, name: 'Welfare', complete: 'Agricluture'},
      {id: 2, name: 'Biology', complete: 'Agricluture'},
      {id: 3, name: 'ApplicationData', complete: 'Agricluture'},
      {id: 4, name: 'Valves{Piping}', complete: 'Agricluture'},
      {id: 5, name: 'Vaccination', complete: 'Agricluture'},
      {id: 6, name: 'Budgeting', complete: 'Agricluture'},
      {id: 7, name: 'MaterialHandling', complete: 'Agricluture'},
      {id: 8, name: 'AnimalHusbandry', complete: 'Agricluture'},
      {id: 9, name: 'Pruning', complete: 'Agricluture'},
      {id: 10, name: 'WaterDistribution', complete: 'Agricluture'},
      {id: 11, name: 'Horticulture', complete: 'Agricluture'},
      {id: 12, name: 'AnimalHusbandry', complete: 'Agricluture'},
      {id: 13, name: 'Agriculture', complete: 'Agricluture'},
      {id: 14, name: 'InventoryControl', complete: 'Agricluture'},
      {id: 15, name: 'FarmImplements', complete: 'Agricluture'},
      {id: 16, name: 'CertifiedArborist', complete: 'Agricluture'},
      {
        id: 17,
        name: 'EmergencyCommunicationSystems',
        complete: 'Agricluture'
      },
      {id: 18, name: 'SpanishLanguage', complete: 'Agricluture'},
      {id: 19, name: 'EnvironmentalHealth', complete: 'Agricluture'},
      {id: 20, name: 'Pipe{Conveyance}', complete: 'Agricluture'},
      {id: 21, name: 'Forestry', complete: 'Agricluture'},
      {id: 22, name: 'InjuryPrevention', complete: 'Agricluture'},
      {id: 23, name: 'Auditing', complete: 'Agricluture'},
      {id: 24, name: 'Tires', complete: 'Agricluture'},
      {id: 25, name: 'SprinklerSystems', complete: 'Agricluture'},
      {id: 26, name: 'WeedControls', complete: 'Agricluture'},
      {id: 27, name: 'EnvironmentalLaws', complete: 'Agricluture'},
      {id: 28, name: 'Purchasing', complete: 'Agricluture'},
      {id: 29, name: 'Brakes', complete: 'Agricluture'},
      {id: 30, name: 'EmbankmentDesign', complete: 'Agricluture'},
      {id: 31, name: 'Landscaping', complete: 'Agricluture'},
      {id: 32, name: 'In-PlaneSwitching{IPS}', complete: 'Agricluture'},
      {id: 33, name: 'Entomology', complete: 'Agricluture'},
      {
        id: 34,
        name: 'OccupationalHealthAndSafetyAdministration{OSHA}',
        complete: 'Agricluture'
      },
      {id: 35, name: 'Trench', complete: 'Agricluture'},
      {id: 36, name: 'Trimming', complete: 'Agricluture'},
      {
        id: 37,
        name: 'IntravenousTherapyInterpersonalSkills',
        complete: 'Agricluture'
      },
      {id: 38, name: 'InterpersonalSkills', complete: 'Agricluture'},
      {id: 39, name: 'OilAndGas', complete: 'Agricluture'},
      {id: 40, name: 'AgriculturalMachinery', complete: 'Agricluture'},
      {
        id: 41,
        name: 'TransplantingEnvironmentalIssue',
        complete: 'Agricluture'
      },
      {id: 42, name: 'EnvironmentalIssue', complete: 'Agricluture'},
      {id: 43, name: 'Botany', complete: 'Agricluture'},
      {
        id: 44,
        name: 'CommercialDriverSLicense{CDL}',
        complete: 'Agricluture'
      },
      {id: 45, name: 'SoilScience', complete: 'Agricluture'},
      {id: 46, name: 'Mulch', complete: 'Agricluture'},
      {id: 47, name: 'Cleanliness', complete: 'Agricluture'},
      {id: 48, name: 'LandTenure', complete: 'Agricluture'},
      {id: 49, name: 'Palletizing', complete: 'Agricluture'},
      {id: 50, name: 'AutomationClips', complete: 'Agricluture'},
      {id: 51, name: 'Shears', complete: 'Agricluture'},
      {id: 52, name: 'Sanitation', complete: 'Agricluture'},
      {id: 53, name: 'Writing', complete: 'Agricluture'},
      {id: 54, name: 'CustomerSatisfaction', complete: 'Agricluture'},
      {id: 55, name: 'Spillway', complete: 'Agricluture'},
      {
        id: 56,
        name: 'CardiopulmonaryResuscitation{CPR}',
        complete: 'Agricluture'
      },
      {id: 57, name: 'Teamwork', complete: 'Agricluture'},
      {id: 58, name: 'GeographicInformationSystems', complete: 'Agricluture'},
      {id: 59, name: 'GoodDrivingRecord', complete: 'Agricluture'},

      {id: 60, name: 'Farming', complete: 'Agricluture'},
      {id: 61, name: 'AerialLifts', complete: 'Agricluture'},
      {id: 62, name: 'Tagging', complete: 'Agricluture'},
      {id: 63, name: 'Chemistry', complete: 'Agricluture'},
      {id: 64, name: 'Sampling{Statistics}', complete: 'Agricluture'},
      {id: 65, name: 'Pumps', complete: 'Agricluture'},
      {id: 66, name: 'FirstA', complete: 'Agricluture'},
      {id: 67, name: 'ArtificialInsemination', complete: 'Agricluture'},
      {id: 68, name: 'Teaching', complete: 'Agricluture'},
      {id: 69, name: 'RollingStock', complete: 'Agricluture'},
      {id: 70, name: 'Hydraulics', complete: 'Agricluture'},
      {id: 71, name: 'UrbanForestry', complete: 'Agricluture'},
      {id: 72, name: 'Tattooing', complete: 'Agricluture'},
      {id: 73, name: 'Pathology', complete: 'Agricluture'},
      {id: 74, name: 'BatteriesDrilling', complete: 'Agricluture'},
      {id: 75, name: 'Drilling', complete: 'Agricluture'},
      {id: 76, name: 'Roofing', complete: 'Agricluture'},
      {id: 77, name: 'Castration', complete: 'Agricluture'},
      {id: 78, name: 'EnvironmentalScience', complete: 'Agricluture'},
      {id: 79, name: 'ManufacturingEngineering', complete: 'Agricluture'},
      {id: 80, name: 'R{:ProgrammingLanguage}', complete: 'Agricluture'},
      {id: 81, name: 'AirBrakes', complete: 'Agricluture'},
      {id: 82, name: 'Mowing', complete: 'Agricluture'},
      {id: 83, name: 'Ecology', complete: 'Agricluture'},
      {
        id: 84,
        name: 'ArchitecturalEngineeringDieselGenerators',
        complete: 'Agricluture'
      },
      {id: 85, name: 'DieselGenerators', complete: 'Agricluture'},
      {id: 86, name: 'Gardening', complete: 'Agricluture'},
      {id: 87, name: 'DataCollection', complete: 'Agricluture'},
      {id: 88, name: 'Mapping', complete: 'Agricluture'},
      {id: 89, name: 'ComputerSciences', complete: 'Agricluture'},
      {id: 90, name: 'WarningSigns', complete: 'Agricluture'},
      {id: 91, name: 'LandscapeMaintenance', complete: 'Agricluture'},
      {id: 92, name: 'AdministeringBloodTests', complete: 'Agricluture'},
      {id: 93, name: 'Mathematics', complete: 'Agricluture'},
      {id: 94, name: 'Electronics', complete: 'Agricluture'},
      {id: 95, name: 'Winches', complete: 'Agricluture'},
      {id: 96, name: 'Tilling', complete: 'Agricluture'},
      {id: 97, name: 'AnimalHandling', complete: 'Agricluture'},
      {id: 98, name: 'PublicRelations', complete: 'Agricluture'},
      {id: 99, name: 'PublicAdministration', complete: 'Agricluture'},
      {id: 100, name: 'TrafficControl', complete: 'Agricluture'},
      {id: 101, name: 'LandscapeDesign', complete: 'Agricluture'},
      {id: 102, name: 'LaboratoryAnimals', complete: 'Agricluture'},
      {id: 103, name: 'Forecasting', complete: 'Agricluture'},

      {id: 104, name: 'ElectricalEngineering', complete: 'Agricluture'},
      {id: 105, name: 'LearningManagementSystems', complete: 'Agricluture'},
      {id: 106, name: 'Grinding', complete: 'Agricluture'},
      {id: 107, name: 'Antibiotics', complete: 'Agricluture'},
      {id: 108, name: 'PackagingAndLabeling', complete: 'Agricluture'},
      {id: 109, name: 'ChemicalComposition', complete: 'Agricluture'},
      {id: 110, name: 'Towing', complete: 'Agricluture'},
      {id: 111, name: 'LandscapeArchitecture', complete: 'Agricluture'},
      {id: 112, name: 'Biosecurity', complete: 'Agricluture'},
      {
        id: 113,
        name: 'Troubleshooting{ProblemSolving}',
        complete: 'Agricluture'
      },
      {id: 114, name: 'Geography', complete: 'Agricluture'},
      {id: 115, name: 'GermanLanguage', complete: 'Agricluture'},
      {id: 116, name: 'JobPlanning', complete: 'Agricluture'},
      {
        id: 117,
        name: 'AssistantLaboratoryAnimalTechnician',
        complete: 'Agricluture'
      },
      {id: 118, name: 'InventoryManagement', complete: 'Agricluture'},
      {id: 119, name: 'Photography', complete: 'Agricluture'},
      {
        id: 120,
        name: 'ElectricalDiagnosticsAndRepairs',
        complete: 'Agricluture'
      },
      {id: 121, name: 'Agricluture', complete: 'Agricluture'},
      {id: 122, name: 'HerbiceApplicatorLicense', complete: 'Agricluture'},
      {id: 123, name: 'SecurityPolicies', complete: 'Agricluture'},
      {id: 124, name: 'ProductionManagement', complete: 'Agricluture'},
      {id: 125, name: 'Anthropology', complete: 'Agricluture'},
      {id: 126, name: 'Agricluture', complete: 'Agricluture'},
      {id: 127, name: 'PressureWasher', complete: 'Agricluture'},
      {id: 128, name: 'Painting', complete: 'Agricluture'},
      {
        id: 129,
        name: 'LaboratoryAnimalTechnologist{LATG}',
        complete: 'Agricluture'
      },
      {id: 130, name: 'OperationsManagement', complete: 'Agricluture'},
      {id: 131, name: 'ElectronicEngineering', complete: 'Agricluture'},
      {id: 132, name: 'Sawing', complete: 'Agricluture'},
      {id: 133, name: 'Construction', complete: 'Agricluture'},
      {id: 134, name: 'ProsciADKARModel', complete: 'Agricluture'},
      {id: 135, name: 'Zoology', complete: 'Agricluture'},
      {id: 136, name: 'RegionalPlanning', complete: 'Agricluture'},
      {id: 137, name: 'EngineeringMathematics', complete: 'Agricluture'},
      {id: 138, name: 'Tillage', complete: 'Agricluture'},
      {id: 139, name: 'StockFarming', complete: 'Agricluture'},
      {id: 140, name: 'VerbalCommunicationSkills', complete: 'Agricluture'},
      {id: 141, name: 'MechanicalEngineering', complete: 'Agricluture'},
      {id: 142, name: 'Sociology', complete: 'Agricluture'},
      {id: 143, name: 'IncomeTax', complete: 'Agricluture'},
      {id: 144, name: 'PersonalProtectiveEquipment', complete: 'Agricluture'},
      {id: 145, name: 'Economics', complete: 'Agricluture'},
      {id: 146, name: 'SportsMedicine', complete: 'Agricluture'},
      {id: 147, name: 'Dozer', complete: 'Agricluture'},
      {id: 148, name: 'Merchandising', complete: 'Agricluture'},
      {id: 149, name: 'StandardOperatingProcedure', complete: 'Agricluture'},
      {id: 150, name: 'Genetics', complete: 'Agricluture'},
      {id: 151, name: 'PoliticalSciences', complete: 'Agricluture'},
      {id: 152, name: 'DirectDriveMechanism', complete: 'Agricluture'},
      {id: 153, name: 'Cooking', complete: 'Agricluture'},
      {id: 154, name: 'ApachePig', complete: 'Agricluture'},
      {id: 155, name: 'Spreadsheets', complete: 'Agricluture'},
      {id: 156, name: 'ComputerEngineering', complete: 'Agricluture'},
      {id: 157, name: 'HeavyEquipment', complete: 'Agricluture'},
      {id: 158, name: 'CiscoDiscoveryProtocol', complete: 'Agricluture'},
      {id: 159, name: 'AnimalResources', complete: 'Agricluture'},
      {id: 160, name: 'FoodSafety', complete: 'Agricluture'},
      {id: 161, name: 'DoctorOfPharmacy{PharmD}', complete: 'Agricluture'},
      {id: 162, name: 'Statistics', complete: 'Agricluture'},
      {id: 163, name: 'WorkingQuickly', complete: 'Agricluture'},
      {id: 164, name: 'ConflictResolution', complete: 'Agricluture'},
      {id: 165, name: 'StrategicPlanning', complete: 'Agricluture'},
      {id: 166, name: 'CreativeWriting', complete: 'Agricluture'},
      {id: 167, name: 'CivilEngineering', complete: 'Agricluture'},
      {id: 168, name: 'Groundskeeping', complete: 'Agricluture'},
      {id: 169, name: 'CaesareanSection', complete: 'Agricluture'},
      {id: 170, name: 'Accounting', complete: 'Agricluture'},
      {id: 171, name: 'Criminology', complete: 'Agricluture'},
      {id: 172, name: 'IndustrialRelations', complete: 'Agricluture'},
      {id: 173, name: 'ProjectCommissioning', complete: 'Agricluture'},
      {id: 174, name: 'Immunology', complete: 'Agricluture'},
      {id: 175, name: 'Procurement', complete: 'Agricluture'},
      {id: 176, name: 'CulturalStudies', complete: 'Agricluture'},
      {id: 177, name: 'Plumbing', complete: 'Agricluture'},
      {id: 178, name: 'HandSaw', complete: 'Agricluture'},
      {id: 179, name: 'ISO14000', complete: 'Agricluture'},
      {id: 180, name: 'FinancialManagement', complete: 'Agricluture'},
      {id: 181, name: 'DecisionTheories', complete: 'Agricluture'},
      {id: 182, name: 'BusinessAdministration', complete: 'Agricluture'},
      {id: 183, name: 'WorkOrder', complete: 'Agricluture'},
      {id: 184, name: 'TransferStation', complete: 'Agricluture'},

      {id: 185, name: 'QualityControl', complete: 'Agricluture'},
      {id: 186, name: 'DigitalRadiography', complete: 'Agricluture'},
      {id: 187, name: 'Soleno', complete: 'Agricluture'},
      {id: 188, name: 'ElectricUtility', complete: 'Agricluture'},
      {id: 189, name: 'Rabies', complete: 'Agricluture'},
      {id: 190, name: 'TeamBuilding', complete: 'Agricluture'},
      {id: 191, name: 'EducationalEvaluation', complete: 'Agricluture'},
      {id: 192, name: 'Paneling', complete: 'Agricluture'},
      {id: 193, name: 'FieldResearch', complete: 'Agricluture'},
      {id: 194, name: 'Anesthesias', complete: 'Agricluture'},
      {
        id: 195,
        name: 'KeyPerformanceIndicators{KPIs}',
        complete: 'Agricluture'
      },
      {id: 196, name: 'EngineeringGeology', complete: 'Agricluture'},
      {id: 197, name: 'Incubators', complete: 'Agricluture'},
      {
        id: 198,
        name: 'ProfessionalDevelopmentProgra',
        complete: 'Agricluture'
      },
      {id: 199, name: 'ISO9000', complete: 'Agricluture'},
      {id: 200, name: 'FacilityManagement', complete: 'Agricluture'},
      {id: 201, name: 'Aluminum', complete: 'Agricluture'},
      {id: 202, name: 'EntertainmentManagement', complete: 'Agricluture'},
      {id: 203, name: 'GardenTools', complete: 'Agricluture'},
      {id: 204, name: 'Morphology', complete: 'Agricluture'},
      {id: 205, name: 'Prioritization', complete: 'Agricluture'},
      {id: 206, name: 'EnvironmentalDesign', complete: 'Agricluture'},
      {id: 207, name: 'PowerToolOperation', complete: 'Agricluture'},
      {id: 208, name: 'SampleCollection', complete: 'Agricluture'},
      {id: 209, name: 'SafetyTraining', complete: 'Agricluture'},
      {id: 210, name: 'Funnels', complete: 'Agricluture'},
      {id: 211, name: 'PlannedMaintenance', complete: 'Agricluture'},
      {id: 212, name: 'PerformanceMonitor', complete: 'Agricluture'},
      {id: 213, name: 'PeopleManagement', complete: 'Agricluture'},
      {id: 214, name: 'GenderStudies', complete: 'Agricluture'},
      {id: 215, name: 'PerformanceAppraisal', complete: 'Agricluture'},
      {id: 216, name: 'EnvironmentalMonitoring', complete: 'Agricluture'},
      {
        id: 217,
        name: 'CorrectiveAndPreventiveAction{CAPA}',
        complete: 'Agricluture'
      },
      {id: 218, name: 'Geophysics', complete: 'Agricluture'},
      {id: 219, name: 'Asepsis', complete: 'Agricluture'},
      {id: 220, name: 'MedicalResearch', complete: 'Agricluture'},
      {id: 221, name: 'JPEG', complete: 'Agricluture'},
      {id: 222, name: 'AsepticTechniques', complete: 'Agricluture'},
      {id: 223, name: 'HealthPhysics', complete: 'Agricluture'},
      {id: 224, name: 'AsepticTechniques', complete: 'Agricluture'},
      {id: 225, name: 'CommunicationStudies', complete: 'Agricluture'},
      {id: 226, name: 'DataEntry', complete: 'Agricluture'},
      {id: 227, name: 'LegalResearch', complete: 'Agricluture'},
      {id: 228, name: 'CreditCounseling', complete: 'Agricluture'},
      {id: 229, name: 'InfectionControl', complete: 'Agricluture'},
      {id: 230, name: 'ProgramManagement', complete: 'Agricluture'},
      {id: 231, name: 'MagneticResonanceImaging', complete: 'Agricluture'},
      {id: 232, name: 'Counters{', complete: 'Agricluture'},
      {id: 233, name: 'DoctorOfVeterinaryMedicine{', complete: 'Agricluture'},
      {id: 234, name: 'LanguageTranslation', complete: 'Agricluture'},
      {id: 235, name: 'MarineEngineering', complete: 'Agricluture'},
      {id: 236, name: 'ComplianceTraining', complete: 'Agricluture'},

      {id: 237, name: 'PorcineAnimalCare', complete: 'Agricluture'},
      {id: 238, name: 'Annuities', complete: 'Agricluture'},
      {id: 239, name: 'MilitaryStudies', complete: 'Agricluture'},
      {
        id: 240,
        name: 'FederalEmployeesHealthBenefitsPrograms',
        complete: 'Agricluture'
      },
      {id: 241, name: 'Immunization', complete: 'Agricluture'},
      {id: 242, name: 'DiskCloning', complete: 'Agricluture'},
      {id: 243, name: 'MiningEngineering', complete: 'Agricluture'},
      {id: 244, name: 'Filtration', complete: 'Agricluture'},
      {id: 245, name: 'Autoclaves', complete: 'Agricluture'},
      {id: 246, name: 'Filing', complete: 'Agricluture'},
      {id: 247, name: 'NavalArchitecture', complete: 'Agricluture'},
      {id: 248, name: 'PreventiveMaintenance', complete: 'Agricluture'},
      {
        id: 249,
        name: 'GoodLaboratoryPractice{:GLP}',
        complete: 'Agricluture'
      },
      {id: 250, name: 'WaterResourceManagement', complete: 'Agricluture'},
      {id: 251, name: 'NuclearEngineering', complete: 'Agricluture'},
      {id: 252, name: 'Appeals', complete: 'Agricluture'},
      {id: 253, name: 'Respiration', complete: 'Agricluture'},
      {id: 254, name: 'Physics', complete: 'Agricluture'},
      {id: 255, name: 'LandscapeArchitecture', complete: 'Architecture'},
      {id: 256, name: 'NavalArchitecture', complete: 'Architecture'},
      {id: 257, name: 'EnvironmentalControl', complete: 'Architecture'},
      {id: 258, name: 'AdobePhotoshop', complete: 'Architecture'},
      {id: 259, name: 'FinancialAnalysis', complete: 'Architecture'},
      {id: 260, name: 'BuildingCodes', complete: 'Architecture'},
      {id: 261, name: 'LandscapeDesign', complete: 'Architecture'},
      {id: 262, name: 'MarineEngineering', complete: 'Architecture'},
      {id: 263, name: 'ArchitecturalEngineering', complete: 'Architecture'},
      {id: 264, name: 'AdobeIllustrator', complete: 'Architecture'},
      {id: 265, name: 'QualityAssurance', complete: 'Architecture'},
      {id: 266, name: 'LandZoning', complete: 'Architecture'},
      {id: 267, name: 'Landscaping', complete: 'Architecture'},
      {id: 268, name: 'MechanicalEngineering', complete: 'Architecture'},
      {id: 269, name: 'EthicalConduct', complete: 'Architecture'},
      {id: 270, name: 'AdobeInDesign', complete: 'Architecture'},
      {id: 271, name: 'Memos', complete: 'Architecture'},
      {id: 4220, name: 'ValueProposition', complete: 'Sales'},
      {id: 4221, name: 'SalesManagement', complete: 'Sales'},
      {id: 4222, name: 'Biotechnology', complete: 'Sales'},
      {id: 4223, name: 'FoodServices', complete: 'Sales'},
      {id: 4224, name: 'RequestForProposal', complete: 'Sales'},
      {id: 4225, name: 'ProductLining', complete: 'Sales'},
      {id: 4226, name: 'FoodPreparation', complete: 'Sales'},
      {id: 4227, name: 'CustomerSatisfaction', complete: 'Sales'},
      {id: 4228, name: 'SalesTraining', complete: 'Sales'},
      {id: 4229, name: 'StrategicAlignment', complete: 'Sales'},
      {id: 4230, name: 'CategoryManagement', complete: 'Sales'},
      {id: 4231, name: 'S', complete: 'Sales'},
      {id: 4232, complete: 'Sales'},
      {id: 4233, name: 'Oncology', complete: 'Sales'},
      {id: 4234, name: 'FastMovingConsumerGoods', complete: 'Sales'},
      {id: 4235, name: 'CustomerRelationshipManagement', complete: 'Sales'},
      {id: 4236, name: 'Mechanics', complete: 'Sales'},
      {id: 4237, name: 'ManagedCare', complete: 'Sales'},
      {id: 4238, name: 'Presales', complete: 'Sales'},
      {id: 4239, name: 'Budgeting', complete: 'Sales'},
      {id: 4240, name: 'DevelopmentPlanning', complete: 'Sales'},
      {id: 4241, name: 'OperatingTheater', complete: 'Sales'},
      {id: 4242, name: 'Disabilities', complete: 'Sales'},
      {id: 4243, name: 'Negotiation', complete: 'Sales'},
      {id: 4244, name: 'Hospitality', complete: 'Sales'},
      {id: 4245, name: 'Nursing', complete: 'Sales'},
      {id: 4246, complete: 'Sales'},
      {id: 4247, name: 'NeedsAnalysis', complete: 'Sales'},
      {id: 4248, name: 'ProductManagement', complete: 'Sales'},
      {id: 4249, name: 'RealEstate', complete: 'Sales'},
      {id: 4250, name: 'Biology', complete: 'Sales'},
      {id: 4251, name: 'FoodSafetyCertification', complete: 'Sales'},
      {
        id: 4252,
        name: 'MasterOfBusinessAdministration{:MBA}',
        complete: 'Sales'
      },
      {id: 4253, name: 'MerchantServices', complete: 'Sales'},
      {id: 4254, name: 'LifeSciences', complete: 'Sales'},
      {id: 4255, name: 'FoodSafety', complete: 'Sales'},
      {id: 4256, name: 'ProfessionalServices', complete: 'Sales'},
      {
        id: 4257,
        name: 'Autism{:Therapies}',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4258,
        name: 'ClinicalPsychology',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4259,
        name: 'LicensedClinicalSocialWorker{:LCSW}',
        complete: 'CounsellingandPsychology'
      },
      {id: 4260, name: 'Writing', complete: 'CounsellingandPsychology'},
      {
        id: 4261,
        name: 'TreatmentPlanning',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4262,
        name: 'BoardCertifiedBehaviorAnalyst',
        complete: 'CounsellingandPsychology'
      },
      {id: 4263, name: 'Psychology', complete: 'CounsellingandPsychology'},
      {
        id: 4264,
        name: 'LicensedProfessionalCounselor{:LPC}',
        complete: 'CounsellingandPsychology'
      },
      {id: 4265, name: 'Advocacy', complete: 'CounsellingandPsychology'},
      {
        id: 4266,
        name: 'CaseManagement',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4267,
        name: 'AppliedBehaviorAnalysis',
        complete: 'CounsellingandPsychology'
      },
      {id: 4268, name: 'Psychotherapy', complete: 'CounsellingandPsychology'},
      {
        id: 4269,
        name: 'LicensedMarriageAndFamilyTherapist{:LMFT}',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4270,
        name: 'MedicalRecords',
        complete: 'CounsellingandPsychology'
      },
      {id: 4271, name: 'FirstA', complete: 'CounsellingandPsychology'},
      {
        id: 4272,
        name: 'DevelopmentalDisabilities',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4273,
        name: 'HealthPsychology',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4274,
        name: 'LicensedMasterSocialWorker',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4275,
        name: 'DiagnosticAndStatisticalManualOfMentalDisorders',
        complete: 'CounsellingandPsychology'
      },
      {id: 4276, name: 'SocialWork', complete: 'CounsellingandPsychology'},
      {
        id: 4277,
        name: 'BehaviourTherapy',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4278,
        name: 'PsychologicalTesting',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4279,
        name: 'LicensedProfessionalClinicalCounselor',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4280,
        name: 'SoftwareDevelopmentLifeCycle',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4281,
        name: 'SubstanceAbuse',
        complete: 'CounsellingandPsychology'
      },
      {id: 4282, name: 'Teaching', complete: 'CounsellingandPsychology'},
      {
        id: 4283,
        name: 'ClinicalResearch',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4284,
        name: 'LicensedClinicalProfessionalCounselor',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4285,
        name: 'ProgramManagement',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4286,
        name: 'DischargePlanning',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4287,
        name: 'DataCollection',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4288,
        name: 'PsychologicalEvaluations',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4289,
        name: 'LicensedSocialWorker',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4290,
        name: 'PsychosocialAssessments',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4291,
        name: 'SubstanceDependence',
        complete: 'CounsellingandPsychology'
      },
      {id: 4292, name: 'SocialSkills', complete: 'CounsellingandPsychology'},
      {
        id: 4293,
        name: 'BehavioralMedicine',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4294,
        name: 'LicensedIndependentClinicalSocialWorker',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4295,
        name: 'MotivationalInterviewing',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4296,
        name: 'CardiopulmonaryResuscitation{:CPR}',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4297,
        name: 'RehabilitationCounseling',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4298,
        name: 'OfficerTraining',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4299,
        name: 'EmployeeAssistancePrograms',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4300,
        name: 'InterpersonalSkills',
        complete: 'CounsellingandPsychology'
      },
      {id: 4301, name: 'FamilyTherapy', complete: 'CounsellingandPsychology'},
      {
        id: 4302,
        name: 'Speech-LanguagePathology',
        complete: 'CounsellingandPsychology'
      },
      {id: 4303, name: 'PrimaryCare', complete: 'CounsellingandPsychology'},
      {
        id: 4304,
        name: 'HealthCareBenefits',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4305,
        name: 'HealthCareBenefits',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4306,
        name: 'CrisisIntervention',
        complete: 'CounsellingandPsychology'
      },
      {
        id: 4307,
        name: 'CertifiedRehabilitationCounselor',
        complete: 'CounsellingandPsychology'
      },
      {id: 4308, name: 'Geography', complete: 'SocialSciences'},
      {id: 4309, name: 'Economics', complete: 'SocialSciences'},
      {id: 4310, name: 'UrbanPlanning', complete: 'SocialSciences'},
      {
        id: 4311,
        name: 'GeographicInformationSystems',
        complete: 'SocialSciences'
      },
      {id: 4312, name: 'Psychology', complete: 'SocialSciences'},
      {id: 4313, name: 'Geology', complete: 'SocialSciences'},
      {id: 4314, name: 'Statistics', complete: 'SocialSciences'},
      {id: 4315, name: 'LandUse', complete: 'SocialSciences'},
      {id: 4316, name: 'TransportationPlanning', complete: 'SocialSciences'},
      {
        id: 4317,
        name: 'SPSS{:StatisticalSoftware}',
        complete: 'SocialSciences'
      },
      {id: 4318, name: 'ScientificWriting', complete: 'SocialSciences'},
      {id: 4319, name: 'Econometrics', complete: 'SocialSciences'},
      {
        id: 4320,
        name: 'AmericanInstituteOfCertifiedPlanners{:AICP}Certification',
        complete: 'SocialSciences'
      },
      {id: 4321, name: 'AdobeInDesign', complete: 'SocialSciences'},
      {id: 4322, name: 'QualitativeResearch', complete: 'SocialSciences'},
      {id: 4323, name: 'CulturalGeography', complete: 'SocialSciences'},
      {id: 4324, name: 'R{:ProgrammingLanguage}', complete: 'SocialSciences'},
      {id: 4325, name: 'CommunityDevelopment', complete: 'SocialSciences'},
      {id: 4326, name: 'ArcGIS{:GISSoftware}', complete: 'SocialSciences'},
      {id: 4327, name: 'SurveyResearch', complete: 'SocialSciences'},
      {id: 4328, name: 'AnalyticalTechniques', complete: 'SocialSciences'},
      {id: 4329, name: 'Stata', complete: 'SocialSciences'},
      {id: 4330, name: 'UrbanDesign', complete: 'SocialSciences'},
      {id: 4331, name: 'AdobeCreativeSuite', complete: 'SocialSciences'},
      {id: 4332, name: 'FocusGroup', complete: 'SocialSciences'},
      {id: 4333, name: 'Compositions', complete: 'SocialSciences'},
      {id: 4334, name: 'SAS{:Software}', complete: 'SocialSciences'},
      {id: 4335, name: 'ComprehensivePlanning', complete: 'SocialSciences'},
      {id: 4336, name: 'LandZoning', complete: 'SocialSciences'},
      {id: 4337, name: 'QuantitativeResearch', complete: 'SocialSciences'},
      {id: 4338, name: 'Surveying', complete: 'SocialSciences'},
      {
        id: 4339,
        name: 'Python{:ProgrammingLanguage}',
        complete: 'SocialSciences'
      },
      {id: 4340, name: 'RegionalPlanning', complete: 'SocialSciences'},
      {id: 4341, name: 'RegionalPlanning', complete: 'SocialSciences'},
      {id: 4342, name: 'DataCollection', complete: 'SocialSciences'},
      {id: 4343, name: 'AcademicAchievement', complete: 'SocialSciences'},
      {id: 4344, name: 'Forecasting', complete: 'SocialSciences'},
      {id: 4345, name: 'Land-UsePlanning', complete: 'SocialSciences'},
      {id: 4346, name: 'Construction', complete: 'SocialSciences'},
      {id: 4347, name: 'NewProductDevelopment', complete: 'SocialSciences'},
      {id: 4348, name: 'NewProductDevelopment', complete: 'SocialSciences'},
      {id: 4349, name: 'Calculus', complete: 'SocialSciences'},
      {id: 4350, name: 'AdobePhotoshop', complete: 'SocialSciences'},
      {id: 4351, name: 'Usability', complete: 'SocialSciences'},
      {id: 4352, name: 'EnvironmentalStudies', complete: 'SocialSciences'},
      {id: 4353, name: 'EconometricModeling', complete: 'SocialSciences'},
      {id: 4354, name: 'LandDevelopment', complete: 'SocialSciences'},
      {id: 4355, name: 'Mapping', complete: 'SocialSciences'},
      {
        id: 4356,
        name: 'Writing',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4357,
        name: 'MentalHealth',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4358,
        name: 'PsychosocialAssessments',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4359,
        name: 'FirstA',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4360,
        name: 'PublicAdministration',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4361,
        name: 'Psychiatry',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4362,
        name: 'LicensedClinicalSocialWorker{:LCSW}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4363,
        name: 'CardiopulmonaryResuscitation{:CPR}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4364,
        name: 'PublicHealth',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4365,
        name: 'SubstanceAbuse',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4366,
        name: 'DischargePlanning',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4367,
        name: 'Disabilities',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4368,
        name: 'StrategicPlanning',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4369,
        name: 'BehavioralHealth',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4370,
        name: 'Hospice',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4371,
        name: 'DevelopmentalDisabilities',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4372,
        name: 'Budgeting',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4373,
        name: 'LicensedMarriageAndFamilyTherapist{:LMFT}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4374,
        name: 'ManagedCare',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4375,
        name: 'Autism{:Therapies}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4376,
        name: 'HealthEducation',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4377,
        name: 'TreatmentPlanning',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4378,
        name: 'MedicalSocialWork',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4379,
        name: 'IntellectualDisabilities',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4380,
        name: 'BusinessAdministration',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4381,
        name: 'LicensedProfessionalCounselor{:LPC}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4382,
        name: 'LicensedMasterSocialWorker',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4383,
        name: 'BoardCertifiedBehaviorAnalyst',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4384,
        name: 'ProgramEvaluations',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4385,
        name: 'FamilyTherapy',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4386,
        name: 'UtilizationManagement',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4387,
        name: 'PersonalCare',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4388,
        name: 'Economics',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4389,
        name: 'Psychotherapy',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4390,
        name: 'Nursing',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4391,
        name: 'MedicationAdministration',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4392,
        name: 'CommunityDevelopment',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4393,
        name: 'MentalDiseases',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4394,
        name: 'Medicare',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4395,
        name: 'ActivitiesOfDailyLiving{:ADLs}',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4396,
        name: 'SoftwareDevelopmentLifeCycle',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4397,
        name: 'CrisisIntervention',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4398,
        name: 'LicensedSocialWorker',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4399,
        name: 'AppliedBehaviorAnalysis',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4400,
        name: 'Fundraising',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4401,
        name: 'CommunityMentalHealthServices',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4402,
        name: 'CarePlanning',
        complete: 'Social,CommunityandReligiousServices'
      },
      {
        id: 4403,
        name: 'VehicleMaintenance',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4404,
        name: 'TruckloadShipping',
        complete: 'TransportationandWarehousing'
      },
      {id: 4405, name: 'Writing', complete: 'TransportationandWarehousing'},
      {
        id: 4406,
        name: 'TransportationWorkerentificationCredential{:TWIC}Card',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4407,
        name: 'Transcribing',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4408,
        name: 'FlatbedTruck',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4409,
        name: 'WritingSystems',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4410,
        name: 'Purchasing',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4411,
        name: 'FifthWheelCoupling',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4412,
        name: 'GoodDrivingRecord',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4413,
        name: 'GoodDrivingRecord',
        complete: 'TransportationandWarehousing'
      },
      {id: 4414, name: 'Brokerage', complete: 'TransportationandWarehousing'},
      {id: 4415, name: 'Eddystone', complete: 'TransportationandWarehousing'},
      {id: 4416, name: 'Eddystone', complete: 'TransportationandWarehousing'},
      {
        id: 4417,
        name: 'FederalAviationAdministration',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4418,
        name: 'Furnishing',
        complete: 'TransportationandWarehousing'
      },
      {id: 4419, name: 'Loans', complete: 'TransportationandWarehousing'},
      {
        id: 4420,
        name: 'MaterialRequirementsPlanning',
        complete: 'TransportationandWarehousing'
      },
      {id: 4421, name: 'TankTruck', complete: 'TransportationandWarehousing'},
      {
        id: 4422,
        name: 'SupplyChainManagement',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4423,
        name: 'StartProcess',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4424,
        name: 'Forecasting',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4425,
        name: 'OccupationalInjury',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4426,
        name: 'ContinuousImprovementProcess',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4427,
        name: 'GPSNavigationDevices',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4428,
        name: 'KeyPerformanceIndicators{:KPIs}',
        complete: 'TransportationandWarehousing'
      },
      {id: 4429, name: 'UseTax', complete: 'TransportationandWarehousing'},
      {
        id: 4430,
        name: 'Procurement',
        complete: 'TransportationandWarehousing'
      },
      {id: 4431, name: 'Brakes', complete: 'TransportationandWarehousing'},
      {
        id: 4432,
        name: 'ReverseLogistics',
        complete: 'TransportationandWarehousing'
      },
      {id: 4433, name: 'Budgeting', complete: 'TransportationandWarehousing'},
      {
        id: 4434,
        name: 'FlightPlanning',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4435,
        name: 'TransportLayerSecurity',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4436,
        name: 'AirTrafficControl',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4437,
        name: 'CollisionAvoanceSystems',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4438,
        name: 'Accounting',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4439,
        name: 'TransportServices',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4440,
        name: 'Balancing{Ledger/Billing}',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4441,
        name: 'DocumentManagementSystems',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4442,
        name: 'EnterpriseResourcePlanning',
        complete: 'TransportationandWarehousing'
      },
      {
        id: 4443,
        name: 'WebBrowsers',
        complete: 'TransportationandWarehousing'
      },
      {id: 4444, name: 'FirstA', complete: 'SurveyingandMapping'},
      {
        id: 4445,
        name: 'Balancing{:Ledger/Billing}',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4446,
        name: 'EmployeePerformanceManagement',
        complete: 'SurveyingandMapping'
      },
      {id: 4447, name: 'Mapping', complete: 'SurveyingandMapping'},
      {id: 4448, name: 'Punctuality', complete: 'SurveyingandMapping'},
      {id: 4449, name: 'FieldSurveys', complete: 'SurveyingandMapping'},
      {id: 4450, name: 'VisualAcuity', complete: 'SurveyingandMapping'},
      {
        id: 4451,
        name: 'DimensionalModeling',
        complete: 'SurveyingandMapping'
      },
      {id: 4452, name: 'Oceanography', complete: 'SurveyingandMapping'},
      {id: 4453, name: 'TrafficControl', complete: 'SurveyingandMapping'},
      {id: 4454, name: 'Levelling', complete: 'SurveyingandMapping'},
      {id: 4455, name: 'GeophysicalSurvey', complete: 'SurveyingandMapping'},
      {
        id: 4456,
        name: 'CommercialConstruction',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4457,
        name: 'StereophonicSoundSystem',
        complete: 'SurveyingandMapping'
      },
      {id: 4458, name: 'Arithmetic', complete: 'SurveyingandMapping'},
      {id: 4459, name: 'Calibration', complete: 'SurveyingandMapping'},
      {
        id: 4460,
        name: 'CollectiveBargaining',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4461,
        name: 'LightDetectionAndRanging',
        complete: 'SurveyingandMapping'
      },
      {id: 4462, name: 'CoordinateGeometry', complete: 'SurveyingandMapping'},
      {id: 4463, name: 'SanitarySewer', complete: 'SurveyingandMapping'},
      {id: 4464, name: 'Troubleshooting', complete: 'SurveyingandMapping'},
      {id: 4465, name: 'PropertyRights', complete: 'SurveyingandMapping'},
      {id: 4466, name: 'GeospatialAnalysis', complete: 'SurveyingandMapping'},
      {
        id: 4467,
        name: 'InterpersonalSkills',
        complete: 'SurveyingandMapping'
      },
      {id: 4468, name: 'Instrumentation', complete: 'SurveyingandMapping'},
      {
        id: 4469,
        name: 'LandscapeArchitecture',
        complete: 'SurveyingandMapping'
      },
      {id: 4470, name: 'LarData', complete: 'SurveyingandMapping'},
      {id: 4471, name: 'Geophysics', complete: 'SurveyingandMapping'},
      {
        id: 4472,
        name: 'BusinessMathematics',
        complete: 'SurveyingandMapping'
      },
      {id: 4473, name: 'TechnicalStandard', complete: 'SurveyingandMapping'},
      {id: 4474, name: 'Stormwater', complete: 'SurveyingandMapping'},
      {id: 4475, name: 'ApacheOpenOffice', complete: 'SurveyingandMapping'},
      {
        id: 4476,
        name: 'PerformanceAppraisal',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4477,
        name: 'ComplexProblemSolving',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4478,
        name: 'ElectronicDataProcessing',
        complete: 'SurveyingandMapping'
      },
      {
        id: 4479,
        name: 'Value-AddedServices',
        complete: 'SurveyingandMapping'
      },
      {id: 4480, name: 'ListeningSkills', complete: 'SurveyingandMapping'},
      {id: 4481, name: 'Geography', complete: 'SurveyingandMapping'},
      {id: 4482, name: 'Theodolite', complete: 'SurveyingandMapping'},
      {
        id: 4483,
        name: 'ControlNetworksPublicUtilityz',
        complete: 'SurveyingandMapping'
      },
      {id: 4484, name: 'PublicUtility', complete: 'SurveyingandMapping'},
      {id: 4485, name: 'AutodeskRevit', complete: 'SurveyingandMapping'},
      {id: 4486, name: 'Digitization', complete: 'SurveyingandMapping'},
      {id: 4487, name: 'ErrorAnalysis', complete: 'SurveyingandMapping'},
      {id: 4488, name: 'FloodControls', complete: 'SurveyingandMapping'},
      {id: 4489, name: 'FloodControls', complete: 'SurveyingandMapping'},
      {
        id: 4490,
        name: 'AdobeCreativeSuite',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4491,
        name: 'Cleanliness',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4492,
        name: 'HyperTextMarkupLanguage',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4493,
        name: 'DigitalAssetManagement',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4494,
        name: 'DigitalSignage',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4495,
        name: 'Microwaves',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4496,
        name: 'AdobeAfterEffects',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4497,
        name: 'PortraitPhotography',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4498,
        name: 'AdobeFlash',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4499,
        name: 'SoundSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4500,
        name: 'UltraHighFrequency',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4501,
        name: 'SocialMedia',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4502,
        name: 'PhotoEditing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4503,
        name: 'StudioRecording',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4504,
        name: 'MacOS',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4505,
        name: 'ProjectionScreen',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4506,
        name: 'RadioEquipment',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4507,
        name: 'GraphicDesign',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4508,
        name: 'DigitalSingle-LensReflexCameras',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4509,
        name: 'ProductionSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4510,
        name: 'Metadata',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4511,
        name: 'Category5Cabling',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4512,
        name: 'SecurityDevices',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4513,
        name: 'Multimedia',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4514,
        name: 'RealEstate',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4515,
        name: 'DevelopmentEnvironment',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4516,
        name: 'ColorManagement',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4517,
        name: 'Linux',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4518,
        name: 'Writing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4519,
        name: 'EmailMarketing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4520,
        name: 'StandardOperatingProcedure',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4521,
        name: 'ArtDirection',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4522,
        name: 'NetworkSwitches',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4523,
        name: 'Transmission',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4524,
        name: 'VeoEditingSoftware',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4525,
        name: 'PhotographicAssistant',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4526,
        name: 'ProductionSupport',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4527,
        name: 'Brochures',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4528,
        name: 'AudioVeoInterleave',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4529,
        name: 'TestEquipment',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4530,
        name: 'Auditions',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4531,
        name: 'Prioritization',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4532,
        name: 'PerformanceManagement',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4533,
        name: 'SoundReinforcementSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4534,
        name: 'ElectricalEngineering',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4535,
        name: 'DocumentaryFilms',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4536,
        name: 'AudioEditingSoftware',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 4537,
        name: 'Programming:Music}',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4538,
        name: 'Photojournalism',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4539,
        name: 'Budgeting',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4540,
        name: 'SafetyStandards',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4541,
        name: 'AdobeCreativeCloud',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4542,
        name: 'MusicProduction',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 4543, name: 'Edius', complete: 'EntertainmentandPerformingArts'},
      {
        id: 4544,
        name: 'Multimedia',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4545,
        name: 'PersonalComputers',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4546,
        name: 'DigitalMarketing',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4547,
        name: 'Alterations',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4548,
        name: 'WordPress',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4549,
        name: 'ProductionProcess',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 4550, name: 'Staging', complete: 'EntertainmentandPerformingArts'},
      {
        id: 4551,
        name: 'AdobePhotoshopLightroom',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4552,
        name: 'Compositions',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4553,
        name: 'CopyEditing',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4554,
        name: 'Scripting',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4555,
        name: 'Vectorworks',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4556,
        name: 'Auditions',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4557,
        name: 'Teaching',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4558,
        name: 'GoogleAnalytics',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4559,
        name: 'MarketingCommunications',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 4560, name: 'Maya', complete: 'EntertainmentandPerformingArts'},
      {
        id: 4561,
        name: 'Illustration',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4562,
        name: 'Textiles',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4563,
        name: 'UserGeneratedContent',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4564,
        name: 'DigitalAssetManagement',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4565,
        name: 'PowerToolOperation',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4566,
        name: 'PhotoManipulation',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 4567, name: 'Pattern', complete: 'EntertainmentandPerformingArts'},
      {
        id: 4568,
        name: 'OnlineProducer',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4569,
        name: 'Compositing',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4570,
        name: 'CustomerExperience',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4571,
        name: 'Merchandising',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4572,
        name: 'ImageStitching',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4573,
        name: 'SocialNetworks',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4574,
        name: 'ContentStrategy',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4575,
        name: 'Blockchain',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4576,
        name: 'InstrumentalMusic',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4577,
        name: 'FileTransferProtocol',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 4578, name: 'Tagging', complete: 'EntertainmentandPerformingArts'},
      {
        id: 4579,
        name: 'Mechanics',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4580,
        name: 'BrandManagement',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4581,
        name: 'LeadershipDevelopment',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4582,
        name: 'PhotoEditing',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4583,
        name: 'Negotiation',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4584,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 4585,
        name: 'ArabicLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4586,
        name: 'ApplicationProgrammingInterface',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4587,
        name: 'AdobePhotoshop',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4588,
        name: 'OfficeManagement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4589,
        name: 'Branding',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4590,
        name: 'SignLanguages',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4591,
        name: 'SoftwareEngineering',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4592,
        name: 'SocialMediaMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4593,
        name: 'MedicalSales',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4594,
        name: 'AdobeCreativeSuite',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4595,
        name: 'Vocabulary',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4596,
        name: 'DarwinInformationTypingArchitecture',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4597,
        name: 'HyperTextMarkupLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4598,
        name: 'PharmaceuticalSales',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4599,
        name: 'CorporateCommunications',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4600,
        name: 'MedicalProcedure',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4601,
        name: 'AtlassianConfluence',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4602,
        name: 'AppleIOS',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4603,
        name: 'AcademicAchievement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4604,
        name: 'StrategicPlanning',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4605,
        name: 'HigherEducation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {id: 4606, name: 'Java', complete: 'Journalism,WritingandCommunicatin'},
      {
        id: 4607,
        name: 'Andro',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4608,
        name: 'Entrepreneurship',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4609,
        name: 'CustomerRelationshipManagement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4610,
        name: 'FrenchLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {id: 4611, name: 'JIRA', complete: 'Journalism,WritingandCommunicatin'},
      {
        id: 4612,
        name: 'Blogs',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4613,
        name: 'Mathematics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4614,
        name: 'MarketingManagement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4615,
        name: 'PashtoLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4616,
        name: 'MedicalWriting',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4617,
        name: 'DataScience',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4618,
        name: 'DataScience',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4619,
        name: 'AdobeIllustrator',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4620,
        name: 'JapaneseLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4621,
        name: 'ReleaseNotes',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4622,
        name: 'Copywriting',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4623,
        name: 'FoodServices',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4624,
        name: 'CommunicationStrategies',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4625,
        name: 'SourceLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 4626,
        name: 'Usability',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {id: 4627, complete: 'Journalism,WritingandCommunicatin'},
      {id: 4628, name: 'AuditPlanning', complete: 'Acconting/Finance'},
      {
        id: 4629,
        name: 'CommunicationSoftwares',
        complete: 'Acconting/Finance'
      },
      {id: 4630, name: 'Receivables', complete: 'Acconting/Finance'},
      {id: 4631, name: 'BankSecrecyAct', complete: 'Acconting/Finance'},
      {id: 4632, name: 'Mathematics', complete: 'Acconting/Finance'},
      {id: 4633, name: 'FinancialAnalysis', complete: 'Acconting/Finance'},
      {
        id: 4634,
        name: 'CertifiedInformationSystemsSecurityProfessional',
        complete: 'Acconting/Finance'
      },
      {id: 4635, name: 'CorporateTax', complete: 'Acconting/Finance'},
      {id: 4636, name: 'ClericalWorks', complete: 'Acconting/Finance'},
      {id: 4637, name: 'CallCenters', complete: 'Acconting/Finance'},
      {id: 4638, name: 'CommercialLaws', complete: 'Acconting/Finance'},
      {id: 4639, name: 'Depreciation', complete: 'Acconting/Finance'},
      {
        id: 4640,
        name: 'CertifiedFraudExaminer',
        complete: 'Acconting/Finance'
      },
      {id: 4641, name: 'Troubleshooting', complete: 'Acconting/Finance'},
      {id: 4642, name: 'QuickBooks', complete: 'Acconting/Finance'},
      {id: 4643, name: 'ValueProposition', complete: 'Acconting/Finance'},
      {id: 4644, name: 'Automation', complete: 'Acconting/Finance'},
      {id: 4645, name: 'Reconciliation', complete: 'Acconting/Finance'},
      {id: 4646, name: 'Governance', complete: 'Acconting/Finance'},
      {id: 4647, name: 'TaxConsulting', complete: 'Acconting/Finance'},
      {
        id: 4648,
        name: 'MedicalBillingAndCoding',
        complete: 'Acconting/Finance'
      },
      {id: 4649, name: 'CreditReports', complete: 'Acconting/Finance'},
      {id: 4650, name: 'SQL', complete: 'Acconting/Finance'},
      {id: 4651, name: 'Accruals', complete: 'Acconting/Finance'},
      {id: 4652, name: 'ProjectManagement', complete: 'Acconting/Finance'},
      {
        id: 4653,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {
        id: 4654,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {id: 4655, name: 'Filing', complete: 'Acconting/Finance'},
      {id: 4656, name: 'Equities', complete: 'Acconting/Finance'},
      {id: 4657, name: 'Procurement', complete: 'Acconting/Finance'},
      {id: 4658, name: 'IncomeStatement', complete: 'Acconting/Finance'},
      {
        id: 4659,
        name: 'InstituteOfInternalAuditors',
        complete: 'Acconting/Finance'
      },
      {id: 4660, name: 'SpanishLanguage', complete: 'Acconting/Finance'},
      {id: 4661, name: 'Ledgers', complete: 'Acconting/Finance'},
      {id: 4662, name: 'CreditAnalysis', complete: 'Acconting/Finance'},
      {id: 4663, name: 'Purchasing', complete: 'Acconting/Finance'},
      {id: 4664, name: 'ExternalAuditor', complete: 'Acconting/Finance'},
      {id: 4665, name: 'FinancialAuditing', complete: 'Acconting/Finance'},
      {
        id: 4666,
        name: 'InternationalTaxation',
        complete: 'Acconting/Finance'
      },
      {id: 4667, name: 'Voucher', complete: 'Acconting/Finance'},
      {id: 4668, name: 'PortfolioManagement', complete: 'Acconting/Finance'},
      {id: 4669, name: 'Statistics', complete: 'Acconting/Finance'},
      {id: 4670, name: 'CostManagement', complete: 'Acconting/Finance'},
      {
        id: 4671,
        name: 'ControlObjectivesForInformationAndRelatedTechnology',
        complete: 'Acconting/Finance'
      },
      {id: 4672, name: 'CriticalThinking', complete: 'Acconting/Finance'},
      {id: 4673, name: 'Spreadsheets', complete: 'Acconting/Finance'},
      {id: 4674, name: 'LoanServicing', complete: 'Acconting/Finance'},
      {id: 4675, name: 'Writing', complete: 'Acconting/Finance'},
      {id: 4676, name: 'Consoation', complete: 'Acconting/Finance'},
      {
        id: 4677,
        name: 'ManagementInformationSystems',
        complete: 'Acconting/Finance'
      },
      {
        id: 4678,
        name: 'AgileSoftwareDevelopment',
        complete: 'Acconting/Finance'
      },
      {id: 4679, name: 'AuditPlanning', complete: 'Acconting/Finance'},
      {
        id: 4680,
        name: 'CommunicationSoftwares',
        complete: 'Acconting/Finance'
      },
      {id: 4681, name: 'Receivables', complete: 'Acconting/Finance'},
      {id: 4682, name: 'BankSecrecyAct', complete: 'Acconting/Finance'},
      {id: 4683, name: 'Mathematics', complete: 'Acconting/Finance'},
      {id: 4684, name: 'FinancialAnalysis', complete: 'Acconting/Finance'},
      {
        id: 4685,
        name: 'CertifiedInformationSystemsSecurityProfessional',
        complete: 'Acconting/Finance'
      },
      {id: 4686, name: 'CorporateTax', complete: 'Acconting/Finance'},
      {id: 4687, name: 'ClericalWorks', complete: 'Acconting/Finance'},
      {id: 4688, name: 'CallCenters', complete: 'Acconting/Finance'},
      {id: 4689, name: 'CommercialLaws', complete: 'Acconting/Finance'},
      {id: 4690, name: 'Depreciation', complete: 'Acconting/Finance'},
      {
        id: 4691,
        name: 'CertifiedFraudExaminer',
        complete: 'Acconting/Finance'
      },
      {id: 4692, name: 'Troubleshooting', complete: 'Acconting/Finance'},
      {id: 4693, name: 'QuickBooks', complete: 'Acconting/Finance'},
      {id: 4694, name: 'ValueProposition', complete: 'Acconting/Finance'},
      {id: 4695, name: 'Automation', complete: 'Acconting/Finance'},
      {id: 4696, name: 'Reconciliation', complete: 'Acconting/Finance'},
      {id: 4697, name: 'Governance', complete: 'Acconting/Finance'},
      {id: 4698, name: 'TaxConsulting', complete: 'Acconting/Finance'},
      {
        id: 4699,
        name: 'MedicalBillingAndCoding',
        complete: 'Acconting/Finance'
      },
      {id: 4700, name: 'CreditReports', complete: 'Acconting/Finance'},
      {id: 4701, name: 'SQL', complete: 'Acconting/Finance'},
      {id: 4702, name: 'Accruals', complete: 'Acconting/Finance'},
      {id: 4703, name: 'ProjectManagement', complete: 'Acconting/Finance'},
      {
        id: 4704,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {
        id: 4705,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {id: 4706, name: 'Filing', complete: 'Acconting/Finance'},
      {id: 4707, name: 'Equities', complete: 'Acconting/Finance'},
      {id: 4708, name: 'Procurement', complete: 'Acconting/Finance'},
      {id: 4709, name: 'IncomeStatement', complete: 'Acconting/Finance'},
      {
        id: 4710,
        name: 'InstituteOfInternalAuditors',
        complete: 'Acconting/Finance'
      },
      {id: 4711, name: 'SpanishLanguage', complete: 'Acconting/Finance'},
      {id: 4712, name: 'Ledgers', complete: 'Acconting/Finance'},
      {id: 4713, name: 'CreditAnalysis', complete: 'Acconting/Finance'},
      {id: 4714, name: 'Purchasing', complete: 'Acconting/Finance'},
      {id: 4715, name: 'ExternalAuditor', complete: 'Acconting/Finance'},
      {id: 4716, name: 'FinancialAuditing', complete: 'Acconting/Finance'},
      {
        id: 4717,
        name: 'InternationalTaxation',
        complete: 'Acconting/Finance'
      },
      {id: 4718, name: 'Voucher', complete: 'Acconting/Finance'},
      {id: 4719, name: 'PortfolioManagement', complete: 'Acconting/Finance'},
      {id: 4720, name: 'Statistics', complete: 'Acconting/Finance'},
      {id: 4721, name: 'CostManagement', complete: 'Acconting/Finance'},
      {
        id: 4722,
        name: 'ControlObjectivesForInformationAndRelatedTechnology',
        complete: 'Acconting/Finance'
      },
      {id: 4723, name: 'CriticalThinking', complete: 'Acconting/Finance'},
      {id: 4724, name: 'Spreadsheets', complete: 'Acconting/Finance'},
      {id: 4725, name: 'LoanServicing', complete: 'Acconting/Finance'},
      {id: 4726, name: 'Writing', complete: 'Acconting/Finance'},
      {id: 4727, name: 'Consolation', complete: 'Acconting/Finance'},
      {
        id: 4728,
        name: 'ManagementInformationSystems',
        complete: 'Acconting/Finance'
      },
      {
        id: 4729,
        name: 'AgileSoftwareDevelopment',
        complete: 'Acconting/Finance'
      },
      {id: 4730, name: 'AuditPlanning', complete: 'Acconting/Finance'},
      {
        id: 4731,
        name: 'CommunicationSoftwares',
        complete: 'Acconting/Finance'
      },
      {id: 4732, name: 'Receivables', complete: 'Acconting/Finance'},
      {id: 4733, name: 'BankSecrecyAct', complete: 'Acconting/Finance'},
      {id: 4734, name: 'Mathematics', complete: 'Acconting/Finance'},
      {id: 4735, name: 'FinancialAnalysis', complete: 'Acconting/Finance'},
      {
        id: 4736,
        name: 'CertifiedInformationSystemsSecurityProfessional',
        complete: 'Acconting/Finance'
      },
      {id: 4737, name: 'CorporateTax', complete: 'Acconting/Finance'},
      {id: 4738, name: 'ClericalWorks', complete: 'Acconting/Finance'},
      {id: 4739, name: 'CallCenters', complete: 'Acconting/Finance'},
      {id: 4740, name: 'CommercialLaws', complete: 'Acconting/Finance'},
      {id: 4741, name: 'Depreciation', complete: 'Acconting/Finance'},
      {
        id: 4742,
        name: 'CertifiedFraudExaminer',
        complete: 'Acconting/Finance'
      },
      {id: 4743, name: 'Troubleshooting', complete: 'Acconting/Finance'},
      {id: 4744, name: 'QuickBooks}', complete: 'Acconting/Finance'},
      {id: 4745, name: 'ValueProposition', complete: 'Acconting/Finance'},
      {id: 4746, name: 'Automation', complete: 'Acconting/Finance'},
      {id: 4747, name: 'Reconciliation', complete: 'Acconting/Finance'},
      {id: 4748, name: 'Governance', complete: 'Acconting/Finance'},
      {id: 4749, name: 'TaxConsulting', complete: 'Acconting/Finance'},
      {
        id: 4750,
        name: 'MedicalBillingAndCoding',
        complete: 'Acconting/Finance'
      },
      {id: 4751, name: 'CreditReports', complete: 'Acconting/Finance'},
      {id: 4752, name: 'SQL', complete: 'Acconting/Finance'},
      {id: 4753, name: 'Accruals', complete: 'Acconting/Finance'},
      {id: 4754, name: 'ProjectManagement', complete: 'Acconting/Finance'},
      {
        id: 4755,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {
        id: 4756,
        name: 'TaxComplianceSoftware',
        complete: 'Acconting/Finance'
      },
      {id: 4757, name: 'Filing', complete: 'Acconting/Finance'},
      {id: 4758, name: 'Equities', complete: 'Acconting/Finance'},
      {id: 4759, name: 'Procurement', complete: 'Acconting/Finance'},
      {id: 4760, name: 'IncomeStatement', complete: 'Acconting/Finance'},
      {
        id: 4761,
        name: 'InstituteOfInternalAuditors',
        complete: 'Acconting/Finance'
      },
      {id: 4762, name: 'SpanishLanguage', complete: 'Acconting/Finance'},
      {id: 4763, name: 'Ledgers', complete: 'Acconting/Finance'},
      {id: 4764, name: 'CreditAnalysis', complete: 'Acconting/Finance'},
      {id: 4765, name: 'Purchasing', complete: 'Acconting/Finance'},
      {id: 4766, name: 'ExternalAuditor', complete: 'Acconting/Finance'},
      {id: 4767, name: 'FinancialAuditing', complete: 'Acconting/Finance'},
      {
        id: 4768,
        name: 'InternationalTaxation',
        complete: 'Acconting/Finance'
      },
      {id: 4769, name: 'Voucher', complete: 'Acconting/Finance'},
      {id: 4770, name: 'PortfolioManagement', complete: 'Acconting/Finance'},
      {id: 4771, name: 'Statistics', complete: 'Acconting/Finance'},
      {id: 4772, name: 'CostManagement', complete: 'Acconting/Finance'},
      {
        id: 4773,
        name: 'ControlObjectivesForInformationAndRelatedTechnology',
        complete: 'Acconting/Finance'
      },
      {id: 4774, name: 'CriticalThinking', complete: 'Acconting/Finance'},
      {id: 4775, name: 'Spreadsheets', complete: 'Acconting/Finance'},
      {id: 4776, name: 'LoanServicing', complete: 'Acconting/Finance'},
      {id: 4777, name: 'Writing', complete: 'Acconting/Finance'},
      {id: 4778, name: 'Consoation', complete: 'Acconting/Finance'},
      {
        id: 4779,
        name: 'ManagementInformationSystems',
        complete: 'Acconting/Finance'
      },
      {
        id: 4780,
        name: 'AgileSoftwareDevelopment',
        complete: 'Acconting/Finance'
      },
      {
        id: 4781,
        name: 'GeneralLedger',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4782,
        name: 'Underwriting',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4783,
        name: 'RequirementsElicitation',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4784,
        name: 'ProjectManagementProfessionalCertification',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4785,
        name: 'ContractManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4786,
        name: 'BusinessIntelligence',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4787,
        name: 'VarianceAnalysis',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4788, name: 'Equities', complete: 'BusinessAnalysis/Opreation'},
      {id: 4789, name: 'UserStory', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4790,
        name: 'ContinuousImprovementProcess',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4791, name: 'ManagedCare', complete: 'BusinessAnalysis/Opreation'},
      {id: 4792, name: 'DataScience', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4793,
        name: 'InternalControls',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4794, name: 'FederalLaws', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4795,
        name: 'FunctionalRequirement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4796,
        name: 'CustomerRelationshipManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4797,
        name: 'HealthcareEffectivenessDataAndInformationSet',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4798, name: 'Dashboard', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4799,
        name: 'AccountsPayable',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4800, name: 'MarketRisk', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4801,
        name: 'ExtractTransformLoad',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4802,
        name: 'OrganizationDevelopment',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4803,
        name: 'InventoryManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4804,
        name: 'ApacheHadoop',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4805,
        name: 'MasterOfBusinessAdministration',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4806,
        name: 'MarketLiquity',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4807,
        name: 'TestPlanning',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4808, name: 'Writing', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4809,
        name: 'StatisticalModeling',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4810,
        name: 'FinancialModeling',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4811, name: 'Derivatives', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4812,
        name: 'DataManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4813,
        name: 'ManagementConsulting',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4814,
        name: 'PerformanceImprovement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4815,
        name: 'BalanceSheet',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4816,
        name: 'InvestmentManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4817,
        name: 'DataGovernance',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4818, name: 'Mitigation', complete: 'BusinessAnalysis/Opreation'},
      {id: 4819, name: 'Biology', complete: 'BusinessAnalysis/Opreation'},
      {id: 4820, name: 'DataMining', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4821,
        name: 'FinancialData',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4822,
        name: 'OperationalRiskManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4823, name: 'DataQuality', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4824,
        name: 'Construction',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4825, name: 'TeradataSQL', complete: 'BusinessAnalysis/Opreation'},
      {id: 4826, name: 'Auditing', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 4827,
        name: 'MortgageLoans',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 4828,
        name: 'DataWarehousing',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 4829, name: 'ComputerSciences', complete: 'Management'},
      {id: 4830, name: 'FoodSafety', complete: 'Management'},
      {id: 4831, name: 'InternalControls', complete: 'Management'},
      {id: 4832, name: 'LeanSixSigma', complete: 'Management'},
      {id: 4833, name: 'RealEstate', complete: 'Management'},
      {id: 4834, name: 'PMICertified', complete: 'Management'},
      {id: 4835, name: 'ProjectManagementLifeCycle', complete: 'Management'},
      {id: 4836, name: 'Medica', complete: 'Management'},
      {id: 4837, name: 'DrugDevelopment', complete: 'Management'},
      {id: 4838, name: 'Subcontracting', complete: 'Management'},
      {id: 4839, name: 'BusinessRequirements', complete: 'Management'},
      {id: 4840, name: 'LabourLaws', complete: 'Management'},
      {id: 4841, name: 'WorkingCapital', complete: 'Management'},
      {id: 4842, name: 'ClinicalTrials', complete: 'Management'},
      {id: 4843, name: 'Renovation', complete: 'Management'},
      {id: 4844, name: 'SoftwareDevelopment', complete: 'Management'},
      {id: 4845, name: 'InventoryManagement', complete: 'Management'},
      {
        id: 4846,
        name: 'CardiopulmonaryResuscitation',
        complete: 'Management'
      },
      {id: 4847, name: 'CostReduction', complete: 'Management'},
      {id: 4848, name: 'PropertyManagement', complete: 'Management'},
      {id: 4849, name: 'Mitigation', complete: 'Management'},
      {id: 4850, complete: 'Management'},
      {id: 4851, name: 'CustomerExperience', complete: 'Management'},
      {id: 4852, name: 'PublicPolicies', complete: 'Management'},
      {id: 4853, name: 'Purchasing', complete: 'Management'},
      {id: 4854, name: 'Submittals', complete: 'Management'},
      {id: 4855, name: 'BusinessProcess', complete: 'Management'},
      {id: 4856, name: 'SellingTechniques', complete: 'Management'},
      {id: 4857, name: 'WiringDiagram', complete: 'Canstruction'},
      {id: 4858, name: 'MicrosoftProject', complete: 'Canstruction'},
      {id: 4859, name: 'HeavyEquipment', complete: 'Canstruction'},
      {id: 4860, name: 'DueDiligence', complete: 'Canstruction'},
      {id: 4861, name: 'PlasticMaterials', complete: 'Canstruction'},
      {id: 4862, name: 'ElectricalCodes', complete: 'Canstruction'},
      {id: 4863, name: 'Purchasing', complete: 'Canstruction'},
      {id: 4864, name: 'FirstA', complete: 'Canstruction'},
      {id: 4865, name: 'Mitigation', complete: 'Canstruction'},
      {id: 4866, name: 'Laser', complete: 'Canstruction'},
      {id: 4867, name: 'TestEquipment', complete: 'Canstruction'},
      {id: 4868, name: 'Constructability', complete: 'Canstruction'},
      {id: 4869, name: 'Welding', complete: 'Canstruction'},
      {id: 4870, name: 'RiskAnalysis', complete: 'Canstruction'},
      {id: 4871, name: 'CompositeMaterials', complete: 'Canstruction'},
      {
        id: 4872,
        name: 'ProgrammableLogicControllers',
        complete: 'Canstruction'
      },
      {id: 4873, name: 'ProjectPlanning', complete: 'Canstruction'},
      {id: 4874, name: 'Brazing', complete: 'Canstruction'},
      {id: 4875, name: 'BusinessEthics', complete: 'Canstruction'},
      {id: 4876, name: 'WoodworkingMachines', complete: 'Canstruction'},
      {id: 4877, name: 'Voltmeter', complete: 'Canstruction'},
      {id: 4878, name: 'Negotiation', complete: 'Canstruction'},
      {id: 4879, name: 'Surveys', complete: 'Canstruction'},
      {id: 4880, name: 'Tiling', complete: 'Canstruction'},
      {id: 4881, name: 'Voltage', complete: 'Canstruction'},
      {id: 4882, name: 'Primavera', complete: 'Canstruction'},
      {id: 4883, name: 'TrafficControl', complete: 'Canstruction'},
      {id: 4884, name: 'Ordinances', complete: 'Canstruction'},
      {id: 4885, name: 'Flooring', complete: 'Canstruction'},
      {id: 4886, name: 'ControlSystems', complete: 'Canstruction'},
      {id: 4887, name: 'Forecasting', complete: 'Canstruction'},
      {
        id: 4888,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'Canstruction'
      },
      {id: 4889, name: 'ArchitecturalEngineering', complete: 'Canstruction'},
      {id: 4890, name: 'RoofingMaterials', complete: 'Canstruction'},
      {id: 4891, name: 'Relays', complete: 'Canstruction'},
      {id: 4892, name: 'Bding', complete: 'Canstruction'},
      {id: 4893, name: 'Coupling', complete: 'Canstruction'},
      {id: 4894, name: 'InterpersonalSkills', complete: 'Canstruction'},
      {id: 4895, name: 'Masonry', complete: 'Canstruction'},
      {id: 4896, name: 'Hydraulics', complete: 'Canstruction'},
      {id: 4897, name: 'CommercialConstruction', complete: 'Canstruction'},
      {id: 4898, name: 'Cutters', complete: 'Canstruction'},
      {id: 4899, name: 'Smoothing', complete: 'Canstruction'},
      {id: 4900, name: 'NationalElectricalCodes', complete: 'Canstruction'},
      {id: 4901, name: 'Accounting', complete: 'Canstruction'},
      {id: 4902, name: 'Boilers', complete: 'Canstruction'},
      {id: 4903, name: 'Writing', complete: 'Canstruction'},
      {id: 4904, name: 'Millwork', complete: 'Canstruction'},
      {id: 4905, name: 'Theology', complete: 'Education'},
      {id: 4906, name: 'Editing', complete: 'Education'},
      {id: 4907, complete: 'Education'},
      {id: 4908, name: 'SocialSkills', complete: 'Education'},
      {id: 4909, name: 'Geography', complete: 'Education'},
      {id: 4910, name: 'ProgressReporting', complete: 'Education'},
      {id: 4911, name: 'TrainingAndDevelopment', complete: 'Education'},
      {id: 4912, name: 'SocialChange', complete: 'Education'},
      {id: 4913, name: 'Nursing', complete: 'Education'},
      {id: 4914, name: 'Moodle', complete: 'Education'},
      {id: 4915, name: 'Sanitation', complete: 'Education'},
      {id: 4916, name: 'Photography', complete: 'Education'},
      {id: 4917, name: 'Lecturing', complete: 'Education'},
      {id: 4918, name: 'NeedsAnalysis', complete: 'Education'},
      {id: 4919, name: 'BasicFirstA', complete: 'Education'},
      {id: 4920, name: 'Journalism', complete: 'Education'},
      {id: 4921, name: 'Biology', complete: 'Education'},
      {id: 4922, name: 'AdobeIllustrator', complete: 'Education'},
      {id: 4923, name: 'M{ProgrammingLanguage}', complete: 'Education'},
      {id: 4924, name: 'Rhetoric', complete: 'Education'},
      {id: 4925, name: 'CriticalThinking', complete: 'Education'},
      {id: 4926, name: 'AdobeConnect', complete: 'Education'},
      {id: 4927, name: 'Tuberculosis', complete: 'Education'},
      {id: 4928, name: 'FrenchLanguageFrenchLanguage', complete: 'Education'},
      {id: 4929, name: 'ClericalWorks', complete: 'Education'},
      {id: 4930, name: 'Animations', complete: 'Education'},
      {id: 4931, name: 'Influenza', complete: 'Education'},
      {id: 4932, name: 'PublicRelations', complete: 'Education'},
      {id: 4933, name: 'Autism{Therapies}', complete: 'Education'},
      {id: 4934, name: 'EducationalSoftware', complete: 'Education'},
      {id: 4935, name: 'Diphtheria', complete: 'Education'},
      {id: 4936, name: 'DataCollection', complete: 'Education'},
      {id: 4937, name: 'CascadingStyleSheets{CSS}', complete: 'Education'},
      {id: 4938, name: 'Tetanus', complete: 'Education'},
      {id: 4939, name: 'DemonstrationSkills', complete: 'Education'},
      {
        id: 4940,
        name: 'SharableContentObjectReferenceModel',
        complete: 'Education'
      },
      {id: 4941, name: 'SuddenInfantDeathSyndrome', complete: 'Education'},
      {id: 4942, name: 'SocialWork', complete: 'Education'},
      {id: 4943, name: 'StrategicPlanning', complete: 'Education'},
      {id: 4944, name: 'PersonalCare', complete: 'Education'},
      {id: 4945, name: 'PersonalCare', complete: 'Education'},
      {id: 4946, name: 'SyllabusDevelopment', complete: 'Education'},
      {
        id: 4947,
        name: 'JavaScript{:ProgrammingLanguage}',
        complete: 'Education'
      },
      {id: 4948, name: 'FoodServices', complete: 'Education'},
      {id: 4949, name: 'SecondaryEducation', complete: 'Education'},
      {id: 4950, name: 'Arithmetic', complete: 'Education'},
      {id: 4951, name: 'LearningDisabilities', complete: 'Education'},
      {id: 4952, name: 'Python{:ProgrammingLanguage}', complete: 'Education'},
      {id: 4953, name: 'ElementaryAlgebra', complete: 'Education'},
      {id: 4954, name: 'SocialSciences', complete: 'EducationAdministraion'},
      {
        id: 4955,
        name: 'InformationSystems',
        complete: 'EducationAdministraion'
      },
      {id: 4956, name: 'Accounting', complete: 'EducationAdministraion'},
      {id: 4957, name: 'Measles', complete: 'EducationAdministraion'},
      {id: 4958, name: 'Appeals', complete: 'EducationAdministraion'},
      {id: 4959, name: 'Socioeconomics', complete: 'EducationAdministraion'},
      {
        id: 4960,
        name: 'SPSS{:StatisticalSoftware}',
        complete: 'EducationAdministraion'
      },
      {
        id: 4961,
        name: 'StrategicLeadership',
        complete: 'EducationAdministraion'
      },
      {id: 4962, name: 'Disabilities', complete: 'EducationAdministraion'},
      {id: 4963, name: 'Editing', complete: 'EducationAdministraion'},
      {id: 4964, name: 'Sanitation', complete: 'EducationAdministraion'},
      {
        id: 4965,
        name: 'ProgramAssessmentRatingTool',
        complete: 'EducationAdministraion'
      },
      {id: 4966, name: 'Fundraising', complete: 'EducationAdministraion'},
      {
        id: 4967,
        name: 'LearningManagementSystems',
        complete: 'EducationAdministraion'
      },
      {
        id: 4968,
        name: 'AdultCardiopulmonaryResuscitation{:CPR}',
        complete: 'EducationAdministraion'
      },
      {
        id: 4969,
        name: 'InternationalEducation',
        complete: 'EducationAdministraion'
      },
      {id: 4970, name: 'PublicRelations', complete: 'EducationAdministraion'},
      {id: 4971, name: 'DataManagement', complete: 'EducationAdministraion'},
      {id: 4972, name: 'Pediatrics', complete: 'EducationAdministraion'},
      {id: 4973, name: 'EventPlanning', complete: 'EducationAdministraion'},
      {
        id: 4974,
        name: 'CurriculumDevelopment',
        complete: 'EducationAdministraion'
      },
      {id: 4975, name: 'Collections', complete: 'EducationAdministraion'},
      {
        id: 4976,
        name: 'MedicalImaging/Equipment',
        complete: 'EducationAdministraion'
      },
      {
        id: 4977,
        name: 'ExperientialLearning',
        complete: 'EducationAdministraion'
      },
      {id: 4978, name: 'NurseEducation', complete: 'EducationAdministraion'},
      {id: 4979, name: 'Prioritization', complete: 'EducationAdministraion'},
      {
        id: 4980,
        name: 'AutomatedExternalDefibrillator',
        complete: 'EducationAdministraion'
      },
      {
        id: 4981,
        name: 'ProjectManagement',
        complete: 'EducationAdministraion'
      },
      {
        id: 4982,
        name: 'PolicyDevelopment',
        complete: 'EducationAdministraion'
      },
      {id: 4983, name: 'SocialMedia', complete: 'EducationAdministraion'},
      {id: 4984, name: 'SocialWork', complete: 'EducationAdministraion'},
      {
        id: 4985,
        name: 'WorkforceDevelopment',
        complete: 'EducationAdministraion'
      },
      {
        id: 4986,
        name: 'InterpersonalSkills',
        complete: 'EducationAdministraion'
      },
      {id: 4987, name: 'Reconciliation', complete: 'EducationAdministraion'},
      {id: 4988, name: 'Vaccination', complete: 'EducationAdministraion'},
      {
        id: 4989,
        name: 'UndergraduateResearch',
        complete: 'EducationAdministraion'
      },
      {id: 4990, name: 'LearningStyles', complete: 'EducationAdministraion'},
      {id: 4991, name: 'DetailOriented', complete: 'EducationAdministraion'},
      {id: 4992, name: 'Influenza', complete: 'EducationAdministraion'},
      {
        id: 4993,
        name: 'CollaborativeSkills',
        complete: 'EducationAdministraion'
      },
      {id: 4994, name: 'TeamBuilding', complete: 'EducationAdministraion'},
      {id: 4995, name: 'Spreadsheets', complete: 'EducationAdministraion'},
      {id: 4996, name: 'FamilyChildCare', complete: 'EducationAdministraion'},
      {id: 4997, name: 'BusinessProcess', complete: 'EducationAdministraion'},
      {id: 4998, name: 'GrantWriting', complete: 'EducationAdministraion'},
      {
        id: 4999,
        name: 'PackagingAndLabeling',
        complete: 'EducationAdministraion'
      },
      {id: 5000, name: 'Caregiving', complete: 'EducationAdministraion'},
      {
        id: 5001,
        name: 'PublicAdministration',
        complete: 'EducationAdministraion'
      },
      {
        id: 5002,
        name: 'PerformanceAppraisal',
        complete: 'EducationAdministraion'
      },
      {id: 5003, name: 'Curettage', complete: 'DentistryandOrthodontia'},
      {id: 5004, name: 'Autoclaves', complete: 'DentistryandOrthodontia'},
      {id: 5005, name: 'SelfCare', complete: 'DentistryandOrthodontia'},
      {
        id: 5006,
        name: 'RelationshipBuilding',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5007, name: 'Polishing', complete: 'DentistryandOrthodontia'},
      {
        id: 5008,
        name: 'BiologicalHazards',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5009,
        name: 'AssessPatientNeeds',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5010,
        name: 'ForensicDentistryDentistryBranches}',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5011, name: 'Antimicrobials', complete: 'DentistryandOrthodontia'},
      {
        id: 5012,
        name: 'ConstructStudyCasts',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5013,
        name: 'InterpersonalCommunications',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5014, name: 'Cleanliness', complete: 'DentistryandOrthodontia'},
      {
        id: 5015,
        name: 'VisualPerception',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5016,
        name: 'ConflictResolution',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5017,
        name: 'SmokingCessation',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5018,
        name: 'Troubleshooting:ProblemSolving}',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5019,
        name: 'CoronalPolishingCertificate',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5020,
        name: 'MaterialSafetyDataSheet',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5021, name: 'ClericalWorks', complete: 'DentistryandOrthodontia'},
      {id: 5022, name: 'Writing', complete: 'DentistryandOrthodontia'},
      {
        id: 5023,
        name: 'CriticalThinking',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5024,
        name: 'RadiationProtection',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5025,
        name: 'DentalProstheticsFabrication',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5026,
        name: 'OfficeManagement',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5027,
        name: 'HealthAssessment',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5028,
        name: 'LearningManagementSystems',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5029, name: 'Auditing', complete: 'DentistryandOrthodontia'},
      {id: 5030, name: 'Registration', complete: 'DentistryandOrthodontia'},
      {id: 5031, name: 'Teaching', complete: 'DentistryandOrthodontia'},
      {id: 5032, name: 'RiskAnalysis', complete: 'DentistryandOrthodontia'},
      {id: 5033, name: 'FrontOffice', complete: 'DentistryandOrthodontia'},
      {id: 5034, name: 'X-Rays', complete: 'DentistryandOrthodontia'},
      {id: 5035, name: 'Podiatry', complete: 'DentistryandOrthodontia'},
      {
        id: 5036,
        name: 'InventoryControl',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5037,
        name: 'AddressingEthicalConcerns',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5038, name: 'Triage', complete: 'DentistryandOrthodontia'},
      {id: 5039, name: 'WoundDressing', complete: 'DentistryandOrthodontia'},
      {
        id: 5040,
        name: 'InterpersonalSkills',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5041, name: 'Vaccination', complete: 'DentistryandOrthodontia'},
      {
        id: 5042,
        name: 'TopicalAnesthetic',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5043,
        name: 'EmergencyMedicalServices',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5044,
        name: 'ContinuousQualityImprovement',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 5045,
        name: 'DentalApplianceFabricationAndCare',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5046, name: 'Filing', complete: 'DentistryandOrthodontia'},
      {id: 5047, name: 'Chiropractics', complete: 'DentistryandOrthodontia'},
      {id: 5048, name: 'Spreadsheets', complete: 'DentistryandOrthodontia'},
      {
        id: 5049,
        name: 'ProjectCommissioning',
        complete: 'DentistryandOrthodontia'
      },
      {id: 5050, name: 'Surgeries', complete: 'DentistryandOrthodontia'},
      {id: 5051, name: 'PreventiveCare', complete: 'DentistryandOrthodontia'},
      {id: 5052, name: 'QualityAssurance', complete: 'DieteticsandAudiology'},
      {id: 5053, name: 'Tympanometry', complete: 'DieteticsandAudiology'},
      {
        id: 5054,
        name: 'Data/RecordLogging',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5055,
        name: 'BusinessPerformanceManagement',
        complete: 'DieteticsandAudiology'
      },
      {id: 5056, name: 'Nursing', complete: 'DieteticsandAudiology'},
      {id: 5057, name: 'eonystagmography', complete: 'DieteticsandAudiology'},
      {id: 5058, name: 'CommunityHealth', complete: 'DieteticsandAudiology'},
      {
        id: 5059,
        name: 'Drug-FoodInteraction',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5060,
        name: 'StrategicBusinessUnit',
        complete: 'DieteticsandAudiology'
      },
      {id: 5061, name: 'Podcasting', complete: 'DieteticsandAudiology'},
      {id: 5062, name: 'Budgeting', complete: 'DieteticsandAudiology'},
      {
        id: 5063,
        name: 'ProtectedHealthInformation',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5064,
        name: 'PerformanceImprovement',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5065,
        name: 'MarketingStrategies',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5066,
        name: 'SettingAppointments',
        complete: 'DieteticsandAudiology'
      },
      {id: 5067, name: 'WorkplaceSafety', complete: 'DieteticsandAudiology'},
      {
        id: 5068,
        name: 'NutritionCounseling',
        complete: 'DieteticsandAudiology'
      },
      {id: 5069, name: 'ProgrammingTools', complete: 'DieteticsandAudiology'},
      {id: 5070, name: 'WrittenEnglish', complete: 'DieteticsandAudiology'},
      {id: 5071, name: 'DataCollection', complete: 'DieteticsandAudiology'},
      {id: 5072, name: 'Collection', complete: 'DieteticsandAudiology'},
      {id: 5073, complete: 'DieteticsandAudiology'},
      {id: 5074, name: 'Collection', complete: 'DieteticsandAudiology'},
      {
        id: 5075,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {id: 5076, name: 'Collection', complete: 'DieteticsandAudiology'},
      {
        id: 5077,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5078,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {id: 5079, name: 'Collection', complete: 'DieteticsandAudiology'},
      {id: 5080, complete: 'DieteticsandAudiology'},
      {id: 5081, complete: 'DieteticsandAudiology'},
      {
        id: 5082,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 5083,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {id: 5084, name: 'Renal', complete: 'DieteticsandAudiology'},
      {id: 5085, name: 'Renal', complete: 'DieteticsandAudiology'},
      {
        id: 5086,
        name:
          'PatientEducation\r\nCashManagement\r\nBiology\r\nDischargePlanning\r\nBoardCertifiedSpecialistInRenalNutrition\r\nLearningManagementSystems\r\nLanguageTranslation\r\nProjectCommissioning',
        complete: 'DieteticsandAudiology'
      },
      {id: 5087, name: 'Renal', complete: 'DieteticsandAudiology'},
      {id: 5088, name: 'Renal', complete: 'DieteticsandAudiology'},
      {id: 5089, name: 'Renal', complete: 'DieteticsandAudiology'},
      {id: 5090, name: 'Renal', complete: 'DieteticsandAudiology'},
      {
        id: 5128,
        name: 'GeographicInformationSystems',
        complete: 'Agricluture'
      },
      {id: 5215, name: 'Spillway', complete: 'Agricluture'},
      {id: 5345, name: 'Vaccination', complete: 'Agricluture'},
      {id: 6232, name: 'Tagging', complete: 'Agricluture'},
      {id: 6324, name: 'Sampling{:Statistics}', complete: 'Agricluture'},
      {id: 6333, name: 'Chemistry', complete: 'Agricluture'},
      {id: 6523, name: 'Pumps', complete: 'Agricluture'},
      {id: 6623, name: 'FirstA', complete: 'Agricluture'},
      {id: 6723, name: 'ArtificialInsemination', complete: 'Agricluture'},
      {id: 6823, name: 'Teaching', complete: 'Agricluture'},
      {id: 6923, name: 'RollingStock', complete: 'Agricluture'},
      {id: 7234, name: 'MaterialHandling', complete: 'Agricluture'},
      {id: 8023, name: 'R{ProgrammingLanguage}', complete: 'Agricluture'},
      {id: 8231, name: 'AirBrakes', complete: 'Agricluture'},
      {id: 8232, name: 'Mowing', complete: 'Agricluture'},
      {id: 8233, name: 'Ecology', complete: 'Agricluture'},
      {
        id: 8234,
        name: 'ArchitecturalEngineeringDieselGenerators',
        complete: 'Agricluture'
      },
      {id: 8235, name: 'DieselGenerators', complete: 'Agricluture'},
      {id: 8236, name: 'Gardening', complete: 'Agricluture'},
      {id: 8237, name: 'DataCollection', complete: 'Agricluture'},
      {id: 8238, name: 'Mapping', complete: 'Agricluture'},
      {id: 8329, name: 'ComputerSciences', complete: 'Agricluture'},
      {id: 9123, name: 'Pruning', complete: 'Agricluture'},
      {id: 10234, name: 'WaterDistribution', complete: 'Agricluture'},
      {id: 10780, name: 'FinancialAnalysis', complete: 'Architecture'},
      {id: 10879, name: 'PlannedMaintenance', complete: 'Agricluture'},
      {
        id: 10931,
        name: 'SoftwareTechnicalReview',
        complete: 'CivilEngineering'
      },
      {
        id: 10932,
        name: 'PersonallyentifiableInformation',
        complete: 'CivilEngineering'
      },
      {id: 10933, name: 'SafetyStandards', complete: 'CivilEngineering'},
      {id: 10934, name: 'LowImpactDevelopment', complete: 'CivilEngineering'},
      {
        id: 10937,
        name: 'ElectricalEngineering',
        complete: 'CivilEngineering'
      },
      {
        id: 10938,
        name: 'StrategicBusinessUnit',
        complete: 'CivilEngineering'
      },
      {id: 10939, name: 'Logos', complete: 'CivilEngineering'},
      {id: 10940, name: 'Loans', complete: 'CivilEngineering'},
      {id: 10941, name: 'Flowmaster', complete: 'CivilEngineering'},
      {id: 10942, name: 'Compositions', complete: 'CivilEngineering'},
      {
        id: 10943,
        name: 'ComputerAedThree-DimensionalInteractiveApplication{:CATIA}',
        complete: 'CivilEngineering'
      },
      {id: 10944, name: 'SystemsManagement', complete: 'CivilEngineering'},
      {id: 10945, name: 'ElectronicDocuments', complete: 'CivilEngineering'},
      {id: 10946, name: 'HydrogenSulfe', complete: 'CivilEngineering'},
      {id: 10947, name: 'TechnicalTraining', complete: 'CivilEngineering'},
      {id: 10948, name: 'Groundwater', complete: 'CivilEngineering'},
      {id: 10949, name: 'FineMotorSkills', complete: 'CivilEngineering'},
      {id: 10950, name: 'PipeRamming', complete: 'CivilEngineering'},
      {id: 10951, name: 'UrbanPlanning', complete: 'CivilEngineering'},
      {id: 10952, name: 'VisualAcuity', complete: 'CivilEngineering'},
      {
        id: 10953,
        name: 'HyperTextMarkupLanguage{:HTML}',
        complete: 'CivilEngineering'
      },
      {id: 10954, name: 'TaskPlanning', complete: 'CivilEngineering'},
      {id: 10955, name: 'Spillway', complete: 'CivilEngineering'},
      {
        id: 10956,
        name: 'MaterialsTesting{:Tests}',
        complete: 'CivilEngineering'
      },
      {id: 10957, name: 'Vibrations', complete: 'CivilEngineering'},
      {id: 10958, name: 'Memos', complete: 'CivilEngineering'},
      {id: 10960, name: 'WaterHeating', complete: 'CivilEngineering'},
      {id: 10961, name: 'Land-UsePlanning', complete: 'CivilEngineering'},
      {id: 10962, name: 'Calculations', complete: 'CivilEngineering'},
      {id: 10963, name: 'ForensicEngineering', complete: 'CivilEngineering'},
      {id: 10964, name: 'DemandForecasting', complete: 'CivilEngineering'},
      {id: 10965, name: 'ServiceProver', complete: 'CivilEngineering'},
      {
        id: 10966,
        name: 'MagneticParticleInspections',
        complete: 'CivilEngineering'
      },
      {
        id: 10967,
        name: 'LandscapeArchitecture',
        complete: 'CivilEngineering'
      },
      {id: 10968, name: 'Calculus', complete: 'CivilEngineering'},
      {
        id: 10969,
        name: 'GeotechnicalInvestigation',
        complete: 'CivilEngineering'
      },
      {id: 10970, complete: 'CivilEngineering'},
      {
        id: 10971,
        name: 'Sampling{:Statistics}',
        complete: 'CivilEngineering'
      },
      {id: 10972, name: 'Petrochemical', complete: 'CivilEngineering'},
      {id: 10973, name: 'SystemsEngineering', complete: 'CivilEngineering'},
      {id: 10974, name: 'Reliability', complete: 'CivilEngineering'},
      {id: 10975, name: 'Derivatives', complete: 'CivilEngineering'},
      {id: 10976, name: 'JobCosting', complete: 'CivilEngineering'},
      {id: 10977, name: 'Sanitation', complete: 'CivilEngineering'},
      {id: 10978, name: 'Punctuation', complete: 'CivilEngineering'},
      {id: 10979, name: 'Geomechanics', complete: 'CivilEngineering'},
      {id: 10980, name: 'HighwayEngineering', complete: 'CivilEngineering'},
      {id: 10981, name: 'EngineeringEthics', complete: 'CivilEngineering'},
      {id: 10983, name: 'DueDiligence', complete: 'CivilEngineering'},
      {id: 10984, name: 'DataManagement', complete: 'CivilEngineering'},
      {id: 10985, name: 'BuildingDesign', complete: 'CivilEngineering'},
      {id: 10986, name: 'Wsdot', complete: 'CivilEngineering'},
      {
        id: 10987,
        name: 'SoftwareTechnicalReview',
        complete: 'CivilEngineering'
      },
      {
        id: 10988,
        name: 'PersonallyentifiableInformation',
        complete: 'CivilEngineering'
      },
      {id: 10989, name: 'SafetyStandards', complete: 'CivilEngineering'},
      {id: 10990, name: 'LowImpactDevelopment', complete: 'CivilEngineering'},
      {id: 10991, name: 'Mitigation', complete: 'CivilEngineering'},
      {
        id: 10992,
        name: 'FreeLosslessAudioCodec{:FLAC}',
        complete: 'CivilEngineering'
      },
      {
        id: 10993,
        name: 'ElectricalEngineering',
        complete: 'CivilEngineering'
      },
      {id: 11111, name: 'Welfare', complete: 'Agricluture'},
      {id: 12234, name: 'AnimalHusbandry', complete: 'Agricluture'},
      {id: 12324, name: 'Tires', complete: 'Agricluture'},
      {id: 12390, name: 'TeamBuilding', complete: 'Agricluture'},
      {id: 13234, name: 'Agriculture', complete: 'Agricluture'},
      {id: 14234, name: 'InventoryControl', complete: 'Agricluture'},
      {
        id: 14329,
        name: 'StandardOperatingProcedure',
        complete: 'Agricluture'
      },
      {id: 15234, name: 'FarmImplements', complete: 'Agricluture'},
      {
        id: 17234,
        name: 'EmergencyCommunicationSystems',
        complete: 'Agricluture'
      },
      {id: 19123, name: 'EnvironmentalHealth', complete: 'Agricluture'},
      {
        id: 19238,
        name: 'ProfessionalDevelopmentProgra',
        complete: 'Agricluture'
      },
      {id: 20123, name: 'Pipe{:FluConveyance}', complete: 'Agricluture'},
      {id: 21123, name: 'Forestry', complete: 'Agricluture'},
      {id: 22213, name: 'InjuryPrevention', complete: 'Agricluture'},
      {id: 22222, name: 'Biology', complete: 'Agricluture'},
      {id: 22304, name: 'Morphology', complete: 'Agricluture'},
      {id: 22325, name: 'CommunicationStudies', complete: 'Agricluture'},
      {id: 22350, name: 'WaterResourceManagement', complete: 'Agricluture'},
      {id: 23234, name: 'Auditing', complete: 'Agricluture'},
      {id: 25123, name: 'SprinklerSystems', complete: 'Agricluture'},
      {id: 26123, name: 'WeedControls', complete: 'Agricluture'},
      {id: 27123, name: 'EnvironmentalLaws', complete: 'Agricluture'},
      {id: 28123, name: 'Purchasing', complete: 'Agricluture'},
      {id: 29123, name: 'Brakes', complete: 'Agricluture'},
      {id: 30123, name: 'EmbankmentDesign', complete: 'Agricluture'},
      {id: 31123, name: 'Landscaping', complete: 'Agricluture'},
      {id: 32123, name: 'In-PlaneSwitching{:IPS}', complete: 'Agricluture'},
      {id: 33123, name: 'Entomology', complete: 'Agricluture'},
      {
        id: 34123,
        name: 'OccupationalHealthAndSafetyAdministration{:OSHA}',
        complete: 'Agricluture'
      },
      {id: 34532, name: 'ApplicationData', complete: 'Agricluture'},
      {id: 35123, name: 'Trench', complete: 'Agricluture'},
      {id: 36123, name: 'Trimming', complete: 'Agricluture'},
      {
        id: 37123,
        name: 'IntravenousTherapyInterpersonalSkills',
        complete: 'Agricluture'
      },
      {id: 38123, name: 'InterpersonalSkills', complete: 'Agricluture'},
      {
        id: 41231,
        name: 'TransplantingEnvironmentalIssue',
        complete: 'Agricluture'
      },
      {id: 41236, name: 'Mulch', complete: 'Agricluture'},
      {id: 41237, name: 'Cleanliness', complete: 'Agricluture'},
      {id: 41238, name: 'LandTenure', complete: 'Agricluture'},
      {id: 41239, name: 'Palletizing', complete: 'Agricluture'},
      {id: 42423, name: 'Valves{:Piping}', complete: 'Agricluture'},
      {id: 43123, name: 'Botany', complete: 'Agricluture'},
      {
        id: 44421,
        name: 'CommercialDriverSLicense{:CDL}',
        complete: 'Agricluture'
      },
      {id: 51230, name: 'AutomationClips', complete: 'Agricluture'},
      {
        id: 51236,
        name: 'CardiopulmonaryResuscitation{:CPR}',
        complete: 'Agricluture'
      },
      {id: 51237, name: 'Teamwork', complete: 'Agricluture'},
      {id: 52131, name: 'Shears', complete: 'Agricluture'},
      {id: 52134, name: 'CustomerSatisfaction', complete: 'Agricluture'},
      {id: 52232, name: 'Sanitation', complete: 'Agricluture'},
      {id: 60123, name: 'Farming', complete: 'Agricluture'},
      {id: 73237, name: 'Castration', complete: 'Agricluture'},
      {id: 91231, name: 'LandscapeMaintenance', complete: 'Agricluture'},
      {id: 91233, name: 'Mathematics', complete: 'Agricluture'},
      {id: 101232, name: 'LaboratoryAnimals', complete: 'Agricluture'},
      {id: 101233, name: 'Forecasting', complete: 'Agricluture'},
      {
        id: 101235,
        name: 'LearningManagementSystems',
        complete: 'Agricluture'
      },
      {id: 101238, name: 'PackagingAndLabeling', complete: 'Agricluture'},
      {id: 101239, name: 'ChemicalComposition', complete: 'Agricluture'},
      {id: 107123, name: 'Antibiotics', complete: 'Agricluture'},
      {id: 111123, name: 'LandscapeArchitecture', complete: 'Agricluture'},
      {id: 112300, name: 'TrafficControl', complete: 'Agricluture'},
      {id: 112345, name: 'GermanLanguage', complete: 'Agricluture'},
      {id: 112349, name: 'Photography', complete: 'Agricluture'},
      {id: 118234, name: 'InventoryManagement', complete: 'Agricluture'},
      {id: 122234, name: 'HerbiceApplicatorLicense', complete: 'Agricluture'},
      {id: 122345, name: 'Anthropology', complete: 'Agricluture'},
      {
        id: 123240,
        name: 'ElectricalDiagnosticsAndRepairs',
        complete: 'Agricluture'
      },
      {id: 123580, name: 'FinancialManagement', complete: 'Agricluture'},
      {id: 123582, name: 'BusinessAdministration', complete: 'Agricluture'},
      {id: 123583, name: 'WorkOrder', complete: 'Agricluture'},
      {id: 123584, name: 'TransferStation', complete: 'Agricluture'},
      {id: 123585, name: 'QualityControl', complete: 'Agricluture'},
      {id: 123586, name: 'DigitalRadiography', complete: 'Agricluture'},
      {id: 123587, name: 'Soleno', complete: 'Agricluture'},
      {id: 123588, name: 'ElectricUtility', complete: 'Agricluture'},
      {id: 123589, name: 'Rabies', complete: 'Agricluture'},
      {id: 123591, name: 'EducationalEvaluation', complete: 'Agricluture'},
      {id: 123593, name: 'FieldResearch', complete: 'Agricluture'},
      {id: 123594, name: 'Anesthesias', complete: 'Agricluture'},
      {id: 126234, complete: 'Agricluture'},
      {id: 127242, name: 'PressureWasher', complete: 'Agricluture'},
      {id: 128234, name: 'Painting', complete: 'Agricluture'},
      {id: 130234, name: 'OperationsManagement', complete: 'Agricluture'},
      {id: 132234, name: 'Sawing', complete: 'Agricluture'},
      {id: 137234, name: 'EngineeringMathematics', complete: 'Agricluture'},
      {id: 138234, name: 'Tillage', complete: 'Agricluture'},
      {id: 139234, name: 'StockFarming', complete: 'Agricluture'},
      {
        id: 140324,
        name: 'VerbalCommunicationSkills',
        complete: 'Agricluture'
      },
      {id: 141234, name: 'MechanicalEngineering', complete: 'Agricluture'},
      {id: 142234, name: 'Sociology', complete: 'Agricluture'},
      {
        id: 142344,
        name: 'PersonalProtectiveEquipment',
        complete: 'Agricluture'
      },
      {id: 142345, name: 'Economics', complete: 'Agricluture'},
      {id: 142346, name: 'SportsMedicine', complete: 'Agricluture'},
      {id: 142347, name: 'Dozer', complete: 'Agricluture'},
      {id: 142348, name: 'Merchandising', complete: 'Agricluture'},
      {id: 143324, name: 'IncomeTax', complete: 'Agricluture'},
      {id: 152340, name: 'Genetics', complete: 'Agricluture'},
      {id: 152341, name: 'PoliticalSciences', complete: 'Agricluture'},
      {id: 152342, name: 'DirectDriveMechanism', complete: 'Agricluture'},
      {id: 152343, name: 'Cooking', complete: 'Agricluture'},
      {id: 152344, name: 'ApachePig', complete: 'Agricluture'},
      {id: 152346, name: 'ComputerEngineering', complete: 'Agricluture'},
      {id: 152347, name: 'HeavyEquipment', complete: 'Agricluture'},
      {id: 152348, name: 'CiscoDiscoveryProtocol', complete: 'Agricluture'},
      {id: 152349, name: 'AnimalResources', complete: 'Agricluture'},
      {id: 153245, name: 'Spreadsheets', complete: 'Agricluture'},
      {id: 153281, name: 'DecisionTheories', complete: 'Agricluture'},
      {id: 161234, name: 'ConflictResolution', complete: 'Agricluture'},
      {id: 161235, name: 'StrategicPlanning', complete: 'Agricluture'},
      {id: 161236, name: 'CreativeWriting', complete: 'Agricluture'},
      {id: 162340, name: 'FoodSafety', complete: 'Agricluture'},
      {
        id: 162341,
        name: 'DoctorOfPharmacy{:PharmD}',
        complete: 'Agricluture'
      },
      {id: 163423, name: 'CertifiedArborist', complete: 'Agricluture'},
      {id: 172340, name: 'Accounting', complete: 'Agricluture'},
      {id: 172342, name: 'IndustrialRelations', complete: 'Agricluture'},
      {id: 172344, name: 'Immunology', complete: 'Agricluture'},
      {id: 172345, name: 'Procurement', complete: 'Agricluture'},
      {id: 173529, name: 'ISO14000', complete: 'Agricluture'},
      {id: 182123, name: 'SpanishLanguage', complete: 'Agricluture'},
      {
        id: 192355,
        name: 'KeyPerformanceIndicators{:KPIs}',
        complete: 'Agricluture'
      },
      {id: 192359, name: 'ISO9000', complete: 'Agricluture'},
      {id: 202350, name: 'FacilityManagement', complete: 'Agricluture'},
      {id: 202352, name: 'EntertainmentManagement', complete: 'Agricluture'},
      {id: 202356, name: 'EnvironmentalDesign', complete: 'Agricluture'},
      {id: 202359, name: 'SafetyTraining', complete: 'Agricluture'},
      {id: 203251, name: 'Aluminum', complete: 'Agricluture'},
      {id: 203258, name: 'SampleCollection', complete: 'Agricluture'},
      {id: 212350, name: 'Funnels', complete: 'Agricluture'},
      {id: 212354, name: 'GenderStudies', complete: 'Agricluture'},
      {id: 212355, name: 'PerformanceAppraisal', complete: 'Agricluture'},
      {id: 212460, name: 'BuildingCodes', complete: 'Architecture'},
      {id: 222353, name: 'HealthPhysics', complete: 'Agricluture'},
      {id: 222356, name: 'DataEntry', complete: 'Agricluture'},
      {id: 223451, name: 'NuclearEngineering', complete: 'Agricluture'},
      {id: 223452, name: 'Appeals', complete: 'Agricluture'},
      {id: 223455, name: 'LandscapeArchitecture', complete: 'Architecture'},
      {id: 223456, name: 'NavalArchitecture', complete: 'Architecture'},
      {id: 223457, name: 'EnvironmentalControl', complete: 'Architecture'},
      {id: 223458, name: 'AdobePhotoshop', complete: 'Architecture'},
      {id: 223461, name: 'LandscapeDesign', complete: 'Architecture'},
      {id: 223462, name: 'MarineEngineering', complete: 'Architecture'},
      {
        id: 223463,
        name: 'ArchitecturalEngineering',
        complete: 'Architecture'
      },
      {id: 223464, name: 'AdobeIllustrator', complete: 'Architecture'},
      {id: 223466, name: 'LandZoning', complete: 'Architecture'},
      {id: 223467, name: 'Landscaping', complete: 'Architecture'},
      {id: 223468, name: 'MechanicalEngineering', complete: 'Architecture'},
      {id: 223469, name: 'EthicalConduct', complete: 'Architecture'},
      {id: 223470, name: 'AdobeInDesign', complete: 'Architecture'},
      {id: 223512, name: 'PerformanceMonitor', complete: 'Agricluture'},
      {id: 223513, name: 'PeopleManagement', complete: 'Agricluture'},
      {id: 223516, name: 'EnvironmentalMonitoring', complete: 'Agricluture'},
      {
        id: 223517,
        name: 'CorrectiveAndPreventiveAction{:CAPA}',
        complete: 'Agricluture'
      },
      {id: 223518, name: 'Geophysics', complete: 'Agricluture'},
      {id: 223520, name: 'MedicalResearch', complete: 'Agricluture'},
      {id: 223521, name: 'JPEG', complete: 'Agricluture'},
      {id: 223522, name: 'AsepticTechniques', complete: 'Agricluture'},
      {id: 223527, name: 'LegalResearch', complete: 'Agricluture'},
      {id: 223541, name: 'Immunization', complete: 'Agricluture'},
      {id: 223573, name: 'Grading{:Landscape}', complete: 'Architecture'},
      {id: 223574, name: 'HVAC', complete: 'Architecture'},
      {
        id: 223576,
        name: 'SketchUp{:3DModelingSoftware}',
        complete: 'Architecture'
      },
      {id: 223584, name: 'BuildingDesign', complete: 'Architecture'},
      {id: 223593, name: 'Budgeting', complete: 'Architecture'},
      {id: 225383, name: 'BusinessDevelopment', complete: 'Architecture'},
      {
        id: 232353,
        name: 'DoctorOfVeterinaryMedicine{:DVM/VMD}',
        complete: 'Agricluture'
      },
      {id: 232355, name: 'MarineEngineering', complete: 'Agricluture'},
      {id: 232453, name: 'Respiration', complete: 'Agricluture'},
      {id: 232530, name: 'ProgramManagement', complete: 'Agricluture'},
      {id: 232543, name: 'MiningEngineering', complete: 'Agricluture'},
      {
        id: 232585,
        name: 'Irrigation{:LandscapingAndAgriculture}',
        complete: 'Architecture'
      },
      {id: 233252, name: 'Counters{:Digital}', complete: 'Agricluture'},
      {id: 233254, name: 'LanguageTranslation', complete: 'Agricluture'},
      {id: 233359, name: 'MilitaryStudies', complete: 'Agricluture'},
      {id: 233526, name: 'ComplianceTraining', complete: 'Agricluture'},
      {id: 233528, name: 'Annuities', complete: 'Agricluture'},
      {id: 234254, name: 'Physics', complete: 'Agricluture'},
      {id: 235192, name: 'Paneling', complete: 'Agricluture'},
      {id: 235205, name: 'Prioritization', complete: 'Agricluture'},
      {id: 235219, name: 'Asepsis', complete: 'Agricluture'},
      {id: 235224, name: 'AsepticTechniques', complete: 'Agricluture'},
      {
        id: 235240,
        name: 'FederalEmployeesHealthBenefitsPrograms',
        complete: 'Agricluture'
      },
      {id: 242352, name: 'DiskCloning', complete: 'Agricluture'},
      {id: 242354, name: 'Filtration', complete: 'Agricluture'},
      {id: 242356, name: 'Filing', complete: 'Agricluture'},
      {id: 242357, name: 'NavalArchitecture', complete: 'Agricluture'},
      {id: 242358, name: 'PreventiveMaintenance', complete: 'Agricluture'},
      {id: 242535, name: 'Autoclaves', complete: 'Agricluture'},
      {id: 272341, name: 'Memos', complete: 'Architecture'},
      {id: 272357, name: 'QualityControl', complete: 'Architecture'},
      {
        id: 272532,
        name: 'Submittals{:Construction}',
        complete: 'Architecture'
      },
      {id: 282350, name: 'MechanicalSystems', complete: 'Architecture'},
      {id: 282351, name: 'AcademicAchievement', complete: 'Architecture'},
      {
        id: 282352,
        name: 'Autodesk3DSMax{:3DGraphicsSoftware}',
        complete: 'Architecture'
      },
      {id: 282356, name: 'Propulsion', complete: 'Architecture'},
      {id: 282357, name: 'FinancialManagement', complete: 'Architecture'},
      {id: 282358, name: 'Rendering', complete: 'Architecture'},
      {id: 282359, name: 'InformationModel', complete: 'Architecture'},
      {id: 292350, name: 'ConstructionManagement', complete: 'Architecture'},
      {id: 292351, name: 'Land-UsePlanning', complete: 'Architecture'},
      {id: 292355, name: 'IntegratedDesign', complete: 'Architecture'},
      {id: 293522, name: 'Shipyard', complete: 'Architecture'},
      {id: 295234, name: 'Sketching', complete: 'Architecture'},
      {id: 392312, name: 'OilAndGas', complete: 'Agricluture'},
      {id: 452312, name: 'SoilScience', complete: 'Agricluture'},
      {id: 513129, name: 'GoodDrivingRecord', complete: 'Agricluture'},
      {id: 521323, name: 'Writing', complete: 'Agricluture'},
      {id: 611232, name: 'AerialLifts', complete: 'Agricluture'},
      {id: 687687, name: 'MagneticResonanceImaging', complete: 'Agricluture'},
      {id: 722332, name: 'Tattooing', complete: 'Agricluture'},
      {id: 723348, name: 'EnvironmentalScience', complete: 'Agricluture'},
      {id: 723431, name: 'UrbanForestry', complete: 'Agricluture'},
      {id: 723433, name: 'Pathology', complete: 'Agricluture'},
      {id: 723435, name: 'Drilling', complete: 'Agricluture'},
      {id: 723436, name: 'Roofing', complete: 'Agricluture'},
      {id: 723439, name: 'ManufacturingEngineering', complete: 'Agricluture'},
      {id: 812213, name: 'AnimalHusbandry', complete: 'Agricluture'},
      {id: 912315, name: 'Winches', complete: 'Agricluture'},
      {id: 912316, name: 'Tilling', complete: 'Agricluture'},
      {id: 912317, name: 'AnimalHandling', complete: 'Agricluture'},
      {id: 912319, name: 'PublicAdministration', complete: 'Agricluture'},
      {id: 1010935, name: 'Mitigation', complete: 'CivilEngineering'},
      {
        id: 1010936,
        name: 'FreeLosslessAudioCodec{:FLAC}',
        complete: 'CivilEngineering'
      },
      {id: 1010959, name: 'FinancialAnalysis', complete: 'CivilEngineering'},
      {id: 1010982, name: 'Immunization', complete: 'CivilEngineering'},
      {id: 1012314, name: 'ElectricalEngineering', complete: 'Agricluture'},
      {id: 1023111, name: 'LandscapeDesign', complete: 'Agricluture'},
      {id: 1101231, name: 'Towing', complete: 'Agricluture'},
      {id: 1112312, name: 'Horticulture', complete: 'Agricluture'},
      {id: 1123426, name: 'JobPlanning', complete: 'Agricluture'},
      {
        id: 1123427,
        name: 'AssistantLaboratoryAnimalTechnician',
        complete: 'Agricluture'
      },
      {id: 1223421, complete: 'Agricluture'},
      {id: 1223424, name: 'ProductionManagement', complete: 'Agricluture'},
      {id: 1242343, name: 'SecurityPolicies', complete: 'Agricluture'},
      {
        id: 1292342,
        name: 'LaboratoryAnimalTechnologist{LATG}',
        complete: 'Agricluture'
      },
      {id: 1312342, name: 'ElectronicEngineering', complete: 'Agricluture'},
      {id: 1342342, name: 'ProsciADKARModel', complete: 'Agricluture'},
      {id: 1352342, name: 'Zoology', complete: 'Agricluture'},
      {id: 1362234, name: 'RegionalPlanning', complete: 'Agricluture'},
      {id: 1612318, name: 'Groundskeeping', complete: 'Agricluture'},
      {id: 1723421, name: 'Criminology', complete: 'Agricluture'},
      {id: 1723426, name: 'CulturalStudies', complete: 'Agricluture'},
      {id: 1723528, name: 'HandSaw', complete: 'Agricluture'},
      {id: 1725357, name: 'Plumbing', complete: 'Agricluture'},
      {
        id: 2235279,
        name: 'GeographicInformationSystems',
        complete: 'Architecture'
      },
      {id: 2332357, name: 'PorcineAnimalCare', complete: 'Agricluture'},
      {
        id: 2423423,
        name: 'dLaboratoryPractice{GLP}',
        complete: 'Agricluture'
      },
      {id: 2533278, name: 'ProjectCoordination', complete: 'Architecture'},
      {id: 6345345, name: 'Budgeting', complete: 'Agricluture'},
      {id: 7022343, name: 'Hydraulics', complete: 'Agricluture'},
      {id: 7223434, name: 'BatteriesDrilling', complete: 'Agricluture'},
      {id: 9123120, name: 'WarningSigns', complete: 'Agricluture'},
      {id: 9123124, name: 'Electronics', complete: 'Agricluture'},
      {id: 9123132, name: 'AdministeringBloodTests', complete: 'Agricluture'},
      {id: 11234234, name: 'Geography', complete: 'Agricluture'},
      {id: 12312398, name: 'PublicRelations', complete: 'Agricluture'},
      {id: 12323596, name: 'EngineeringGeology', complete: 'Agricluture'},
      {id: 12342312, name: 'Biosecurity', complete: 'Agricluture'},
      {id: 13323423, name: 'Construction', complete: 'Agricluture'},
      {id: 16234242, name: 'Statistics', complete: 'Agricluture'},
      {id: 16434239, name: 'CaesareanSection', complete: 'Agricluture'},
      {id: 17234323, name: 'ProjectCommissioning', complete: 'Agricluture'},
      {id: 19232357, name: 'Incubators', complete: 'Agricluture'},
      {id: 20232533, name: 'GardenTools', complete: 'Agricluture'},
      {id: 22232539, name: 'InfectionControl', complete: 'Agricluture'},
      {id: 22332528, name: 'CreditCounseling', complete: 'Agricluture'},
      {id: 23423265, name: 'QualityAssurance', complete: 'Architecture'},
      {id: 25235275, name: 'Construction', complete: 'Architecture'},
      {id: 41230123, name: 'AgriculturalMachinery', complete: 'Agricluture'},
      {id: 41231232, name: 'EnvironmentalIssue', complete: 'Agricluture'},
      {id: 101231326, name: 'Grinding', complete: 'Agricluture'},
      {id: 123122163, name: 'WorkingQuickly', complete: 'Agricluture'},
      {id: 161231327, name: 'CivilEngineering', complete: 'Agricluture'},
      {id: 202355237, name: 'PowerToolOperation', complete: 'Agricluture'},
      {
        id: 3806,
        name: 'Instrumentation',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3807, name: 'Purchasing', complete: 'MaintenanceandInstallation'},
      {id: 3808, name: 'Drilling', complete: 'MaintenanceandInstallation'},
      {id: 3809, name: 'Hydraulics', complete: 'MaintenanceandInstallation'},
      {id: 3810, name: 'Carpentry', complete: 'MaintenanceandInstallation'},
      {
        id: 3811,
        name: 'Category5Cabling',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3812, name: 'Pumps', complete: 'MaintenanceandInstallation'},
      {id: 3813, name: 'Electronics', complete: 'MaintenanceandInstallation'},
      {id: 3814, name: 'Auditing', complete: 'MaintenanceandInstallation'},
      {
        id: 3815,
        name: 'Blueprinting',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3816, complete: 'MaintenanceandInstallation'},
      {id: 3817, complete: 'MaintenanceandInstallation'},
      {id: 3818, complete: 'MaintenanceandInstallation'},
      {id: 3819, complete: 'MaintenanceandInstallation'},
      {id: 3820, complete: 'MaintenanceandInstallation'},
      {id: 3821, complete: 'MaintenanceandInstallation'},
      {id: 3822, complete: 'MaintenanceandInstallation'},
      {id: 3823, complete: 'MaintenanceandInstallation'},
      {id: 3824, complete: 'MaintenanceandInstallation'},
      {id: 3825, complete: 'MaintenanceandInstallation'},
      {id: 3826, complete: 'MaintenanceandInstallation'},
      {id: 3827, complete: 'MaintenanceandInstallation'},
      {id: 3828, complete: 'MaintenanceandInstallation'},
      {id: 3829, complete: 'MaintenanceandInstallation'},
      {id: 3830, complete: 'MaintenanceandInstallation'},
      {id: 3831, complete: 'MaintenanceandInstallation'},
      {
        id: 3832,
        name: 'roubleshooting{:ProblemSolving}',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3833, name: 'Plumbing', complete: 'MaintenanceandInstallation'},
      {
        id: 3834,
        name: 'Telecommunications',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3835, name: 'Welding', complete: 'MaintenanceandInstallation'},
      {
        id: 3836,
        name: 'ProgrammableLogicControllers',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3837, name: 'Budgeting', complete: 'MaintenanceandInstallation'},
      {id: 3838, name: 'Sawing', complete: 'MaintenanceandInstallation'},
      {
        id: 3839,
        name: 'PreventiveMaintenance',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3840, name: 'HVAC', complete: 'MaintenanceandInstallation'},
      {
        id: 3841,
        name: 'TestEquipment',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3842, name: 'Mechanics', complete: 'MaintenanceandInstallation'},
      {
        id: 3843,
        name: 'Instrumentation',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3844, name: 'Purchasing', complete: 'MaintenanceandInstallation'},
      {id: 3845, name: 'Drilling', complete: 'MaintenanceandInstallation'},
      {id: 3846, name: 'Hydraulics', complete: 'MaintenanceandInstallation'},
      {id: 3847, name: 'Carpentry', complete: 'MaintenanceandInstallation'},
      {
        id: 3848,
        name: 'Category5Cabling',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3849, name: 'Pumps', complete: 'MaintenanceandInstallation'},
      {id: 3850, name: 'Electronics', complete: 'MaintenanceandInstallation'},
      {id: 3851, name: 'Auditing', complete: 'MaintenanceandInstallation'},
      {
        id: 3852,
        name: 'Blueprinting',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3853, name: 'Painting', complete: 'MaintenanceandInstallation'},
      {id: 3854, name: 'HeavyEquipment', complete: 'VehicleMaintenance'},
      {
        id: 3855,
        name: 'Troubleshooting{ProblemSolving}',
        complete: 'VehicleMaintenance'
      },
      {id: 3856, name: 'TechnicalTraining', complete: 'VehicleMaintenance'},
      {id: 3857, name: 'HVAC', complete: 'VehicleMaintenance'},
      {id: 3858, name: 'OilAndGas', complete: 'VehicleMaintenance'},
      {id: 3859, name: 'ChangingOil', complete: 'VehicleMaintenance'},
      {id: 3860, name: 'Pumps', complete: 'VehicleMaintenance'},
      {id: 3861, name: 'AircraftMaintenance', complete: 'VehicleMaintenance'},
      {
        id: 3862,
        name: 'MetalInertGas{MIG}Welding',
        complete: 'VehicleMaintenance'
      },
      {id: 3863, name: 'Brakes', complete: 'VehicleMaintenance'},
      {id: 3864, name: 'Welding', complete: 'VehicleMaintenance'},
      {id: 3865, name: 'FingerDexterity', complete: 'VehicleMaintenance'},
      {id: 3866, name: 'ExhaustSystems', complete: 'VehicleMaintenance'},
      {id: 3867, name: 'Merchandising', complete: 'VehicleMaintenance'},
      {id: 3868, name: 'HandheldPC', complete: 'VehicleMaintenance'},
      {id: 3869, name: 'Construction', complete: 'VehicleMaintenance'},
      {
        id: 3870,
        name: 'FederalAviationAdministration',
        complete: 'VehicleMaintenance'
      },
      {id: 3871, name: 'VehicleInspection', complete: 'VehicleMaintenance'},
      {id: 3872, name: 'ComputerSystems', complete: 'VehicleMaintenance'},
      {id: 3873, name: 'LossPrevention', complete: 'VehicleMaintenance'},
      {id: 3874, name: 'SteeringMechanisms', complete: 'VehicleMaintenance'},
      {
        id: 3875,
        name: 'AgriculturalMachinery',
        complete: 'VehicleMaintenance'
      },
      {id: 3876, name: 'Blueprinting', complete: 'VehicleMaintenance'},
      {id: 3877, name: 'LowVoltage', complete: 'VehicleMaintenance'},
      {
        id: 3878,
        name: 'IndustrialManagement',
        complete: 'VehicleMaintenance'
      },
      {
        id: 3879,
        name: 'DistributedCommonGroundSystem{DCGS}',
        complete: 'VehicleMaintenance'
      },
      {
        id: 3880,
        name: 'ContentRepositoryAPIForJava',
        complete: 'VehicleMaintenance'
      },
      {id: 3881, name: 'Instrumentation', complete: 'VehicleMaintenance'},
      {id: 3882, name: 'Uranium', complete: 'VehicleMaintenance'},
      {id: 3883, name: 'GIACCertifications', complete: 'VehicleMaintenance'},
      {
        id: 3884,
        name: 'CompTIACybersecurityAnalyst{CySA+}',
        complete: 'VehicleMaintenance'
      },
      {
        id: 3885,
        name: 'GIACSecurityEssentialsCertification',
        complete: 'VehicleMaintenance'
      },
      {
        id: 3886,
        name: 'PersonalProtectiveEquipment',
        complete: 'VehicleMaintenance'
      },
      {
        id: 3887,
        name: 'SystemsSecurityCertifiedPractitioner',
        complete: 'VehicleMaintenance'
      },
      {id: 3888, name: 'MaterialHandling', complete: 'VehicleMaintenance'},
      {id: 3889, name: 'ForeignExchangeRisk', complete: 'Legal'},
      {id: 3890, name: 'Patents', complete: 'Legal'},
      {id: 3891, name: 'Advising', complete: 'Legal'},
      {id: 3892, name: 'Filing', complete: 'Legal'},
      {id: 3893, name: 'Economy', complete: 'Legal'},
      {id: 3894, name: 'IntellectualProperty', complete: 'Legal'},
      {id: 3895, name: 'CorporateGovernance', complete: 'Legal'},
      {id: 3896, name: 'Writing', complete: 'Legal'},
      {id: 3897, name: 'Typing', complete: 'Legal'},
      {id: 3898, name: 'Punching{Cabling}', complete: 'Legal'},
      {id: 3899, name: 'Trademarks', complete: 'Legal'},
      {id: 3900, name: 'Securities{Finance}', complete: 'Legal'},
      {id: 3901, name: 'LegalResearch', complete: 'Legal'},
      {id: 3902, name: 'ClericalWorks', complete: 'Legal'},
      {id: 3903, name: 'Hedging', complete: 'Legal'},
      {id: 3904, name: 'PatentProsecution', complete: 'Legal'},
      {id: 3905, name: 'Negotiation', complete: 'Legal'},
      {id: 3906, name: 'LawEnforcement', complete: 'Legal'},
      {id: 3907, name: 'CalendaringSoftware', complete: 'Legal'},
      {id: 3908, name: 'ColdCalling', complete: 'Legal'},
      {id: 3909, name: 'PatentApplications', complete: 'Legal'},
      {id: 3910, name: 'PatentApplications', complete: 'Legal'},
      {id: 3911, name: 'Punctuation', complete: 'Legal'},
      {id: 3912, name: 'Docketing{Court}', complete: 'Legal'},
      {id: 3913, name: 'AccountingSystems', complete: 'Legal'},
      {id: 3914, name: 'AccountingSystems', complete: 'Legal'},
      {id: 3915, name: 'Governance', complete: 'Legal'},
      {id: 3916, name: 'Appeals', complete: 'Legal'},
      {id: 3917, complete: 'Legal'},
      {id: 3918, name: 'TelephoneSkills', complete: 'Legal'},
      {id: 3919, name: 'CashManagement', complete: 'Legal'},
      {id: 3920, name: 'ComputerSciences', complete: 'Legal'},
      {id: 3921, name: 'Auditing', complete: 'Legal'},
      {id: 3922, name: 'Spreadsheets', complete: 'Legal'},
      {id: 3923, name: 'DataEntry', complete: 'Legal'},
      {id: 3924, name: 'BusinessSavvy', complete: 'Legal'},
      {id: 3925, name: 'PatentInfringement', complete: 'Legal'},
      {id: 3926, name: 'JointVentures', complete: 'Legal'},
      {id: 3927, name: 'JuryInstructions', complete: 'Legal'},
      {id: 3928, name: 'DetailOriented', complete: 'Legal'},
      {
        id: 3929,
        name: 'GenerallyAcceptedAccountingPrinciples',
        complete: 'Legal'
      },
      {id: 3930, name: 'Chemistry', complete: 'Legal'},
      {id: 3931, name: 'CorporateLaws', complete: 'Legal'},
      {id: 3932, name: 'Enforcement', complete: 'Legal'},
      {id: 3933, name: 'Exhibitions', complete: 'Legal'},
      {id: 3934, name: 'Receivables', complete: 'Legal'},
      {id: 3935, name: 'Physics', complete: 'Legal'},
      {id: 3936, name: 'InvestmentsAdministrativeLaw', complete: 'Legal'},
      {id: 3937, name: 'SystemOperatorCertification', complete: 'Military'},
      {id: 3938, name: 'MineWarfare', complete: 'Military'},
      {
        id: 3939,
        name: 'BattleManagement{MilitaryTerminology}',
        complete: 'Military'
      },
      {id: 3940, name: 'ComputerDisplays', complete: 'Military'},
      {id: 3941, name: 'Guarding', complete: 'Military'},
      {id: 3942, name: 'SubmarineWarfare', complete: 'Military'},
      {id: 3943, name: 'WeAreaNetworks', complete: 'Military'},
      {id: 3944, name: 'NuclearPower', complete: 'Military'},
      {id: 3945, name: 'LocalAreaNetworks', complete: 'Military'},
      {id: 3946, name: 'Commissioning', complete: 'Military'},
      {id: 3947, name: 'DataIntelligence', complete: 'Military'},
      {id: 3948, name: 'Accounting', complete: 'Military'},
      {id: 3949, name: 'DigitalData', complete: 'Military'},
      {id: 3950, name: 'InfrastructureManagement', complete: 'Military'},
      {id: 3951, name: 'ComputerSystems', complete: 'Military'},
      {id: 3952, name: 'BusinessProcessOutsourcing', complete: 'Military'},
      {id: 3953, complete: 'Military'},
      {id: 3954, name: 'Interoperability', complete: 'Military'},
      {id: 3955, complete: 'Military'},
      {id: 3956, name: 'Procurement', complete: 'Military'},
      {id: 3957, name: 'CalmnessUnderPressure', complete: 'Military'},
      {id: 3958, name: 'MarketShare', complete: 'Military'},
      {
        id: 3959,
        name: 'Troubleshooting{ProblemSolving}',
        complete: 'Military'
      },
      {id: 3960, name: 'ServicesMarketing', complete: 'Military'},
      {id: 3961, name: 'AdvancedImagingTechnology', complete: 'Military'},
      {
        id: 3962,
        name: 'MasterOfBusinessAdministration{MBA}',
        complete: 'Military'
      },
      {id: 3963, name: 'Teamwork', complete: 'Military'},
      {
        id: 3964,
        name: 'ApplicationLifecycleManagement',
        complete: 'Military'
      },
      {id: 3965, name: 'BusinessProcess', complete: 'Military'},
      {id: 3966, name: 'Authorization{Computing}', complete: 'Military'},
      {id: 3967, name: 'Demolition', complete: 'Military'},
      {id: 3968, name: 'Trigonometry', complete: 'Military'},
      {id: 3969, name: 'Automation', complete: 'Military'},
      {
        id: 3970,
        name: 'ExplosivesDetectionAndDisposal',
        complete: 'Military'
      },
      {id: 3971, name: 'R{ProgrammingLanguage}', complete: 'Military'},
      {id: 3972, name: 'StockControl', complete: 'Military'},
      {id: 3973, name: 'RoadTrafficControls', complete: 'Military'},
      {id: 3974, name: 'CommandPostOfTheFuture', complete: 'Military'},
      {id: 3975, name: 'CommercialLaws', complete: 'Military'},
      {id: 3976, name: 'IndustrialManagement', complete: 'Military'},
      {
        id: 3977,
        name: 'DistributedCommonGroundSystem{DCGS}',
        complete: 'Military'
      },
      {id: 3978, name: 'ContentRepositoryAPIForJava', complete: 'Military'},
      {id: 3979, name: 'Instrumentation', complete: 'Military'},
      {id: 3980, name: 'Uranium', complete: 'Military'},
      {id: 3981, name: 'GIACCertifications', complete: 'Military'},
      {
        id: 3982,
        name: 'CompTIACybersecurityAnalyst{CySA+}',
        complete: 'Military'
      },
      {
        id: 3983,
        name: 'GIACSecurityEssentialsCertification',
        complete: 'Military'
      },
      {id: 3984, name: 'PersonalProtectiveEquipment', complete: 'Military'},
      {
        id: 3985,
        name: 'SystemsSecurityCertifiedPractitioner',
        complete: 'Military'
      },
      {id: 3986, name: 'MaterialHandling', complete: 'Military'},
      {
        id: 3987,
        name: 'Forestry',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3988,
        name: 'Troubleshooting{ProblemSolving}',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3989,
        name: 'WildlifeManagement',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3990,
        name: 'Thermodynamics',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3991,
        name: 'EnvironmentalHealth',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3992,
        name: 'FireSuppressionSystems',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3993,
        name: 'CorrosionControl',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3994,
        name: 'Agriculture',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3995,
        name: 'Physics',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3996,
        name: 'EnvironmentalLaws',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3997,
        name: 'WatershedManagement',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3998,
        name: 'CathodicProtection',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 3999,
        name: 'NaturalResourceManagement',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4000,
        name: 'HealthSciences',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4001,
        name: 'EnvironmentalCompliance',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4002,
        name: 'WildfireSuppression',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4003,
        name: 'Chemistry',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4004,
        name: 'ResourceManagement',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4005,
        name: 'Astronomy',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4006,
        name: 'WasteManagement',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {id: 4007, name: 'JPEG', complete: 'NaturalandEnviornmentalSciences'},
      {
        id: 4008,
        name: 'AnalyticalChemistry',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4009,
        name: 'Ecology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4010,
        name: 'ComputerEngineering',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4011,
        name: 'PollutionPrevention',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4012,
        name: 'DigitalSignature',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4013,
        name: 'GasChromatographyMassSpectrometry',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4014,
        name: 'PerformanceAppraisal',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4015,
        name: 'MechanicalEngineering',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4016,
        name: 'PublicAdministration',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4017,
        name: 'LanguageTranslation',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4018,
        name: 'High-PerformanceLiqu Chromatography',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4019,
        name: 'Genetics',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4020,
        name: 'Biostatistics',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4021,
        name: 'Sociology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4022,
        name: 'Sociology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4023,
        name: 'WetChemistry',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4024,
        name: 'Biology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4025,
        name: 'Pharmacology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4026,
        name: 'Auditing',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4027,
        name: 'FirePrevention',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4028,
        name: 'GasChromatography',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4029,
        name: 'Entomology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4030,
        name: 'IndustrialEngineering',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4031,
        name: 'Wastewater',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4032,
        name: 'Oceanography',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4033,
        name: 'GoodManufacturingPractices',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4034,
        name: 'Zoology',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4035,
        name: 'LifeSciences',
        complete: 'NaturalandEnviornmentalSciences'
      },
      {
        id: 4036,
        name: 'CustomerSatisfaction',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4037,
        name: 'Budgeting',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4038,
        name: 'Billing',
        complete: 'OfficeandAdministrationServices'
      },
      {id: 4039, name: 'Filing', complete: 'OfficeandAdministrationServices'},
      {
        id: 4040,
        name: 'Auditing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4041,
        name: 'Forecasting',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4042,
        name: 'Purchasing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4043,
        name: 'MedicalRecords',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4044,
        name: 'ClericalWorks',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4045,
        name: 'LossPrevention',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4046,
        name: 'Warehousing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4047,
        name: 'Procurement',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4048,
        name: 'MedicalTerminology',
        complete: 'OfficeandAdministrationServices'
      },
      {id: 4049, name: 'Typing', complete: 'OfficeandAdministrationServices'},
      {
        id: 4050,
        name: 'FinancialServices',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4051,
        name: 'CustomerExperience',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4052,
        name: 'Writing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4053,
        name: 'Collections',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4054,
        name: 'TelephoneSkills',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4055,
        name: 'InternalControls',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4056,
        name: 'StrategicPlanning',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4057,
        name: 'FacilityManagement',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4058,
        name: 'Medicare',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4059,
        name: 'DataEntry',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4060,
        name: 'MarketingCommunications',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4061,
        name: 'CallCenters',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4062,
        name: 'PublicRelations',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4063,
        name: 'Registration',
        complete: 'OfficeandAdministrationServices'
      },
      {id: 4064, name: 'Memos', complete: 'OfficeandAdministrationServices'},
      {
        id: 4065,
        name: 'PerformanceAppraisal',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4066,
        name: 'Telemarketing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4067,
        name: 'Editing',
        complete: 'OfficeandAdministrationServices'
      },
      {id: 4068, name: 'Medica', complete: 'OfficeandAdministrationServices'},
      {
        id: 4069,
        name: 'Punctuation',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4070,
        name: 'AdvertisingMail',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4071,
        name: 'FinancialAnalysis',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4072,
        name: 'FinancialManagement',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4073,
        name: 'MedicalBillingAndCoding',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4074,
        name: 'Transcribing',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4075,
        name: 'TotalReturn',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4076,
        name: 'Hospitality',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4077,
        name: 'Spreadsheets',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4078,
        name: 'PatientRegistration',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4079,
        name: 'SwitchboardOperator',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4080,
        name: 'Investments',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4081,
        name: 'KeyPerformanceIndicators{KPIs}',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4082,
        name: 'Telecommunications',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4083,
        name: 'ElectronicMedicalRecord',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4084,
        name: 'SecretarialExperience',
        complete: 'OfficeandAdministrationServices'
      },
      {
        id: 4085,
        name: 'DiscountsAndAllowances',
        complete: 'DiscountsAndAllowances'
      },
      {id: 4086, name: 'Geriatrics', complete: 'DiscountsAndAllowances'},
      {id: 4087, name: 'ControlSystems', complete: 'DiscountsAndAllowances'},
      {
        id: 4088,
        name: 'CurrentProceduralTerminology{C',
        complete: 'DiscountsAndAllowances'
      },
      {
        id: 4089,
        name: 'ContinuousImprovementProcess',
        complete: 'ProduationandManufacturing'
      },
      {id: 4090, name: 'Lathes', complete: 'ProduationandManufacturing'},
      {id: 4091, name: 'Welding', complete: 'ProduationandManufacturing'},
      {
        id: 4092,
        name: 'PackagingAndLabeling',
        complete: 'ProduationandManufacturing'
      },
      {id: 4093, name: 'Soldering', complete: 'ProduationandManufacturing'},
      {id: 4094, name: 'Soldering', complete: 'ProduationandManufacturing'},
      {id: 4095, name: 'Machining', complete: 'ProduationandManufacturing'},
      {
        id: 4096,
        name: 'MetalInertGas{MIG}Welding',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4097,
        name: 'GoodManufacturingPractices',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4098,
        name: 'ElectricalWirings',
        complete: 'ProduationandManufacturing'
      },
      {id: 4099, name: 'Auditing', complete: 'ProduationandManufacturing'},
      {id: 4100, name: 'Mills', complete: 'ProduationandManufacturing'},
      {
        id: 4101,
        name: 'GasMetalArcWelding',
        complete: 'ProduationandManufacturing'
      },
      {id: 4102, name: 'FoodSafety', complete: 'ProduationandManufacturing'},
      {id: 4103, name: 'Hydraulics', complete: 'ProduationandManufacturing'},
      {
        id: 4104,
        name: 'CorrectiveAndPreventiveAction{CAPA}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4105,
        name: 'ComputerNumericalControl{CNC}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4106,
        name: 'GasTungstenArcWelding',
        complete: 'ProduationandManufacturing'
      },
      {id: 4107, name: 'Warehousing', complete: 'ProduationandManufacturing'},
      {
        id: 4108,
        name: 'ElectronicComponents',
        complete: 'ProduationandManufacturing'
      },
      {id: 4109, name: 'Purchasing', complete: 'ProduationandManufacturing'},
      {id: 4110, name: 'Tooling', complete: 'ProduationandManufacturing'},
      {id: 4111, name: 'Fabrication', complete: 'ProduationandManufacturing'},
      {
        id: 4112,
        name: 'FinishedGood',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4113,
        name: 'MechanicalAssembly',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4114,
        name: 'SixSigmaMethodology',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4115,
        name: 'CuttingTool{Machining}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4116,
        name: 'Pipe{FluConveyance}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4117,
        name: 'ProductionLine',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4118,
        name: 'PowerToolOperation',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4119,
        name: 'KeyPerformanceIndicators{KPIs}',
        complete: 'ProduationandManufacturing'
      },
      {id: 4120, name: 'MetalLathes', complete: 'ProduationandManufacturing'},
      {id: 4121, name: 'Flux', complete: 'ProduationandManufacturing'},
      {id: 4122, name: 'Sanitation', complete: 'ProduationandManufacturing'},
      {
        id: 4123,
        name: 'PublicRelations',
        complete: 'ProduationandManufacturing'
      },
      {id: 4124, name: 'Procurement', complete: 'ProduationandManufacturing'},
      {id: 4125, name: 'Tolerance', complete: 'ProduationandManufacturing'},
      {
        id: 4126,
        name: 'Brazing{MetalWork}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4127,
        name: 'HazardAnalysisAndCriticalControlPoints{HACCP}',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4128,
        name: 'WarningSigns',
        complete: 'ProduationandManufacturing'
      },
      {id: 4129, complete: 'ProduationandManufacturing'},
      {id: 4130, name: 'Budgeting', complete: 'ProduationandManufacturing'},
      {
        id: 4131,
        name: 'Trigonometry',
        complete: 'ProduationandManufacturing'
      },
      {id: 4132, name: 'Aluminum', complete: 'ProduationandManufacturing'},
      {id: 4133, name: 'Palletizing', complete: 'ProduationandManufacturing'},
      {
        id: 4134,
        name: 'PrintedCircuitBoard',
        complete: 'ProduationandManufacturing'
      },
      {
        id: 4135,
        name: 'QualityAssurance',
        complete: 'ProduationandManufacturing'
      },
      {id: 4136, name: 'Geometry', complete: 'ProduationandManufacturing'},
      {
        id: 4137,
        name: 'WeldingSymbols',
        complete: 'ProduationandManufacturing'
      },
      {id: 4138, name: 'Forming', complete: 'ProtectiveServices'},
      {id: 4139, name: 'LawEnforcement', complete: 'ProtectiveServices'},
      {id: 4140, name: 'Firefighting', complete: 'ProtectiveServices'},
      {id: 4141, name: 'Vulnerability', complete: 'ProtectiveServices'},
      {
        id: 4142,
        name: 'InternalInvestigations',
        complete: 'ProtectiveServices'
      },
      {id: 4143, name: 'Accounting', complete: 'ProtectiveServices'},
      {id: 4144, name: 'WoodworkingMachines', complete: 'ProtectiveServices'},
      {id: 4145, name: 'Enforcement', complete: 'ProtectiveServices'},
      {id: 4146, name: 'FirePrevention', complete: 'ProtectiveServices'},
      {
        id: 4147,
        name: 'TopSecret-SensitiveCompartmentedInformation{TS/SCIClearance}',
        complete: 'ProtectiveServices'
      },
      {id: 4148, name: 'LossPrevention', complete: 'ProtectiveServices'},
      {id: 4149, name: 'Budgeting', complete: 'ProtectiveServices'},
      {id: 4150, name: 'Molding', complete: 'ProtectiveServices'},
      {id: 4151, name: 'ValDriverLicense', complete: 'ProtectiveServices'},
      {
        id: 4152,
        name: 'FireSuppressionSystems',
        complete: 'ProtectiveServices'
      },
      {id: 4153, name: 'SecurityPolicies', complete: 'ProtectiveServices'},
      {
        id: 4154,
        name: 'OperationalExcellence',
        complete: 'ProtectiveServices'
      },
      {id: 4155, name: 'Criminology', complete: 'ProtectiveServices'},
      {id: 4156, name: 'PlasticMaterials', complete: 'ProtectiveServices'},
      {
        id: 4157,
        name: 'CrimeScenes{ForensicEvence}',
        complete: 'ProtectiveServices'
      },
      {
        id: 4158,
        name: 'EmergencyMedicalServices',
        complete: 'ProtectiveServices'
      },
      {id: 4159, name: 'AccessControls', complete: 'ProtectiveServices'},
      {id: 4160, name: 'OperationalAuditing', complete: 'ProtectiveServices'},
      {id: 4161, name: 'Mathematics', complete: 'ProtectiveServices'},
      {id: 4162, name: 'GlassInstalling', complete: 'ProtectiveServices'},
      {id: 4163, name: 'Psychology', complete: 'ProtectiveServices'},
      {id: 4164, name: 'FireSafety', complete: 'ProtectiveServices'},
      {id: 4165, name: 'CyberSecurity', complete: 'ProtectiveServices'},
      {id: 4166, name: 'PhysicalSecurity', complete: 'ProtectiveServices'},
      {id: 4167, name: 'SearchWarrant', complete: 'ProtectiveServices'},
      {id: 4168, name: 'Punching{Cabling}', complete: 'ProtectiveServices'},
      {
        id: 4169,
        name: 'PsychologicalEvaluations',
        complete: 'ProtectiveServices'
      },
      {id: 4170, name: 'FireService', complete: 'ProtectiveServices'},
      {id: 4171, name: 'SecuritySystems', complete: 'ProtectiveServices'},
      {id: 4172, name: 'SecuritySystems', complete: 'ProtectiveServices'},
      {id: 4173, name: 'Auditing', complete: 'ProtectiveServices'},
      {
        id: 4174,
        name: 'BusinessAdministration',
        complete: 'ProtectiveServices'
      },
      {id: 4175, name: 'Polishing', complete: 'ProtectiveServices'},
      {
        id: 4176,
        name: 'CriminalInvestigation',
        complete: 'ProtectiveServices'
      },
      {id: 4177, name: 'FireAlarmSystems', complete: 'ProtectiveServices'},
      {id: 4178, name: 'InserThreat', complete: 'ProtectiveServices'},
      {
        id: 4179,
        name: 'OrganizedCrimeInvestigation',
        complete: 'ProtectiveServices'
      },
      {id: 4180, name: 'ComputerForensics', complete: 'ProtectiveServices'},
      {id: 4181, name: 'Casting', complete: 'ProtectiveServices'},
      {id: 4182, name: 'CommunityPolicing', complete: 'ProtectiveServices'},
      {
        id: 4183,
        name: 'IncentCommandSystems',
        complete: 'ProtectiveServices'
      },
      {id: 4184, name: 'BusinessContinuity', complete: 'ProtectiveServices'},
      {id: 4185, name: 'CustomerExperience', complete: 'ProtectiveServices'},
      {
        id: 4186,
        name: 'CertifiedFraudExaminer',
        complete: 'ProtectiveServices'
      },
      {id: 4187, name: 'Grinding', complete: 'ProtectiveServices'},
      {id: 4188, name: 'SalesandCustomerServices', complete: 'Grinding'},
      {id: 4189, name: 'SecondaryEducation', complete: 'Grinding'},
      {id: 4190, name: 'SecondaryEducation', complete: 'Grinding'},
      {id: 4191, name: 'ComplianceTraining', complete: 'Grinding'},
      {id: 4192, name: 'ComplianceTraining', complete: 'Grinding'},
      {id: 4193, name: 'ComplianceTraining', complete: 'Grinding'},
      {id: 4194, complete: 'Grinding'},
      {id: 4195, name: 'ComplianceTraining', complete: 'Grinding'},
      {id: 4196, name: 'MechanicalAptitude', complete: 'Grinding'},
      {id: 4197, name: 'MechanicalAptitude', complete: 'Grinding'},
      {id: 4198, name: 'MechanicalAptitude', complete: 'Grinding'},
      {id: 4199, name: 'MechanicalAptitude', complete: 'Grinding'},
      {id: 4200, name: 'JPEG', complete: 'Grinding'},
      {id: 4201, complete: 'Grinding'},
      {id: 4202, name: 'SQL{ProgrammingLanguage}', complete: 'Grinding'},
      {id: 4203, name: 'AllTerrainVehicles', complete: 'Grinding'},
      {id: 4204, complete: 'Grinding'},
      {id: 4205, name: 'DemonstrationSkills', complete: 'Sales'},
      {id: 4206, name: 'Forecasting', complete: 'Sales'},
      {id: 4207, name: 'Franchising', complete: 'Sales'},
      {id: 4208, name: 'PharmaceuticalSales', complete: 'Sales'},
      {id: 4209, name: 'BrandAwareness', complete: 'Sales'},
      {id: 4210, name: 'StrategicPlanning', complete: 'Sales'},
      {id: 4211, name: 'StrategicPlanning', complete: 'Sales'},
      {id: 4212, name: 'BusinessSoftware', complete: 'Sales'},
      {id: 4213, name: 'MedicalSales', complete: 'Sales'},
      {id: 4214, name: 'ProductDemonstration', complete: 'Sales'},
      {id: 4215, name: 'Salesforce.Com', complete: 'Sales'},
      {id: 4216, name: 'Branding', complete: 'Sales'},
      {id: 4217, complete: 'Sales'},
      {id: 4218, name: 'SocialMedia', complete: 'Sales'},
      {id: 4219, name: 'Cooking', complete: 'Sales'},
      {id: 2816, name: 'AdobePhotoshop', complete: 'Education'},
      {
        id: 2817,
        name: 'AutomatedExternalDefibrillator',
        complete: 'Education'
      },
      {id: 2818, name: 'PreschoolEducation', complete: 'Education'},
      {id: 2819, name: 'LearningStyles', complete: 'Education'},
      {
        id: 2820,
        name: 'HyperTextMarkupLanguage{HTML}',
        complete: 'Education'
      },
      {id: 2821, name: 'Immunization', complete: 'Education'},
      {id: 2822, name: 'Advocacy', complete: 'Education'},
      {id: 2823, name: 'Advising', complete: 'Education'},
      {
        id: 2824,
        name: 'Troubleshooting{ProblemSolving}',
        complete: 'Education'
      },
      {id: 2825, name: 'Measles', complete: 'Education'},
      {id: 2826, name: 'EarlyChildhoodEducation', complete: 'Education'},
      {id: 2827, name: 'Psychology', complete: 'Education'},
      {id: 2828, name: 'ProjectManagement', complete: 'Education'},
      {id: 2829, name: 'ToiletTraining', complete: 'Education'},
      {id: 2830, name: 'ComputerSciences', complete: 'Education'},
      {id: 2831, name: 'ClassroomManagement', complete: 'Education'},
      {id: 2832, name: 'Multimedia', complete: 'Education'},
      {id: 2833, name: 'Vaccination', complete: 'Education'},
      {id: 2834, name: 'Theology', complete: 'Education'},
      {id: 2835, complete: 'Education'},
      {id: 2836, name: 'FederalLaws', complete: 'EducationAdministraion'},
      {id: 2837, name: 'Workflows', complete: 'EducationAdministraion'},
      {id: 2838, name: 'FoodPrograms', complete: 'EducationAdministraion'},
      {
        id: 2839,
        name: 'FamilyEducationalRightsAndPriv',
        complete: 'EducationAdministraion'
      },
      {
        id: 2840,
        name: 'CorrectiveAndPreventiveAction{CAPA}',
        complete: 'EducationAdministraion'
      },
      {id: 2841, name: 'Salesforce.Com', complete: 'EducationAdministraion'},
      {id: 2842, name: 'Imagination', complete: 'EducationAdministraion'},
      {
        id: 2843,
        name: 'Student-CentredLearning',
        complete: 'EducationAdministraion'
      },
      {id: 2844, name: 'Writing', complete: 'EducationAdministraion'},
      {id: 2845, name: 'Surveys', complete: 'EducationAdministraion'},
      {id: 2846, name: 'Immunization', complete: 'EducationAdministraion'},
      {id: 2847, name: 'CostManagement', complete: 'EducationAdministraion'},
      {
        id: 2848,
        name: 'CollectiveBargaining',
        complete: 'EducationAdministraion'
      },
      {id: 2849, name: 'Forecasting', complete: 'EducationAdministraion'},
      {id: 2850, name: 'HumanServices', complete: 'EducationAdministraion'},
      {
        id: 2851,
        name: 'AcademicIntegrity',
        complete: 'EducationAdministraion'
      },
      {id: 2852, name: 'StateLaws', complete: 'EducationAdministraion'},
      {id: 2853, name: 'DataIntegrity', complete: 'EducationAdministraion'},
      {
        id: 2854,
        name: 'HumanDevelopment',
        complete: 'EducationAdministraion'
      },
      {id: 2855, name: 'HealthSciences', complete: 'EducationAdministraion'},
      {
        id: 2856,
        name: 'ConflictResolution',
        complete: 'EducationAdministraion'
      },
      {
        id: 2857,
        name: 'Troubleshooting{roblemSolving}',
        complete: 'EducationAdministraion'
      },
      {
        id: 2858,
        name: 'EmergentCurriculum',
        complete: 'EducationAdministraion'
      },
      {id: 2859, name: 'SocialSciences', complete: 'EducationAdministraion'},
      {
        id: 2860,
        name: 'ChildDevelopment',
        complete: 'EducationAdministraion'
      },
      {id: 2861, name: 'HigherEducation', complete: 'EducationAdministraion'},
      {id: 2862, name: 'Budgeting', complete: 'EducationAdministraion'},
      {
        id: 2863,
        name: 'StudentInformationSystems',
        complete: 'EducationAdministraion'
      },
      {
        id: 2864,
        name: 'PreschoolEducation',
        complete: 'EducationAdministraion'
      },
      {
        id: 2865,
        name: 'EnrollmentManagement',
        complete: 'EducationAdministraion'
      },
      {
        id: 2866,
        name: 'SoftwareDevelopmentLifeCycle',
        complete: 'EducationAdministraion'
      },
      {id: 2867, name: 'Auditing', complete: 'EducationAdministraion'},
      {id: 2868, name: 'FirstA', complete: 'EducationAdministraion'},
      {id: 2869, name: 'Advising', complete: 'EducationAdministraion'},
      {id: 2870, name: 'Governance', complete: 'EducationAdministraion'},
      {
        id: 2871,
        name: 'CustomerRelationshipManagement',
        complete: 'EducationAdministraion'
      },
      {
        id: 2872,
        name: 'CardiopulmonaryResuscitation',
        complete: 'EducationAdministraion'
      },
      {
        id: 2873,
        name: 'StrategicPlanning',
        complete: 'EducationAdministraion'
      },
      {
        id: 2874,
        name: 'ProgramEvaluations',
        complete: 'EducationAdministraion'
      },
      {id: 2875, name: 'Registration', complete: 'EducationAdministraion'},
      {
        id: 2876,
        name: 'EarlyChildhoodEducation',
        complete: 'EducationAdministraion'
      },
      {
        id: 2877,
        name: 'HigherEducationAdministration',
        complete: 'EducationAdministraion'
      },
      {id: 2878, name: 'Loans', complete: 'EducationAdministraion'},
      {
        id: 2879,
        name: 'PrimaryEducation',
        complete: 'EducationAdministraion'
      },
      {
        id: 2880,
        name: 'InstitutionalResearch',
        complete: 'EducationAdministraion'
      },
      {id: 2881, name: 'Nursing', complete: 'EducationAdministraion'},
      {
        id: 2882,
        name: 'FinancialServices',
        complete: 'EducationAdministraion'
      },
      {
        id: 2883,
        name: 'FinancialServices',
        complete: 'EducationAdministraion'
      },
      {
        id: 2884,
        name: 'FinancialServices',
        complete: 'EducationAdministraion'
      },
      {id: 2885, name: 'Sterilization', complete: 'DentistryandOrthodontia'},
      {
        id: 2886,
        name: 'ScalingAndRootPlaning',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2887,
        name: 'DoctorOfDentalSurgery',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2888,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2889, name: 'Sealants', complete: 'DentistryandOrthodontia'},
      {
        id: 2890,
        name: 'DoctorofDentalMedicine',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2891, name: 'Radiography', complete: 'DentistryandOrthodontia'},
      {id: 2892, name: 'Prophylaxis', complete: 'DentistryandOrthodontia'},
      {id: 2893, name: 'MedicalRecords', complete: 'DentistryandOrthodontia'},
      {
        id: 2894,
        name: 'InfectionControl',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2895, name: 'Debrement', complete: 'DentistryandOrthodontia'},
      {id: 2896, name: 'Pediatrics', complete: 'DentistryandOrthodontia'},
      {
        id: 2897,
        name: 'CardiopulmonaryResuscitation',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2898, name: 'Periodontology', complete: 'DentistryandOrthodontia'},
      {id: 2899, name: 'Malpractice', complete: 'DentistryandOrthodontia'},
      {id: 2900, name: 'Teamwork', complete: 'DentistryandOrthodontia'},
      {id: 2901, name: 'Lesion', complete: 'DentistryandOrthodontia'},
      {
        id: 2902,
        name: 'LeadershipDevelopment',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2903,
        name: 'MedicalEmergency',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2904, name: 'Cancer', complete: 'DentistryandOrthodontia'},
      {id: 2905, name: 'Collections', complete: 'DentistryandOrthodontia'},
      {id: 2906, name: 'BloodPressure', complete: 'DentistryandOrthodontia'},
      {id: 2907, name: 'OralHygiene', complete: 'DentistryandOrthodontia'},
      {
        id: 2908,
        name: 'MedicalResearch',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2909,
        name: 'PersonalProtectiveEquipment',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2910,
        name: 'TreatmentPlanning',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2911,
        name: 'MedicalEducation',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2912,
        name: 'PhysicianPatientPrivilege',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2913, name: 'Hygiene', complete: 'DentistryandOrthodontia'},
      {
        id: 2914,
        name: 'LiabilityInsurance',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2915,
        name: 'PatientHealthQuestionnaires',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2916,
        name: 'LocalAnesthesia',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2917, name: 'Loans', complete: 'DentistryandOrthodontia'},
      {id: 2918, name: 'Radiology', complete: 'DentistryandOrthodontia'},
      {
        id: 2919,
        name: 'DentalAntibioticProphylaxis',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2920,
        name: 'SettingAppointments',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2921,
        name: 'IntraoralCameras',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2922,
        name: 'SpanishLanguage',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2923, name: 'Calculus', complete: 'DentistryandOrthodontia'},
      {id: 2924, name: 'Transcribing', complete: 'DentistryandOrthodontia'},
      {
        id: 2925,
        name: 'OralCancerScreening',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2926, name: 'Trimming', complete: 'DentistryandOrthodontia'},
      {id: 2927, name: 'HomeCare', complete: 'DentistryandOrthodontia'},
      {
        id: 2928,
        name: 'BasicLifeSupport',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2929, name: 'Sharpening', complete: 'DentistryandOrthodontia'},
      {
        id: 2930,
        name: 'DentalMaterials',
        complete: 'DentistryandOrthodontia'
      },
      {
        id: 2931,
        name: 'HealthEducation',
        complete: 'DentistryandOrthodontia'
      },
      {id: 2932, name: 'Anatomy', complete: 'DentistryandOrthodontia'},
      {id: 2933, name: 'Curettage', complete: 'DentistryandOrthodontia'},
      {id: 2934, name: 'Algorithms', complete: 'DieteticsandAudiology'},
      {id: 2935, name: 'Audiology', complete: 'DieteticsandAudiology'},
      {
        id: 2936,
        name: 'NutritionEducation',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2937,
        name: 'NutritionIntervention',
        complete: 'DieteticsandAudiology'
      },
      {id: 2938, name: 'Hemodialysis', complete: 'DieteticsandAudiology'},
      {id: 2939, name: 'HearingDisorders', complete: 'DieteticsandAudiology'},
      {id: 2940, name: 'PublicHealth', complete: 'DieteticsandAudiology'},
      {id: 2941, name: 'MedicalRecords', complete: 'DieteticsandAudiology'},
      {
        id: 2942,
        name: 'ComprehensivePlanning',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2943,
        name: 'CertificateOfClinicalCompetenceInAudiology',
        complete: 'DieteticsandAudiology'
      },
      {id: 2944, name: 'Writing', complete: 'DieteticsandAudiology'},
      {
        id: 2945,
        name: 'NutritionCarePlans',
        complete: 'DieteticsandAudiology'
      },
      {id: 2946, name: 'TeamBuilding', complete: 'DieteticsandAudiology'},
      {
        id: 2947,
        name: 'HearingInstrumentSpecialist',
        complete: 'DieteticsandAudiology'
      },
      {id: 2948, name: 'Purchasing', complete: 'DieteticsandAudiology'},
      {id: 2949, name: 'Registration', complete: 'DieteticsandAudiology'},
      {
        id: 2950,
        name: 'DiseaseManagement',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2951,
        name: 'ProgramManagement',
        complete: 'DieteticsandAudiology'
      },
      {id: 2952, name: 'Cooking', complete: 'DieteticsandAudiology'},
      {
        id: 2953,
        name: 'ManagementTraining',
        complete: 'DieteticsandAudiology'
      },
      {id: 2954, name: 'Metabolism', complete: 'DieteticsandAudiology'},
      {id: 2955, name: 'HearingTesting', complete: 'DieteticsandAudiology'},
      {
        id: 2956,
        name: 'CommunityNutrition',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2957,
        name: 'RegulatoryCompliance',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2958,
        name: 'PsychosocialAssessments',
        complete: 'DieteticsandAudiology'
      },
      {id: 2959, name: 'CochlearImplants', complete: 'DieteticsandAudiology'},
      {id: 2960, name: 'SpanishLanguage', complete: 'DieteticsandAudiology'},
      {id: 2961, name: 'Teaching', complete: 'DieteticsandAudiology'},
      {id: 2962, name: 'Malnutrition', complete: 'DieteticsandAudiology'},
      {id: 2963, name: 'OfficerTraining', complete: 'DieteticsandAudiology'},
      {id: 2964, name: 'WeightManagement', complete: 'DieteticsandAudiology'},
      {id: 2965, name: 'Billing', complete: 'DieteticsandAudiology'},
      {id: 2966, name: 'Prescription', complete: 'DieteticsandAudiology'},
      {
        id: 2967,
        name: 'HearingConservationPrograms',
        complete: 'DieteticsandAudiology'
      },
      {id: 2968, name: 'Biochemistry', complete: 'DieteticsandAudiology'},
      {
        id: 2969,
        name: 'ParenteralNutrition',
        complete: 'DieteticsandAudiology'
      },
      {id: 2970, name: 'Caregiving', complete: 'DieteticsandAudiology'},
      {id: 2971, name: 'Audiometer', complete: 'DieteticsandAudiology'},
      {
        id: 2972,
        name: 'DemonstrationSkills',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2973,
        name: 'NutritionDiagnosis',
        complete: 'DieteticsandAudiology'
      },
      {id: 2974, name: 'Dialysis', complete: 'DieteticsandAudiology'},
      {id: 2975, name: 'Audiometry', complete: 'DieteticsandAudiology'},
      {id: 2976, name: 'HealthPromotion', complete: 'DieteticsandAudiology'},
      {id: 2977, name: 'ModifiedDiets', complete: 'DieteticsandAudiology'},
      {
        id: 2978,
        name: 'QualityManagement',
        complete: 'DieteticsandAudiology'
      },
      {
        id: 2979,
        name: 'CertificateOfClinicalCompetenceInSpeech-LanguagePathology',
        complete: 'DieteticsandAudiology'
      },
      {id: 2980, name: 'FoodPreparation', complete: 'DieteticsandAudiology'},
      {
        id: 2981,
        name: 'CertifiedNutritionSupportClinician',
        complete: 'DieteticsandAudiology'
      },
      {id: 2982, name: 'QualityAssurance', complete: 'DieteticsandAudiology'},
      {id: 2983, name: 'UtilizationManagement', complete: 'Healthcare,Other'},
      {id: 2984, name: 'StrategicPlanning', complete: 'Healthcare,Other'},
      {id: 2985, name: 'ClinicalTrials', complete: 'Healthcare,Other'},
      {id: 2986, name: 'AssistedLiving', complete: 'Healthcare,Other'},
      {id: 2987, name: 'BasicLifeSupport', complete: 'Healthcare,Other'},
      {id: 2988, name: 'Medicare', complete: 'Healthcare,Other'},
      {id: 2989, name: 'MedicalManagement', complete: 'Healthcare,Other'},
      {id: 2990, name: 'Budgeting', complete: 'Healthcare,Other'},
      {id: 2991, name: 'ClinicalResearch', complete: 'Healthcare,Other'},
      {
        id: 2992,
        name: 'CardiopulmonaryResuscitation',
        complete: 'Healthcare,Other'
      },
      {
        id: 2993,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'Healthcare,Other'
      },
      {id: 2994, name: 'Medica', complete: 'Healthcare,Other'},
      {id: 2995, name: 'MedicalNecessity', complete: 'Healthcare,Other'},
      {id: 2996, name: 'HealthAdministration', complete: 'Healthcare,Other'},
      {id: 2997, name: 'DoctorOfPharmacy', complete: 'Healthcare,Other'},
      {id: 2998, name: 'Long-TermCare', complete: 'Healthcare,Other'},
      {
        id: 2999,
        name: 'BachelorofScienceinNursing',
        complete: 'Healthcare,Other'
      },
      {
        id: 3000,
        name: 'HealthcareEffectivenessDataAndInformationSet',
        complete: 'Healthcare,Other'
      },
      {id: 3001, name: 'Appeals', complete: 'Healthcare,Other'},
      {
        id: 3002,
        name: 'CorrectiveAndPreventiveAction',
        complete: 'Healthcare,Other'
      },
      {
        id: 3003,
        name: 'Pre-ClinicalDevelopment',
        complete: 'Healthcare,Other'
      },
      {id: 3004, name: 'SocialWork', complete: 'Healthcare,Other'},
      {id: 3005, name: 'Nursing', complete: 'Healthcare,Other'},
      {id: 3006, name: 'QualityManagement', complete: 'Healthcare,Other'},
      {id: 3007, name: 'ManagedCare', complete: 'Healthcare,Other'},
      {id: 3008, name: 'FinancialAnalysis', complete: 'Healthcare,Other'},
      {id: 3009, name: 'Oncology', complete: 'Healthcare,Other'},
      {id: 3010, name: 'MentalHealth', complete: 'Healthcare,Other'},
      {
        id: 3011,
        name: 'PediatricAdvancedLifeSupport',
        complete: 'Healthcare,Other'
      },
      {id: 3012, name: 'MedicareAdvantage', complete: 'Healthcare,Other'},
      {id: 3013, name: 'DischargePlanning', complete: 'Healthcare,Other'},
      {id: 3014, name: 'Billing', complete: 'Healthcare,Other'},
      {id: 3015, name: 'DrugDevelopment', complete: 'Healthcare,Other'},
      {
        id: 3016,
        name: 'LicensedClinicalSocialWorker',
        complete: 'Healthcare,Other'
      },
      {id: 3017, name: 'CriticalCare', complete: 'Healthcare,Other'},
      {id: 3018, name: 'Auditing', complete: 'Healthcare,Other'},
      {
        id: 3019,
        name: 'InterpersonalCommunications',
        complete: 'Healthcare,Other'
      },
      {
        id: 3020,
        name: 'PerformanceImprovement',
        complete: 'Healthcare,Other'
      },
      {id: 3021, name: 'Biotechnology', complete: 'Healthcare,Other'},
      {id: 3022, name: 'ValDriverLicense', complete: 'Healthcare,Other'},
      {id: 3023, name: 'OperatingTheater', complete: 'Healthcare,Other'},
      {id: 3024, name: 'HealthPolicies', complete: 'Healthcare,Other'},
      {id: 3025, name: 'CaseManagement', complete: 'Healthcare,Other'},
      {id: 3026, name: 'FinancialManagement', complete: 'Healthcare,Other'},
      {id: 3027, name: 'GoodClinicalPractices', complete: 'Healthcare,Other'},
      {id: 3028, name: 'InfectionControl', complete: 'Healthcare,Other'},
      {id: 3029, name: 'Surgeries', complete: 'Healthcare,Other'},
      {id: 3030, name: 'MedicalRecords', complete: 'Healthcare,Other'},
      {id: 3031, name: 'TreatmentPlanning', complete: 'Healthcare,Other'},
      {id: 3032, name: 'Psychiatry', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3033, name: 'Oncology', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3034, name: 'Surgeries', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3035, name: 'Nephrology', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3036, name: 'Gynaecology', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3037, name: 'Psychology', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3038, name: 'Neurology', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3039,
        name: 'GeneralSurgery',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3040, name: 'Pulmonology', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3041,
        name: 'ObstetricsAndGynaecology',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3042,
        name: 'MentalHealth',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3043, name: 'WoundCare', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3044,
        name: 'OrthopedicSurgery',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3045,
        name: 'CriticalCare',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3046,
        name: 'PediatricAdvancedLifeSupport',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3047,
        name: 'Psychiatric-MentalHealthNursePractitioner',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3048, name: 'Infection', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3049, name: 'Anesthesias', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3050,
        name: 'Gastroenterology',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3051,
        name: 'ChildAndFamilyServices',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3052,
        name: 'TreatmentPlanning',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3053,
        name: 'MagneticResonanceImaging',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3054,
        name: 'CardiothoracicSurgeries',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3055,
        name: 'ObstetricalNursing',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3056,
        name: 'MedicalRecords',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3057, name: 'Ultrasound', complete: 'MedicalDaigonsisandSurgery'},
      {id: 3058, name: 'Trauma', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3059,
        name: 'Endocrinology',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3060,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3061,
        name: 'Neuropsychiatry',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3062,
        name: 'PediatricOncology',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3063,
        name: 'Anesthesiology',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3064,
        name: 'PediatricEmergencyMedicine',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3065,
        name: 'MedicalUltrasonography',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3066,
        name: 'BehavioralHealth',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3067,
        name: 'PediatricNursing',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3068,
        name: 'Neurosurgeries',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3069, name: 'Pediatrics', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3070,
        name: 'ConsumerPackagedGoods',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3071,
        name: 'MentalDiseases',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3072,
        name: 'MedicalAssistance',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3073, name: 'Cardiology', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 3074,
        name: 'IntensiveCareMedicine',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3075,
        name: 'AdultTraumaLifeSupport',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3076,
        name: 'SubstanceAbuse',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3077,
        name: 'Electromyography',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3078,
        name: 'CardiacSurgeries',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 3079,
        name: 'HospitalMedicine',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 3080, name: 'Ultrasound', complete: 'MedicalImaging/Equipment'},
      {
        id: 3081,
        name: 'RadiationProtection',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3082,
        name: 'Electrocardiography',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3083, name: 'Sonographer', complete: 'MedicalImaging/Equipment'},
      {
        id: 3084,
        name: 'QualityControl',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3085,
        name: 'CardiacDysrhythmia',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3086,
        name: 'MedicalUltrasonography',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3087, name: 'Anatomy', complete: 'MedicalImaging/Equipment'},
      {id: 3088, name: 'HolterMonitor', complete: 'MedicalImaging/Equipment'},
      {
        id: 3089,
        name: 'RegisteredVascularTechnologist',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3090,
        name: 'InfectionControl',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3091,
        name: 'CardiacCatheterization',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3092,
        name: 'ObstetricsAndGynaecology',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3093,
        name: 'SterileTechniques',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3094, name: 'Telemetry', complete: 'MedicalImaging/Equipment'},
      {
        id: 3095,
        name: 'Echocardiography',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3096,
        name: 'MedicalRecords',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3097, name: 'Cardiology', complete: 'MedicalImaging/Equipment'},
      {
        id: 3098,
        name: 'RegisteredDiagnosticCardiacSonographer',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3099, name: 'Asepsis', complete: 'MedicalImaging/Equipment'},
      {
        id: 3100,
        name: 'RegisteredCardiovascularInvasiveSpecialist',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3101,
        name: 'RegisteredDiagnosticMedicalSonographer',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3102,
        name: 'QualityAssurance',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3103,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3104,
        name: 'DiagnosticMedicalSonography',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3105, name: 'ImageQuality', complete: 'MedicalImaging/Equipment'},
      {id: 3106, name: 'Oncology', complete: 'MedicalImaging/Equipment'},
      {
        id: 3107,
        name: 'DopplerUltrasonography',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3108, name: 'Physiology', complete: 'MedicalImaging/Equipment'},
      {
        id: 3109,
        name: 'Electrophysiology',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3110,
        name: 'TransesophagealEchocardiogram',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3111,
        name: 'PatientEducation',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3112,
        name: 'RadiationTherapy',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3113, name: 'Gynaecology', complete: 'MedicalImaging/Equipment'},
      {id: 3114, name: 'Registration', complete: 'MedicalImaging/Equipment'},
      {
        id: 3115,
        name: 'RadiationOncology',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3116,
        name: 'RegisteredCardiacSonographer',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3117,
        name: 'AsepticTechniques',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3118,
        name: 'TreatmentPlanning',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3119,
        name: 'CommunicationsSystems',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3120,
        name: 'SafetyStandards',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 3121,
        name: 'ArtificialCardiacPacemakers',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3122, name: 'Duplex', complete: 'MedicalImaging/Equipment'},
      {id: 3123, name: 'Pathology', complete: 'MedicalImaging/Equipment'},
      {id: 3124, name: 'StressTesting', complete: 'MedicalImaging/Equipment'},
      {
        id: 3125,
        name: 'RecreationalVehicles',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 3126, name: 'Billing', complete: 'MedicalImaging/Equipment'},
      {id: 3127, name: 'Hemodynamics', complete: 'MedicalImaging/Equipment'},
      {id: 3128, name: 'PelvicFloor', complete: 'MedicalImaging/Equipment'},
      {id: 3129, name: 'Phlebotomy', complete: 'MedicalSupport'},
      {id: 3130, name: 'Venipuncture', complete: 'MedicalSupport'},
      {id: 3131, name: 'ClericalWorks', complete: 'MedicalSupport'},
      {id: 3132, name: 'Billing', complete: 'MedicalSupport'},
      {id: 3133, name: 'DataEntry', complete: 'MedicalSupport'},
      {id: 3134, name: 'Collections', complete: 'MedicalSupport'},
      {id: 3135, name: 'Electrocardiography', complete: 'MedicalSupport'},
      {id: 3136, name: 'Capillary', complete: 'MedicalSupport'},
      {id: 3137, name: 'GoodDrivingRecord', complete: 'MedicalSupport'},
      {id: 3138, name: 'SpecimenProcessing', complete: 'MedicalSupport'},
      {id: 3139, name: 'Pediatrics', complete: 'MedicalSupport'},
      {id: 3140, name: 'PackagingAndLabeling', complete: 'MedicalSupport'},
      {id: 3141, name: 'PatientRegistration', complete: 'MedicalSupport'},
      {id: 3142, name: 'HouseCall', complete: 'MedicalSupport'},
      {id: 3143, name: 'EmployeeHandbooks', complete: 'MedicalSupport'},
      {
        id: 3144,
        name: 'StandardOperatingProcedure',
        complete: 'MedicalSupport'
      },
      {id: 3145, name: 'MedicalLaboratory', complete: 'MedicalSupport'},
      {id: 3146, name: 'MedicalTerminology', complete: 'MedicalSupport'},
      {id: 3147, name: 'QualityControl', complete: 'MedicalSupport'},
      {id: 3148, name: 'Geriatrics', complete: 'MedicalSupport'},
      {
        id: 3149,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'MedicalSupport'
      },
      {
        id: 3150,
        name: 'PersonalProtectiveEquipment',
        complete: 'MedicalSupport'
      },
      {id: 3151, name: 'LabTests', complete: 'MedicalSupport'},
      {id: 3152, name: 'BloodDonationDrives', complete: 'MedicalSupport'},
      {id: 3153, name: 'AdministeringBloodTests', complete: 'MedicalSupport'},
      {id: 3154, name: 'QualityAssurance', complete: 'MedicalSupport'},
      {id: 3155, name: 'DiagnosticTests', complete: 'MedicalSupport'},
      {
        id: 3156,
        name: 'OccupationalSafetyAndHealth',
        complete: 'MedicalSupport'
      },
      {id: 3157, name: 'Filing', complete: 'MedicalSupport'},
      {id: 3158, name: 'FineMotorSkills', complete: 'MedicalSupport'},
      {
        id: 3159,
        name: 'CertifiedPhlebotomyTechnician',
        complete: 'MedicalSupport'
      },
      {
        id: 3160,
        name: 'GoodManufacturingPractices',
        complete: 'MedicalSupport'
      },
      {id: 3161, name: 'DetailOriented', complete: 'MedicalSupport'},
      {id: 3162, name: 'CPTCoding', complete: 'MedicalSupport'},
      {
        id: 3163,
        name: 'ElectronicReportingSystems',
        complete: 'MedicalSupport'
      },
      {id: 3164, name: 'Troubleshooting', complete: 'MedicalSupport'},
      {id: 3165, name: 'Plasmapheresis', complete: 'MedicalSupport'},
      {id: 3166, name: 'FrontOffice', complete: 'MedicalSupport'},
      {id: 3167, name: 'DrugAdministration', complete: 'MedicalSupport'},
      {id: 3168, name: 'Point-Of-CareTesting', complete: 'MedicalSupport'},
      {id: 3169, name: 'Fingerstick', complete: 'MedicalSupport'},
      {id: 3170, name: 'Balancing', complete: 'MedicalSupport'},
      {id: 3171, name: 'Automation', complete: 'MedicalSupport'},
      {id: 3172, name: 'Typing', complete: 'MedicalSupport'},
      {
        id: 3173,
        name: 'PersonalDigitalAssistant',
        complete: 'MedicalSupport'
      },
      {
        id: 3174,
        name: 'CertifiedCodingSpecialist',
        complete: 'MedicalTechnology'
      },
      {id: 3175, name: 'MedicalLaboratory', complete: 'MedicalTechnology'},
      {id: 3176, name: 'SurgicalProcedures', complete: 'MedicalTechnology'},
      {id: 3177, name: 'Radiology', complete: 'MedicalTechnology'},
      {
        id: 3178,
        name: 'CorrectiveAndPreventiveAction',
        complete: 'MedicalTechnology'
      },
      {id: 3179, name: 'MedicalRecords', complete: 'MedicalTechnology'},
      {id: 3180, name: 'MedicalTechnologist', complete: 'MedicalTechnology'},
      {id: 3181, name: 'Surgeries', complete: 'MedicalTechnology'},
      {
        id: 3182,
        name: 'RadiologicTechnologist',
        complete: 'MedicalTechnology'
      },
      {
        id: 3183,
        name: 'HealthInsurancePortabilityAndAccountabilityAct',
        complete: 'MedicalTechnology'
      },
      {
        id: 3184,
        name: 'RegisteredHealthInformationTechnician',
        complete: 'MedicalTechnology'
      },
      {id: 3185, name: 'HealthTechnology', complete: 'MedicalTechnology'},
      {id: 3186, name: 'OperatingTheater', complete: 'MedicalTechnology'},
      {id: 3187, name: 'Radiography', complete: 'MedicalTechnology'},
      {id: 3188, name: 'QualityAssurance', complete: 'MedicalTechnology'},
      {
        id: 3189,
        name: 'CertifiedProfessionalCoder',
        complete: 'MedicalTechnology'
      },
      {id: 3190, name: 'QualityControl', complete: 'MedicalTechnology'},
      {id: 3191, name: 'Asepsis', complete: 'MedicalTechnology'},
      {
        id: 3192,
        name: 'AmericanRegistryOfRadiologicTechnologists',
        complete: 'MedicalTechnology'
      },
      {id: 3193, name: 'Teamwork', complete: 'MedicalTechnology'},
      {id: 3194, name: 'CPTCoding', complete: 'MedicalTechnology'},
      {id: 3195, name: 'Chemistry', complete: 'MedicalTechnology'},
      {id: 3196, name: 'AsepticTechniques', complete: 'MedicalTechnology'},
      {id: 3197, name: 'Ultrasound', complete: 'MedicalTechnology'},
      {id: 3198, name: 'QualityManagement', complete: 'MedicalTechnology'},
      {
        id: 3199,
        name: 'RegisteredHealthInformationAdministrator',
        complete: 'MedicalTechnology'
      },
      {id: 3200, name: 'Biology', complete: 'MedicalTechnology'},
      {id: 3201, name: 'SurgicalInstruments', complete: 'MedicalTechnology'},
      {
        id: 3202,
        name: 'MagneticResonanceImaging',
        complete: 'MedicalTechnology'
      },
      {id: 3203, name: 'Writing', complete: 'MedicalTechnology'},
      {
        id: 3204,
        name: 'MedicalBillingAndCoding',
        complete: 'MedicalTechnology'
      },
      {id: 3205, name: 'Microbiology', complete: 'MedicalTechnology'},
      {
        id: 3206,
        name: 'CertifiedSurgicalTechnologist',
        complete: 'MedicalTechnology'
      },
      {id: 3207, name: 'Anesthesias', complete: 'MedicalTechnology'},
      {id: 3208, name: 'InterpersonalSkills', complete: 'MedicalTechnology'},
      {id: 3209, name: 'Auditing', complete: 'MedicalTechnology'},
      {id: 3210, name: 'Hematology', complete: 'MedicalTechnology'},
      {id: 3211, name: 'Sterilization', complete: 'MedicalTechnology'},
      {
        id: 3212,
        name: 'X-RayComputedTomography',
        complete: 'MedicalTechnology'
      },
      {
        id: 3213,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'MedicalTechnology'
      },
      {
        id: 3214,
        name: 'ImplantableCardioverter-Defibrillator',
        complete: 'MedicalTechnology'
      },
      {id: 3215, name: 'BloodBanking', complete: 'MedicalTechnology'},
      {id: 3216, name: 'BasicLifeSupport', complete: 'MedicalTechnology'},
      {id: 3217, name: 'RadiationProtection', complete: 'MedicalTechnology'},
      {
        id: 3218,
        name: 'PhysicianPatientPrivilege',
        complete: 'MedicalTechnology'
      },
      {id: 3219, name: 'Billing', complete: 'MedicalTechnology'},
      {
        id: 3220,
        name: 'AmericanMedicalTechnologists',
        complete: 'MedicalTechnology'
      },
      {id: 3221, name: 'SterileTechniques', complete: 'MedicalTechnology'},
      {
        id: 3222,
        name: 'CertifiedVeterinaryTechnician',
        complete: 'MedicalTechnology'
      },
      {id: 3223, name: 'CardiacCatheterization', complete: 'Nursing'},
      {
        id: 3224,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'Nursing'
      },
      {id: 3225, name: 'MedicalRecords', complete: 'Nursing'},
      {id: 3226, name: 'NursePractitioner', complete: 'Nursing'},
      {id: 3227, name: 'Dialysis', complete: 'Nursing'},
      {id: 3228, name: 'PediatricIntensiveCareUnit', complete: 'Nursing'},
      {id: 3229, name: 'PediatricAdvancedLifeSupport', complete: 'Nursing'},
      {id: 3230, name: 'InfectionControl', complete: 'Nursing'},
      {
        id: 3231,
        name:
          'Psychiatric-MentalHealthNursePractitionerPsychiatric-MentalHealthNursePractitioner',
        complete: 'Nursing'
      },
      {id: 3232, name: 'Hemodialysis', complete: 'Nursing'},
      {id: 3233, name: 'NeonatalIntensiveCareUnit', complete: 'Nursing'},
      {id: 3234, name: 'NurseLicensure', complete: 'Nursing'},
      {id: 3235, name: 'NursingCare', complete: 'Nursing'},
      {id: 3236, name: 'CaseManagement', complete: 'Nursing'},
      {id: 3237, name: 'CertifiedDialysisNurse', complete: 'Nursing'},
      {id: 3238, name: 'Oncology', complete: 'Nursing'},
      {id: 3239, name: 'CriticalCareRegisteredNurse', complete: 'Nursing'},
      {id: 3240, name: 'PatientEducation', complete: 'Nursing'},
      {id: 3241, name: 'PrimaryCare', complete: 'Nursing'},
      {id: 3242, name: 'CertifiedNephrologyNurse', complete: 'Nursing'},
      {id: 3243, name: 'LongTermAcuteCare', complete: 'Nursing'},
      {id: 3244, name: 'BasicLifeSupport', complete: 'Nursing'},
      {id: 3245, name: 'Teaching', complete: 'Nursing'},
      {
        id: 3246,
        name: 'AdvancedPracticeRegisteredNurse',
        complete: 'Nursing'
      },
      {id: 3247, name: 'Sedation', complete: 'Nursing'},
      {id: 3248, name: 'PostpartumPeriod', complete: 'Nursing'},
      {id: 3249, name: 'IntensiveCare', complete: 'Nursing'},
      {id: 3250, name: 'NursingPractices', complete: 'Nursing'},
      {id: 3251, name: 'Medicare', complete: 'Nursing'},
      {id: 3252, name: 'Ultrasound', complete: 'Nursing'},
      {id: 3253, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3254, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3255, name: 'TransitionalCare', complete: 'Nursing'},
      {id: 3256, name: 'PerformanceImprovement', complete: 'Nursing'},
      {id: 3257, name: 'MentalHealth', complete: 'Nursing'},
      {id: 3258, name: 'NuclearMedicine', complete: 'Nursing'},
      {id: 3259, name: 'EmergencyDepartments', complete: 'Nursing'},
      {id: 3260, name: 'MedicalIntensiveCareUnit', complete: 'Nursing'},
      {
        id: 3261,
        name: 'ComputerizedPhysicianOrderEntry',
        complete: 'Nursing'
      },
      {id: 3262, name: 'UtilizationManagement', complete: 'Nursing'},
      {id: 3263, name: 'Fluoroscopy', complete: 'Nursing'},
      {id: 3264, name: 'Telemetry', complete: 'Nursing'},
      {id: 3265, complete: 'Nursing'},
      {id: 3266, name: 'TraumaNurseCoreCourse', complete: 'Nursing'},
      {id: 3267, name: 'TreatmentPlanning', complete: 'Nursing'},
      {id: 3268, name: 'FamilyMedicine', complete: 'Nursing'},
      {id: 3269, name: 'Troubleshooting', complete: 'Nursing'},
      {id: 3270, name: 'Pediatrics', complete: 'Nursing'},
      {id: 3271, name: 'CardiothoracicSurgeries', complete: 'Nursing'},
      {id: 3272, name: 'DataCollection', complete: 'Nursing'},
      {id: 3273, name: 'ManagedCare', complete: 'Nursing'},
      {id: 3274, name: 'CardiacCatheterization', complete: 'Nursing'},
      {
        id: 3275,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'Nursing'
      },
      {id: 3276, name: 'MedicalRecords', complete: 'Nursing'},
      {id: 3277, name: 'NursePractitioner', complete: 'Nursing'},
      {id: 3278, name: 'Dialysis', complete: 'Nursing'},
      {id: 3279, name: 'PediatricIntensiveCareUnit', complete: 'Nursing'},
      {id: 3280, name: 'PediatricAdvancedLifeSupport', complete: 'Nursing'},
      {id: 3281, name: 'InfectionControl', complete: 'Nursing'},
      {
        id: 3282,
        name:
          'Psychiatric-MentalHealthNursePractitionerPsychiatric-MentalHealthNursePractitioner',
        complete: 'Nursing'
      },
      {id: 3283, name: 'Hemodialysis', complete: 'Nursing'},
      {id: 3284, name: 'NeonatalIntensiveCareUnit', complete: 'Nursing'},
      {id: 3285, name: 'NurseLicensure', complete: 'Nursing'},
      {id: 3286, name: 'NursingCare', complete: 'Nursing'},
      {id: 3287, name: 'CaseManagement', complete: 'Nursing'},
      {id: 3288, name: 'CertifiedDialysisNurse', complete: 'Nursing'},
      {id: 3289, name: 'Oncology', complete: 'Nursing'},
      {id: 3290, name: 'CriticalCareRegisteredNurse', complete: 'Nursing'},
      {id: 3291, name: 'PatientEducation', complete: 'Nursing'},
      {id: 3292, name: 'PrimaryCare', complete: 'Nursing'},
      {id: 3293, name: 'CertifiedNephrologyNurse', complete: 'Nursing'},
      {id: 3294, name: 'LongTermAcuteCare', complete: 'Nursing'},
      {id: 3295, name: 'BasicLifeSupport', complete: 'Nursing'},
      {id: 3296, name: 'Teaching', complete: 'Nursing'},
      {
        id: 3297,
        name: 'AdvancedPracticeRegisteredNurse',
        complete: 'Nursing'
      },
      {id: 3298, name: 'Sedation', complete: 'Nursing'},
      {id: 3299, name: 'PostpartumPeriod', complete: 'Nursing'},
      {id: 3300, name: 'IntensiveCare', complete: 'Nursing'},
      {id: 3301, name: 'NursingPractices', complete: 'Nursing'},
      {id: 3302, name: 'Medicare', complete: 'Nursing'},
      {id: 3303, name: 'Ultrasound', complete: 'Nursing'},
      {id: 3304, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3305, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3306, name: 'TransitionalCare', complete: 'Nursing'},
      {id: 3307, name: 'PerformanceImprovement', complete: 'Nursing'},
      {id: 3308, name: 'MentalHealth', complete: 'Nursing'},
      {id: 3309, name: 'NuclearMedicine', complete: 'Nursing'},
      {id: 3310, name: 'EmergencyDepartments', complete: 'Nursing'},
      {id: 3311, name: 'MedicalIntensiveCareUnit', complete: 'Nursing'},
      {
        id: 3312,
        name: 'ComputerizedPhysicianOrderEntry',
        complete: 'Nursing'
      },
      {id: 3313, name: 'UtilizationManagement', complete: 'Nursing'},
      {id: 3314, name: 'Fluoroscopy', complete: 'Nursing'},
      {id: 3315, name: 'Telemetry', complete: 'Nursing'},
      {id: 3316, complete: 'Nursing'},
      {id: 3317, name: 'TraumaNurseCoreCourse', complete: 'Nursing'},
      {id: 3318, name: 'TreatmentPlanning', complete: 'Nursing'},
      {id: 3319, name: 'FamilyMedicine', complete: 'Nursing'},
      {id: 3320, name: 'Troubleshooting', complete: 'Nursing'},
      {id: 3321, name: 'Pediatrics', complete: 'Nursing'},
      {id: 3322, name: 'CardiothoracicSurgeries', complete: 'Nursing'},
      {id: 3323, name: 'DataCollection', complete: 'Nursing'},
      {id: 3324, name: 'ManagedCare', complete: 'Nursing'},
      {id: 3325, name: 'CardiacCatheterization', complete: 'Nursing'},
      {
        id: 3326,
        name: 'AdvancedCardiovascularLifeSupport',
        complete: 'Nursing'
      },
      {id: 3327, name: 'MedicalRecords', complete: 'Nursing'},
      {id: 3328, name: 'NursePractitioner', complete: 'Nursing'},
      {id: 3329, name: 'Dialysis', complete: 'Nursing'},
      {id: 3330, name: 'PediatricIntensiveCareUnit', complete: 'Nursing'},
      {id: 3331, name: 'PediatricAdvancedLifeSupport', complete: 'Nursing'},
      {id: 3332, name: 'InfectionControl', complete: 'Nursing'},
      {
        id: 3333,
        name:
          'Psychiatric-MentalHealthNursePractitionerPsychiatric-MentalHealthNursePractitioner',
        complete: 'Nursing'
      },
      {id: 3334, name: 'Hemodialysis', complete: 'Nursing'},
      {id: 3335, name: 'NeonatalIntensiveCareUnit', complete: 'Nursing'},
      {id: 3336, name: 'NurseLicensure', complete: 'Nursing'},
      {id: 3337, name: 'NursingCare', complete: 'Nursing'},
      {id: 3338, name: 'CaseManagement', complete: 'Nursing'},
      {id: 3339, name: 'CertifiedDialysisNurse', complete: 'Nursing'},
      {id: 3340, name: 'Oncology', complete: 'Nursing'},
      {id: 3341, name: 'CriticalCareRegisteredNurse', complete: 'Nursing'},
      {id: 3342, name: 'PatientEducation', complete: 'Nursing'},
      {id: 3343, name: 'PrimaryCare', complete: 'Nursing'},
      {id: 3344, name: 'CertifiedNephrologyNurse', complete: 'Nursing'},
      {id: 3345, name: 'LongTermAcuteCare', complete: 'Nursing'},
      {id: 3346, name: 'BasicLifeSupport', complete: 'Nursing'},
      {id: 3347, name: 'Teaching', complete: 'Nursing'},
      {
        id: 3348,
        name: 'AdvancedPracticeRegisteredNurse',
        complete: 'Nursing'
      },
      {id: 3349, name: 'Sedation', complete: 'Nursing'},
      {id: 3350, name: 'PostpartumPeriod', complete: 'Nursing'},
      {id: 3351, name: 'IntensiveCare', complete: 'Nursing'},
      {id: 3352, name: 'NursingPractices', complete: 'Nursing'},
      {id: 3353, name: 'Medicare', complete: 'Nursing'},
      {id: 3354, name: 'Ultrasound', complete: 'Nursing'},
      {id: 3355, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3356, name: 'Post-AnesthesiaCareUnit', complete: 'Nursing'},
      {id: 3357, name: 'TransitionalCare', complete: 'Nursing'},
      {id: 3358, name: 'PerformanceImprovement', complete: 'Nursing'},
      {id: 3359, name: 'MentalHealth', complete: 'Nursing'},
      {id: 3360, name: 'NuclearMedicine', complete: 'Nursing'},
      {id: 3361, name: 'EmergencyDepartments', complete: 'Nursing'},
      {id: 3362, name: 'MedicalIntensiveCareUnit', complete: 'Nursing'},
      {
        id: 3363,
        name: 'ComputerizedPhysicianOrderEntry',
        complete: 'Nursing'
      },
      {id: 3364, name: 'UtilizationManagement', complete: 'Nursing'},
      {id: 3365, name: 'Fluoroscopy', complete: 'Nursing'},
      {id: 3366, name: 'Telemetry', complete: 'Nursing'},
      {id: 3367, complete: 'Nursing'},
      {id: 3368, name: 'TraumaNurseCoreCourse', complete: 'Nursing'},
      {id: 3369, name: 'TreatmentPlanning', complete: 'Nursing'},
      {id: 3370, name: 'FamilyMedicine', complete: 'Nursing'},
      {id: 3371, name: 'Troubleshooting', complete: 'Nursing'},
      {id: 3372, name: 'Pediatrics', complete: 'Nursing'},
      {id: 3373, name: 'CardiothoracicSurgeries', complete: 'Nursing'},
      {id: 3374, name: 'DataCollection', complete: 'Nursing'},
      {id: 3375, name: 'ManagedCare', complete: 'Nursing'},
      {id: 3376, name: 'InformationPrivacy', complete: 'PharmacyServices'},
      {id: 3377, name: 'Workstations', complete: 'PharmacyServices'},
      {id: 3378, name: 'Admixtures', complete: 'PharmacyServices'},
      {id: 3379, name: 'LoyaltyPrograms', complete: 'PharmacyServices'},
      {id: 3380, name: 'ManagedCare', complete: 'PharmacyServices'},
      {id: 3381, name: 'PreventiveHealthcare', complete: 'PharmacyServices'},
      {id: 3382, name: 'EthicalConduct', complete: 'PharmacyServices'},
      {id: 3383, name: 'DosageForm', complete: 'PharmacyServices'},
      {
        id: 3384,
        name: 'MedicationTherapyManagement',
        complete: 'PharmacyServices'
      },
      {id: 3385, name: 'Medicare', complete: 'PharmacyServices'},
      {id: 3386, name: 'HealthcareServices', complete: 'PharmacyServices'},
      {id: 3387, name: 'ComputerTerminals', complete: 'PharmacyServices'},
      {id: 3388, name: 'PackagingAndLabeling', complete: 'PharmacyServices'},
      {id: 3389, name: 'Cleanliness', complete: 'PharmacyServices'},
      {id: 3390, name: 'Medica', complete: 'PharmacyServices'},
      {
        id: 3391,
        name: 'KeyPerformanceIndicators',
        complete: 'PharmacyServices'
      },
      {
        id: 3392,
        name: 'PhysicianPatientPrivilege',
        complete: 'PharmacyServices'
      },
      {id: 3393, name: 'Chemotherapy', complete: 'PharmacyServices'},
      {id: 3394, name: 'RestockingShelves', complete: 'PharmacyServices'},
      {id: 3395, name: 'ClinicalPharmacy', complete: 'PharmacyServices'},
      {id: 3396, name: 'AssetProtection', complete: 'PharmacyServices'},
      {id: 3397, name: 'VisualAcuity', complete: 'PharmacyServices'},
      {id: 3398, name: 'AsepticTechniques', complete: 'PharmacyServices'},
      {id: 3399, name: 'PhysicalInventory', complete: 'PharmacyServices'},
      {id: 3400, name: 'Appeals', complete: 'PharmacyServices'},
      {id: 3401, name: 'CustomerExperience', complete: 'PharmacyServices'},
      {id: 3402, name: 'Transcribing', complete: 'PharmacyServices'},
      {id: 3403, name: 'Asepsis', complete: 'PharmacyServices'},
      {id: 3404, name: 'AdverseDrugReactions', complete: 'PharmacyServices'},
      {id: 3405, name: 'UtilizationManagement', complete: 'PharmacyServices'},
      {id: 3406, name: 'PerformanceManagement', complete: 'PharmacyServices'},
      {id: 3407, name: 'Typing', complete: 'PharmacyServices'},
      {id: 3408, name: 'SterileProducts', complete: 'PharmacyServices'},
      {id: 3409, name: 'Pharmacokinetics', complete: 'PharmacyServices'},
      {id: 3410, name: 'MedicalNecessity', complete: 'PharmacyServices'},
      {id: 3411, name: 'Workflows', complete: 'PharmacyServices'},
      {
        id: 3412,
        name: 'CertifiedPharmacyTechnician',
        complete: 'PharmacyServices'
      },
      {id: 3413, name: 'Nursing', complete: 'PharmacyServices'},
      {id: 3414, name: 'CashRegister', complete: 'PharmacyServices'},
      {id: 3415, name: 'CashRegister', complete: 'PharmacyServices'},
      {
        id: 3416,
        name: 'TherapeuticInterventions',
        complete: 'PharmacyServices'
      },
      {
        id: 3417,
        name: 'MedicationAdministration',
        complete: 'PharmacyServices'
      },
      {id: 3418, name: 'Disabilities', complete: 'PharmacyServices'},
      {
        id: 3419,
        name: 'AutomatedDispensingMachines',
        complete: 'PharmacyServices'
      },
      {id: 3420, name: 'HealthCareIndustry', complete: 'PharmacyServices'},
      {
        id: 3421,
        name: 'PharmacyBenefitManagement',
        complete: 'PharmacyServices'
      },
      {id: 3422, name: 'Auditing', complete: 'PharmacyServices'},
      {id: 3423, name: 'CollectiveBargaining', complete: 'PharmacyServices'},
      {id: 3424, name: 'HospitalPharmacy', complete: 'PharmacyServices'},
      {id: 3425, name: 'Billing', complete: 'PharmacyServices'},
      {id: 3426, name: 'RespiratoryTherapy', complete: 'Therapy'},
      {id: 3427, name: 'Speech-LanguagePathology', complete: 'Therapy'},
      {id: 3428, name: 'ActivitiesOfDailyLiving', complete: 'Therapy'},
      {id: 3429, name: 'DischargeSummaries', complete: 'Therapy'},
      {id: 3430, name: 'InfectionControl', complete: 'Therapy'},
      {id: 3431, name: 'Ultrasound', complete: 'Therapy'},
      {id: 3432, name: 'RegisteredRespiratoryTherapist', complete: 'Therapy'},
      {
        id: 3433,
        name: 'CertificateOfClinicalCompetenceInSpeech-LanguagePathology',
        complete: 'Therapy'
      },
      {id: 3434, name: 'HomeCare', complete: 'Therapy'},
      {id: 3435, name: 'Caregiving', complete: 'Therapy'},
      {id: 3436, name: 'PatientSafety', complete: 'Therapy'},
      {id: 3437, name: 'CalmnessUnderPressure', complete: 'Therapy'},
      {
        id: 3438,
        name: 'AdvancedCardiovascularLifeSuppor',
        complete: 'Therapy'
      },
      {id: 3439, name: 'SwallowingDisorders', complete: 'Therapy'},
      {id: 3440, name: 'CaseManagement', complete: 'Therapy'},
      {id: 3441, name: 'Balancing{:Ledger/Billing}', complete: 'Therapy'},
      {id: 3442, name: 'Billing', complete: 'Therapy'},
      {id: 3443, name: 'AssistedLiving', complete: 'Therapy'},
      {id: 3444, name: 'RespiratorySystems', complete: 'Therapy'},
      {id: 3445, name: 'CommunicationDisorders', complete: 'Therapy'},
      {id: 3446, name: 'Geriatrics', complete: 'Therapy'},
      {id: 3447, name: 'InterpersonalSkills', complete: 'Therapy'},
      {id: 3448, name: 'PatientEducation', complete: 'Therapy'},
      {id: 3449, name: 'PatientTransfer', complete: 'Therapy'},
      {id: 3450, name: 'MedicalVentilators', complete: 'Therapy'},
      {id: 3451, name: 'Audiology', complete: 'Therapy'},
      {id: 3452, name: 'Neurology', complete: 'Therapy'},
      {id: 3453, name: 'MedicalRecords', complete: 'Therapy'},
      {id: 3454, name: 'OxygenTherapy', complete: 'Therapy'},
      {id: 3455, name: 'CriticalCare', complete: 'Therapy'},
      {id: 3456, name: 'SpeechDisorder', complete: 'Therapy'},
      {id: 3457, name: 'OrthopedicSurgery', complete: 'Therapy'},
      {id: 3458, name: 'Auditing', complete: 'Therapy'},
      {id: 3459, name: 'Mechanics', complete: 'Therapy'},
      {id: 3460, name: 'DesktopComputing', complete: 'Therapy'},
      {id: 3461, name: 'PediatricAdvancedLifeSupport', complete: 'Therapy'},
      {id: 3462, name: 'Andro', complete: 'PersonalServices'},
      {id: 3463, name: 'Andro', complete: 'PersonalServices'},
      {id: 3464, name: 'FuneralServices', complete: 'PersonalServices'},
      {id: 3465, name: 'SkinCare', complete: 'PersonalServices'},
      {id: 3466, name: 'Hairstyling', complete: 'PersonalServices'},
      {id: 3467, name: 'RetailSales', complete: 'PersonalServices'},
      {id: 3468, name: 'AppleIOS', complete: 'PersonalServices'},
      {id: 3469, name: 'Aromatherapy', complete: 'PersonalServices'},
      {id: 3470, name: 'CustomerSatisfaction', complete: 'PersonalServices'},
      {id: 3471, name: 'HairRemoval', complete: 'PersonalServices'},
      {id: 3472, name: 'Coloring', complete: 'PersonalServices'},
      {id: 3473, name: 'Teamwork', complete: 'PersonalServices'},
      {id: 3474, name: 'Linens', complete: 'PersonalServices'},
      {id: 3475, name: 'Shiatsu', complete: 'PersonalServices'},
      {
        id: 3476,
        name: 'VerbalCommunicationSkills',
        complete: 'PersonalServices'
      },
      {id: 3477, name: 'Dermabrasion', complete: 'PersonalServices'},
      {id: 3478, name: 'CosmetologyLicense', complete: 'PersonalServices'},
      {id: 3479, name: 'Sanitation', complete: 'PersonalServices'},
      {id: 3480, name: 'OilAndGas', complete: 'PersonalServices'},
      {id: 3481, name: 'CraniosacralTherapy', complete: 'PersonalServices'},
      {id: 3482, name: 'Cremation', complete: 'PersonalServices'},
      {id: 3483, name: 'TechnicalServices', complete: 'PersonalServices'},
      {id: 3484, name: 'HairCare', complete: 'PersonalServices'},
      {id: 3485, name: 'Booking', complete: 'PersonalServices'},
      {id: 3486, name: 'Booking', complete: 'PersonalServices'},
      {id: 3487, name: 'Massages', complete: 'PersonalServices'},
      {id: 3488, name: 'Equinox', complete: 'PersonalServices'},
      {id: 3489, name: 'Val', complete: 'PersonalServices'},
      {id: 3490, name: 'Cosmetics', complete: 'PersonalServices'},
      {id: 3491, name: 'HairAnalysis', complete: 'PersonalServices'},
      {id: 3492, name: 'InfectionControl', complete: 'PersonalServices'},
      {
        id: 3493,
        name: 'LicensedMassageTherapist',
        complete: 'PersonalServices'
      },
      {id: 3494, name: 'MortuaryScience', complete: 'PersonalServices'},
      {id: 3495, name: 'ProductLining', complete: 'PersonalServices'},
      {id: 3496, name: 'ProductLining', complete: 'PersonalServices'},
      {id: 3497, name: 'SalesManagement', complete: 'PersonalServices'},
      {
        id: 3498,
        name: 'DiscountsAndAllowances',
        complete: 'PersonalServices'
      },
      {
        id: 3499,
        name: 'CardiopulmonaryResuscitation',
        complete: 'PersonalServices'
      },
      {id: 3500, name: 'DataEntry', complete: 'PersonalServices'},
      {id: 3501, name: 'ChemicalPeels', complete: 'PersonalServices'},
      {id: 3502, name: 'PublicRelations', complete: 'PersonalServices'},
      {id: 3503, name: 'Sterilization', complete: 'PersonalServices'},
      {id: 3504, name: 'TherapeuticTouch', complete: 'PersonalServices'},
      {id: 3505, name: 'InterpersonalSkills', complete: 'PersonalServices'},
      {id: 3506, name: 'MarketShare', complete: 'PersonalServices'},
      {id: 3507, name: 'ProfessionalServices', complete: 'PersonalServices'},
      {id: 3508, name: 'PointOfSale', complete: 'PersonalServices'},
      {id: 3509, name: 'PointOfSale', complete: 'PersonalServices'},
      {id: 3510, name: 'WritingSystems', complete: 'PersonalServices'},
      {
        id: 3511,
        name: 'CertifiedAthleticTrainer',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3512,
        name: 'CertifiedAthleticTrainer',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3513,
        name: 'Punctuality',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3514,
        name: 'GroupFitnessInstructorCertification',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3515,
        name: 'GroupFitnessInstructorCertification',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3516,
        name: 'SportsMedicine',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3517,
        name: 'ValDriverLicense',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3518,
        name: 'BloodPressure',
        complete: 'SportsandRecreationServices'
      },
      {id: 3519, name: 'Yoga', complete: 'SportsandRecreationServices'},
      {
        id: 3520,
        name: 'HealthPromotion',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3521,
        name: 'SportsInjury',
        complete: 'SportsandRecreationServices'
      },
      {id: 3522, name: 'Diving', complete: 'SportsandRecreationServices'},
      {
        id: 3523,
        name: 'ListeningSkills',
        complete: 'SportsandRecreationServices'
      },
      {id: 3524, name: 'Zumba', complete: 'SportsandRecreationServices'},
      {
        id: 3525,
        name: 'ExercisePrescription',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3526,
        name: 'OrthopedicSurgery',
        complete: 'SportsandRecreationServices'
      },
      {id: 3527, complete: 'SportsandRecreationServices'},
      {id: 3528, complete: 'SportsandRecreationServices'},
      {id: 3529, name: 'Softball', complete: 'SportsandRecreationServices'},
      {
        id: 3530,
        name: 'Compositions',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3531,
        name: 'GroupExerciseCertification',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3532,
        name: 'InterpersonalCommunications',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3533,
        name: 'InjuryPrevention',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3534,
        name: 'Fundraising',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3535,
        name: 'FineMotorSkills',
        complete: 'SportsandRecreationServices'
      },
      {id: 3536, name: 'BODYPUMP', complete: 'SportsandRecreationServices'},
      {
        id: 3537,
        name: 'Prioritization',
        complete: 'SportsandRecreationServices'
      },
      {id: 3538, name: 'UrgentCare', complete: 'SportsandRecreationServices'},
      {
        id: 3539,
        name: 'YouthAthletics',
        complete: 'SportsandRecreationServices'
      },
      {id: 3540, name: 'HeartRate', complete: 'SportsandRecreationServices'},
      {
        id: 3541,
        name: 'YogaCertification',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3542,
        name: 'CustomerSatisfaction',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3543,
        name: 'PhysicalTherapy',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3544,
        name: 'SportsEquipment',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3545,
        name: 'Personalization',
        complete: 'SportsandRecreationServices'
      },
      {id: 3546, name: 'Creativity', complete: 'SportsandRecreationServices'},
      {
        id: 3547,
        name: 'CertifiedStrengthAndConditioningSpecialist',
        complete: 'SportsandRecreationServices'
      },
      {id: 3548, name: 'Budgeting', complete: 'SportsandRecreationServices'},
      {
        id: 3549,
        name: 'Reliability',
        complete: 'SportsandRecreationServices'
      },
      {id: 3550, name: 'BODYATTACK', complete: 'SportsandRecreationServices'},
      {id: 3551, name: 'Mechanics', complete: 'SportsandRecreationServices'},
      {
        id: 3552,
        name: 'MedicalRecords',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3553,
        name: 'GoodDrivingRecord',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3554,
        name: 'SoftwarePerformanceTesting',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3555,
        name: 'ClassAction',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3556,
        name: 'HealthEducation',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3557,
        name: 'ElectronicMedicalRecord',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3558,
        name: 'PublicRelations',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3559,
        name: 'PhysicalStrength',
        complete: 'SportsandRecreationServices'
      },
      {
        id: 3560,
        name: 'StereophonicSoundSystem',
        complete: 'SportsandRecreationServices'
      },
      {id: 3561, name: 'OracleWebLogicServer', complete: 'DataManagement'},
      {id: 3562, name: 'OracleDatabases', complete: 'DataManagement'},
      {
        id: 3563,
        name: 'SQLServerIntegrationServices',
        complete: 'DataManagement'
      },
      {id: 3564, name: 'ExtractTransformLoad', complete: 'DataManagement'},
      {id: 3565, name: 'Python', complete: 'DataManagement'},
      {
        id: 3566,
        name: 'AgileSoftwareDevelopment',
        complete: 'DataManagement'
      },
      {id: 3567, name: 'JobControlLanguage', complete: 'DataManagement'},
      {id: 3568, name: 'ComputerSciences', complete: 'DataManagement'},
      {id: 3569, name: 'OracleCloud', complete: 'DataManagement'},
      {id: 3570, name: 'RecoveryManager', complete: 'DataManagement'},
      {id: 3571, name: 'MicrosoftSQLServers', complete: 'DataManagement'},
      {id: 3572, name: 'DataWarehousing', complete: 'DataManagement'},
      {id: 3573, name: 'AmazonWebServices', complete: 'DataManagement'},
      {id: 3574, name: 'SoftwareDevelopment', complete: 'DataManagement'},
      {id: 3575, name: 'IBMDB2', complete: 'DataManagement'},
      {id: 3576, name: 'InformationSystems', complete: 'DataManagement'},
      {id: 3577, name: 'R12', complete: 'DataManagement'},
      {id: 3578, name: 'Linux', complete: 'DataManagement'},
      {
        id: 3579,
        name: 'SQLServerReportingServices',
        complete: 'DataManagement'
      },
      {id: 3580, name: 'DataQuality', complete: 'DataManagement'},
      {id: 3581, name: 'Scripting', complete: 'DataManagement'},
      {id: 3582, name: 'ProjectManagement', complete: 'DataManagement'},
      {id: 3583, name: 'Z/OS', complete: 'DataManagement'},
      {
        id: 3584,
        name: 'DatabaseManagementSystems',
        complete: 'DataManagement'
      },
      {id: 3585, name: 'Mdleware', complete: 'DataManagement'},
      {
        id: 3586,
        name: 'OriginalEquipmentManufacturer',
        complete: 'DataManagement'
      },
      {id: 3587, name: 'SQL', complete: 'DataManagement'},
      {id: 3588, name: 'BusinessIntelligence', complete: 'DataManagement'},
      {id: 3589, name: 'Perl', complete: 'DataManagement'},
      {id: 3590, name: '.NETFramework', complete: 'DataManagement'},
      {id: 3591, name: 'InformationManagement', complete: 'DataManagement'},
      {
        id: 3592,
        name: 'ManagementInformationSystems',
        complete: 'DataManagement'
      },
      {
        id: 3593,
        name: 'Service-OrientedArchitecture',
        complete: 'DataManagement'
      },
      {id: 3594, name: 'OracleEnterpriseManager', complete: 'DataManagement'},
      {
        id: 3595,
        name: 'SQLServerAnalysisServices',
        complete: 'DataManagement'
      },
      {id: 3596, name: 'DataIntegration', complete: 'DataManagement'},
      {id: 3597, name: 'PostgreSQL', complete: 'DataManagement'},
      {id: 3598, name: 'TechnicalSupport', complete: 'DataManagement'},
      {id: 3599, name: 'QualityAssurance', complete: 'DataManagement'},
      {id: 3600, name: 'DatabaseDesign', complete: 'DataManagement'},
      {id: 3601, name: 'E-Business', complete: 'DataManagement'},
      {id: 3602, name: 'OracleDataGuard', complete: 'DataManagement'},
      {id: 3603, name: 'StoredProcedure', complete: 'DataManagement'},
      {id: 3604, name: 'DataManagement', complete: 'DataManagement'},
      {id: 3605, name: 'MySQL', complete: 'DataManagement'},
      {id: 3606, name: 'JavaScript', complete: 'DataManagement'},
      {id: 3607, name: 'DataAdministration', complete: 'DataManagement'},
      {id: 3608, name: 'ComputerEngineering', complete: 'DataManagement'},
      {id: 3609, name: 'BusinessDevelopment', complete: 'DataManagement'},
      {
        id: 3610,
        name: 'CertifiedInformationSystemsSecurityProfessional',
        complete: 'ITNetworksandSystem'
      },
      {id: 3611, name: 'Python', complete: 'ITNetworksandSystem'},
      {
        id: 3612,
        name: 'BorderGatewayProtocol',
        complete: 'ITNetworksandSystem'
      },
      {id: 3613, name: 'WindowsServers', complete: 'ITNetworksandSystem'},
      {id: 3614, name: 'WindowsServers', complete: 'ITNetworksandSystem'},
      {id: 3615, name: 'ProjectManagement', complete: 'ITNetworksandSystem'},
      {id: 3616, name: 'LocalAreaNetworks', complete: 'ITNetworksandSystem'},
      {id: 3617, name: 'AmazonWebServices', complete: 'ITNetworksandSystem'},
      {id: 3618, name: 'Vulnerability', complete: 'ITNetworksandSystem'},
      {id: 3619, name: 'Perl', complete: 'ITNetworksandSystem'},
      {
        id: 3620,
        name: 'OpenShortestPathFirst',
        complete: 'ITNetworksandSystem'
      },
      {id: 3621, name: 'ActiveDirectory', complete: 'ITNetworksandSystem'},
      {id: 3622, name: 'BusinessProcess', complete: 'ITNetworksandSystem'},
      {id: 3623, name: 'WeAreaNetworks', complete: 'ITNetworksandSystem'},
      {id: 3624, name: 'MicrosoftAzure', complete: 'ITNetworksandSystem'},
      {id: 3625, name: 'GIACCertifications', complete: 'ITNetworksandSystem'},
      {id: 3626, name: 'Scripting', complete: 'ITNetworksandSystem'},
      {
        id: 3627,
        name: 'MultiprotocolLabelSwitching',
        complete: 'ITNetworksandSystem'
      },
      {id: 3628, name: 'GroupPolicy', complete: 'ITNetworksandSystem'},
      {id: 3629, name: 'TechnicalSupport', complete: 'ITNetworksandSystem'},
      {id: 3630, name: 'NetworkSwitches', complete: 'ITNetworksandSystem'},
      {
        id: 3631,
        name: 'InfrastructureAsAService',
        complete: 'ITNetworksandSystem'
      },
      {id: 3632, name: 'CyberSecurity', complete: 'ITNetworksandSystem'},
      {id: 3633, name: 'Java', complete: 'ITNetworksandSystem'},
      {id: 3634, name: 'NetworkRouting', complete: 'ITNetworksandSystem'},
      {id: 3635, name: 'OperatingSystems', complete: 'ITNetworksandSystem'},
      {
        id: 3636,
        name: 'InformationTechnologyInfrastructureLibrary',
        complete: 'ITNetworksandSystem'
      },
      {id: 3637, name: 'NetworkingHardware', complete: 'ITNetworksandSystem'},
      {id: 3638, name: 'SoftwareasaService', complete: 'ITNetworksandSystem'},
      {id: 3639, name: 'RiskAnalysis', complete: 'ITNetworksandSystem'},
      {id: 3640, name: 'Bash', complete: 'ITNetworksandSystem'},
      {id: 3641, name: 'NetworkEngineering', complete: 'ITNetworksandSystem'},
      {id: 3642, name: 'VMwareESXServers', complete: 'ITNetworksandSystem'},
      {
        id: 3643,
        name: 'BusinessRequirements',
        complete: 'ITNetworksandSystem'
      },
      {id: 3644, name: 'Firewall', complete: 'ITNetworksandSystem'},
      {id: 3645, name: 'CloudComputing', complete: 'ITNetworksandSystem'},
      {id: 3646, name: 'IncentResponse', complete: 'ITNetworksandSystem'},
      {id: 3647, name: 'Linux', complete: 'ITNetworksandSystem'},
      {
        id: 3648,
        name: 'EnhancedInteriorGatewayRoutingProtocols',
        complete: 'ITNetworksandSystem'
      },
      {id: 3649, name: 'Virtualization', complete: 'ITNetworksandSystem'},
      {id: 3650, name: 'ChangeManagement', complete: 'ITNetworksandSystem'},
      {id: 3651, name: 'NetworkPerformance', complete: 'ITNetworksandSystem'},
      {id: 3652, name: 'PlatformasaService', complete: 'ITNetworksandSystem'},
      {id: 3653, name: 'SecurityControls', complete: 'ITNetworksandSystem'},
      {id: 3654, name: 'RubyOnRails', complete: 'ITNetworksandSystem'},
      {id: 3655, name: 'TestAutomation', complete: 'IT,Other'},
      {id: 3656, name: 'DataWarehousing', complete: 'IT,Other'},
      {id: 3657, name: 'Java', complete: 'IT,Other'},
      {id: 3658, name: 'AmazonWebServices', complete: 'IT,Other'},
      {id: 3659, name: 'Firewall', complete: 'IT,Other'},
      {
        id: 3660,
        name: 'CertifiedInformationSystemsSecurityProfessional',
        complete: 'IT,Other'
      },
      {id: 3661, name: 'ProjectManagement', complete: 'IT,Other'},
      {id: 3662, name: 'BusinessProcess', complete: 'IT,Other'},
      {id: 3663, name: 'Analytics', complete: 'IT,Other'},
      {id: 3664, name: 'TestPlanning', complete: 'IT,Other'},
      {id: 3665, name: 'BusinessIntelligence', complete: 'IT,Other'},
      {id: 3666, name: 'JavaScript', complete: 'IT,Other'},
      {id: 3667, name: 'MicrosoftAzureMicrosoftAzurez', complete: 'IT,Other'},
      {id: 3668, name: 'WeAreaNetworks', complete: 'IT,Other'},
      {id: 3669, name: 'CyberSecurity', complete: 'IT,Other'},
      {
        id: 3670,
        name: 'ProjectManagementProfessionalCertification',
        complete: 'IT,Other'
      },
      {id: 3671, name: 'EnterpriseResourcePlanning', complete: 'IT,Other'},
      {id: 3672, name: 'MachineLearning', complete: 'IT,Other'},
      {id: 3673, name: 'Selenium', complete: 'IT,Other'},
      {id: 3674, name: 'ExtractTransformLoad', complete: 'IT,Other'},
      {id: 3675, name: 'Python', complete: 'IT,Other'},
      {id: 3676, name: 'Python', complete: 'IT,Other'},
      {id: 3677, name: 'Python', complete: 'IT,Other'},
      {
        id: 3678,
        name: 'CertifiedInformationSecurityManager',
        complete: 'IT,Other'
      },
      {id: 3679, name: 'ProgramManagement', complete: 'IT,Other'},
      {
        id: 3680,
        name: 'CustomerRelationshipManagement',
        complete: 'IT,Other'
      },
      {id: 3681, name: 'SearchEngineOptimization', complete: 'IT,Other'},
      {id: 3682, name: 'Automation', complete: 'IT,Other'},
      {id: 3683, complete: 'IT,Other'},
      {id: 3684, name: 'DataManagement', complete: 'IT,Other'},
      {id: 3685, complete: 'IT,Other'},
      {id: 3686, name: 'C#', complete: 'IT,Other'},
      {id: 3687, name: 'InfrastructureAsAService', complete: 'IT,Other'},
      {id: 3688, name: 'NetworkSwitches', complete: 'IT,Other'},
      {id: 3689, name: 'Vulnerability', complete: 'IT,Other'},
      {id: 3690, complete: 'IT,Other'},
      {id: 3691, name: 'MicrosoftProject', complete: 'IT,Other'},
      {id: 3692, name: 'BusinessDevelopment', complete: 'IT,Other'},
      {id: 3693, name: 'DigitalMarketing', complete: 'IT,Other'},
      {id: 3694, name: 'SoftwareTesting', complete: 'IT,Other'},
      {id: 3695, name: 'DataQuality', complete: 'IT,Other'},
      {id: 3696, name: 'Jenkins', complete: 'IT,Other'},
      {id: 3697, name: 'CloudComputing', complete: 'IT,Other'},
      {id: 3698, name: 'OperatingSystems', complete: 'IT,Other'},
      {
        id: 3699,
        name: 'CertifiedInformationSystemAuditor',
        complete: 'IT,Other'
      },
      {id: 3700, name: 'ProjectPlanning', complete: 'IT,Other'},
      {id: 3701, name: 'Governance', complete: 'IT,Other'},
      {id: 3702, name: 'GoogleAnalytics', complete: 'IT,Other'},
      {id: 3703, name: 'SoftwareQuality', complete: 'IT,Other'},
      {id: 3704, name: 'ApacheHadoop', complete: 'IT,Other'},
      {id: 3705, name: 'Scripting', complete: 'IT,Other'},
      {id: 3706, name: 'Docker', complete: 'IT,Other'},
      {
        id: 3707,
        name: 'ClinicalTrials',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3708,
        name: 'ClinicalTrials',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3709,
        name: 'ClinicalTrials',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3710,
        name: 'ClinicalTrials',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3711,
        name: 'ActuarialExams',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3712,
        name: 'Biostatistics',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3713,
        name: 'Python',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3714,
        name: 'Java',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3715,
        name: 'ComputerVision',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3716,
        name: 'ActuarialScience',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3717,
        name: 'SAS',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3718,
        name: 'SQL',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3719,
        name: 'AmazonWebServices',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3720,
        name: 'ElectricalEngineering',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3721,
        name: 'FinancialStatements',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3722,
        name: 'FinancialStatements',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {id: 3723, name: 'R', complete: 'Methametics,Analytics,andStatistics'},
      {
        id: 3724,
        name: 'Scala',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {id: 3725, name: 'C', complete: 'Methametics,Analytics,andStatistics'},
      {
        id: 3726,
        name: 'Underwriting',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3727,
        name: 'ClinicalResearch',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3728,
        name: 'ApacheHive',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3729,
        name: 'ScikitLearn',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3730,
        name: 'ImageProcessing',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3731,
        name: 'Economics',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3732,
        name: 'DataManagement',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3733,
        name: 'BigData',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3734,
        name: 'MapReduce',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3735,
        name: 'SignalProcessing',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3736,
        name: 'FellowoftheSocietyofActuaries',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3737,
        name: 'Pre-ClinicalDevelopment',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3738,
        name: 'DataScience',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3739,
        name: 'NoSQL',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3740,
        name: 'NviaCUDA',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3741,
        name: 'AssociateoftheSocietyofActuaries',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3742,
        name: 'Epemiology',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3743,
        name: 'Tableau',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3744,
        name: 'NumPy',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3745,
        name: 'Pytorch',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3746,
        name: 'GenerallyAcceptedAccountingPrinciples',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3747,
        name: 'DrugDevelopment',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3748,
        name: 'DataMining',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3749,
        name: 'Pandas',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3750,
        name: 'SoftwareEngineering',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3751,
        name: 'Accounting',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3752,
        name: 'ClinicalStudyReports',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3753,
        name: 'PredictiveModelling',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3754,
        name: 'Tensorflow',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3755,
        name: 'ComputerEngineering',
        complete: 'Methametics,Analytics,andStatistics'
      },
      {
        id: 3756,
        name: 'EmbeddedSoftware',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3757,
        name: 'ApacheHadoop',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3758,
        name: 'DataWarehousing',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {id: 3759, name: 'SQL', complete: 'SoftwareDevelopmentandPrograming'},
      {id: 3760, name: 'SQL', complete: 'SoftwareDevelopmentandPrograming'},
      {id: 3761, name: 'SQL', complete: 'SoftwareDevelopmentandPrograming'},
      {
        id: 3762,
        name: 'Ansible',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {id: 3763, name: 'HTML5', complete: 'SoftwareDevelopmentandPrograming'},
      {
        id: 3764,
        name: 'EmbeddedSystems',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3765,
        name: 'EmbeddedSystems',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3766,
        name: 'Troubleshooting',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3767,
        name: 'MicrosoftSQLServers	',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3768,
        name: 'Objective-C',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3769,
        name: 'PostgreSQL',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3770,
        name: 'ContinuousIntegration',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3771,
        name: 'JQuery',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3772,
        name: 'ComputerSciences',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3773,
        name: 'JavaPlatformEnterpriseEdition',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3774,
        name: 'BusinessProcess',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3775,
        name: 'ExtensibleMarkupLanguage',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3776,
        name: 'UserExperience',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3777,
        name: 'UserExperience',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3778,
        name: 'RubyOnRails',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3779,
        name: 'Kubernetes',
        complete: 'SoftwareDevelopmentandPrograming'
      },
      {
        id: 3780,
        name: 'MarketResearch',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3781,
        name: 'ConsumerBehaviour',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3782,
        name: 'ProductCertification',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3783,
        name: 'ProductTesting',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3784,
        name: 'MarketingManagement',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3785,
        name: 'MedicalAssistance',
        complete: 'Inspecation,HealthandSafety'
      },
      {id: 3786, name: 'Accounting', complete: 'Inspecation,HealthandSafety'},
      {
        id: 3787,
        name: 'ClericalWorks',
        complete: 'Inspecation,HealthandSafety'
      },
      {id: 3788, name: 'Writing', complete: 'Inspecation,HealthandSafety'},
      {id: 3789, name: 'DataEntry', complete: 'Inspecation,HealthandSafety'},
      {
        id: 3790,
        name: 'Warehousing',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3791,
        name: 'PrivateNetworks',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3792,
        name: 'UserFeedback',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3793,
        name: 'NewProductDevelopment',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3794,
        name: 'PackagingAndLabeling',
        complete: 'Inspecation,HealthandSafety'
      },
      {
        id: 3795,
        name: 'roubleshooting',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3796, name: 'Plumbing', complete: 'MaintenanceandInstallation'},
      {
        id: 3797,
        name: 'Telecommunications',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3798, name: 'Welding', complete: 'MaintenanceandInstallation'},
      {
        id: 3799,
        name: 'ProgrammableLogicControllers',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3800, name: 'Budgeting', complete: 'MaintenanceandInstallation'},
      {id: 3801, name: 'Sawing', complete: 'MaintenanceandInstallation'},
      {
        id: 3802,
        name: 'PreventiveMaintenance',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3803, name: 'HVAC', complete: 'MaintenanceandInstallation'},
      {
        id: 3804,
        name: 'TestEquipment',
        complete: 'MaintenanceandInstallation'
      },
      {id: 3805, name: 'Mechanics', complete: 'MaintenanceandInstallation'},

      {
        id: 2232,
        name: 'WrittenEnglish',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2233,
        name: 'SoftwareDocumentation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2234,
        name: 'DigitalMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2235,
        name: 'LeadershipDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2236,
        name: 'InternalCommunications',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2237,
        name: 'SpanishLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2238,
        name: 'ExtensibleMarkupLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2239,
        name: 'WordPress',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2240,
        name: 'SocialStudies',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2241,
        name: 'GraphicDesign',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2242,
        name: 'ForeignLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2243,
        name: 'AgileSoftwareDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2244,
        name: 'GoogleAnalytics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2245,
        name: 'Empower',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {id: 2246, complete: 'Journalism,WritingandCommunicatin'},
      {
        id: 2247,
        name: 'GoogleAnalytics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2248,
        name: 'Empower',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2249,
        name: 'Brochures',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2250,
        name: 'AmericanSignLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2251,
        name: 'SoftwareDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2252,
        name: 'ContentMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2253,
        name: 'PreschoolEducation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2254,
        name: 'EventPlanning',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2255,
        name: 'Transcribing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2256,
        name: 'AdobeFrameMaker',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2257,
        name: 'EmailMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2258,
        name: 'EducationalEquity',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2259,
        name: 'MarketingStrategies',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2260,
        name: 'ElectronicPublishing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2261,
        name: 'Templates',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2262,
        name: 'ContentCreation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2263,
        name: 'RestaurantOperation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2264,
        name: 'AdobeInDesign',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2265,
        name: 'NetworkInterfaceControllers',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2266,
        name: 'RevisionControls',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2267,
        name: 'ContentManagementSystems',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2268,
        name: 'Hospitality',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2269,
        name: 'StrategicCommunication',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2270,
        name: 'TechnicalWriting',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2271,
        name: 'SocialMedia',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2272,
        name: 'Teaching',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2273,
        name: 'PublicRelations',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2274,
        name: 'LanguageInterpretation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2275,
        name: 'TechnicalDocumentation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2276,
        name: 'SearchEngineOptimization',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2277,
        name: 'SalesManagement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2278,
        name: 'MarketingCommunications',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2279,
        name: 'LanguageTranslation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2280,
        name: 'TechnicalCommunication',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2281,
        name: 'Analytics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2282,
        name: 'LanguageArts',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2283,
        name: 'PressReleases',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2284,
        name: 'WrittenEnglish',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2285,
        name: 'SoftwareDocumentation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2286,
        name: 'DigitalMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2287,
        name: 'LeadershipDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2288,
        name: 'InternalCommunications',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2289,
        name: 'SpanishLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2290,
        name: 'ExtensibleMarkupLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2291,
        name: 'WordPress',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2292,
        name: 'SocialStudies',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2293,
        name: 'GraphicDesign',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2294,
        name: 'ForeignLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2295,
        name: 'AgileSoftwareDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {id: 2296, name: 'FinancialStatements', complete: 'Acconting/Finance'},
      {
        id: 2297,
        name: 'CertifiedInternalAuditor',
        complete: 'Acconting/Finance'
      },
      {id: 2298, name: 'TaxReturn', complete: 'Acconting/Finance'},
      {id: 2299, name: 'AccountsReceivable', complete: 'Acconting/Finance'},
      {id: 2300, name: 'Loans', complete: 'Acconting/Finance'},
      {id: 2301, name: 'FinancialManagement', complete: 'Acconting/Finance'},
      {
        id: 2302,
        name: 'GenerallyAcceptedAccountingPrinciples',
        complete: 'Acconting/Finance'
      },
      {id: 2303, name: 'InternalAuditing', complete: 'Acconting/Finance'},
      {id: 2304, name: 'TaxPreparation', complete: 'Acconting/Finance'},
      {id: 2305, name: 'AccountsPayable', complete: 'Acconting/Finance'},
      {id: 2306, name: 'Underwriting', complete: 'Acconting/Finance'},
      {id: 2307, name: 'InformationSystems', complete: 'Acconting/Finance'},
      {id: 2308, name: 'Budgeting', complete: 'Acconting/Finance'},
      {id: 2309, name: 'InternalControls', complete: 'Acconting/Finance'},
      {id: 2310, name: 'IncomeTax', complete: 'Acconting/Finance'},
      {id: 2311, name: 'Collections', complete: 'Acconting/Finance'},
      {id: 2312, name: 'MortgageLoans', complete: 'Acconting/Finance'},
      {id: 2313, name: 'FinancialSystems', complete: 'Acconting/Finance'},
      {id: 2314, name: 'BalanceSheet', complete: 'Acconting/Finance'},
      {
        id: 2315,
        name: 'CertifiedInformationSystemAuditor',
        complete: 'Acconting/Finance'
      },
      {id: 2316, name: 'TaxLaws', complete: 'Acconting/Finance'},
      {id: 2317, name: 'Billing', complete: 'Acconting/Finance'},
      {id: 2318, name: 'LoanOrigination', complete: 'Acconting/Finance'},
      {id: 2319, name: 'ComputerSciences', complete: 'Acconting/Finance'},
      {id: 2320, name: 'Forecasting', complete: 'Acconting/Finance'},
      {id: 2321, name: 'Sarbanes-OxleyAct', complete: 'Acconting/Finance'},
      {id: 2322, name: 'TaxPlanning', complete: 'Acconting/Finance'},
      {id: 2323, name: 'DataEntry', complete: 'Acconting/Finance'},
      {id: 2324, name: 'SellingTechniques', complete: 'Acconting/Finance'},
      {
        id: 2325,
        name: 'BusinessAdministration',
        complete: 'Acconting/Finance'
      },
      {id: 2326, name: 'GeneralLedger', complete: 'Acconting/Finance'},
      {id: 2327, name: 'RiskAnalysis', complete: 'Acconting/Finance'},
      {
        id: 2328,
        name: 'PreparerTaxentificationNumber',
        complete: 'Acconting/Finance'
      },
      {id: 2329, name: 'DepositAccounts', complete: 'Acconting/Finance'},
      {id: 2330, name: 'RealEstate', complete: 'Acconting/Finance'},
      {id: 2331, name: 'Economics', complete: 'Acconting/Finance'},
      {id: 2332, complete: 'Acconting/Finance'},
      {id: 2333, name: 'Accounting', complete: 'Acconting/Finance'},
      {id: 2334, name: 'BusinessProcess', complete: 'Acconting/Finance'},
      {
        id: 2335,
        name: 'SoftwareProductManagement',
        complete: 'Acconting/Finance'
      },
      {id: 2336, name: 'Bookkeeping', complete: 'Acconting/Finance'},
      {id: 2337, name: 'CommercialBanking', complete: 'Acconting/Finance'},
      {id: 2338, name: 'PublicAdministration', complete: 'Acconting/Finance'},
      {id: 2339, name: 'FixedAsset', complete: 'Acconting/Finance'},
      {id: 2340, name: 'CashOrShareOptions', complete: 'Acconting/Finance'},
      {id: 2341, name: 'TurboTax', complete: 'Acconting/Finance'},
      {id: 2342, name: 'AccountingSoftwares', complete: 'Acconting/Finance'},
      {id: 2343, name: 'FinancialServices', complete: 'Acconting/Finance'},
      {
        id: 2344,
        name: 'CorrectiveAndPreventiveAction}',
        complete: 'Acconting/Finance'
      },
      {id: 2345, name: 'VarianceAnalysis', complete: 'Acconting/Finance'},
      {id: 2346, complete: 'Acconting/Finance'},
      {id: 2347, name: 'SQL', complete: 'BusinessAnalysis/Opreation'},
      {id: 2348, name: 'Accounting', complete: 'BusinessAnalysis/Opreation'},
      {id: 2349, name: 'Loans', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2350,
        name: 'BusinessRequirements',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2351,
        name: 'ProjectManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2352, name: 'Procurement', complete: 'BusinessAnalysis/Opreation'},
      {id: 2353, name: 'SAS', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2354,
        name: 'FinancialStatements',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2355,
        name: 'FinancialServices',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2356,
        name: 'AgileSoftwareDevelopment',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2357,
        name: 'ChangeManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2358, name: 'Purchasing', complete: 'BusinessAnalysis/Opreation'},
      {id: 2359, name: 'Python', complete: 'BusinessAnalysis/Opreation'},
      {id: 2360, name: 'Budgeting', complete: 'BusinessAnalysis/Opreation'},
      {id: 2361, name: 'Investments', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2362,
        name: 'AcceptanceTesting',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2363,
        name: 'BusinessProcess',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2364, name: 'Medicare', complete: 'BusinessAnalysis/Opreation'},
      {id: 2365, name: 'Statistics', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2366,
        name: 'GenerallyAcceptedAccountingPrinciples',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2367,
        name: 'CommercialBanking',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2368, name: 'Scrum', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2369,
        name: 'OrganizationalLeadership',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2370, name: 'Medica', complete: 'BusinessAnalysis/Opreation'},
      {id: 2371, name: 'Tableau', complete: 'BusinessAnalysis/Opreation'},
      {id: 2372, name: 'Forecasting', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2373,
        name: 'OperationalRisk',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2374,
        name: 'TrackingAndDataRelaySatellites',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2375,
        name: 'StrategicPlanning',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2376,
        name: 'SupplyChainManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2377, name: 'R', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2378,
        name: 'CertifiedPublicAccountant',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2379,
        name: 'PortfolioManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2380,
        name: 'SystemsDevelopmentLifeCycle',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2381, name: 'Governance', complete: 'BusinessAnalysis/Opreation'},
      {id: 2382, name: 'Mathematics', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2383,
        name: 'FinancialAnalysis',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2384,
        name: 'CapitalMarkets',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2385,
        name: 'BusinessAnalysis',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2386,
        name: 'SixSigmaMethodology',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2387,
        name: 'IndustrialManagement',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2388,
        name: 'ComputerSciences',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2389,
        name: 'FinancialPlanning',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2390, name: 'Securities', complete: 'BusinessAnalysis/Opreation'},
      {
        id: 2391,
        name: 'RelationalDatabases',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2392,
        name: 'LeanManufacturing',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2393,
        name: 'PublicAdministration',
        complete: 'BusinessAnalysis/Opreation'
      },
      {
        id: 2394,
        name: 'DataVisualization',
        complete: 'BusinessAnalysis/Opreation'
      },
      {id: 2395, name: 'BusinessSavvy', complete: 'FinacialServices'},
      {id: 2396, name: 'Investments', complete: 'FinacialServices'},
      {id: 2397, name: 'Medicare', complete: 'FinacialServices'},
      {id: 2398, name: 'DisabilityIncome', complete: 'FinacialServices'},
      {
        id: 2399,
        name: 'Series7GeneralSecuritiesRepresentativeLicense',
        complete: 'FinacialServices'
      },
      {id: 2400, name: 'InsuranceSales', complete: 'FinacialServices'},
      {
        id: 2401,
        name: 'Long-TermCareInsurance',
        complete: 'FinacialServices'
      },
      {id: 2402, name: 'WealthManagement', complete: 'FinacialServices'},
      {id: 2403, name: 'PhoneSales', complete: 'FinacialServices'},
      {
        id: 2404,
        name: 'FinancialIndustryRegulatoryAuthorities',
        complete: 'FinacialServices'
      },
      {
        id: 2405,
        name: 'CertifiedFinancialPlanner',
        complete: 'FinacialServices'
      },
      {id: 2406, name: 'SellingTechniques', complete: 'FinacialServices'},
      {id: 2407, name: 'BrandAwareness', complete: 'FinacialServices'},
      {id: 2408, name: 'FinancialPlanning', complete: 'FinacialServices'},
      {id: 2409, name: 'AdvertisingMail', complete: 'FinacialServices'},
      {id: 2410, name: 'Securities', complete: 'FinacialServices'},
      {
        id: 2411,
        name: 'Series66UniformInvestmentAdviser:CombinedStateLawsLicens',
        complete: 'FinacialServices'
      },
      {id: 2412, name: 'LifeInsuranceSales', complete: 'FinacialServices'},
      {id: 2413, name: 'MarketingPlanning', complete: 'FinacialServices'},
      {id: 2414, name: 'RetailBanking', complete: 'FinacialServices'},
      {id: 2415, name: 'Purchasing', complete: 'FinacialServices'},
      {id: 2416, name: 'MarketingStrategies', complete: 'FinacialServices'},
      {
        id: 2417,
        name: 'SelfServiceTechnologies',
        complete: 'FinacialServices'
      },
      {id: 2418, name: 'LeadGeneration', complete: 'FinacialServices'},
      {id: 2419, name: 'Annuities', complete: 'FinacialServices'},
      {id: 2420, name: 'InvestmentManagement', complete: 'FinacialServices'},
      {id: 2421, name: 'Dashboard', complete: 'FinacialServices'},
      {id: 2422, name: 'ClientRapport', complete: 'FinacialServices'},
      {id: 2423, name: 'BusinessDevelopment', complete: 'FinacialServices'},
      {id: 2424, name: 'Scripting', complete: 'FinacialServices'},
      {id: 2425, name: 'BusinessEthics', complete: 'FinacialServices'},
      {
        id: 2426,
        name: 'Series6InvestmentCompanyAndVariableContractsLicense',
        complete: 'FinacialServices'
      },
      {id: 2427, name: 'Medigap', complete: 'FinacialServices'},
      {id: 2428, name: 'Advocacy', complete: 'FinacialServices'},
      {id: 2429, name: 'CustomerExperience', complete: 'FinacialServices'},
      {id: 2430, name: 'ProductLining', complete: 'FinacialServices'},
      {id: 2431, name: 'WorkplaceDiversity', complete: 'FinacialServices'},
      {
        id: 2432,
        name: 'CustomerAcquisitionManagement',
        complete: 'FinacialServices'
      },
      {id: 2433, name: 'MedicareAdvantage', complete: 'FinacialServices'},
      {id: 2434, name: 'PharmaceuticalSales', complete: 'FinacialServices'},
      {id: 2435, name: 'Sourcing', complete: 'FinacialServices'},
      {id: 2436, name: 'InsurancePolicies', complete: 'FinacialServices'},
      {id: 2437, name: 'DigitalMedia', complete: 'FinacialServices'},
      {
        id: 2438,
        name: 'CustomerRelationshipManagement',
        complete: 'FinacialServices'
      },
      {id: 2439, name: 'R', complete: 'FinacialServices'},
      {id: 2440, name: 'DripMarketing', complete: 'FinacialServices'},
      {id: 2441, name: 'Balancing', complete: 'FinacialServices'},
      {id: 2442, name: 'InboundMarketing', complete: 'FinacialServices'},
      {id: 2443, name: 'EstatePlanning', complete: 'FinacialServices'},
      {id: 2444, name: 'InstructionalDesign', complete: 'HumanResources'},
      {id: 2445, name: 'Sourcing', complete: 'HumanResources'},
      {id: 2446, name: 'Auditing', complete: 'HumanResources'},
      {
        id: 2447,
        name: 'OrganizationalLeadership',
        complete: 'HumanResources'
      },
      {id: 2448, name: 'IndustrialRelations', complete: 'HumanResources'},
      {id: 2449, name: 'CurriculumDevelopment', complete: 'HumanResources'},
      {id: 2450, name: 'TalentSourcing', complete: 'HumanResources'},
      {
        id: 2451,
        name: 'EmployeeRetirementIncomeSecurityAct',
        complete: 'HumanResources'
      },
      {id: 2452, name: 'OrganizationDevelopment', complete: 'HumanResources'},
      {id: 2453, name: 'PerformanceManagement', complete: 'HumanResources'},
      {id: 2454, name: 'ProjectManagement', complete: 'HumanResources'},
      {
        id: 2455,
        name: 'ApplicantTrackingSystems',
        complete: 'HumanResources'
      },
      {id: 2456, name: 'Accounting', complete: 'HumanResources'},
      {id: 2457, name: 'ChangeManagement', complete: 'HumanResources'},
      {
        id: 2458,
        name: 'SeniorProfessionalInHumanReso',
        complete: 'HumanResources'
      },
      {id: 2459, name: 'AdultEducation', complete: 'HumanResources'},
      {id: 2460, name: 'ColdCalling', complete: 'HumanResources'},
      {id: 2461, name: 'EmployeeBenefits', complete: 'HumanResources'},
      {id: 2462, name: 'LeadershipDevelopment', complete: 'HumanResources'},
      {id: 2463, name: 'LabourLaws', complete: 'HumanResources'},
      {id: 2464, name: 'EducationalSoftware', complete: 'HumanResources'},
      {id: 2465, name: 'Taleo', complete: 'HumanResources'},
      {
        id: 2466,
        name: 'CertifiedEmployeeBenefitSpecialist',
        complete: 'HumanResources'
      },
      {id: 2467, name: 'StrategicPlanning', complete: 'HumanResources'},
      {id: 2468, name: 'LaborRelations', complete: 'HumanResources'},
      {id: 2469, name: 'ProgramManagement', complete: 'HumanResources'},
      {
        id: 2470,
        name: 'CustomerRelationshipManagement',
        complete: 'HumanResources'
      },
      {id: 2471, name: 'Mathematics', complete: 'HumanResources'},
      {
        id: 2472,
        name: 'OrganizationalEffectiveness',
        complete: 'HumanResources'
      },
      {id: 2473, name: 'SuccessionPlanning', complete: 'HumanResources'},
      {
        id: 2474,
        name: 'VirtualLearningEnvironments',
        complete: 'HumanResources'
      },
      {id: 2475, name: 'Branding', complete: 'HumanResources'},
      {
        id: 2476,
        name: 'CertifiedCompensationProfessional',
        complete: 'HumanResources'
      },
      {id: 2477, name: 'WorkforcePlanning', complete: 'HumanResources'},
      {id: 2478, name: 'EmployeeEngagement', complete: 'HumanResources'},
      {id: 2479, name: 'ContentCreation', complete: 'HumanResources'},
      {id: 2480, name: 'BooleanSearch', complete: 'HumanResources'},
      {
        id: 2481,
        name: 'HealthInsurancePortabilityAndAccountabilityAct',
        complete: 'HumanResources'
      },
      {id: 2482, name: 'BusinessStrategies', complete: 'HumanResources'},
      {
        id: 2483,
        name: 'SHRM-SCPentSeniorCertifiedProfessional}',
        complete: 'HumanResources'
      },
      {id: 2484, name: 'ExperientialLearning', complete: 'HumanResources'},
      {id: 2485, name: 'SocialMedia', complete: 'HumanResources'},
      {id: 2486, name: 'Welfare', complete: 'HumanResources'},
      {id: 2487, name: 'StrategicManagement', complete: 'HumanResources'},
      {id: 2488, name: 'HumanResourceManagement', complete: 'HumanResources'},
      {
        id: 2489,
        name: 'BusinessContinuityPlanning',
        complete: 'HumanResources'
      },
      {id: 2490, name: 'MarketIntelligence', complete: 'HumanResources'},
      {id: 2491, name: 'Writing', complete: 'HumanResources'},
      {
        id: 2492,
        name: 'IndustrialAndOrganizationalPsychology',
        complete: 'HumanResources'
      },
      {id: 2493, name: 'Construction', complete: 'LogisticsandProcurement'},
      {id: 2494, name: 'Sourcing', complete: 'LogisticsandProcurement'},
      {
        id: 2495,
        name: 'ContinuousImprovementProcess',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2496,
        name: 'MaterialHandling',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2497,
        name: 'QuantitativeResearch',
        complete: 'LogisticsandProcurement'
      },
      {id: 2498, name: 'Estimators', complete: 'LogisticsandProcurement'},
      {
        id: 2499,
        name: 'StrategicSourcing',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2500,
        name: 'SupplyChainManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2501, name: 'Warehousing', complete: 'LogisticsandProcurement'},
      {
        id: 2502,
        name: 'IndustrialManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2503, name: 'Subcontracting', complete: 'LogisticsandProcurement'},
      {id: 2504, name: 'Procurement', complete: 'LogisticsandProcurement'},
      {
        id: 2505,
        name: 'LeanManufacturing',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2506,
        name: 'WarehouseManagement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2507,
        name: 'IntegratedLogisticsSupport',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2508,
        name: 'ConstructionManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2509, name: 'Negotiation', complete: 'LogisticsandProcurement'},
      {
        id: 2510,
        name: 'MaterialRequirementsPlanning',
        complete: 'LogisticsandProcurement'
      },
      {id: 2511, name: 'ForkliftTruck', complete: 'LogisticsandProcurement'},
      {
        id: 2512,
        name: 'LogisticsManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2513, name: 'Bding', complete: 'LogisticsandProcurement'},
      {
        id: 2514,
        name: 'CategoryManagement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2515,
        name: 'NewProductDevelopment',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2516,
        name: 'WarehouseManagementSystems',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2517,
        name: 'ProgramManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2518, name: 'CostEstimation', complete: 'LogisticsandProcurement'},
      {
        id: 2519,
        name: 'SupplierPerformanceManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2520, name: 'Forecasting', complete: 'LogisticsandProcurement'},
      {
        id: 2521,
        name: 'PhysicalInventory',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2522,
        name: 'PerformanceAppraisal',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2523,
        name: 'CommercialConstruction',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2524,
        name: 'MasterOfBusinessAdministration',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2525,
        name: 'SixSigmaMethodology',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2526,
        name: 'CertifiedForkliftOperator',
        complete: 'LogisticsandProcurement'
      },
      {id: 2527, name: 'Writing', complete: 'LogisticsandProcurement'},
      {
        id: 2528,
        name: 'ConstructionEstimatingSoftware',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2529,
        name: 'RequestForProposal',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2530,
        name: 'EnterpriseResourcePlanning',
        complete: 'LogisticsandProcurement'
      },
      {id: 2531, name: 'StockControl', complete: 'LogisticsandProcurement'},
      {id: 2532, name: 'Economics', complete: 'LogisticsandProcurement'},
      {
        id: 2533,
        name: 'Constructability',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2534,
        name: 'SupplierRelationshipManagement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2535,
        name: 'CertifiedInProductionAndInventoryManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2536, name: 'Planogram', complete: 'LogisticsandProcurement'},
      {
        id: 2537,
        name: 'AcquisitionProcesses',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2538,
        name: 'CivilEngineering',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 2539,
        name: 'ProjectManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 2540, name: 'CostReduction', complete: 'LogisticsandProcurement'},
      {id: 2541, name: 'Automation', complete: 'LogisticsandProcurement'},
      {id: 2542, name: 'ProjectManagement', complete: 'Management'},
      {id: 2543, name: 'Merchandising', complete: 'Management'},
      {id: 2544, name: 'FinancialServices', complete: 'Management'},
      {id: 2545, name: 'LeanManufacturing', complete: 'Management'},
      {id: 2546, name: 'Construction', complete: 'Management'},
      {
        id: 2547,
        name: 'ProjectManagementProfessionalCertification',
        complete: 'Management'
      },
      {id: 2548, name: 'RestaurantOperation', complete: 'Management'},
      {id: 2549, name: 'Auditing', complete: 'Management'},
      {id: 2550, name: 'SupplyChainManagement', complete: 'Management'},
      {id: 2551, name: 'ConstructionManagement', complete: 'Management'},
      {id: 2552, name: 'AgileSoftwareDevelopment', complete: 'Management'},
      {id: 2553, name: 'Sanitation', complete: 'Management'},
      {id: 2554, name: 'RiskAnalysis', complete: 'Management'},
      {
        id: 2555,
        name: 'ContinuousImprovementProcess',
        complete: 'Management'
      },
      {
        id: 2556,
        name: 'Milestones:ProjectManagement}',
        complete: 'Management'
      },
      {id: 2557, name: 'LossPrevention', complete: 'Management'},
      {id: 2558, name: 'InternalAuditing', complete: 'Management'},
      {id: 2559, name: 'SixSigmaMethodology', complete: 'Management'},
      {id: 2560, name: 'Budgeting', complete: 'Management'},
      {id: 2561, name: 'MicrosoftProject', complete: 'Management'},
      {id: 2562, name: 'Cleanliness', complete: 'Management'},
      {id: 2563, name: 'Medicare', complete: 'Management'},
      {id: 2564, name: 'GoodManufacturingPractices', complete: 'Management'},
      {id: 2565, name: 'AccountsPayable', complete: 'Management'},
      {id: 2566, name: 'ProgramManagement', complete: 'Management'},
      {id: 2567, name: 'RetailSales', complete: 'Management'},
      {id: 2568, name: 'Investments', complete: 'Management'},
      {id: 2569, name: 'NewProductDevelopment', complete: 'Management'},
      {id: 2570, name: 'AccountsReceivable', complete: 'Management'},
      {id: 2572, name: 'TrainingAndDevelopment', complete: 'Management'},
      {id: 2573, name: 'DueDiligence', complete: 'Management'},
      {id: 2574, name: 'ManufacturingOperations', complete: 'Management'},
      {id: 2575, name: 'FinancialStatements', complete: 'Management'},
      {id: 2576, name: 'ProjectPlanning', complete: 'Management'},
      {id: 2577, name: 'ResolveGuestConcerns', complete: 'Management'},
      {id: 2578, name: 'RegulatoryCompliance', complete: 'Management'},
      {id: 2579, name: 'Procurement', complete: 'Management'},
      {id: 2580, name: 'Collections', complete: 'Management'},
      {id: 2581, name: 'SystemsDevelopmentLifeCycle', complete: 'Management'},
      {id: 2582, name: 'RetailManagement', complete: 'Management'},
      {id: 2583, name: 'OperationalRisk', complete: 'Management'},
      {
        id: 2584,
        name: 'MaterialRequirementsPlanning',
        complete: 'Management'
      },
      {id: 2585, name: 'Calculations', complete: 'Management'},
      {id: 2586, name: 'Timelines', complete: 'Management'},
      {id: 2587, name: 'InventoryControl', complete: 'Management'},
      {id: 2588, name: 'Writing', complete: 'Management'},
      {id: 2589, name: 'EnterpriseResourcePlanning', complete: 'Management'},
      {
        id: 2590,
        name: 'SQL',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2591,
        name: 'SearchEngineOptimization',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2592,
        name: 'ProductManagement',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2593,
        name: 'SocialMedia',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2594,
        name: 'BusinessDevelopment',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2595,
        name: 'Fundraising',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2596,
        name: 'Statistics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2597,
        name: 'DigitalMarketing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2598,
        name: 'NewProductDevelopment',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2599,
        name: 'PublicRelations',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2600,
        name: 'SellingTechniques',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2601,
        name: 'Python',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2602,
        name: 'GoogleAnalytics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2603,
        name: 'AgileSoftwareDevelopment',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2604,
        name: 'Editing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2605,
        name: 'Forecasting',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2606,
        name: 'Philanthropy',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2607,
        name: 'SAS',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2608,
        name: 'Analytics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2609,
        name: 'TechnologyRoadmap',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2610,
        name: 'Writing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2611,
        name: 'Negotiation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2612,
        name: 'Budgeting',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2613,
        name: 'Tableau{:BusinessIntelligenceSoftware}',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2614,
        name: 'SearchEngineMarketing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2615,
        name: 'Scrum{}',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2616,
        name: 'AdobePhotoshop',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2617,
        name: 'ValueProposition',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2618,
        name: 'StrategicPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2619,
        name: 'R',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2620,
        name: 'MarketingAutomation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2621,
        name: 'MarketingAutomation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2622,
        name: 'Journalism',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2623,
        name: 'SalesProcess',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2624,
        name: 'Hospitality',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2625,
        name: 'Mathematics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2626,
        name: 'WebAnalytics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2627,
        name: 'ProductLining',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2628,
        name: 'MarketingCommunications',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2629,
        name: 'BusinessPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2630,
        name: 'PlannedGiving',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2631,
        name: 'Economics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2632,
        name: 'EmailMarketing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2633,
        name: 'ProductRequirements',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2634,
        name: 'ContentMarketing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2635,
        name: 'MarketShare',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2636,
        name: 'EventPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 2637,
        name: 'DataVisualization',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {id: 2638, name: 'ElectricalWirings', complete: 'Canstruction'},
      {id: 2639, name: 'ConstructionManagement', complete: 'Canstruction'},
      {id: 2640, name: 'Pipe', complete: 'Canstruction'},
      {id: 2641, name: 'RequestForProposal', complete: 'Canstruction'},
      {id: 2642, name: 'Carpentry', complete: 'Canstruction'},
      {
        id: 2643,
        name: 'TroubleshootingroblemSolving}',
        complete: 'Canstruction'
      },
      {id: 2644, name: 'Subcontracting', complete: 'Canstruction'},
      {id: 2645, name: 'Valvesiping}', complete: 'Canstruction'},
      {id: 2646, name: 'RealEstate', complete: 'Canstruction'},
      {id: 2647, name: 'Cabinetry', complete: 'Canstruction'},
      {id: 2648, name: 'ElectricalSystems', complete: 'Canstruction'},
      {id: 2649, name: 'Construction', complete: 'Canstruction'},
      {id: 2650, name: 'Soldering', complete: 'Canstruction'},
      {
        id: 2651,
        name: 'MilestonesrojectManagement}',
        complete: 'Canstruction'
      },
      {id: 2652, name: 'Drywal}', complete: 'Canstruction'},
      {id: 2653, name: 'Transformersical}', complete: 'Canstruction'},
      {id: 2654, name: 'ProjectManagement', complete: 'Canstruction'},
      {id: 2655, name: 'PlumbingFixtures', complete: 'Canstruction'},
      {id: 2656, name: 'CostEstimation', complete: 'Canstruction'},
      {id: 2657, name: 'Woodworking', complete: 'Canstruction'},
      {id: 2658, name: 'Blueprinting', complete: 'Canstruction'},
      {id: 2659, name: 'Budgeting', complete: 'Canstruction'},
      {id: 2660, name: 'DrainageSystems', complete: 'Canstruction'},
      {id: 2661, name: 'Timelines', complete: 'Canstruction'},
      {id: 2662, name: 'Roofing', complete: 'Canstruction'},
      {id: 2663, name: 'ElectricalEquipment', complete: 'Canstruction'},
      {id: 2664, name: 'Submittalsuction}', complete: 'Canstruction'},
      {id: 2665, name: 'Pumps', complete: 'Canstruction'},
      {
        id: 2666,
        name: 'ProjectManagementProfessionalCertification',
        complete: 'Canstruction'
      },
      {id: 2667, name: 'DoorFrames', complete: 'Canstruction'},
      {id: 2668, name: 'CircuitSwitches', complete: 'Canstruction'},
      {id: 2669, name: 'QualityControl', complete: 'Canstruction'},
      {id: 2670, name: 'Plumbing', complete: 'Canstruction'},
      {id: 2671, name: 'ArchitecturalDrawing', complete: 'Canstruction'},
      {id: 2672, name: 'Lumber', complete: 'Canstruction'},
      {id: 2673, name: 'Paneling', complete: 'Canstruction'},
      {id: 2674, name: 'Procurement', complete: 'Canstruction'},
      {id: 2675, name: 'PlumbingCodes', complete: 'Canstruction'},
      {id: 2676, name: 'LandZoning', complete: 'Canstruction'},
      {id: 2677, name: 'Furnishing', complete: 'Canstruction'},
      {id: 2678, name: 'ElectronicComponents', complete: 'Canstruction'},
      {id: 2679, name: 'CivilEngineering', complete: 'Canstruction'},
      {id: 2680, name: 'PipeThreading', complete: 'Canstruction'},
      {id: 2681, name: 'Auditing', complete: 'Canstruction'},
      {id: 2682, name: 'PlumbBob', complete: 'Canstruction'},
      {id: 2683, name: 'CircuitBreakers', complete: 'Canstruction'},
      {id: 2684, name: 'CostManagement', complete: 'Canstruction'},
      {
        id: 2685,
        name: 'CardiopulmonaryResuscitation}',
        complete: 'Canstruction'
      },
      {
        id: 2686,
        name: 'LEEDAccreditedProfessionalAP}',
        complete: 'Canstruction'
      },
      {id: 2687, name: 'ReservoirEngineering', complete: 'MiningandDrilling'},
      {id: 2688, name: 'ConePenetrationTests', complete: 'MiningandDrilling'},
      {id: 2689, name: 'DrillingRig', complete: 'MiningandDrilling'},
      {id: 2690, name: 'Geology', complete: 'MiningandDrilling'},
      {id: 2691, name: 'Transmission', complete: 'MiningandDrilling'},
      {id: 2692, name: 'PetroleumEngineering', complete: 'MiningandDrilling'},
      {id: 2693, name: 'CPTCoding', complete: 'MiningandDrilling'},
      {
        id: 2694,
        name: 'PersonalProtectiveEquipment',
        complete: 'MiningandDrilling'
      },
      {id: 2695, name: 'MiningEngineering', complete: 'MiningandDrilling'},
      {id: 2696, name: 'DieselEngines', complete: 'MiningandDrilling'},
      {id: 2697, name: 'Forecasting', complete: 'MiningandDrilling'},
      {
        id: 2698,
        name: 'UnifiedSoilClassificationSystems',
        complete: 'MiningandDrilling'
      },
      {id: 2699, name: 'HydrogenSulfTraining', complete: 'MiningandDrilling'},
      {id: 2700, name: 'EngineeringGeology', complete: 'MiningandDrilling'},
      {id: 2701, name: 'OilAndGas', complete: 'MiningandDrilling'},
      {
        id: 2702,
        name: 'MathematicalOptimization',
        complete: 'MiningandDrilling'
      },
      {id: 2703, name: 'TaskPlanning', complete: 'MiningandDrilling'},
      {id: 2704, name: 'OilFields', complete: 'MiningandDrilling'},
      {id: 2705, name: 'UndergroundMining', complete: 'MiningandDrilling'},
      {id: 2706, name: 'Solvent', complete: 'MiningandDrilling'},
      {id: 2707, name: 'ReservoirModeling', complete: 'MiningandDrilling'},
      {id: 2708, name: 'Piezometer', complete: 'MiningandDrilling'},
      {id: 2709, name: 'Pumps', complete: 'MiningandDrilling'},
      {id: 2710, name: 'Geophysics', complete: 'MiningandDrilling'},
      {id: 2711, name: 'ServiceDelivery', complete: 'MiningandDrilling'},
      {id: 2712, name: 'Economics', complete: 'MiningandDrilling'},
      {id: 2713, name: 'QualityManagement', complete: 'MiningandDrilling'},
      {id: 2714, name: 'PowerToolOperation', complete: 'MiningandDrilling'},
      {id: 2715, name: 'QualityControl', complete: 'MiningandDrilling'},
      {id: 2716, name: 'Mechanics', complete: 'MiningandDrilling'},
      {id: 2717, name: 'DrillingEngineering', complete: 'MiningandDrilling'},
      {id: 2718, name: 'SoilScience', complete: 'MiningandDrilling'},
      {id: 2719, name: 'ServiceIndustries', complete: 'MiningandDrilling'},
      {id: 2720, name: 'Hydrology', complete: 'MiningandDrilling'},
      {id: 2721, name: 'Petrochemical', complete: 'MiningandDrilling'},
      {id: 2722, name: 'MassBalance', complete: 'MiningandDrilling'},
      {id: 2723, name: 'Groundwater', complete: 'MiningandDrilling'},
      {id: 2724, name: 'HeavyEquipment', complete: 'MiningandDrilling'},
      {id: 2725, name: 'Mathematics', complete: 'MiningandDrilling'},
      {id: 2726, name: 'Brakes', complete: 'MiningandDrilling'},
      {id: 2727, name: 'Budgeting', complete: 'MiningandDrilling'},
      {id: 2728, name: 'SafetyStandards', complete: 'MiningandDrilling'},
      {id: 2729, name: 'Drilling', complete: 'MiningandDrilling'},
      {id: 2730, name: 'CivilEngineering', complete: 'MiningandDrilling'},
      {id: 2731, name: 'Refinery', complete: 'MiningandDrilling'},
      {id: 2732, name: 'ReservoirSimulation', complete: 'MiningandDrilling'},
      {id: 2733, name: 'FirstA', complete: 'MiningandDrilling'},
      {id: 2734, name: 'PipConveyance}', complete: 'MiningandDrilling'},
      {
        id: 2735,
        name: 'GeographicInformationSystems',
        complete: 'MiningandDrilling'
      },
      {id: 2736, name: 'ControlPanels', complete: 'MiningandDrilling'},
      {id: 2737, name: 'Metadata', complete: 'CurationandLibraryScience'},
      {id: 2738, name: 'Curation', complete: 'CurationandLibraryScience'},
      {
        id: 2739,
        name: 'ClericalWorks',
        complete: 'CurationandLibraryScience'
      },
      {id: 2740, name: 'Teaching', complete: 'CurationandLibraryScience'},
      {
        id: 2741,
        name: 'TechnicalServices',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2742,
        name: 'MetadataStandards',
        complete: 'CurationandLibraryScience'
      },
      {id: 2743, name: 'Exhibitions', complete: 'CurationandLibraryScience'},
      {id: 2744, name: 'Pedagogy', complete: 'CurationandLibraryScience'},
      {
        id: 2745,
        name: 'IntegratedLibrarySystems',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2746,
        name: 'ExtensibleMarkupLanguage',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2747,
        name: 'MuseumStudies',
        complete: 'CurationandLibraryScience'
      },
      {id: 2748, name: 'Filing', complete: 'CurationandLibraryScience'},
      {
        id: 2749,
        name: 'LibraryInstruction',
        complete: 'CurationandLibraryScience'
      },
      {id: 2750, name: 'Cataloguing', complete: 'CurationandLibraryScience'},
      {id: 2751, name: 'Workflows', complete: 'CurationandLibraryScience'},
      {id: 2752, name: 'Photography', complete: 'CurationandLibraryScience'},
      {id: 2753, name: 'Punctuation', complete: 'CurationandLibraryScience'},
      {
        id: 2754,
        name: 'StrategicPlanning',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2755,
        name: 'LibraryOfCongressSubjectHeadings',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2756,
        name: 'HyperTextMarkupLanguage',
        complete: 'CurationandLibraryScience'
      },
      {id: 2757, name: 'Collections', complete: 'CurationandLibraryScience'},
      {id: 2758, name: 'CashRegister', complete: 'CurationandLibraryScience'},
      {
        id: 2759,
        name: 'CurriculumDevelopment',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2760,
        name: 'ResourceManagement',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2761,
        name: 'CascadingStyleSheets',
        complete: 'CurationandLibraryScience'
      },
      {id: 2762, name: 'Loans', complete: 'CurationandLibraryScience'},
      {id: 2763, name: 'Typing', complete: 'CurationandLibraryScience'},
      {id: 2764, name: 'Tutorials', complete: 'CurationandLibraryScience'},
      {
        id: 2765,
        name: 'Authentications',
        complete: 'CurationandLibraryScience'
      },
      {id: 2766, name: 'JavaScript', complete: 'CurationandLibraryScience'},
      {id: 2767, name: 'Anthropology', complete: 'CurationandLibraryScience'},
      {id: 2768, name: 'DataEntry', complete: 'CurationandLibraryScience'},
      {
        id: 2769,
        name: 'EducationalTechnologies',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2770,
        name: 'TroubleshootingroblemSolving}',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2771,
        name: 'DigitalPreservation',
        complete: 'CurationandLibraryScience'
      },
      {id: 2772, name: 'Lecturing', complete: 'CurationandLibraryScience'},
      {
        id: 2773,
        name: 'DepositAccounts',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2774,
        name: 'InstructionalDesign',
        complete: 'CurationandLibraryScience'
      },
      {
        id: 2775,
        name: 'QualityControl',
        complete: 'CurationandLibraryScience'
      },
      {id: 2776, name: 'Python', complete: 'CurationandLibraryScience'},
      {
        id: 2777,
        name: 'ExhibitDesign',
        complete: 'CurationandLibraryScience'
      },
      {id: 2778, name: 'Spreadsheets', complete: 'CurationandLibraryScience'},
      {
        id: 2779,
        name: 'LearningManagementSystems',
        complete: 'CurationandLibraryScience'
      },
      {id: 2780, name: 'Purchasing', complete: 'CurationandLibraryScience'},
      {id: 2781, name: 'Scripting', complete: 'CurationandLibraryScience'},
      {id: 2782, name: 'Catalogues', complete: 'CurationandLibraryScience'},
      {id: 2783, name: 'Memos', complete: 'CurationandLibraryScience'},
      {
        id: 2784,
        name: 'HealthSciences',
        complete: 'CurationandLibraryScience'
      },
      {id: 2785, name: 'LeadershipDevelopment', complete: 'Education'},
      {id: 2786, name: 'Writing', complete: 'Education'},
      {id: 2787, complete: 'Education'},
      {id: 2788, name: 'InstructionalDesign', complete: 'Education'},
      {id: 2789, name: 'CardiopulmonaryResuscitation', complete: 'Education'},
      {id: 2790, name: 'SpanishLanguage', complete: 'Education'},
      {id: 2791, name: 'SocialSciences', complete: 'Education'},
      {id: 2792, name: 'LearningManagementSystems', complete: 'Education'},
      {id: 2793, name: 'FirstA', complete: 'Education'},
      {id: 2794, name: 'LanguageArts', complete: 'Education'},
      {id: 2795, name: 'Sociology', complete: 'Education'},
      {id: 2796, name: 'AdultEducation', complete: 'Education'},
      {id: 2797, name: 'ChildDevelopment', complete: 'Education'},
      {id: 2798, name: 'AcademicAchievement', complete: 'Education'},
      {id: 2799, name: 'Phonics', complete: 'Education'},
      {id: 2800, name: 'EducationalTechnologies', complete: 'Education'},
      {id: 2801, name: 'ParentCommunication', complete: 'Education'},
      {id: 2802, name: 'Mathematics', complete: 'Education'},
      {id: 2803, name: 'PersonalComputers', complete: 'Education'},
      {id: 2804, name: 'CamtasiaStudio', complete: 'Education'},
      {id: 2805, name: 'CognitiveDevelopment', complete: 'Education'},
      {id: 2806, name: 'Empower', complete: 'Education'},
      {id: 2807, name: 'InterpersonalSkills', complete: 'Education'},
      {id: 2808, name: 'GraphicDesign', complete: 'Education'},
      {id: 2809, name: 'Caregiving', complete: 'Education'},
      {id: 2810, name: 'SocialStudies', complete: 'Education'},
      {id: 2811, name: 'AcademicWriting', complete: 'Education'},
      {id: 2812, name: 'AdobeCaptivate', complete: 'Education'},
      {id: 2813, name: 'Cleanliness', complete: 'Education'},
      {id: 2814, name: 'EducationalEquity', complete: 'Education'},
      {id: 2815, name: 'Disabilities', complete: 'Education'},

      {
        id: 1917,
        name: 'ProductQuality/QC}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1918,
        name: 'SheetMetal',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1919,
        name: 'Avionics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1920,
        name: 'Ventilation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1921,
        name: 'Refinery',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1922,
        name: 'LadderLogic',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1923,
        name: 'AxureRP',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1924,
        name: 'DevOps',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1925,
        name: 'ProductionPartApprovalProcess',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1926,
        name: 'Welding',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1927,
        name: 'TopSecret-SensitiveCompartmentedInformation/SCIClearance}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1928,
        name: 'IntegralCalculus',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1929,
        name: 'OccupationalHealthAndSafetyAdministratioSHA}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1930,
        name: 'ControlEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1931,
        name: 'AgileSoftwareDevelopment',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1932,
        name: 'SoftwareDevelopment',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1933,
        name: 'GoodManufacturingPractices',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1934,
        name: 'ComputerAedThree-DimensionalInteractiveApplicatioATIA}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1935,
        name: 'Propulsion',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1936,
        name: 'Geology',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1937,
        name: 'Chemistry',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1938,
        name: 'Wonderware',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1939,
        name: 'JavaScript',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1940,
        name: 'Jenkins',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1941,
        name: 'ManufacturingProcesses',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1942,
        name: 'InjectionMolding',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1943,
        name: 'Mathematics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1944,
        name: 'SoilMechanics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1945,
        name: 'ProcessSafety',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1946,
        name: 'BusinessProcessAutomation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1947,
        name: 'UserFlows',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1948,
        name: 'ComputerSciences',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1949,
        name: 'ProcessFailureModeAndEffectsAnalysisFMEA}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1950,
        name: 'SolModeling',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1951,
        name: 'Python',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1952, name: 'Cartography', complete: 'SurveyingandMapping'},
      {id: 1953, name: 'Trigonometry', complete: 'SurveyingandMapping'},
      {
        id: 1954,
        name: 'GlobalPositioningSystems',
        complete: 'SurveyingandMapping'
      },
      {id: 1955, name: 'Construction', complete: 'SurveyingandMapping'},
      {id: 1956, name: 'QualityControl', complete: 'SurveyingandMapping'},
      {id: 1957, name: 'RemoteSensing', complete: 'SurveyingandMapping'},
      {id: 1958, name: 'Geometry', complete: 'SurveyingandMapping'},
      {id: 1959, name: 'TotalStation', complete: 'SurveyingandMapping'},
      {id: 1960, name: 'HydrographicSurvey', complete: 'SurveyingandMapping'},
      {id: 1961, name: 'Billing', complete: 'SurveyingandMapping'},
      {id: 1962, name: 'Astronomy', complete: 'SurveyingandMapping'},
      {id: 1963, name: 'Mathematics', complete: 'SurveyingandMapping'},
      {id: 1964, name: 'DataCollection', complete: 'SurveyingandMapping'},
      {id: 1965, name: 'PublicWorks', complete: 'SurveyingandMapping'},
      {id: 1966, name: 'QualityAssurance', complete: 'SurveyingandMapping'},
      {id: 1967, name: 'Photogrammetry', complete: 'SurveyingandMapping'},
      {id: 1968, name: 'Algebra', complete: 'SurveyingandMapping'},
      {
        id: 1969,
        name: 'ConstructionManagement',
        complete: 'SurveyingandMapping'
      },
      {id: 1970, name: 'Collections', complete: 'SurveyingandMapping'},
      {
        id: 1971,
        name: 'LandInformationSystems',
        complete: 'SurveyingandMapping'
      },
      {id: 1972, name: 'Writing', complete: 'SurveyingandMapping'},
      {id: 1973, name: 'Elevation', complete: 'SurveyingandMapping'},
      {id: 1974, name: 'Hydrography', complete: 'SurveyingandMapping'},
      {id: 1975, name: 'ProjectAccounting', complete: 'SurveyingandMapping'},
      {
        id: 1976,
        name: 'AcademicAchievement',
        complete: 'SurveyingandMapping'
      },
      {id: 1977, name: 'Reliability', complete: 'SurveyingandMapping'},
      {id: 1978, name: 'GoodDrivingRecord', complete: 'SurveyingandMapping'},
      {id: 1979, name: 'Geomatics', complete: 'SurveyingandMapping'},
      {id: 1980, name: 'Registration', complete: 'SurveyingandMapping'},
      {
        id: 1981,
        name: 'ArcGIS:GISSoftware}',
        complete: 'SurveyingandMapping'
      },
      {id: 1982, name: 'Calculus', complete: 'SurveyingandMapping'},
      {id: 1983, name: 'Surveying', complete: 'SurveyingandMapping'},
      {id: 1984, name: 'FineMotorSkills', complete: 'SurveyingandMapping'},
      {id: 1985, name: 'MachineControls', complete: 'SurveyingandMapping'},
      {
        id: 1986,
        name: 'GeographicInformationSystems',
        complete: 'SurveyingandMapping'
      },
      {id: 1987, name: 'Statistics', complete: 'SurveyingandMapping'},
      {
        id: 1988,
        name: 'OccupationalHealthAndSafetyAdministration{OSHA}',
        complete: 'SurveyingandMapping'
      },
      {
        id: 1989,
        name: 'PersonalProtectiveEquipment',
        complete: 'SurveyingandMapping'
      },
      {id: 1990, name: 'ProjectManagement', complete: 'SurveyingandMapping'},
      {
        id: 1991,
        name: 'InternetProtocolTelephony',
        complete: 'SurveyingandMapping'
      },
      {id: 1992, name: 'ElementaryAlgebra', complete: 'SurveyingandMapping'},
      {
        id: 1993,
        name: 'CardiopulmonaryResuscitation{CPR}',
        complete: 'SurveyingandMapping'
      },
      {id: 1994, name: 'RealProperty', complete: 'SurveyingandMapping'},
      {id: 1995, name: '3DModeling', complete: 'SurveyingandMapping'},
      {id: 1996, name: 'Advising', complete: 'SurveyingandMapping'},
      {id: 1997, name: 'ComputerTerminals', complete: 'SurveyingandMapping'},
      {id: 1998, name: 'RainGutters', complete: 'SurveyingandMapping'},
      {
        id: 1999,
        name: 'MultibeamEchosounder',
        complete: 'SurveyingandMapping'
      },
      {id: 2000, name: 'Groundwater', complete: 'PhysicalScienceEngineering'},
      {id: 2001, name: 'Mathematics', complete: 'PhysicalScienceEngineering'},
      {
        id: 2002,
        name: 'ChemicalEngineering',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2003,
        name: 'WaterResources',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2004, name: 'Biology', complete: 'PhysicalScienceEngineering'},
      {
        id: 2005,
        name: 'NuclearPower',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2006,
        name: 'EnvironmentalScience',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2007, name: 'Electronics', complete: 'PhysicalScienceEngineering'},
      {
        id: 2008,
        name: 'ProcessEngineering',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2009,
        name: 'Construction',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2010,
        name: 'NaturalResourceManagement',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2011,
        name: 'NuclearEngineering',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2012, name: 'SoilScience', complete: 'PhysicalScienceEngineering'},
      {
        id: 2013,
        name: 'EngineeringPhysics',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2014,
        name: 'TroubleshootingroblemSolving',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2015,
        name: 'GeographicInformationSystems',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2016,
        name: 'EnvironmentalPlanning',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2017,
        name: 'NuclearSafety',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2018,
        name: 'EnvironmentalLaws',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2019, name: 'Physics', complete: 'PhysicalScienceEngineering'},
      {
        id: 2020,
        name: 'ChemicalProcess',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2021,
        name: 'CivilEngineering',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2022, name: 'Agriculture', complete: 'PhysicalScienceEngineering'},
      {
        id: 2023,
        name: 'NuclearReactor',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2024,
        name: 'EnvironmentalCompliance',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2025,
        name: 'IntegralCalculus',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2026,
        name: 'ProcessDevelopment',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2027, name: 'Ecology', complete: 'PhysicalScienceEngineering'},
      {
        id: 2028,
        name: 'ElectricalEngineering',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2029,
        name:
          'OccupationalHealthAndSafetyHazardousWasteOperationsAndEmergencyResponseStandardHAZWOPER}Administration{OSHA}',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2030,
        name: 'ComputerSciences',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2031,
        name: 'ManufacturingProcesses',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2032, name: 'Stormwater', complete: 'PhysicalScienceEngineering'},
      {
        id: 2033,
        name: 'NationalEnvironmentalPolicyAct',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2034, name: 'NuclearFuel', complete: 'PhysicalScienceEngineering'},
      {
        id: 2035,
        name: 'Sampling{Statistics}',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2036,
        name: 'SoilMechanics',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2037,
        name: 'GoodManufacturingPractices',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2038,
        name: 'GoodDrivingRecord',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2039,
        name: 'ProgramManagement',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2040,
        name: 'RadiationProtection',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2041,
        name: 'HazardousWasteOperationsAndEmergencyResponseStandard{HAZWOPER}',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2042,
        name: 'MathematicalSciences',
        complete: 'PhysicalScienceEngineering'
      },
      {
        id: 2043,
        name: 'ProcessFlowDiagrams',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2044, name: 'AutoCAD', complete: 'PhysicalScienceEngineering'},
      {id: 2045, name: 'Economics', complete: 'PhysicalScienceEngineering'},
      {
        id: 2046,
        name: 'RadioactiveWaste',
        complete: 'PhysicalScienceEngineering'
      },
      {id: 2047, name: 'Geology', complete: 'PhysicalScienceEngineering'},
      {id: 2048, name: 'WordPress', complete: 'Art,DesginandAnimation'},
      {
        id: 2049,
        name: 'PhotoManipulation',
        complete: 'Art,DesginandAnimation'
      },
      {
        id: 2050,
        name: 'AdobeAfterEffects',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2051, name: '3DModeling', complete: 'Art,DesginandAnimation'},
      {
        id: 2052,
        name: 'AgileSoftwareDevelopment',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2053, name: 'SocialMedia', complete: 'Art,DesginandAnimation'},
      {id: 2054, name: 'Illustration', complete: 'Art,DesginandAnimation'},
      {
        id: 2055,
        name: 'AdobePremierePro',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2056, name: 'SpacePlanning', complete: 'Art,DesginandAnimation'},
      {id: 2057, name: 'AppleIOS', complete: 'Art,DesginandAnimation'},
      {
        id: 2058,
        name: 'AdobeDreamweaver',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2059, name: 'BrandManagement', complete: 'Art,DesginandAnimation'},
      {
        id: 2060,
        name: 'ComputerAnimation',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2061, name: 'Construction', complete: 'Art,DesginandAnimation'},
      {
        id: 2062,
        name: 'AndroOperatingSystem}',
        complete: 'Art,DesginandAnimation'
      },
      {
        id: 2063,
        name: 'FrontEnd{SoftwareEngineering}',
        complete: 'Art,DesginandAnimation'
      },
      {
        id: 2064,
        name: 'StrategicPlanning',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2065, name: 'VeoEditing', complete: 'Art,DesginandAnimation'},
      {id: 2066, name: 'ZBrush', complete: 'Art,DesginandAnimation'},
      {
        id: 2067,
        name: 'User-CenteredDesign',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2068, name: 'EmailMarketing', complete: 'Art,DesginandAnimation'},
      {id: 2069, name: 'Copywriting', complete: 'Art,DesginandAnimation'},
      {id: 2070, name: 'Maya', complete: 'Art,DesginandAnimation'},
      {id: 2071, name: 'Prototyping', complete: 'Art,DesginandAnimation'},
      {
        id: 2072,
        name: 'HyperTextMarkupLanguage{:HTML}',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2073, name: 'Photography', complete: 'Art,DesginandAnimation'},
      {id: 2074, name: 'Animations', complete: 'Art,DesginandAnimation'},
      {id: 2075, name: 'AutodeskRevit', complete: 'Art,DesginandAnimation'},
      {id: 2076, name: 'UserInterface', complete: 'Art,DesginandAnimation'},
      {
        id: 2077,
        name: 'CascadingStyleSheets{:CSS}',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2078, name: 'Branding', complete: 'Art,DesginandAnimation'},
      {
        id: 2079,
        name: 'MotionGraphicDesign',
        complete: 'Art,DesginandAnimation'
      },
      {
        id: 2080,
        name: 'Autodesk3DSMax{3DGraphicsSoftware}',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2081, name: 'UserExperience', complete: 'Art,DesginandAnimation'},
      {id: 2082, name: 'JavaScript', complete: 'Art,DesginandAnimation'},
      {
        id: 2083,
        name: 'VisualCommunications',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2084, name: 'Cinema4D', complete: 'Art,DesginandAnimation'},
      {id: 2085, name: 'Rendering', complete: 'Art,DesginandAnimation'},
      {
        id: 2086,
        name: 'InteractionDesign',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2087, name: 'WebDesign', complete: 'Art,DesginandAnimation'},
      {
        id: 2088,
        name: 'QuarkXPressDesignSoftware',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2089, name: 'Multimedia', complete: 'Art,DesginandAnimation'},
      {
        id: 2090,
        name: 'SketchUp{3DModelingSoftware}',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2091, name: 'Usability', complete: 'Art,DesginandAnimation'},
      {id: 2092, name: 'HTML5', complete: 'Art,DesginandAnimation'},
      {
        id: 2093,
        name: 'MarketingStrategies',
        complete: 'Art,DesginandAnimation'
      },
      {id: 2094, name: 'VisualEffects', complete: 'Art,DesginandAnimation'},
      {id: 2095, name: 'Furnishing', complete: 'Art,DesginandAnimation'},
      {id: 2096, name: 'ProductDesign', complete: 'Art,DesginandAnimation'},
      {
        id: 2097,
        name: 'TroubleshootingroblemSolving}',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2098,
        name: 'BroadcastEngineering',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2099,
        name: 'AdobePremierePro',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2100,
        name: 'Photography',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2101,
        name: 'ProTools',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2102,
        name: 'PhotoManipulation',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2103,
        name: 'ControlSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2104,
        name: 'Electronics',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2105,
        name: 'Editing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2106,
        name: 'AdobePhotoshop',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2107,
        name: 'SoundRecordingAndReproduction',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2108,
        name: 'Compositions',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2109,
        name: 'Veoconferencing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2110,
        name: 'TransmissionSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2111,
        name: 'VeoEditing',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2112,
        name: 'Aperture',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2113,
        name: 'ProductionEquipmentControls',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2114,
        name: 'PhotographicLighting',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2115,
        name: 'AudioSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2116,
        name: 'Telecommunications',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2117,
        name: 'MotionGraphicDesign',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2118,
        name: 'DigitalPhotography',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2119,
        name: 'Encodings',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2120,
        name: 'PhotoShooting',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2121,
        name: 'Liqu',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2122,
        name: 'NetworkRouting',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2123,
        name: 'FinalCutPro',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2124,
        name: 'Teaching',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2125,
        name: 'Transcoding',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2126,
        name: 'Merchandising',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2127,
        name: 'ElectricalWirings',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2128,
        name: 'Antenna',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2129,
        name: 'Storytelling',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2130,
        name: 'AdobePhotoshopLightroom',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2131,
        name: 'Workflows',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2132,
        name: 'ColorCorrection',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2133,
        name: 'AVIXACertifiedTechnologySpecialist',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2134,
        name: 'RadioFrequency',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2135,
        name: 'Animations',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2136,
        name: 'SellingTechniques',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2137,
        name: 'InterpersonalSkills',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2138,
        name: 'StillLife',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2139,
        name: 'TechnicalSupport',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2140,
        name: 'OpticalFiber',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2141,
        name: 'AdobeIllustrator',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2142,
        name: 'PhotographicStudio',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2143,
        name: 'DataStreaming',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2144,
        name: 'AdobeInDesign',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {
        id: 2145,
        name: 'VisualSystems',
        complete: 'Audio-VisualandBroadcastingTechnology'
      },
      {id: 2146, name: 'Jazz', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2147,
        name: 'SocialMedia',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2148,
        name: 'Post-Production',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2149,
        name: 'Rigging{3DModeling}',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2150,
        name: 'AdobePhotoshop',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2151,
        name: 'MusicTheories',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2152,
        name: 'Journalism',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2153,
        name: 'MotionGraphicDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2154,
        name: 'MotionGraphicDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2155,
        name: 'AdobePremierePro',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2156, name: 'Guitars', complete: 'EntertainmentandPerformingArts'},
      {id: 2157, name: 'Guitars', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2158,
        name: 'Animations',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2159,
        name: 'Carpentry',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2160,
        name: 'AdobeIllustrator',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2161, name: 'Piano', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2162,
        name: 'HyperTextMarkupLanguage{HTML}',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2163,
        name: 'AdobeCreativeSuite',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2164,
        name: 'TroubleshootingroblemSolving}',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2165,
        name: 'Photography',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2166,
        name: 'ElectricBassGuitars',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2167,
        name: 'SearchEngineOptimization',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2168,
        name: 'Timelines',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2169,
        name: 'Construction',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2170,
        name: 'AdobeAfterEffects',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2171,
        name: 'Costumes',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2172,
        name: 'APStylebook',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2173,
        name: 'Workflows',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2174,
        name: 'StageLighting',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2175,
        name: 'DigitalSingle-LensReflexCameras',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2176, name: 'Tuba', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2177,
        name: 'Analytics',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2178,
        name: 'ColorCorrection',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2179,
        name: 'ColorCorrection',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2180,
        name: 'GraphicDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2181,
        name: 'GraphicDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2182, name: 'Writing', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2183,
        name: 'VisualEffects',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2184,
        name: 'BusinessDevelopment',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2185,
        name: 'FinalCutPro',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2186,
        name: 'CostumeDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2187,
        name: 'ContentManagementSystems',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2188,
        name: 'Storyboarding',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2189, name: 'Python', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2190,
        name: 'AdobeInDesign',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2191, name: 'Sewing', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2192,
        name: 'CascadingStyleSheets{:CSS}',
        complete: 'EntertainmentandPerformingArts'
      },
      {
        id: 2193,
        name: 'ContentCreation',
        complete: 'EntertainmentandPerformingArts'
      },
      {id: 2194, name: 'Linux', complete: 'EntertainmentandPerformingArts'},
      {
        id: 2195,
        name: 'GoogleAnalytics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2196,
        name: 'Empower',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2197,
        name: 'Brochures',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2198,
        name: 'AmericanSignLanguage',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2199,
        name: 'SoftwareDevelopment',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2200,
        name: 'ContentMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2201,
        name: 'PreschoolEducation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2202,
        name: 'EventPlanning',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2203,
        name: 'Transcribing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2204,
        name: 'AdobeFrameMaker',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2205,
        name: 'EmailMarketing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2206,
        name: 'EducationalEquity',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2207,
        name: 'MarketingStrategies',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2208,
        name: 'ElectronicPublishing',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2209,
        name: 'Templates',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2210,
        name: 'ContentCreation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2211,
        name: 'RestaurantOperation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2212,
        name: 'AdobeInDesign',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2213,
        name: 'NetworkInterfaceControllers',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2214,
        name: 'RevisionControls',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2215,
        name: 'ContentManagementSystems',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2216,
        name: 'Hospitality',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2217,
        name: 'StrategicCommunication',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2218,
        name: 'TechnicalWriting',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2219,
        name: 'SocialMedia',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2220,
        name: 'Teaching',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2221,
        name: 'PublicRelations',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2222,
        name: 'LanguageInterpretation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2223,
        name: 'TechnicalDocumentation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2224,
        name: 'SearchEngineOptimization',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2225,
        name: 'SalesManagement',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2226,
        name: 'MarketingCommunications',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2227,
        name: 'LanguageTranslation',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2228,
        name: 'TechnicalCommunication',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2229,
        name: 'Analytics',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2230,
        name: 'LanguageArts',
        complete: 'Journalism,WritingandCommunicatin'
      },
      {
        id: 2231,
        name: 'PressReleases',
        complete: 'Journalism,WritingandCommunicatin'
      },

      {id: 1055, name: 'SheetMetal', complete: 'Drafting'},
      {id: 1056, name: 'AdobeCreativeSuite', complete: 'Drafting'},
      {id: 1057, name: 'Mapping', complete: 'Drafting'},
      {id: 1058, name: 'Electronics', complete: 'Drafting'},
      {id: 1059, name: 'ArchitecturalDrawing', complete: 'Drafting'},
      {id: 1060, name: 'NewProductDevelopment', complete: 'Drafting'},
      {id: 1061, name: 'Rhino', complete: 'Drafting'},
      {id: 1062, name: 'CivilDesigner', complete: 'Drafting'},
      {id: 1063, name: 'WiringDiagram', complete: 'Drafting'},
      {id: 1064, name: 'FloorPlanning', complete: 'Drafting'},
      {id: 1065, name: 'Welding', complete: 'Drafting'},
      {id: 1066, name: 'LEEDAccreditedProfessiona}', complete: 'Drafting'},
      {id: 1067, name: 'ArcGIS', complete: 'Drafting'},
      {id: 1068, name: 'Interconnection', complete: 'Drafting'},
      {id: 1069, name: 'Submittals', complete: 'Drafting'},
      {id: 1070, name: 'EngineeringChangeOrder', complete: 'Drafting'},
      {id: 1071, name: 'V-Ray', complete: 'Drafting'},
      {id: 1072, name: 'Stormwater', complete: 'Drafting'},
      {id: 1073, name: 'ElectricalEngineering', complete: 'Drafting'},
      {id: 1074, name: 'Furnishing', complete: 'Drafting'},
      {
        id: 1075,
        name: 'ProductLifecycleManagement',
        complete: 'ArchitecturalDesign'
      },
      {id: 1076, name: 'CADStandards', complete: 'Drafting'},
      {id: 1077, name: 'CADStandards', complete: 'Drafting'},
      {id: 1078, name: 'CADStandards', complete: 'Drafting'},
      {id: 1079, name: 'CADStandards', complete: 'Drafting'},
      {id: 1080, name: 'Sol:CAD}', complete: 'Drafting'},
      {id: 1081, name: 'SketchUp:3DModelingSoftware}', complete: 'Drafting'},
      {id: 1082, name: 'CivilEngineering', complete: 'Drafting'},
      {id: 1083, name: 'FireAlarmSystems', complete: 'Drafting'},
      {id: 1084, name: 'Construction', complete: 'Drafting'},
      {id: 1085, name: 'Machining', complete: 'Drafting'},
      {id: 1086, name: 'AdobePhotoshop', complete: 'Drafting'},
      {id: 1087, name: 'Grading{:Landscape}', complete: 'Drafting'},
      {id: 1088, name: 'LowVoltage', complete: 'Drafting'},
      {id: 1089, name: 'Plumbing', complete: 'Drafting'},
      {id: 1090, name: 'Fabrication', complete: 'Drafting'},
      {id: 1091, name: 'AutodeskRevit', complete: 'Drafting'},
      {id: 1092, name: 'GeographicInformationSystems', complete: 'Drafting'},
      {id: 1093, name: 'AccessControls', complete: 'Drafting'},
      {id: 1094, name: 'BuildingCodes', complete: 'Drafting'},
      {
        id: 1095,
        name: 'GeometricDimensioningAndTolerancing',
        complete: 'Drafting'
      },
      {id: 1096, name: 'AdobeIllustrator', complete: 'Drafting'},
      {id: 1097, name: 'LandDevelopment', complete: 'Drafting'},
      {id: 1098, name: 'ElectronicSystems', complete: 'Drafting'},
      {id: 1099, name: 'Elevation', complete: 'Drafting'},
      {id: 1100, name: 'ProductDataManagement', complete: 'Drafting'},
      {id: 1101, name: 'Rendering', complete: 'Drafting'},
      {id: 1102, name: 'Surveying', complete: 'Drafting'},
      {id: 1103, name: 'Datasheets', complete: 'Drafting'},
      {id: 1104, name: 'ConstructionManagement', complete: 'Drafting'},
      {id: 1105, name: 'ManufacturingProcesses', complete: 'Drafting'},
      {
        id: 1106,
        name: 'Autodesk3DSMax{DGraphicsSoftware}',
        complete: 'Drafting'
      },
      {id: 1107, name: 'Topography', complete: 'Drafting'},
      {id: 1108, name: 'PrintedCircuitBoard', complete: 'Drafting'},
      {id: 1109, name: 'ShopDrawing', complete: 'Drafting'},
      {
        id: 1110,
        name: 'CreoElements/Pro{mputer-AedDesignSoftware}',
        complete: 'Drafting'
      },
      {id: 1111, name: 'AdobeInDesign', complete: 'Drafting'},
      {id: 1112, name: 'MicroStation', complete: 'Drafting'},
      {id: 1113, name: 'ElectricalWirings', complete: 'Drafting'},
      {id: 1114, name: 'Renovation', complete: 'Drafting'},
      {id: 1115, name: 'SheetMetal', complete: 'Drafting'},
      {id: 1116, name: 'AdobeCreativeSuite', complete: 'Drafting'},
      {id: 1117, name: 'Mapping', complete: 'Drafting'},
      {id: 1118, name: 'Electronics', complete: 'Drafting'},
      {id: 1119, name: 'ArchitecturalDrawing', complete: 'Drafting'},
      {id: 1120, name: 'NewProductDevelopment', complete: 'Drafting'},
      {id: 1121, name: 'Rhino{ModelingSoftware}', complete: 'Drafting'},
      {id: 1122, name: 'CivilDesigner', complete: 'Drafting'},
      {id: 1123, name: 'WiringDiagram', complete: 'Drafting'},
      {id: 1124, name: 'FloorPlanning', complete: 'Drafting'},
      {id: 1125, name: 'Welding', complete: 'Drafting'},
      {
        id: 1126,
        name: 'LEEDAccreditedProfessional{:AP}',
        complete: 'Drafting'
      },
      {id: 1127, name: 'ArcGIS{GISSoftware}', complete: 'Drafting'},
      {id: 1128, name: 'Interconnection', complete: 'Drafting'},
      {id: 1129, name: 'Submittals{:Construction}', complete: 'Drafting'},
      {id: 1130, name: 'EngineeringChangeOrder', complete: 'Drafting'},
      {id: 1131, name: 'V-Ray', complete: 'Drafting'},
      {id: 1132, name: 'Stormwater', complete: 'Drafting'},
      {id: 1133, name: 'ElectricalEngineering', complete: 'Drafting'},
      {id: 1134, name: 'Furnishing', complete: 'Drafting'},
      {id: 1135, name: 'ProductLifecycleManagement'},
      {id: 1136, name: 'CADStandards', complete: 'Drafting'},
      {id: 1137, name: 'Centering', complete: 'Drafting'},
      {id: 1138, name: 'Millwork', complete: 'Drafting'},
      {id: 1139, name: 'ProductDesign', complete: 'Drafting'},
      {id: 1140, name: 'GraphicCommunication', complete: 'Drafting'},
      {id: 1141, name: 'OutsePlant', complete: 'Drafting'},
      {id: 1142, name: 'VoltageDrop', complete: 'Drafting'},
      {id: 1143, name: 'Subcontracting', complete: 'Drafting'},
      {id: 1144, name: 'Geometry', complete: 'Drafting'},
      {id: 1145, name: 'SustainableDesign', complete: 'Drafting'},
      {id: 1146, complete: 'Drafting'},
      {id: 1147, name: 'ElectronicDesignAutomation', complete: 'Drafting'},
      {id: 1148, name: 'Tooling', complete: 'Drafting'},
      {id: 1149, name: 'ConceptualDesign', complete: 'Drafting'},
      {id: 1150, name: 'Erosions', complete: 'Drafting'},
      {id: 1151, name: 'PrintedCircuitBoardsDesign', complete: 'Drafting'},
      {id: 1152, name: 'HVAC', complete: 'Drafting'},
      {id: 1153, name: 'SolModeling', complete: 'Drafting'},
      {id: 1154, name: 'Hospitality', complete: 'Drafting'},
      {id: 1155, name: 'Wastewater', complete: 'Drafting'},
      {id: 1156, name: 'ElectricalSystems', complete: 'Drafting'},
      {id: 1157, name: 'ResentialConstruction', complete: 'Drafting'},
      {id: 1158, name: 'Trigonometry', complete: 'Drafting'},
      {id: 1159, name: 'Sketching', complete: 'Drafting'},
      {id: 1160, name: 'StormwaterManagement', complete: 'Drafting'},
      {id: 1161, name: 'ManufacturingOperations', complete: 'Drafting'},
      {id: 1162, name: 'Constructability', complete: 'Drafting'},
      {id: 1163, name: 'MechanicalEngineering', complete: 'Drafting'},
      {id: 1164, name: '3DRendering', complete: 'Drafting'},
      {id: 1165, name: 'Telecommunications', complete: 'Drafting'},
      {id: 1166, name: 'HighVoltage', complete: 'Drafting'},
      {id: 1167, name: 'BuildingDesign', complete: 'Drafting'},
      {id: 1168, name: 'ToleranceAnalysis', complete: 'Drafting'},
      {id: 1169, name: 'GraphicDesign', complete: 'Drafting'},
      {id: 1170, name: 'ProjectWise', complete: 'Drafting'},
      {id: 1171, name: 'Electricity', complete: 'Drafting'},
      {id: 1172, name: 'Cabinetry', complete: 'Drafting'},
      {id: 1173, name: 'Tolerance', complete: 'Drafting'},
      {id: 1174, name: 'Visualization', complete: 'Drafting'},
      {id: 1175, name: 'CivilSiteDesign', complete: 'Drafting'},
      {id: 1176, name: 'CivilSiteDesign', complete: 'Drafting'},
      {id: 1177, name: 'ProjectManagement', complete: 'Drafting'},
      {id: 1178, name: 'Mathematics', complete: 'Drafting'},
      {id: 1179, name: 'Animations', complete: 'Drafting'},
      {id: 1180, name: 'PublicWorks', complete: 'Drafting'},
      {id: 1181, name: 'ElectricalEquipment', complete: 'Drafting'},
      {id: 1182, name: 'Bding', complete: 'Drafting'},
      {id: 1183, name: 'PTCWindchill', complete: 'Drafting'},
      {id: 1184, name: 'Drawing', complete: 'Drafting'},
      {id: 1185, name: 'Land-UsePlanning', complete: 'Drafting'},
      {id: 1186, name: 'SecuritySystems', complete: 'Drafting'},
      {id: 1187, name: 'Roofing', complete: 'Drafting'},
      {id: 1189, name: 'Rhinoceros3D', complete: 'Drafting'},
      {id: 1190, name: 'Easement', complete: 'Drafting'},
      {id: 1191, name: 'NetworkSwitches', complete: 'Drafting'},
      {id: 1192, name: 'SocialWork', complete: 'Drafting'},
      {id: 1193, name: 'PackagingAndLabeling', complete: 'Drafting'},
      {
        id: 1194,
        name: 'ArchitectRegistrationExamination',
        complete: 'Drafting'
      },
      {id: 1195, name: 'GlobalPositioningSystems', complete: 'Drafting'},
      {id: 1196, name: 'SchematicCapture', complete: 'Drafting'},
      {id: 1197, name: 'BuildingInformationModeling', complete: 'Drafting'},
      {id: 1198, name: 'Algebra', complete: 'Drafting'},
      {id: 1199, name: 'Maya', complete: 'Drafting'},
      {id: 1200, name: 'SedimentControls', complete: 'Drafting'},
      {id: 1201, name: 'ElectricalSubstation', complete: 'Drafting'},
      {id: 1202, name: 'ValueEngineering', complete: 'Drafting'},
      {id: 1203, name: 'Electromechanics', complete: 'Drafting'},
      {id: 1204, name: '3DComputerGraphicsSoftware', complete: 'Drafting'},
      {id: 1205, name: 'GoodDrivingRecord', complete: 'Drafting'},
      {id: 1206, name: 'ElectricalCAD', complete: 'Drafting'},
      {id: 1207, name: 'Woodworking', complete: 'Drafting'},
      {id: 1208, name: 'SiemensNX', complete: 'Drafting'},
      {id: 1209, name: 'ModelBuilding', complete: 'Drafting'},
      {id: 1210, name: 'ErosionControl', complete: 'Drafting'},
      {id: 1211, name: 'Instrumentation', complete: 'Drafting'},
      {id: 1212, name: 'Landscaping', complete: 'Drafting'},
      {id: 1213, name: 'Prototyping', complete: 'Drafting'},
      {id: 1214, name: 'Sustainability', complete: 'Drafting'},
      {id: 1215, name: 'SanitarySewer', complete: 'Drafting'},
      {id: 1216, name: 'Voltage', complete: 'Drafting'},
      {id: 1217, name: 'AutoCADArchitecture', complete: 'Drafting'},
      {id: 1218, name: 'Dimensioning', complete: 'Drafting'},
      {
        id: 1219,
        name: 'ArchitectureDesignAndAssessmentSystems',
        complete: 'Drafting'
      },
      {id: 1220, name: 'GoogleEarth', complete: 'Drafting'},
      {id: 1221, name: 'ElectronicCircuits', complete: 'Drafting'},
      {id: 1222, name: 'SitePlanning', complete: 'Drafting'},
      {id: 1223, name: 'MechanicalAptitude', complete: 'Drafting'},
      {id: 1224, name: 'InteriorArchitecture', complete: 'Drafting'},
      {id: 1225, name: 'LandscapeArchitecture', complete: 'Drafting'},
      {id: 1226, name: 'Paneling', complete: 'Drafting'},
      {id: 1227, name: 'Estimators', complete: 'Drafting'},
      {id: 1228, name: 'EngineeringDrawings', complete: 'Drafting'},
      {id: 1229, name: 'CodeReview', complete: 'Drafting'},
      {id: 1230, name: 'CivilDrafting', complete: 'Drafting'},
      {id: 1231, name: 'ElectricPowerSystems', complete: 'Drafting'},
      {id: 1232, name: 'CommercialConstruction', complete: 'Drafting'},
      {id: 1233, name: 'Purchasing', complete: 'Drafting'},
      {id: 1234, name: 'DocumentProduction', complete: 'Drafting'},
      {id: 1235, name: 'TrafficControl', complete: 'Drafting'},
      {id: 1236, name: 'Switchgear', complete: 'Drafting'},
      {id: 1237, name: 'RealEstate', complete: 'Drafting'},
      {id: 1238, name: 'MaterialRequirementsPlanning', complete: 'Drafting'},
      {id: 1239, name: 'DesignStudio', complete: 'Drafting'},
      {id: 1240, name: 'WaterResources', complete: 'Drafting'},
      {id: 1241, name: 'MotorControl', complete: 'Drafting'},
      {id: 1242, name: 'LandZoning', complete: 'Drafting'},
      {id: 1243, name: 'DesignForManufacturability', complete: 'Drafting'},
      {id: 1244, name: 'Dynamo', complete: 'Drafting'},
      {id: 1245, name: 'QualityControl', complete: 'Drafting'},
      {id: 1246, name: 'BusinessSystems', complete: 'Drafting'},
      {id: 1247, name: 'ProjectCoordination', complete: 'Drafting'},
      {id: 1248, name: 'ConfigurationManagement', complete: 'Drafting'},
      {id: 1249, name: 'DigitalModelingAndFabrication', complete: 'Drafting'},
      {id: 1250, name: 'AutoTURN', complete: 'Drafting'},
      {id: 1251, name: 'ControlEngineering', complete: 'Drafting'},
      {
        id: 1252,
        name: 'Computer-AedFacilityManagement',
        complete: 'Drafting'
      },
      {id: 1253, name: 'ProductEngineering', complete: 'Drafting'},
      {id: 1254, name: 'RetailDesign', complete: 'Drafting'},
      {id: 1255, name: 'StormDrains', complete: 'Drafting'},
      {id: 1256, name: 'SystemPreferences', complete: 'Drafting'},
      {id: 1257, name: 'FacilityManagement', complete: 'Drafting'},
      {
        id: 1258,
        name: 'TechnicalDataManagementSystems',
        complete: 'Drafting'
      },
      {id: 1259, name: 'IntegratedDesign', complete: 'Drafting'},
      {id: 1260, name: 'GeotechnicalEngineering', complete: 'Drafting'},
      {id: 1261, name: 'ProgrammableLogicControllers', complete: 'Drafting'},
      {id: 1262, name: 'AmericanInstituteOfArchitects', complete: 'Drafting'},
      {id: 1263, name: 'InjectionMolding', complete: 'Drafting'},
      {id: 1264, name: 'Illustration', complete: 'Drafting'},
      {id: 1265, name: 'Geology', complete: 'Drafting'},
      {id: 1266, name: 'PWB', complete: 'Drafting'},
      {id: 1267, name: 'Budgeting', complete: 'Drafting'},
      {id: 1268, name: 'RevisionControls', complete: 'Drafting'},
      {id: 1269, name: 'DesignThinking', complete: 'Drafting'},
      {id: 1270, name: 'OpticalFiber', complete: 'Drafting'},
      {id: 1271, name: 'Automation', complete: 'Drafting'},
      {id: 1272, name: 'RestaurantOperation', complete: 'Drafting'},
      {id: 1273, name: 'ElementaryAlgebra', complete: 'Drafting'},
      {id: 1274, name: 'MockUps', complete: 'Drafting'},
      {id: 1275, name: 'FieldSurveys', complete: 'Drafting'},
      {id: 1276, name: 'ControlSystems', complete: 'Drafting'},
      {id: 1277, name: 'Carpentry', complete: 'Drafting'},
      {id: 1278, name: 'ManufacturingEngineering', complete: 'Drafting'},
      {id: 1279, name: 'TakingMeetingMinutes', complete: 'Drafting'},
      {id: 1280, name: 'Surveys', complete: 'Drafting'},
      {
        id: 1281,
        name: 'SupervisoryControlAndDataAcquisition',
        complete: 'Drafting'
      },
      {id: 1282, name: 'Ordinances', complete: 'Drafting'},
      {id: 1283, name: 'SolEdge', complete: 'Drafting'},
      {id: 1284, name: 'ManagingClientExpectations', complete: 'Drafting'},
      {id: 1285, name: 'ArcMap', complete: 'Drafting'},
      {
        id: 1286,
        name: 'ProgrammableLogicControllerControlPanel',
        complete: 'Drafting'
      },
      {id: 1287, name: 'FacilitiesEngineering', complete: 'Drafting'},
      {id: 1288, name: 'Computer-AedTechnologies', complete: 'Drafting'},
      {id: 1289, name: 'Form-Z', complete: 'Drafting'},
      {id: 1290, name: 'WaterDistribution', complete: 'Drafting'},
      {id: 1291, name: 'SystemsEngineering', complete: 'Drafting'},
      {id: 1292, name: 'Writing', complete: 'Drafting'},
      {id: 1293, name: 'Writing', complete: 'Drafting'},
      {id: 1294, name: 'BuiltEnvironment', complete: 'Drafting'},
      {id: 1295, name: 'AppliedScience', complete: 'Drafting'},
      {id: 1296, name: 'ControlPanels', complete: 'Drafting'},
      {id: 1297, name: 'PlumbingCodes', complete: 'Drafting'},
      {id: 1298, name: 'Computer-AedManufacturing', complete: 'Drafting'},
      {id: 1299, name: 'UrbanDesign', complete: 'Drafting'},
      {id: 1300, name: 'SewageTreatments', complete: 'Drafting'},
      {id: 1301, name: 'NetworkRouting', complete: 'Drafting'},
      {id: 1302, name: 'NetworkRouting', complete: 'Drafting'},
      {id: 1303, name: 'CollaborativeSkills', complete: 'Drafting'},
      {id: 1304, name: 'CollaborativeSkills', complete: 'Drafting'},
      {id: 1305, name: 'CollaborativeSkills', complete: 'Drafting'},
      {id: 1306, name: 'TroubleshootingroblemSolving}', complete: 'Drafting'},
      {id: 1307, name: 'ArchitecturalEngineering', complete: 'Drafting'},
      {id: 1308, name: 'DataCollection', complete: 'Drafting'},
      {id: 1309, name: 'Simplex', complete: 'Drafting'},
      {id: 1310, name: 'EngineeringManagement', complete: 'Drafting'},
      {id: 1311, name: 'GreenBuilding', complete: 'Drafting'},
      {id: 1312, name: 'Digitization', complete: 'Drafting'},
      {id: 1313, name: 'Mechanics', complete: 'Drafting'},
      {id: 1314, name: 'StatisticalInference', complete: 'Drafting'},
      {id: 1315, name: 'ideaGeneration', complete: 'Drafting'},
      {id: 1316, name: 'NetworkPlanningAndDesign', complete: 'Drafting'},
      {id: 1317, name: 'AlarmDevices', complete: 'Drafting'},
      {id: 1318, name: 'Probability', complete: 'Drafting'},
      {id: 1319, name: 'Creativity', complete: 'Drafting'},
      {id: 1320, name: 'ElectricPowerDistribution', complete: 'Drafting'},
      {id: 1321, name: 'SystemsManagement', complete: 'Drafting'},
      {id: 1322, name: 'Forming', complete: 'Drafting'},
      {id: 1323, name: 'ComputerGraphics', complete: 'Drafting'},
      {id: 1324, name: 'AerialPhotography', complete: 'Drafting'},
      {id: 1325, name: 'Avionics', complete: 'Drafting'},
      {id: 1326, name: 'Surfacing', complete: 'Drafting'},
      {id: 1327, name: 'ClassDesign', complete: 'Drafting'},
      {id: 1328, name: 'DigitalData', complete: 'Drafting'},
      {id: 1329, name: 'ANSYSMeshing', complete: 'Drafting'},
      {
        id: 1330,
        name: 'CharteredStrategicWealthProfessional',
        complete: 'Drafting'
      },
      {id: 1331, name: 'ArchiCAD', complete: 'DCompositionsrafting'},
      {id: 1332, name: 'Earthworks', complete: 'Drafting'},
      {id: 1333, name: 'Earthworks', complete: 'Drafting'},
      {id: 1334, name: 'MachineryDesign', complete: 'Drafting'},
      {id: 1335, name: 'Compositions', complete: 'Drafting'},
      {id: 1336, name: 'BusinessAdministration', complete: 'Drafting'},
      {id: 1337, name: 'AltiumDesigner', complete: 'Drafting'},
      {id: 1338, name: 'AgileSoftwareDevelopment', complete: 'Drafting'},
      {id: 1339, name: 'PowerBI', complete: 'Drafting'},
      {id: 1340, name: 'Hydrology', complete: 'Drafting'},
      {id: 1341, name: 'ElectricalDrawing', complete: 'Drafting'},
      {id: 1342, name: 'Reliability', complete: 'Drafting'},
      {id: 1343, name: 'StaticProgramAnalysis', complete: 'Drafting'},
      {id: 1344, name: 'TeleCommunicationsEngineering', complete: 'Drafting'},
      {id: 1345, name: 'Relays', complete: 'Drafting'},
      {id: 1346, name: 'DataManagement', complete: 'Drafting'},
      {id: 1347, name: 'BusinessDevelopment', complete: 'Drafting'},
      {id: 1348, name: 'FieldResearch', complete: 'Drafting'},
      {id: 1349, name: 'ElectricalNetworks', complete: 'Drafting'},
      {id: 1350, name: 'CorrectiveAndPreventiveAction', complete: 'Drafting'},
      {id: 1351, name: 'PublicUtility', complete: 'Drafting'},
      {id: 1352, name: 'Cataloguing', complete: 'Drafting'},
      {
        id: 1353,
        name: 'MicrosoftCertifiedProfessional',
        complete: 'Drafting'
      },
      {id: 1354, name: 'Garmin', complete: 'Drafting'},
      {id: 1355, name: 'Stamping', complete: 'Drafting'},
      {id: 1356, name: 'Transmission', complete: 'Drafting'},
      {
        id: 1357,
        name: 'Simulations',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1358,
        name: 'ProgrammableLogicControllers',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1359,
        name: 'LTE',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1360,
        name: 'Construction',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1361,
        name: 'RadioFrequency',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1362,
        name: 'NewProductDevelopment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1363,
        name: 'Physics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1364,
        name: 'Field-ProgrammableGateArray',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1365,
        name: 'Human-ComputerInteraction',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1366,
        name: 'Linux',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1367,
        name: 'AutodeskRevit',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1368,
        name: 'Antenna',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1369,
        name: 'Electronics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1370,
        name: 'Mathematics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1371,
        name: 'C++',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1372,
        name: 'C++',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1373,
        name: 'ControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1374,
        name: 'OperatingSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1375,
        name: 'ElectricPowerSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1376,
        name: 'Microwaves',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1377,
        name: 'PrintedCircuitBoard',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1378,
        name: 'Chemistry',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {id: 1379, name: 'C', complete: 'ElectronicsandElectricalEngineering'},
      {
        id: 1380,
        name: 'Instrumentation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1381,
        name: 'WirelessCommunications',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1382,
        name: 'ShortCircuits',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1383,
        name: 'RFAndMicrowaveFilter',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1384,
        name: 'TestPlanning',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1385,
        name: 'Optics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1386,
        name: 'Python',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1387,
        name: 'TroubleshootingroblemSolvin',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1388,
        name: 'WeAreaNetworks',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1389,
        name: 'ProfessionalEngineer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1390,
        name: 'RadioFrequencyEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1391,
        name: 'ProductDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1392,
        name: 'EngineeringPhysics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1393,
        name: 'MATLAB',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1394,
        name: 'Commissioning',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1395,
        name: 'WirelessNetworks',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1396,
        name: 'LicensedProfessionalEngineer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1397,
        name: 'Radar',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1398,
        name: 'Reliability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1399,
        name: 'Thermodynamics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1400,
        name: 'Verilog',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1401,
        name: 'Variable-FrequencyDrive',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1402,
        name: 'SQL',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1403,
        name: 'Switchgear',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1404,
        name: 'AdvanceDesignSystem',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1405,
        name: 'TestEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1406,
        name: 'Algebra',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1407,
        name: 'Debugging',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1408,
        name: 'Automation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1409,
        name: 'Unix',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1410,
        name: 'FireAlarmSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1411,
        name: 'ElectronicWarfare',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1412,
        name: 'Prototyping',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1413,
        name: 'Fluechanics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1414,
        name: 'VHSICHardwareDescriptionLanguage',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1415,
        name: 'SupervisoryControlAndDataAcquisitioSCADA}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1416,
        name: 'TCP/IP',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1417,
        name: 'Transformers{Electrical}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1418,
        name: 'Telecommunications',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1419,
        name: 'TestEquipment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1420,
        name: 'IntegralCalculus',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1421,
        name: 'Algorithms',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1422,
        name: 'HumanMachineInterfaces',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1423,
        name: 'NetworkSwitches',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1424,
        name: 'ElectricPowerDistribution',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1425,
        name: 'Modulation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1426,
        name: 'SystemsEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1427,
        name: 'Geometry',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1428,
        name: 'Firmware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1429,
        name: 'DistributedControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1430,
        name: 'GlobalSystemForMobileCommunications',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1431,
        name: 'ElectricalSubstation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1432,
        name: 'ScatteringParameters',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1433,
        name: 'SystemsIntegration',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1434,
        name: 'Geology',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1435,
        name: 'LogicSynthesis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1436,
        name: 'PreventiveMaintenance',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1437,
        name: 'ComputerSciences',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1438,
        name: 'ElectricalSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1439,
        name: 'NoiseFigure',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1440,
        name: 'VerificationAndValation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1441,
        name: 'SoilMechanics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1442,
        name: 'Perl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1443,
        name: 'ElectricalWirings',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1444,
        name: 'LocalAreaNetworks',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1445,
        name: 'NationalElectricalCodes',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1446,
        name: 'SignalGenerators',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1447,
        name: 'ElectronicCircuits',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1448,
        name: 'Photonics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1449,
        name: 'DigitalSignalProcessing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1450,
        name: 'LadderLogic',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1451,
        name: 'CodeDivisionMultipleAccess',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1452,
        name: 'Voltage',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1453,
        name: 'CommunicationsSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1454,
        name: 'ProgramManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1455,
        name: 'Hydrology',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1456,
        name: 'ApplicationSpecificIntegratedCircuits',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1457,
        name: 'Wonderware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1458,
        name: 'CiscoCertifiedNetworkAssociate',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1459,
        name: 'NationalElectricalSafetyCode',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1460,
        name: 'Wave{Band}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1461,
        name: 'ProductRequirements',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1462,
        name: 'Trigonometry',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1463,
        name: 'SchematicCapture',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1464,
        name: 'ControlEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1465,
        name: 'NetworkingHardware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1466,
        name: 'ETAP',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1467,
        name: 'SpectrumAnalyzer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1468,
        name: 'Avionics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1469,
        name: 'MathematicalSciences',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1470,
        name: 'Scripting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1471,
        name: 'Calibration',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1472,
        name: 'UniversalMobileTelecommunicationsSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1473,
        name: 'ConstructionManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1474,
        name: 'ImpedanceMatching',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1475,
        name: 'FailureModeAndEffectsAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1476,
        name: 'Laser',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1477,
        name: 'EmbeddedSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1478,
        name: 'Relays',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1479,
        name: 'Firewall',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1480,
        name: 'LowVoltage',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1481,
        name: 'SatelliteCommunications',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1482,
        name: 'ManufacturingProcesses',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1483,
        name: 'Statistics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1484,
        name: 'SystemVerilog{DirectProgrammingInterface}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1485,
        name: 'Hydraulics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1486,
        name: 'ActiveDirectory',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1487,
        name: 'ElectricalEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1488,
        name: 'Electromagnetism',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1489,
        name: 'Subsystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1490,
        name: 'OutsePlant',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1491,
        name: 'ComputerEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1492,
        name: 'ProcessControl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1493,
        name: 'NetworkPlanningAndDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1494,
        name: 'MicroStation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1495,
        name: 'Low-NoiseAmplifier',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1496,
        name: 'SystemRequirements',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1497,
        name: 'PerformanceAppraisal',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1498,
        name: 'Simulink',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1499,
        name: 'CircuitBreakers',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1500,
        name: 'NetworkEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1501,
        name: 'LoadFlowAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1502,
        name: 'DataTransmissions',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1503,
        name: 'DesignFailureModeAndEffectsAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1504,
        name: 'OpticalEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1505,
        name: 'CircuitDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1506,
        name: 'MotorControl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1507,
        name: '3GPP',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1508,
        name: 'AutoCAD',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1509,
        name: 'MonolithicMicrowaveIntegratedCircuit',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1510,
        name: 'TestAutomation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1511,
        name: 'HumanServices',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1512,
        name: 'PCIExpress',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1513,
        name: 'MotionControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1514,
        name: 'Virtualization',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1515,
        name: 'ElectricalEquipment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1516,
        name: 'Atoll',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1517,
        name: 'ElectronicComponents',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1518,
        name: 'ElementaryAlgebra',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1519,
        name: 'SignalProcessing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1520,
        name: 'Modbus',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1521,
        name: 'WindowsServers',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1522,
        name: 'ElectricUtility',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1523,
        name: 'Advertisement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1524,
        name: 'FailureAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1525,
        name: 'Zemax',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1526,
        name: 'SignalIntegrity',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1527,
        name: 'FieldServiceManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1528,
        name: 'JavaScript',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1529,
        name: 'Submittals',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1530,
        name: 'MicrowaveEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1531,
        name: 'IntegrationTesting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1532,
        name: 'Calculus',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1533,
        name: 'USB',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1534,
        name: 'PersonalProtectiveEquipment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1535,
        name: 'NetworkSecurity',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1536,
        name: 'Computer-AedDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1537,
        name: 'TopSecret-SensitiveCompartmentedInformation{TS/SCIClearance}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1538,
        name: 'DesignForManufacturability{:IndustrialDesign}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1539,
        name: 'OutsePlantEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1540,
        name: 'Tcl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1541,
        name: 'Turbines',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1542,
        name: 'AmazonWebServices',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1543,
        name: 'AmazonWebServices',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1544,
        name: 'LinkBudget',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1545,
        name: 'Soldering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1546,
        name: 'InsePlant',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1547,
        name: 'DesignVerification',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1548,
        name: 'Paneling',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1549,
        name: 'MicrosoftAzure',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1550,
        name: 'ArchitecturalDrawing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1551,
        name: 'GenerallyAcceptedAuditingStandards',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1552,
        name: 'ElectronicEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1553,
        name: 'Immunization',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1554,
        name: 'DigitalElectronics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1555,
        name: 'Pneumatics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1556,
        name: 'DynamicHostConfigurationProtocol{:DHCP}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1557,
        name: 'ArcFlash',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1558,
        name: 'ElectronicCountermeasures',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1559,
        name: 'RequirementsAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1560,
        name: 'OpticalFiber',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1561,
        name: 'StaticTimingAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1562,
        name: 'Valvesiping}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1563,
        name: 'NetworkTroubleshooting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1564,
        name: 'DistributedComputing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1565,
        name: 'Genesys',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1566,
        name: 'TestData',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1567,
        name: 'ComplianceTraining',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1568,
        name: 'HardwareDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1569,
        name: 'OilAndGas',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1570,
        name: 'MultiprotocolLabelSwitching',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1571,
        name: 'HighVoltage',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1572,
        name: 'PhaseNoise',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1573,
        name: 'FailureCauses',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1574,
        name: 'OpticalTime-DomainReflectometer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1575,
        name: 'SerialPeripheralInterface',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1576,
        name: 'Robotics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1577,
        name: 'AgileSoftwareDevelopment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1578,
        name: 'ShopDrawing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1579,
        name: 'StandingWaveRatios',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1580,
        name: 'SystemsArchitecture',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1581,
        name: 'Splicing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1582,
        name: 'AnalogueElectronics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1583,
        name: 'HVAC',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1584,
        name: 'W-CDMA{UMTS}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1585,
        name: 'VoltageDrop',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1586,
        name: 'Multiple-InputAndMultiple-Output{:MIMO}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1587,
        name: 'ContinuousImprovementProcess',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1588,
        name: 'HeatTransfer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1589,
        name: 'LabVIEW',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1590,
        name: 'ControlPanels',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1591,
        name: 'NetworkPerformance',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1592,
        name: 'Power-SystemProtection',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1593,
        name: 'EMSimulationSoftware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1594,
        name: 'ProductLifecycle',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1595,
        name: 'Probability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1596,
        name: 'SPICE{SimulationTool}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1597,
        name: 'ProgrammableLogicControllerControlPanel',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1598,
        name: 'ComputerNetworks',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1599,
        name: 'ArchitecturalEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1600,
        name: 'Broadband',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1601,
        name: 'InternetProtocolTelephony',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1602,
        name: 'Value-AddedServices',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1603,
        name: 'IntegratedCircuitDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1604,
        name: 'Blueprinting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1605,
        name: 'WindowsPowerShell',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1606,
        name: 'ProjectManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1607,
        name: 'FEKO',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1608,
        name: 'IntegratedProductTeam',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1609,
        name: 'Spectroscopy',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1610,
        name: 'OrCAD',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1611,
        name: 'MaterialHandling',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1612,
        name: 'BorderGatewayProtocol',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1613,
        name: 'LEEDAccreditedProfessional',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1614,
        name: 'LEEDAccreditedProfessional',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1615,
        name: 'GlobalPositioningSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1616,
        name: 'RequirementsManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1617,
        name: 'BusinessCorrespondence',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1618,
        name: 'LogicAnalyzer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1619,
        name: 'Machining',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1620,
        name: 'Evolution-DataOptimized',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1621,
        name: 'GeographicInformationSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1622,
        name: 'CommunicationTheories',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1623,
        name: 'ConfigurationManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1624,
        name: 'HybrFiber-Coaxial',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1625,
        name: 'EmbeddedSoftware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1626,
        name: 'Electromechanics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1627,
        name: 'CyberSecurity',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1628,
        name: 'Interconnection',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1629,
        name: 'MathematicalOptimization',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1630,
        name: 'EngineeringChangeOrder',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1631,
        name: 'DataCollection',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1632,
        name: 'HardwareArchitecture',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1633,
        name: 'DeviceNet',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1634,
        name: 'SessionInitiationProtocols',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1635,
        name: 'BuildingCodes',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1636,
        name: 'ElectromagneticWavegues',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1637,
        name: 'MechanicalEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1638,
        name: 'Fire-ControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1639,
        name: 'ComplementaryMetalOxeSemiconductor{CMOS}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1640,
        name: 'OccupationalHealthAndSafetyAdministration{OSHA}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1642,
        name: 'TransmissionSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1643,
        name: 'Microelectronics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1644,
        name: 'ElectronicSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1645,
        name: 'ComputerSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1646,
        name: 'ComputerControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1647,
        name: 'Servomechanism',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1648,
        name: 'CompTIASecurity+',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1649,
        name: 'Transmission',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1650,
        name: 'ElectronicOscillator',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1651,
        name: 'ElectronicHardware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1652,
        name: 'Thermal',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1653,
        name:
          'EnterpriseDesktopAdministrator{MicrosoftCertifiedITProfessional}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1654,
        name: 'CircuitSwitches',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1655,
        name: 'QualityControl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1656,
        name: 'SignalsIntelligence',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1657,
        name: 'ResearchAndDevelopment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1658,
        name: 'OutsePlantConstruction',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1659,
        name: 'Register-TransferLevel',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1660,
        name: 'MachineControls',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1661,
        name: 'CiscoCertifiedNetworkProfessional',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1662,
        name: 'Renovation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1663,
        name: 'DetectionTheory',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1664,
        name: 'DevelopmentTesting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1665,
        name: 'ScanningElectronMicroscope',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1666,
        name: 'SoftwareDevelopment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1667,
        name: 'SafetyStandards',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1668,
        name: 'RoutingProtocols',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1669,
        name: 'Budgeting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1670,
        name: 'BaseTransceiverStations',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1671,
        name: 'CorrectiveAndPreventiveAction{:CAPA}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1672,
        name: 'AcademicAchievement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1673,
        name: 'PrintedCircuitBoardsDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1674,
        name: 'PowerToolOperation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1675,
        name: 'InformationSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1676,
        name: 'PDMS',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1677,
        name: 'ProtocolIndependentMulticast',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1678,
        name: 'EarnedValueManagement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1679,
        name: 'CompoundSemiconductor',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1680,
        name: 'Pspice',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1681,
        name: 'DCMotors',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1682,
        name: 'MySQL',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1683,
        name: 'ElectricPowerTransmission',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1684,
        name: 'DataSystem',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1685,
        name: 'ProductEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1686,
        name: 'Aerodynamics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1687,
        name: 'High-DensityLipoprotein',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1688,
        name: 'Profibus',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1689,
        name: 'VirtualMachines',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1690,
        name: 'PowerEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1691,
        name: 'Baseband',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1692,
        name: 'ElectricalCircuitAnalysis',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1693,
        name: 'MaterialCharacterization',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1694,
        name: 'Java',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1695,
        name: 'Mechanics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1696,
        name: 'MicrosoftCertifiedSystemsEngineer',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1697,
        name: 'Constructability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1698,
        name: 'OrthogonalFrequency-DivisionMultiplexing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1699,
        name: 'SoftwareTesting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1700,
        name: 'StatisticalInference',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1701,
        name: 'Vivado',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1702,
        name: 'Stator',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1703,
        name: 'OpenShortestPathFirst',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1704,
        name: 'CivilEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1705,
        name: 'SpreadSpectrum',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1706,
        name: 'Spacecraft',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1707,
        name: 'Biology',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1708,
        name: 'ElectronicDesignAutomation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1709,
        name: 'IndustrialControlSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1710,
        name: 'VirtualLocalAreaNetwork',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1711,
        name: 'CostEstimation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1712,
        name: 'MarketingBrochures',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1713,
        name: 'Testability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1714,
        name: 'ChemicalEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1715,
        name: 'Modelsim',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1716,
        name: 'Electricity',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1717,
        name: 'WirelessLAN',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1718,
        name: 'Procurement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1719,
        name: 'QuadratureAmplitudeModulation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1720,
        name: 'TechnologyRoadmap',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1721,
        name: 'WaferFabrication',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {id: 1722, name: 'C#', complete: 'ElectronicsandElectricalEngineering'},
      {
        id: 1723,
        name: 'PredictiveMaintenance',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1724,
        name: 'Scrum',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1725,
        name: 'PowerSystemSimulatorForEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1726,
        name: 'Phase-ShiftKeying',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1727,
        name: 'AutomaticTestEquipment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1728,
        name: 'AirTrafficControl',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1729,
        name: 'ElectronicCircuitSimulation',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1730,
        name: 'ControlNet',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {id: 1731, complete: 'ElectronicsandElectricalEngineering'},
      {id: 1732, complete: 'ElectronicsandElectricalEngineering'},
      {
        id: 1733,
        name: 'Bash',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1734,
        name: 'BusinessDevelopment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1735,
        name: 'AdvancedDesignSystems',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1736,
        name: 'LeanManufacturing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1737,
        name: 'BroadbandDistributionSpecialist',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1738,
        name: 'UniversalAsynchronousReceiver/Transmitter',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1739,
        name: 'Three-Phase',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1740,
        name: 'Scalability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1741,
        name: 'ElectricalCodes',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1742,
        name: 'PostProcessing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1743,
        name: 'UnitTesting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1744,
        name: 'Writing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1745,
        name: 'PhysicalDesign',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1746,
        name: 'Boilers',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1747,
        name: 'WirelessEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1748,
        name: 'Easement',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1749,
        name: 'RadioEquipment',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1750,
        name: 'Traceability',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1751,
        name: 'ProcessIntegration',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1752,
        name: 'IntegratedCircuits',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1753,
        name: 'Fabrication',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1754,
        name: 'KeyPerformanceIndicators{:KPIs}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1755,
        name: 'Bding',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1756,
        name: 'RFSwitch',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1757,
        name: 'CostReduction',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1758,
        name: 'Photodetector',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1759,
        name: 'SoftwareEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1760,
        name: 'Drilling',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1761,
        name: 'InternetProtocolsSuite',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1762,
        name: 'ElectricalLoad',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1763,
        name: 'MarketingCommunications',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1764,
        name: 'ElectronicsManufacturing',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1765,
        name: 'EngineeringMathematics',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1766,
        name: 'Analog-To-DigitalConverters',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1767,
        name: 'ControlValves',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1768,
        name: 'AndroOperatingSystem}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1769,
        name: 'MotorSoftStarter',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1770,
        name: 'SimulationSoftware',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1771,
        name: 'ManufacturingEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1772,
        name: 'ManufacturingEngineering',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1773,
        name: 'Pumps',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1774,
        name: 'PhysicalLayers',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1775,
        name: 'Forecasting',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1776,
        name: 'Diversification{Investing}',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1777,
        name: 'Tooling',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1778,
        name: 'WorkingModel2D',
        complete: 'ElectronicsandElectricalEngineering'
      },
      {
        id: 1779,
        name: 'UserExperience',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1780,
        name: 'ContinuousImprovementProcess',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1781,
        name: 'SolWorks{:CAD}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1782,
        name: 'MATLAB',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1783,
        name: 'HVAC',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1784,
        name: 'ChemicalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1785,
        name: 'ProgrammableLogicControllers',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1786,
        name: 'UserInterface',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1787,
        name: 'Java',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1788,
        name: 'LeanManufacturing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1789,
        name: 'Computer-AedDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1790,
        name: 'Physics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1791,
        name: 'Construction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1792,
        name: 'TroubleshootingroblemSolvin',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1793,
        name: 'Human-ComputerInteraction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1794,
        name: 'Prototyping',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1795,
        name: 'Linux',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1796,
        name: 'CorrectiveAndPreventiveAction{:CAPA}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1797,
        name: 'MechanicalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1798,
        name: 'AerospaceEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1799,
        name: 'Plumbing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1800,
        name: 'Commissioning',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1801,
        name: 'Automation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1802,
        name: 'UsabilityTesting',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1803, name: 'C++', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1804,
        name: 'Auditing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1805,
        name: 'Machining',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1806,
        name: 'SystemsEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1807,
        name: 'AutodeskRevit',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1808,
        name: 'PreventiveMaintenance',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1809,
        name: 'ControlSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1810,
        name: 'Usability',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1811,
        name: 'Scripting',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1812,
        name: 'QualityManagementSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1813,
        name: '3DModeling',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1814,
        name: 'Simulations',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1815,
        name: 'ProfessionalEngineer',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1816,
        name: 'ProcessEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1817,
        name: 'ElectricalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1818,
        name: 'GraphicDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1819,
        name: 'TestAutomation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1820,
        name: 'SixSigmaMethodology',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1821,
        name: 'AutoCAD',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1822,
        name: 'Simulink',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1823,
        name: 'ConstructionManagement',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1824,
        name: 'Petrochemical',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1825,
        name: 'Instrumentation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1826,
        name: 'InteractionDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1827, name: 'SQL', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1828,
        name: 'StatisticalProcessControls',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1829,
        name: 'Fabrication',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1830,
        name: 'Spacecraft',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1831,
        name: 'MechanicalSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1832,
        name: 'UserExperience',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1833,
        name: 'ContinuousImprovementProcess',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1834,
        name: 'SolWorks',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1835,
        name: 'MATLAB',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1836,
        name: 'HVAC',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1837,
        name: 'ChemicalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1838,
        name: 'ProgrammableLogicControllers',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1839,
        name: 'UserInterface',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1840,
        name: 'Java',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1841,
        name: 'LeanManufacturing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1842,
        name: 'Computer-AedDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1843,
        name: 'Physics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1844,
        name: 'Construction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1845,
        name: 'TroubleshootingroblemSolvin',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1846,
        name: 'Computer-AedDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1847,
        name: 'Physics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1848,
        name: 'Construction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1849,
        name: 'TroubleshootingroblemSolvin',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1850,
        name: 'Human-ComputerInteraction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1851,
        name: 'Prototyping',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1852,
        name: 'Linux',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1853,
        name: 'CorrectiveAndPreventiveAction',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1854,
        name: 'MechanicalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1855,
        name: 'AerospaceEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1856,
        name: 'Plumbing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1857,
        name: 'Commissioning',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1858,
        name: 'Automation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1859,
        name: 'UsabilityTesting',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1860, name: 'C++', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1861,
        name: 'Auditing',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1862,
        name: 'Machining',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1863,
        name: 'SystemsEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1864,
        name: 'AutodeskRevit',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1865,
        name: 'PreventiveMaintenance',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1866,
        name: 'ControlSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1867,
        name: 'Usability',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1868,
        name: 'Scripting',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1869,
        name: 'QualityManagementSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1870,
        name: '3DModeling',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1871,
        name: 'Simulations',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1872,
        name: 'ProfessionalEngineer',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1873,
        name: 'ProcessEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1874,
        name: 'ElectricalEngineering',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1875,
        name: 'GraphicDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1876,
        name: 'TestAutomation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1877,
        name: 'SixSigmaMethodology',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1878,
        name: 'AutoCAD',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1879,
        name: 'Simulink',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1880,
        name: 'ConstructionManagement',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1881,
        name: 'Petrochemical',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1882,
        name: 'Instrumentation',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1883,
        name: 'InteractionDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1884, name: 'SQL', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1885,
        name: 'StatisticalProcessControls',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1886,
        name: 'Fabrication',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1887,
        name: 'Spacecraft',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1888,
        name: 'MechanicalSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1889,
        name: 'Biotechnology',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1890,
        name: 'Robotics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1891,
        name: 'CascadingStyleSheetsCSS}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1892, name: 'C', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1893,
        name: 'QualityManagement',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1894,
        name: 'CreoElements/ProComputer-Aed',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1895,
        name: 'TestPlanning',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1896,
        name: 'SubmittalsConstruction}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1897,
        name: 'PredictiveMaintenance',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1898,
        name: 'SupervisoryControlAndDataAcquisitionSCADA}',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1899,
        name: 'User-CenteredDesign',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {id: 1900, name: 'C#', complete: 'IndustrialandMechanicalEngineering'},
      {
        id: 1901,
        name: 'QualityAssurance',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1902,
        name: 'Tooling',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1903,
        name: 'Optics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1904,
        name: 'Pumps',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1905,
        name: 'ProjectManagement',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1906,
        name: 'DistributedControlSystems',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1907,
        name: 'HyperTextMarkupLanguage',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1908,
        name: 'Selenium',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1909,
        name: 'ISO9000',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1910,
        name: 'FiniteElementMethods',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1911,
        name: 'Electronics',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1912,
        name: 'Boilers',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1913,
        name: 'PersonalProtectiveEquipment',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1914,
        name: 'HumanMachineInterfaces',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1915,
        name: 'InformationArchitecture',
        complete: 'IndustrialandMechanicalEngineering'
      },
      {
        id: 1916,
        name: 'Perl',
        complete: 'IndustrialandMechanicalEngineering'
      },

      {
        id: 801,
        name:
          'Indexing\r\nDrupal\r\nVisualArts\r\nComputerHardware\r\nLearningTheory\r\nSerialsSolutions\r\nSPS',
        complete: 'MiningandDrilling'
      },
      {id: 802, name: 'MudSystems', complete: 'MiningandDrilling'},
      {id: 803, name: 'MudSystems', complete: 'MiningandDrilling'},
      {
        id: 804,
        name:
          'Textiles\r\nTechnicalCommunication\r\nBudgeting\r\nLibraryManagement\r\nApplicationProgrammingInterface\r\nBrochures\r\nPrioritization\r\nLifeSciences',
        complete: 'MiningandDrilling'
      },
      {
        id: 805,
        name:
          'RemoteAccessSystems\r\nOntologies\r\nFurnishing\r\nFineMotorSkills\r\nGrantWriting\r\nMergersAndAcquisitions\r\nDataVisualization\r\nArchivalResearch\r\nCalculations',
        complete: 'MiningandDrilling'
      },
      {
        id: 806,
        name:
          'Chemistry\r\nSystemsManagement\r\nUserExperience\r\nProjectManagementSoftware\r\nBusinessEnglish\r\nMedicalEducation',
        complete: 'MiningandDrilling'
      },
      {id: 807, name: 'MudSystems', complete: 'MiningandDrilling'},
      {id: 808, name: 'MudSystems', complete: 'MiningandDrilling'},
      {id: 809, name: 'MudSystems', complete: 'MiningandDrilling'},
      {id: 810, name: 'MudSystems', complete: 'MiningandDrilling'},
      {id: 811, name: 'BusinessDevelopment', complete: 'MiningandDrilling'},
      {id: 812, name: 'BusinessDevelopment', complete: 'MiningandDrilling'},
      {
        id: 813,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'MiningandDrilling'
      },
      {
        id: 814,
        name: 'OccupationalHealthAndSafetyAdministration',
        complete: 'MiningandDrilling'
      },
      {
        id: 815,
        name:
          'AssociatesDegreeInNursing\r\nClinicalQualityManagement\r\nSalesPlanning\r\nPurchasing\r\nMedicalWriting\r\nComputerizedPhysicianOrderEntry\r\nPediatrics\r\nBusinessProcess\r\nBusinessProcessReengineering\r\nPerformanceAppraisal\r\n',
        complete: 'Healthcare,Other'
      },
      {
        id: 816,
        name:
          'OccupationalTherapy\r\nTraumaNurseCoreCourse{TNCC}\r\nTableau{BusinessIntelligenceSoft\r\nHealthCareIndustry\r\nInformationManagement\r\nClinicalStudyDesign\r\nDisabilities',
        complete: 'Healthcare,Other'
      },
      {
        id: 817,
        name:
          'Telemetry\r\nHealthInformationManagement\r\nClinicalMedicalEthics\r\nCustomerSatisfaction\r\nBiostatistics\r\nLicensedMasterSocialWorker\r\nNurseEducation\r\nServiceDelivery',
        complete: 'Healthcare,Other'
      },
      {id: 818, complete: 'Healthcare', name: 'Other'},
      {
        id: 819,
        name:
          'ClaimProcessing\r\nRegulatoryCompliance\r\nBiopharmaceuticals\r\nPhysicalTherapy\r\nCardiacCatheterization\r\nDiseasePrevention',
        complete: 'Healthcare,Other'
      },
      {
        id: 820,
        name:
          'FunctionalSpecification\r\nInterpersonalSkills\r\nClinicalStudyReports\r\nTuberculosis\r\nObstetricsAndGynaecology\r\nMedicalTerminology',
        complete: 'Healthcare,Other'
      },
      {
        id: 821,
        name:
          'QueryLanguages\r\nLeanSixSigma\r\nSafetyMonitoring\r\nClinicalSupervision\r\nIntensiveCare\r\nRegisteredHealthInformationAd\r\nUnderwriting',
        complete: 'Healthcare,Other'
      },
      {
        id: 822,
        name:
          'BusinessPlanning\r\nEpemiology\r\nImmunization\r\nNationalCouncilLicensureExamination\r\nIntegratedCare\r\nPeerSupport',
        complete: 'Healthcare,Other'
      },
      {
        id: 823,
        name:
          'ChangeManagement\r\nLanguageTranslation\r\nOccupationalHealthAndSafetyAdministration\r\nRadiology\r\nMedicaManagedCare\r\nForecasting\r\nNewProductDevelopment\r\nPersonalCare\r\nAnesthesiology\r\nHealthEducation',
        complete: 'Healthcare,Other'
      },
      {id: 824, name: 'Education', complete: 'Healthcare,Other'},
      {
        id: 825,
        name:
          'OrganizationalLeadership\r\nMicrobiology\r\nHospice\r\nGynaecology\r\nCallCenters\r\nAccountsReceivable',
        complete: 'Healthcare,Other'
      },
      {
        id: 826,
        name:
          'ScientificLiterature\r\nPsychosocialAssessments\r\nGeneralSurgery\r\nRegisteredHealthInformationTechnician\r\nTrainingAndDevelopment\r\nMedicalEducation\r\nSpeech-LanguagePathology\r\nBasicCardiacLifeSupport\r\nICD-10\r\nBusiness',
        complete: 'Healthcare,Other'
      },
      {
        id: 827,
        name:
          'LabTests\r\nEndoscopy\r\nBusinessIntelligence\r\nPrioritization\r\nInformationSciences\r\nCertifiedNursingAssistant\r\nElectrophysiology\r\nCostBenefitAnalysis\r\nBalancedBudgeting\r\nPharmacotherapy\r\nCrisisIntervention',
        complete: 'Healthcare,Other'
      },
      {id: 828, complete: 'Healthcare,Other'},
      {
        id: 829,
        name:
          'CardiacSurgeries\r\nWorkgroup\r\nEmployeeEngagement\r\nChemistry\r\nCarePlanning\r\nEmergencyNursePediatricCourse{ENPC}\r\nReportingTools\r\nSurveys',
        complete: 'Healthcare,Other'
      },
      {
        id: 830,
        name:
          'InstitutionalReviewBoard{\r\nPersonalProtectiveEquipment\r\nNeurosurgeries\r\nPhysicalFitness\r\nSoftwareDevelopmentLifeCycle\r\nValueProposition\r\nCleanliness',
        complete: 'Healthcare,Other'
      },
      {
        id: 831,
        name:
          'Ultrasound\r\nSocialDeterminantsOfHealth\r\nOperationsManagement\r\nCancer\r\nPsychotherapy\r\nEmergencyMedicalServices\r\nQuantitativeAnalysis',
        complete: 'Healthcare,Other'
      },
      {id: 832, complete: 'Healthcare,Other'},
      {
        id: 833,
        name:
          'QuantitativeAnalysis		\r\nGovernance		\r\nCommercialization		\r\nGoodManufacturingPractices		\r\nMagneticResonanceImaging		\r\nCertifiedCodingSpecialist		\r\nContinuousQualityImprovement	\r\nResearchAndDevelopment		\r\nHome',
        complete: 'Healthcare,Other'
      },
      {id: 834, name: 'Editing', complete: 'Healthcare,Other'},
      {id: 835, name: 'Geography', complete: 'Healthcare,Other'},
      {
        id: 836,
        name:
          'CertifiedEmergencyNurse	\r\nInvestments	\r\nDataCollection	\r\nMatrixManagement	\r\nGeriatrics	\r\nIntensiveCareMedicine	\r\nICD-10MedicalClassification	\r\nPolicyDevelopment	\r\nProjectManagementProfessionalCertification	\r',
        complete: 'Healthcare,Other'
      },
      {
        id: 837,
        name:
          'X-RayComputedTomography\r\nRadiology\r\nIntensiveCare\r\nNeonatalIntensiveCareUnit\r\nPatientEducation\r\nNuclearMedicine\r\nOperatingTheater\r\nIntensiveCareUnit\r\nBasicLifeSupport',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 838,
        name:
          'HealthPromotion\r\nElectroencephalography\r\nCardiacCatheterization\r\nSleepMedicine\r\nNurseMwife\r\nCardiopulmonaryResuscitation',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 839,
        name:
          'Performance-EnhancingDrugs\r\nCardiovascularDisease\r\nEndoscopy\r\nGynecologicOncology\r\nPatientManagementSoftware\r\nPhysicalMedicineAndRehabilitation',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 840,
        name:
          'CertifiedRegisteredNurseAnesthetis\r\nRespiratoryDiseases\r\nMalpractice\r\nWrittenEnglish\r\nCancer\r\nPediatricSurgeries\r\nNeonatology\r\nNeonatalResuscitationProgram\r\nMentalStatusExamination',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 841,
        name:
          'Electrocardiography\r\nPodiatry\r\nTeleradiology\r\nMaternity\r\nVeterinaryMedicine\r\nInterventionalCardiology\r\nEchocardiography\r\nColonoscopy\r\nPostpartumPeriod',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 842,
        name:
          'DiagnosticTests\r\nMedicalOncology\r\nRadiologicTechnologist\r\nCaregiving\r\nUrogynecology\r\nPsychotherapy\r\nPainManagement\r\nInterventionalRadiology\r\nEsophagogastroduodenoscopy\r\nWriting\r\nJointInjection\r\nReconstructiveSurgery\r\nB',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 843,
        name:
          'SurgicalSuturing\r\nEndovascularSurgery\r\nEmergencyDepartments\r\nPreventiveHealthcare\r\nPhysicalTherapy\r\nGeneralAnaesthesia\r\nCollections\r\nAuditing\r\nRadiationOncology\r\nNuclearCardiology\r\nArterialCatheter\r\nPreventiveCare',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 844,
        name:
          'Dermatology\r\nOphthalmology\r\nPediatricIntensiveCareUnit\r\nAdvocacy\r\nHumanMusculoskeletalSystems\r\nSportsMedicine\r\nRespiratoryTherapy\r\nPatientHealthQuestionnaires\r\nPathology\r\nEpuralAnesthesia',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 845,
        name:
          'ChestTubes\r\nDoctorOfVeterinaryMedicine\r\nPositronEmissionTomography\r\nMicrosurgery\r\nMedicalVentilators\r\nInfectionControl\r\nParacentesis\r\nBariatrics\r\nHepatology\r\nElectronicMedicalRecord\r\nBotulinumToxin',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 846,
        name:
          'JointReplacement		\r\nDialysis		\r\nImmunization		\r\nDual-EnergyX-RayAbsorptiometry		\r\nArtificialCardiacPacemakers		\r\nSigmooscopy		\r\nMedicalScience		\r\nAnatomy		\r\nProfessionalLiability		\r\nPracticeManagement		\r\nClientRapp',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 847,
        name:
          'Fluoroscopy		\r\nHandSurgery		\r\nKneyTransplantation		\r\nQualityManagement		\r\nInterventionalPainManagement		\r\nPerioperative		\r\nLearningPlatforms		\r\nPerformanceImprovement		\r\nThoracentesis		\r\nAcuteCare		\r\nEndoscopicUltr',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 848,
        name:
          'SurgicalProcedures		\r\nCoronaryArteryBypassSurgery		\r\nPrescription		\r\nInfectiousDiseases		\r\nCustomerSupport		\r\nVerbalCommunicationSkills		\r\nCompositions		\r\nRoboticSurgery		\r\nDoctorOfMedicine		\r\nAllergology		\r\nO',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 849, name: 'Rhetoric', complete: 'MedicalDaigonsisandSurgery'},
      {id: 850, name: 'AdobeConnect', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 851,
        name: 'FrenchLanguage',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 852, name: 'Tuberculosis', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 853,
        name: 'FrenchLanguage',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 854,
        name: 'FrenchLanguage',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {id: 855, name: 'Tuberculosis', complete: 'MedicalDaigonsisandSurgery'},
      {
        id: 856,
        name: 'FrenchLanguage',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 857,
        name: 'FrenchLanguage',
        complete: 'MedicalDaigonsisandSurgery'
      },
      {
        id: 858,
        name:
          'PelvicFloor	\r\nPatientTransport	\r\nCardiacMonitoring	\r\nNuchalScan	\r\nElectronicMedicalRecord	\r\nNursing	\r\nPediatrics	\r\nOccupationalHealthAndSafetyAdministration{:OSHA}	\r\nCardiacSurgeries	\r\nRegisteredVascularSpecialis',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 859,
        name:
          'RespiratoryTherapy	\r\nSampleCollection	\r\nQualityManagement	\r\nRegisteredCardiacElectrophysiologySpecialist	\r\nAbdomen{:Medical}	\r\nRadiologyInformationSystems	\r\nDysrhythmia	\r\nBloodDonationDrives	\r\nCleanliness	\r\nBloodPr',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 860,
        name:
          'FetalEchocardiography	\r\nMechanics	\r\nIntensity-ModulatedRadiationTherapy	\r\nContinuousWave	\r\nPhysics	\r\nCardiacStressTests	\r\nVascularSurgery	\r\nPatientSafety	\r\nCertifiedCardiographicTechnician	\r\nClinicalAssessments	\r\nH',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 861,
        name:
          'CertificateOfClinicalCompetenceInSpeech-LanguagePathology{:CCC-SLP}	\r\nFineMotorSkills	\r\nAngioplasty	\r\nCongenitalHeartDefects	\r\nPhysicianPatientPrivilege	\r\nHeartRate	\r\nTroubleshooting{:ProblemSolving}	\r\nSimulatio',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 862,
        name:
          'CriticalCare	\r\nSettingAppointments	\r\nIntensiveCareUnit	\r\nAuditing	\r\nCertifiedMedicalDosimetrist	\r\nFrontOffice	\r\nImplantableCardioverter-Defibrillator	\r\nFirstA	\r\nVehicleMaintenance	\r\nPerformanceImprovement	\r\nClini',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 863,
        name:
          'CertifiedNursingAssistant	\r\nDataEntry	\r\nFamilyMedicine	\r\nCollections	\r\nHospitalMedicine	\r\nWorkflows	\r\nCancerCare	\r\nClientRapport	\r\nElectroencephalography	\r\nPrioritization	\r\nPsychiatry	\r\nInformationSystems	\r\nCriticalC',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 864,
        name:
          'CertifiedRhythmAnalysisTechnician	\r\nFiling	\r\nAblation	\r\nTeamwork	\r\nEclipse	\r\nBiology	\r\nIntraVascularsUltrasound	\r\nWrittenEnglish	\r\nDosimetry	\r\nDataCollection	\r\nMedicalPhysics	\r\nSterilization	\r\nCirculatorySy',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 865,
        name: 'UrineDrugScreen	\r\nLicensedPracticalNurse	',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 866,
        name:
          'Parasitology\r\nCardiacSurgeries\r\nBiopsies\r\nSpreadsheets\r\nCareerReadinessCertificate\r\nLaboratoryInformationManagementSystems\r\nUrology\r\nFamilyMedicine\r\nRegulatoryCompliance',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 867,
        name:
          'ICD-10MedicalClassification	Psychology\r\nCapillary	Cardiology\r\nPatientTransport	CardiacMonitoring\r\nSafeRestrainingTechniques{Veterinary}	CorrectiveAndPreventiveAction{CAPA}\r\nCodeOfFederalRegulations	UrgentCare\r\n',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 868,
        name:
          'OfficerTraining\r\nGynaecology\r\nEmergencyMedicine\r\nDevelopmentEnvironment\r\nDataQuality\r\nSpecialistinBloodBanking{SBB-ASCP}\r\nCharteredFinancialAnalyst\r\nOccupationalTherapy\r\nRegulatoryRequirements\r\nCertifiedMedicalC',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 869,
        name:
          'CommunicableDiseases\r\nAccountsReceivable\r\nBoneMarrowTransplant\r\nNeurosurgeries\r\nTrachealIntubation\r\nSafetyTraining\r\nPracticeManagement\r\nLaboratoryTechniques\r\nLinens',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 870,
        name:
          'Gastroenterology\r\nOccupationalSafetyAndHealth\r\nCaseManagement\r\nCompetencyAssessment\r\nAdvancedCardiovascularLifeSupport\r\nDentistry\r\nAccessioning\r\nDocumentImaging\r\nPreventiveMaintenance\r\nCardiothoracicSurgerie',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 871,
        name:
          'DigitalRadiography\r\nCytology\r\nHealthInformationSystems{:HIS}\r\nInstrumentation\r\nPediatrics\r\nCardiacCatheterization\r\nHelpDesk',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 872,
        name:
          'UtilizationManagement\r\nAnatomicalPathology\r\nEvokedPotential\r\nCardiology\r\nPunctuality\r\nDataValation\r\nRemedialAction\r\nDecontamination',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 873,
        name:
          'AnimalHandling\r\nPerformanceAppraisal\r\nEmergencyDepartments\r\nInternationalEducation\r\nElectromyography\r\nPathology\r\nCriticalThinking\r\nProfessionalServices\r\nAnalyticalChemistry\r\nPositiveAirwayPressure\r\nBloodPressure',
        complete: 'MedicalImaging/Equipment'
      },
      {id: 874, name: 'id', complete: 'MedicalImaging/Equipment'},
      {
        id: 875,
        name:
          'FinancialAnalysis		\r\nReconciliation		\r\nCytogenetics		\r\nUniversalPrecautions		\r\nSpeech-LanguagePathology		\r\nBusinessEthics		\r\nCertifiedDocumentationImprovementPractitioner		\r\nDiagnosticTests		\r\nOxygenTherapy		\r\nInterv',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 876,
        name:
          'Podiatry	\r\nDermatology	\r\nTelephoneSkills	\r\nDataIntegrity	\r\nRespiratorySystems	\r\nCriticalCare	\r\nPatientRegistration	\r\nTimelines	\r\nSleepPatterns	\r\nTriage	\r\nOutliers	\r\nRadiologyCertifiedCoder	\r\nRegisteredSleepTechnolog',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 877,
        name:
          'CustomerRelationshipManagement		\r\nAnalytics		\r\nOralAndMaxillofacialSurgery		\r\nHandHygiene		\r\nDocumentManagementSystems		\r\nLaparoscopicSurgeries		\r\nPhilosophyOfBusiness		\r\nGoodDrivingRecord		\r\nCaesareanSection		\r',
        complete: 'MedicalImaging/Equipment'
      },
      {
        id: 878,
        name:
          'ComprehensivePlanning\r\nRegistration\r\nWriting\r\nCirculatorySystems\r\nDeficits\r\nCardiopulmonaryResuscitation\r\nQualityManagement\r\nPatientAssessment\r\nContinuingCare\r\nPulmonaryFunctionTesting\r\nDisabilities\r\nSportsMedi',
        complete: 'Therapy'
      },
      {
        id: 879,
        name:
          'VisualAcuity\r\nInstructing\r\nArterialBloodGases\r\nFluencyDisorders\r\nNursing\r\nPsychosocialAssessments\r\nCommunicableDiseases\r\nMagneticResonanceImaging\r\nAerosols\r\nHearingDisorders\r\nOrthotics',
        complete: 'Therapy'
      },
      {id: 880, complete: 'Therapy'},
      {
        id: 881,
        name:
          'Anatomy\r\nDischargePlanning\r\nNursingHomes\r\nMechanicalVentilation\r\nAugmentativeAndAlternativeCommunications\r\nTreatmentPlanning\r\nHealthEducation\r\nPatientDischarges\r\nHospitalMedicine\r\nPulseOximetry\r\nPediatrics\r\nManual',
        complete: 'Therapy'
      },
      {
        id: 882,
        name:
          'Physiology\r\nEnduranceTraining\r\nInternalMedicine\r\nElectrocardiography\r\nAutism{:Therapies}\r\nAdaptiveEquipment\r\nPerformanceImprovement\r\nComputerizedPhysicianOrderEntry\r\nCertifiedTherapeuticRecreationSpecialist\r\nSpirome',
        complete: 'Therapy'
      },
      {id: 883, complete: 'Therapy'},
      {
        id: 884,
        name:
          'CommunityEducation	\r\nInfection	\r\nRadiology	\r\nNeonatalIntensiveCareUnit	\r\nInjuryPrevention	\r\nSoftwareDevelopmentLifeCycle	\r\nCarePlanning	\r\nNursingHomeCare	\r\nAirwayManagement	\r\nUtilizationManagement	\r\nPublicRelati',
        complete: 'Therapy'
      },
      {id: 885, complete: 'Therapy'},
      {
        id: 886,
        name:
          'Muscles	\r\nOccupationalHealthAndSafetyAdministration{:OSHA}	\r\nBasicCardiacLifeSupport	\r\nClinicalResearch	\r\nIntensiveCareUnit	\r\nCommunityDesign	\r\nTeamwork	\r\nCleanliness	\r\nVaccination	\r\nTracheotomy	\r\nPelvicFloor	\r\nPe',
        complete: 'Therapy'
      },
      {id: 887, complete: 'Therapy'},
      {
        id: 888,
        name:
          'Bronchoscopy	\r\nHealthCareFinanceAdministration{:HCFA}Regulations	\r\nPatientHealthQuestionnaires	\r\nSocialSkills	\r\nMusicTherapy	\r\nPrescription	\r\nAdvising	\r\nKinesiology	\r\nAmputation	\r\nImmunization	\r\nHemodynamics{',
        complete: 'Therapy'
      },
      {
        id: 889,
        name:
          'SpinalCordInjury	\r\nPsychiatry	\r\nComplianceTraining	\r\nErgonomics	\r\nTeaching	\r\nPersonalProtectiveEquipment	\r\nCreativeArtsTherapy	\r\nEmergencyDepartments	\r\nMotorControl	\r\nFiling	\r\nQualityAssurance	\r\nParaffin	\r\nChestPhy',
        complete: 'Therapy'
      },
      {
        id: 890,
        name:
          'FineMotorSkills	\r\nExpressiveTherapy	\r\nTrachealIntubation	\r\nInterpersonalCommunications	\r\nPhysicianPatientPrivilege	\r\nBiologicalHazards	\r\nImplantableCardioverter-Defibrillator	\r\nSputum	\r\nWoundCare	\r\nPunctuality	\r\nCri',
        complete: 'Therapy'
      },
      {
        id: 891,
        name:
          'UrinarySystems	\r\nProgressReporting	\r\nLicensedPracticalNurse	\r\nDataEntry	\r\nCareStandardsAct2000	\r\nSportsInjury	\r\nFirstA	\r\nTyping	\r\nWorkersCompensation	\r\nSanitation	',
        complete: 'Therapy'
      },
      {
        id: 892,
        name:
          'MasterOfPublicAdministration	\r\nMedicare	\r\nPatientManagementSoftware	\r\nCorrectiveAndPreventiveAction{:CAPA}	\r\nQualityControl	\r\nPainManagement	\r\nExercisePhysiology	\r\nMemos	\r\nCompositions	\r\nTroubleshootingroblemSo',
        complete: 'Therapy'
      },
      {
        id: 893,
        name:
          'Self-Sufficiency	\r\nLanguagePragmatics	\r\nOsteoarthritis	\r\nTractionEquipment	\r\nCommunityHealth	\r\nStretching	\r\nHealthPromotion	\r\nRheumatoArthritis	\r\nWritingSystems	\r\nBloodPressure	\r\nStrokeRecovery	\r\nClericalWorks	\r\nMe',
        complete: 'Therapy'
      },
      {
        id: 894,
        name:
          'AdaptiveCommunicationEnvironments	\r\nReconciliation	\r\nNationallyCertifiedInTherapeuticMassageAndBodywork	\r\nPediatricIntensiveCareUnit	\r\nChildbirth	\r\nCustomerExperience	\r\nPatientTransport	\r\nHealthSystems	\r\nIntensi',
        complete: 'Therapy'
      },
      {
        id: 895,
        name:
          'SocialWork	\r\nTrauma	\r\nProgramDesignLanguages	\r\nEthicalConduct	\r\nDiseaseManagement	\r\nInterventionalRadiology	\r\nPositivePressure	\r\nBusinessSense	\r\nData/RecordLogging	\r\nOccupationalSafetyAndHealth	\r\nLanguageInterpre',
        complete: 'Therapy'
      },
      {
        id: 896,
        name:
          'PerformanceAppraisal	\r\nClientRapport	\r\nCreativeArts	\r\nSterilization	\r\nTopSecret-SensitiveCompartmentedInformation{TS/SCIClearance}	\r\nOperationalExcellence	\r\nTherapeuticModalities	\r\nLabourLaws	\r\nUrgentCare	\r\nPrimar',
        complete: 'Therapy'
      },
      {
        id: 897,
        name:
          'FireSafety	\r\nMathematics	\r\nEnvironmentalControl	\r\nAmbulatoryCare	\r\nHygiene	\r\nMentalDiseases	\r\nAsthma	\r\nSettingAppointments	\r\nManagedCare	\r\nInfluenza	\r\nStressTesting	\r\nLiabilityInsurance	\r\nEmployeeAssistancePrograms	',
        complete: 'Therapy'
      },
      {
        id: 898,
        name:
          'CertifiedPulmonaryFunctionTechnologist	\r\nEmpowerment	\r\nWarehousing	\r\nApplicantTrackingSystems	\r\nPositiveAirwayPressure	\r\nHepatitis	\r\nPatents	\r\nMeasles	\r\nPeakExpiratoryFlow	\r\nServiceProver	\r\nDelegationSkills	\r\nSen',
        complete: 'Therapy'
      },
      {
        id: 899,
        name:
          'FunctionalElectricalStimulation\r\nSpreadsheets\r\nLathes\r\nCriticalThinking\r\nCardiacArrest\r\nDrillPress\r\nOrthotics\r\nPerformanceAppraisal\r\nMarketingStrategies\r\nCommunityEducation\r\nLeadershipDevelopment',
        complete: 'Therapy'
      },
      {
        id: 900,
        name:
          'ApplicationServices\r\nTopSecret-SensitiveCompartmentedInformation{:TS/SCIClearance}\r\nNewProductDevelopment\r\nCorrectiveAndPreventiveAction{CAPA}\r\nIndexing\r\nTalend\r\nLinuxAdministration\r\nObject-OrientedProgramming{O',
        complete: 'Therapy'
      },
      {
        id: 901,
        name:
          'WebServices\r\nEthernet\r\nMicrosoftCertifiedITProfessional\r\nDataManagement\r\nTestEquipment\r\nMulti-FactorAuthentication\r\nForensicSciences\r\nUnixShell\r\nNetworkMonitoring\r\nCentOS\r\nServiceManagement\r\nCategory5Cabling\r\nPub',
        complete: 'Therapy'
      },
      {
        id: 902,
        name:
          'CompTIAServer+\r\nSurveys\r\nNetworkingCables\r\nApplicationProgrammingInterface{:API}\r\nPing{NetworkingUtility}\r\nLoadBalancing\r\nConfigurationManagement\r\nTechnicalManagement\r\nFileServers\r\nOracleDatabases\r\nGhost{:BackupSo',
        complete: 'Therapy'
      },
      {id: 994, name: 'StrategicBusinessUnit', complete: 'CivilEngineering'},
      {id: 995, name: 'Logos', complete: 'CivilEngineering'},
      {id: 996, name: 'Loans', complete: 'CivilEngineering'},
      {id: 997, name: 'Flowmaster', complete: 'CivilEngineering'},
      {id: 998, name: 'Compositions', complete: 'CivilEngineering'},
      {
        id: 999,
        name: 'ComputerAedThree-DimensionalInteractiveApplication',
        complete: 'CivilEngineering'
      },
      {id: 1000, name: 'SystemsManagement', complete: 'CivilEngineering'},
      {id: 1001, name: 'ElectronicDocuments', complete: 'CivilEngineering'},
      {id: 1002, name: 'HydrogenSulfe', complete: 'CivilEngineering'},
      {id: 1003, name: 'TechnicalTraining', complete: 'CivilEngineering'},
      {id: 1004, name: 'Groundwater', complete: 'CivilEngineering'},
      {id: 1005, name: 'FineMotorSkills', complete: 'CivilEngineering'},
      {id: 1006, name: 'PipeRamming', complete: 'CivilEngineering'},
      {id: 1007, name: 'UrbanPlanning', complete: 'CivilEngineering'},
      {id: 1008, name: 'VisualAcuity', complete: 'CivilEngineering'},
      {
        id: 1009,
        name: 'HyperTextMarkupLanguage{HTML}',
        complete: 'CivilEngineering'
      },
      {id: 1010, name: 'TaskPlanning', complete: 'CivilEngineering'},
      {id: 1011, name: 'Spillway', complete: 'CivilEngineering'},
      {
        id: 1012,
        name: 'MaterialsTesting{Tests}',
        complete: 'CivilEngineering'
      },
      {id: 1013, name: 'Vibrations', complete: 'CivilEngineering'},
      {id: 1014, name: 'Memos', complete: 'CivilEngineering'},
      {id: 1015, name: 'FinancialAnalysis', complete: 'CivilEngineering'},
      {id: 1016, name: 'WaterHeating', complete: 'CivilEngineering'},
      {id: 1017, name: 'Land-UsePlanning', complete: 'CivilEngineering'},
      {id: 1018, name: 'Calculations', complete: 'CivilEngineering'},
      {id: 1019, name: 'ForensicEngineering', complete: 'CivilEngineering'},
      {id: 1020, name: 'SolWorks', complete: 'Drafting'},
      {id: 1021, name: 'SketchUp', complete: 'Drafting'},
      {id: 1022, name: 'CivilEngineering', complete: 'Drafting'},
      {id: 1023, name: 'FireAlarmSystems', complete: 'Drafting'},
      {id: 1024, name: 'Construction', complete: 'Drafting'},
      {id: 1025, name: 'Machining', complete: 'Drafting'},
      {id: 1026, name: 'AdobePhotoshop', complete: 'Drafting'},
      {id: 1027, name: 'Grading{:Landscape}', complete: 'Drafting'},
      {id: 1028, name: 'LowVoltage', complete: 'Drafting'},
      {id: 1029, name: 'Plumbing', complete: 'Drafting'},
      {id: 1030, name: 'Fabrication', complete: 'Drafting'},
      {id: 1031, name: 'AutodeskRevit', complete: 'Drafting'},
      {id: 1032, name: 'GeographicInformationSystems', complete: 'Drafting'},
      {id: 1033, name: 'AccessControls', complete: 'Drafting'},
      {id: 1034, name: 'BuildingCodes', complete: 'Drafting'},
      {
        id: 1035,
        name: 'GeometricDimensioningAndTolerancing',
        complete: 'Drafting'
      },
      {id: 1036, name: 'AdobeIllustrator', complete: 'Drafting'},
      {id: 1037, name: 'LandDevelopment', complete: 'Drafting'},
      {id: 1038, name: 'ElectronicSystems', complete: 'Drafting'},
      {id: 1039, name: 'Elevation', complete: 'Drafting'},
      {id: 1040, name: 'ProductDataManagement', complete: 'Drafting'},
      {id: 1041, name: 'Rendering', complete: 'Drafting'},
      {id: 1042, name: 'Surveying', complete: 'Drafting'},
      {id: 1043, name: 'Datasheets', complete: 'Drafting'},
      {id: 1044, name: 'ConstructionManagement', complete: 'Drafting'},
      {id: 1045, name: 'ManufacturingProcesses', complete: 'Drafting'},
      {
        id: 1046,
        name: 'Autodesk3DSMax{:3DGraphicsSoftware}',
        complete: 'Drafting'
      },
      {id: 1047, name: 'Topography', complete: 'Drafting'},
      {id: 1048, name: 'PrintedCircuitBoard', complete: 'Drafting'},
      {id: 1049, name: 'ShopDrawing', complete: 'Drafting'},
      {
        id: 1050,
        name: 'CreoElements/Pro{:Computer-AedDesignSoftware}',
        complete: 'Drafting'
      },
      {id: 1051, name: 'AdobeInDesign', complete: 'Drafting'},
      {id: 1052, name: 'MicroStation', complete: 'Drafting'},
      {id: 1053, name: 'ElectricalWirings', complete: 'Drafting'},
      {id: 1054, name: 'Renovation', complete: 'Drafting'},

      {
        id: 651,
        name: 'ManufacturingProcesses',
        complete: 'LogisticsandProcurement'
      },
      {id: 652, name: 'Accounting', complete: 'LogisticsandProcurement'},
      {
        id: 653,
        name: 'SellingTechniques',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 654,
        name: 'ComputerSciences',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 655,
        name: 'VisualMerchandising',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 656,
        name: 'InternalControls',
        complete: 'LogisticsandProcurement'
      },
      {id: 657, name: 'RealEstate', complete: 'LogisticsandProcurement'},
      {id: 658, name: 'PMICertified', complete: 'LogisticsandProcurement'},
      {
        id: 659,
        name: 'CivilEngineering',
        complete: 'LogisticsandProcurement'
      },
      {id: 660, name: 'JIRA', complete: 'LogisticsandProcurement'},
      {id: 661, name: 'AssetProtection', complete: 'LogisticsandProcurement'},
      {
        id: 662,
        name: 'RegulatoryRequirements',
        complete: 'LogisticsandProcurement'
      },
      {id: 663, name: 'Biotechnology', complete: 'LogisticsandProcurement'},
      {id: 664, name: 'Spreadsheets', complete: 'LogisticsandProcurement'},
      {
        id: 665,
        name: 'ProductManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 666, name: 'CashRegister', complete: 'LogisticsandProcurement'},
      {id: 667, name: 'Fundraising', complete: 'LogisticsandProcurement'},
      {id: 668, name: 'Biology', complete: 'LogisticsandProcurement'},
      {
        id: 669,
        name: 'GoodDrivingRecord',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 670,
        name: 'ProjectStakeholders',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 671,
        name: 'MarketingPlanning',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 672,
        name: 'ComputerSciences',
        complete: 'LogisticsandProcurement'
      },
      {id: 673, name: 'FoodSafety', complete: 'LogisticsandProcurement'},
      {id: 674, name: 'LeanSixSigma', complete: 'LogisticsandProcurement'},
      {id: 675, name: 'RealEstate', complete: 'LogisticsandProcurement'},
      {
        id: 676,
        name: 'PricingStrategies\r\nBiotechnology',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 677,
        name: 'ProjectManagementLifeCycle',
        complete: 'LogisticsandProcurement'
      },
      {id: 678, name: 'Medica', complete: 'LogisticsandProcurement'},
      {id: 679, name: 'Subcontracting', complete: 'LogisticsandProcurement'},
      {
        id: 680,
        name: 'BusinessRequirements',
        complete: 'LogisticsandProcurement'
      },
      {id: 681, name: 'LabourLaws', complete: 'LogisticsandProcurement'},
      {
        id: 682,
        name: 'ChangeManagement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 683,
        name: 'WorkingCapitalWorkingCapital',
        complete: 'LogisticsandProcurement'
      },
      {id: 684, name: 'Renovation', complete: 'LogisticsandProcurement'},
      {id: 685, name: 'Maintainability', complete: 'LogisticsandProcurement'},
      {id: 686, name: 'BuildingCodes', complete: 'LogisticsandProcurement'},
      {id: 687, name: 'Logisticsand', complete: 'Procurement'},
      {id: 688, name: 'Mitigation', complete: 'LogisticsandProcurement'},
      {
        id: 689,
        name:
          'APICSCertifiedSupplyChainProfessional\r\nPointOfSale\r\nPsychiatry\r\nElectricalSystems\r\nMarketAnalysis\r\nMachining\r\nInventoryControl\r\nAcademicAchievement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 690,
        name:
          'ConstructionEngineering\r\nProcurementManagement\r\nCommodityManagement\r\nInventoryTurnover\r\nFinancialManagement\r\nAutodeskRevit',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 691,
        name:
          'MarketIntelligence\r\nIndustrialEngineering\r\nCleanliness\r\nConfigurationManagement\r\nWorkBreakdownStructure\r\nProfessionalServices\r\nBusinessProcess\r\nSalesTax\r\nEarnedValueManagement\r\nResentialConstruction\r\nPerformance',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 692,
        name:
          'LeanSixSigma\r\nHeavyEquipment\r\nSystemsEngineering\r\nCostManagement\r\nOutsourcing\r\nJustInTimeMethodology\r\nProactivity\r\nLogisticsSupportAnalysis\r\nRoofing\r\nSupplierDiversity\r\nPackagingAndLabeling\r\nSellingTechniques\r\nP',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 693,
        name: 'OrganizationalLeadership',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 694,
        name:
          'GoogleAdWords\r\nUserStory\r\nContentCreation\r\nPricingStrategies\r\nBiotechnology\r\nComputerSciences\r\nHyperTextMarkupLanguage\r\nMasterOfBusinessAdministration\r\nPressReleases\r\nFinancialAnalysis\r\nOncology\r\nBusi',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 695,
        name:
          'AdobeInDesign\r\nStrategicBusinessUnit\r\nGoodDrivingRecord\r\nStatisticalModeling\r\nOnlineAdvertising\r\nSoftwareEngineering\r\nGraphicDesign\r\nSalesManagement\r\nRestaurantOperation\r\nDashboard\r\nCascadingStyleSheetsnPr',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 696,
        name:
          'BrandManagement\r\nSPSS\r\nPayPerClick\r\nProjectPortfolioManagement\r\nBranding\r\nBusinessStrategies\r\nGrantWriting\r\nDataScience\r\nMarketo\r\nSoftwareDevelopment\r\nAdobeCreativeSuite\r\nSupplyChainManagem',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 697,
        name:
          'SocialMediaMarketing\r\nAccountPlanning\r\nAppeals\r\nMachineLearning\r\nA/BTesting\r\nJIRA\r\nContentStrategy\r\nConsultativeSelling\r\nEventManagement\r\nBigData\r\nKeyPerformanceIndicator\r\nProjectManagement\r\nWordPress\r\nFin',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 698,
        name:
          'RevenueGrowth\r\nPharmaceuticalSales\r\nApacheHadoop\r\nE-Commerce\r\nMarketRequirementsDocuments\r\nContentDevelopment\r\nLeverage\r\nFranchising\r\nRelationalDatabases\r\nUserExperience\r\nProductPlanning\r\nBlogs\r\nCustomerSatisfaction',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 699,
        name:
          'Photography\r\nRequestForProposal\r\nCategoryManagement\r\nPowerBI\r\nJavaScript\r\nSoftwareasaService\r\nEditorialCalendar\r\nAccountManagement\r\nRelationshipBuilding\r\nDataManagement\r\nDemandGenerat',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 700,
        name:
          'MarketTrend\r\nPublicPolicies\r\nEconometrics\r\nHubspot\r\nPrototyping\r\nIntegratedMarketingCommunications\r\nStrategicManagement\r\nOrganizationalLeadership\r\nDataModeling\r\nKeywordResearch',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 701,
        name:
          'Usability\r\nInternalCommunications\r\nProcurement\r\nHealthEconomics\r\nDataCollection\r\nContentManagementSystems\r\nCloudComputing\r\nContentManagement\r\nSolutionSelling\r\nPortfolioManagement\r\nAlgorithms',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 702,
        name:
          'Tagging\r\nCompetitiveAnalysis\r\nWebConferencing\r\nDirectSelling\r\nFoodServices\r\nAppliedMathematics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 703,
        name:
          'Pardot\r\nStrategicCommunication\r\nProfessionalServices\r\nFastMovingConsumerGoods\r\nDataQuality\r\nRetargeting\r\nBusinessValuation\r\nAdvertisement',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 704,
        name:
          'Accounting\r\nRealEstate\r\nOperationsResearch\r\nWebDevelopment\r\nProductStrategies\r\nMarketingManagement\r\nRegionalSales\r\nReservations\r\nBusinessAnalytics\r\nSalesforce.Com\r\nSoftwareProductManagement\r\nDigitalContent',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 705,
        name:
          'ManagementConsulting\r\nRetailSales\r\nExtractTransformLoad\r\nVerticalMarket\r\nCorporateCommunications\r\nCommercialization\r\nBiology\r\nApacheSpark\r\nWebsiteArchitecture',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 706,
        name:
          'InfrastructureAsAService\r\nCrisisCommunications\r\nDueDiligence\r\nMerchandising\r\nApacheHive\r\nDisplayAdvertising\r\nPlatformasaServiceaaS}\r\nWebDesign\r\nMarketIntelligence\r\nCostManagement\r\nPredictiveAnalytics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 707,
        name:
          'SearchOptimization\r\nSoftwareArchitecture\r\nReputationManagement\r\nMergersAndAcquisitions\r\nMarketingPlanning\r\nStatisticalSoftwares\r\nWebTraffic\r\nUnitTesting\r\nMarketingStrategies\r\nInterpersonalSkills\r\nOfficeManagement',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 708,
        name:
          'QuantitativeResearch\r\nGoogleTagManager\r\nProductLifecycleManagement\r\nMarketingCollateral\r\nNewBusinessDevelopment\r\nConsumerPackagedGoods\r\nTeradataSQL\r\nConversionRateOptimization\r\nAtlassianConfluence\r\nSocialNetwor',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 709,
        name:
          'MarketingAnalytics\r\nMarketingPerformanceMeasurementAndManagement\r\nAmazonWebServices\r\nCommunicationStrategies\r\nClosingSkills\r\nShopperMarketing\r\nStata\r\nCustomerAcquisitionManagement\r\nInternetOfThings\r\nQuarkX',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 710,
        name:
          'Spreadsheets\r\nTimeSeries\r\nMobileData\r\nCustomerExperience\r\nInfographics{\r\nBusinessAdministration\r\nBiopharmaceuticals\r\nScripting\r\nGoogleAdSense\r\nMicrosoftAzure\r\nStorytelling\r\nLinkAggregation{\r\nTra',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 711,
        name:
          'InboundMarketing\r\nMarketResearch\r\nTargetAudience\r\nFinancialManagement\r\nLeadershipStyles\r\nQuantitativeAnalysis\r\nMarketingEffectiveness\r\nAcceptanceTesting\r\nAdvertisingMail\r\nMarketAnalysis\r\nAnnualReports',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 712,
        name:
          'UnstructuredData\r\nTagManagement\r\nFunctionalRequirement\r\nBusinessToBusiness\r\nFinancialPlanning\r\nNursing\r\nSocialSciences\r\nLeadManagement',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 713,
        name:
          'ProgramManagement\r\nBrandentity\r\nGrowthStrategies\r\nDataEntry\r\nAnalyticalTechniques\r\nSocialMarketing\r\nEnterpriseResourcePlanning\r\nPodcasting',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 714,
        name: 'HeavyEquipment',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 715,
        name: 'DueDiligence',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 716,
        name: 'Purchasing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 717,
        name:
          'SecondaryEducation\r\nKol\r\nDataStructures\r\nMultivariateTesting\r\nSprintPlanning\r\nVeoEditing\r\nStrategicMarketing',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 718,
        name:
          'Timelines\r\nMicroStrategy\r\nCustomerRelationshipManagement\r\nMilestonesrojectManagement}\r\nPublicAffairs\r\nInvestmentBanking\r\nOrganizationDevelopment\r\nDataManipulation\r\nAutomation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 719,
        name:
          'EngineeringDesignProcess		\r\nCommunityManagement		\r\nCorporateFinance		\r\nCertifiedFundraisingExecutive		\r\nNaturalLanguageProcessing		\r\nDirectMarketing		\r\nMobileApplicationDevelopment		\r\nWebPages		\r\nProfitableGrowth',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 720,
        name: 'TestEquipment',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 721,
        name:
          'ColdCalling	\r\nPre-ClinicalDevelopment	\r\nAlteryx	\r\nFunnels	\r\nScalability	\r\nCopyEditing	\r\nResourceAllocation	\r\nEquities	\r\nScala	',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 722,
        name: 'RiskAnalysis',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 723,
        name: 'Welding',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 724,
        name: 'Programming',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 725,
        name: 'Marketing,AdvertisingandPublicnam',
        complete: 'eRelation'
      },
      {
        id: 726,
        name: 'CompositeMaterials',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 727,
        name: 'ProjectPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 728,
        name: 'CompositeMaterials',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 729,
        name: 'ProgrammableLogicControllers',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 730,
        name: 'CompositeMaterials',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 731,
        name: 'Voltmeter',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 732,
        name: 'CompositeMaterials',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 733,
        name: 'ProjectPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 734,
        name: 'WoodworkingMachines',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 735,
        name: 'ProjectPlanning',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 736,
        name: 'BrazingMetalWork}',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 737,
        name: 'BusinessEthics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 738,
        name: 'BrazingMetalWork}',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 739,
        name: 'Forecasting',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 740,
        name: 'BusinessEthics',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 741,
        name: 'OccupationalHealthAndSafetyAdministrationOSHA}',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 742,
        name: 'Voltmeter',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 743,
        name: 'Forecasting',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 744,
        name: 'Negotiation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 745,
        name: 'Negotiation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 746,
        name: 'FlooringControlSystemsFlooringControlSystems',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 747,
        name: 'Negotiation',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 748,
        name: 'Ordinances',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 749,
        name: 'CommercialDriverSLicense',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 750,
        name: 'TrafficControl',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 751,
        name: 'Tiling',
        complete: 'Marketing,AdvertisingandPublicRelation'
      },
      {
        id: 752,
        name: 'Primavera',
        complete: 'Marketing,AdvertisingandPublicRelatio'
      },
      {
        id: 753,
        name:
          'Writing\r\nAdvocacy\r\nResolver\r\nPHP\r\nPainting\r\nAutomation\r\nHigherEducation\r\nResourceManagementSystems\r\nControlledVocabulary\r\nAdobePhotoshop\r\nMicroform\r\nCreativity\r\nResourceAccessControlFacility',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 754,
        name:
          'DigitalContent\r\nArtwork\r\nTablets\r\nReferenceManagementSoftware\r\nTumblr\r\nAnglo-AmericanCataloguingRules',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 755,
        name:
          'FrenchLanguage\r\nVocabulary\r\nLibraryResearch\r\nSocialMedia\r\nExtensibleStylesheetLanguageTransformations\r\nEnvironmentalMonitoring\r\nSpanishLanguage',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 756,
        name:
          'Governance\r\nBibliographicDatabases\r\nSQL\r\nArtEducation\r\nGoodDrivingRecord\r\nLiteracy',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 757,
        name:
          'GoogleApplications\r\nDataManagement\r\nPowerToolOperation\r\nFingerDexterity\r\nSocialSciences\r\nWebConferencing',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 758,
        name:
          'DigitalAssets\r\nSculpture\r\nComputerTerminals\r\nCamtasiaStudio\r\nBlogs\r\nOmeka\r\nQuarkXPressDesignSoftware',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 759,
        name:
          'Mathematics\r\nCollaborativeSkills\r\nProcurement\r\nCONTENTdm\r\nLatin\r\nPatientDischarges\r\nInterpersonalSkills',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 760,
        name:
          'KnowledgeManagement\r\nContentManagementSystems\r\nGermanLanguage\r\nLibraryCatalog\r\nStudentEngagement\r\nTechnicalSupport\r\nDublinCore',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 761,
        name: 'ControlPanels',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 762,
        name: 'CardiopulmonaryResuscitation',
        complete: '6}CurationandLibraryScience'
      },
      {id: 763, complete: 'Artitecture', name: '6}CurationandLibraryScience'},
      {
        id: 764,
        name: 'ForkliftTruck',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 765,
        name: 'ProjectCommissioning',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 766,
        name: 'IntegralCalculus',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 767,
        name: 'ProjectCommissioning',
        complete: '6}CurationandLibraryScience'
      },
      {id: 768, name: 'Refining', complete: '6}CurationandLibraryScience'},
      {id: 769, name: 'Refining', complete: '6}CurationandLibraryScience'},
      {
        id: 770,
        name: 'MechanicalEngineering',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 771,
        name: 'TechnicalServices',
        complete: '6}CurationandLibraryScience'
      },
      {id: 772, name: 'Petrel', complete: '6}CurationandLibraryScience'},
      {
        id: 773,
        name: 'TechnicalServices',
        complete: '6}CurationandLibraryScience'
      },
      {id: 774, name: 'Optics', complete: '6}CurationandLibraryScience'},
      {
        id: 775,
        name: 'ProductionEquipmentControls',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 776,
        name: 'ProductionEquipmentControlsProductionEquipmentControls',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 777,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 778,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 779,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 780,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 781,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 782,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 783,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 784,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 785,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 786,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 787,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 788,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 789,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 790,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 791,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 792,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 793,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 794,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 795,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 796,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 797,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 798,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 799,
        name: 'SurfaceMining',
        complete: '6}CurationandLibraryScience'
      },
      {
        id: 800,
        name:
          'Student-CentredLearning\r\nMicrosoftSharePoint\r\n\r\nProjectManagement\r\nDigitalImaging\r\nPublicRelations\r\nEndNote',
        complete: 'MiningandDrilling'
      },

      {id: 483, name: 'SystemsEngineering', complete: 'Architecture'},
      {id: 484, name: 'ValueEngineering', complete: 'Architecture'},
      {id: 485, name: 'DesignTool', complete: 'Architecture'},
      {id: 486, name: 'ContractorManagement', complete: 'Architecture'},
      {id: 487, name: 'ControlSystems', complete: 'Architecture'},
      {id: 488, name: 'Spreadsheets', complete: 'Architecture'},
      {id: 489, name: 'ManagementStyles', complete: 'Architecture'},
      {id: 490, name: 'DataScience', complete: 'Architecture'},
      {id: 491, name: 'DataDefinitionLanguage', complete: 'Architecture'},
      {id: 492, name: 'CapitalBudgeting', complete: 'Architecture'},
      {id: 493, name: 'OperationsManagement', complete: 'Architecture'},
      {id: 494, name: 'SafetyCodes', complete: 'Architecture'},
      {id: 495, name: 'Automation', complete: 'Architecture'},
      {id: 496, name: 'Advising', complete: 'Architecture'},
      {id: 497, name: 'DesignLeadership', complete: 'Architecture'},
      {id: 498, name: 'ValueProposition', complete: 'Architecture'},
      {id: 499, name: 'ComplaintInvestigations', complete: 'Architecture'},
      {id: 500, name: 'Commissioning', complete: 'Architecture'},
      {id: 501, name: 'DoorSecurity', complete: 'Architecture'},
      {id: 502, name: 'InternationalStandards', complete: 'Architecture'},
      {id: 503, name: 'Subcontracting', complete: 'Architecture'},
      {id: 504, name: 'ProcessDesign', complete: 'Architecture'},
      {id: 505, name: 'IntegralCalculus', complete: 'CivilEngineering'},
      {id: 506, name: 'Construction', complete: 'CivilEngineering'},
      {id: 507, name: 'Stormwater', complete: 'CivilEngineering'},
      {id: 508, name: 'ValDriversLicense', complete: 'CivilEngineering'},
      {id: 509, name: 'StructuralEngineering', complete: 'CivilEngineering'},
      {id: 510, name: 'TrafficEngineering', complete: 'CivilEngineering'},
      {id: 511, name: 'ProjectManagement', complete: 'CivilEngineering'},
      {id: 512, name: 'Chemistry', complete: 'CivilEngineering'},
      {id: 513, name: 'ConstructionManagement', complete: 'CivilEngineering'},
      {id: 514, name: 'Grading{:Landscape}', complete: 'CivilEngineering'},
      {id: 515, name: 'Surveying', complete: 'CivilEngineering'},
      {id: 516, name: 'StructuralAnalysis', complete: 'CivilEngineering'},
      {id: 517, name: 'Synchro', complete: 'CivilEngineering'},
      {id: 518, name: 'QualityControl', complete: 'CivilEngineering'},
      {id: 519, name: 'Thermodynamics', complete: 'CivilEngineering'},
      {id: 520, name: 'Subcontracting', complete: 'CivilEngineering'},
      {id: 521, name: 'Erosions', complete: 'CivilEngineering'},
      {id: 522, name: 'Trigonometry', complete: 'CivilEngineering'},
      {
        id: 523,
        name: 'RibosomalIntergenicSpacerAnalysis',
        complete: 'CivilEngineering'
      },
      {
        id: 524,
        name: 'Vissim{:MathematicalModeling}',
        complete: 'CivilEngineering'
      },
      {id: 525, name: 'Budgeting', complete: 'CivilEngineering'},
      {id: 526, name: 'Physics', complete: 'CivilEngineering'},
      {
        id: 527,
        name: 'ConstructionEngineering',
        complete: 'CivilEngineering'
      },
      {id: 528, name: 'StormwaterManagement', complete: 'CivilEngineering'},
      {id: 529, name: 'Geometry', complete: 'CivilEngineering'},
      {
        id: 530,
        name: 'GeotechnicalEngineering',
        complete: 'CivilEngineering'
      },
      {id: 531, name: 'TransportationPlanning', complete: 'CivilEngineering'},
      {id: 532, name: 'ProgramManagement', complete: 'CivilEngineering'},
      {id: 533, name: 'Optics', complete: 'CivilEngineering'},
      {
        id: 534,
        name: 'Submittals{Construction}',
        complete: 'CivilEngineering'
      },
      {id: 535, name: 'Hydrology', complete: 'CivilEngineering'},
      {id: 536, name: 'Algebra', complete: 'CivilEngineering'},
      {id: 537, name: 'Mathcad', complete: 'CivilEngineering'},
      {id: 538, name: 'TransportEngineering', complete: 'CivilEngineering'},
      {id: 539, name: 'BusinessDevelopment', complete: 'CivilEngineering'},
      {id: 541, name: 'Procurement', complete: 'CivilEngineering'},
      {id: 542, name: 'LandDevelopment', complete: 'CivilEngineering'},
      {id: 543, name: 'Surveys', complete: 'CivilEngineering'},
      {id: 544, name: 'STAAD', complete: 'CivilEngineering'},
      {id: 545, name: 'TrafficControl', complete: 'CivilEngineering'},
      {id: 546, name: 'QualityAssurance', complete: 'CivilEngineering'},
      {id: 547, name: 'Electronics', complete: 'CivilEngineering'},
      {id: 548, name: 'Plumbing', complete: 'CivilEngineering'},
      {id: 549, name: 'SedimentControls', complete: 'CivilEngineering'},
      {id: 550, name: 'Topography', complete: 'CivilEngineering'},
      {id: 551, name: 'Masonry', complete: 'CivilEngineering'},
      {id: 552, name: 'TrafficAnalysis', complete: 'CivilEngineering'},
      {
        id: 553,
        name: 'ProjectManagementProfessionalCertification',
        complete: 'CivilEngineering'
      },
      {id: 554, name: 'EngineeringPhysics', complete: 'CivilEngineering'},
      {id: 555, name: 'Primavera', complete: 'CivilEngineering'},
      {id: 556, name: 'WaterResources', complete: 'CivilEngineering'},
      {id: 557, name: 'PublicWorks', complete: 'CivilEngineering'},
      {id: 558, name: 'SteelDesign', complete: 'CivilEngineering'},
      {
        id: 559,
        name: 'IntelligentTransportationSystems',
        complete: 'CivilEngineering'
      },
      {
        id: 560,
        name: 'SketchUp:3DModelingSoftware}',
        complete: 'CivilEngineering'
      },
      {id: 561, name: 'ComputerSciences', complete: 'CivilEngineering'},
      {id: 562, name: 'Purchasing', complete: 'CivilEngineering'},
      {id: 563, name: 'StormDrains', complete: 'CivilEngineering'},
      {id: 564, name: 'ElectricalWirings', complete: 'CivilEngineering'},
      {id: 565, name: 'StructuralSteel', complete: 'CivilEngineering'},
      {id: 566, name: 'OperationsResearch', complete: 'CivilEngineering'},
      {
        id: 567,
        name: 'MilestonesrojectManagement}',
        complete: 'CivilEngineering'
      },
      {id: 568, name: 'SoilMechanics', complete: 'CivilEngineering'},
      {id: 569, name: 'Constructability', complete: 'CivilEngineering'},
      {id: 570, name: 'SanitarySewer', complete: 'CivilEngineering'},
      {id: 571, name: 'DataCollection', complete: 'CivilEngineering'},
      {id: 572, name: 'FiniteElementMethods', complete: 'CivilEngineering'},
      {id: 573, name: 'RoadTrafficControls', complete: 'CivilEngineering'},
      {id: 574, name: 'AdobePhotoshop', complete: 'CivilEngineering'},
      {id: 575, name: 'MathematicalSciences', complete: 'CivilEngineering'},
      {id: 576, name: 'HeavyEquipment', complete: 'CivilEngineering'},
      {id: 577, name: 'HEC-RAS', complete: 'CivilEngineering'},
      {id: 578, name: 'Writing', complete: 'CivilEngineering'},
      {id: 579, name: 'SAP2000', complete: 'CivilEngineering'},
      {id: 580, name: 'SraIntersection', complete: 'CivilEngineering'},
      {id: 581, name: 'RequestForProposal', complete: 'CivilEngineering'},
      {id: 582, name: 'Geology', complete: 'CivilEngineering'},
      {id: 583, name: 'Carpentry', complete: 'CivilEngineering'},
      {id: 584, name: 'ErosionControl', complete: 'CivilEngineering'},
      {
        id: 585,
        name: 'PersonalProtectiveEquipment',
        complete: 'CivilEngineering'
      },
      {id: 586, name: 'SeismicAnalysis', complete: 'CivilEngineering'},
      {id: 587, name: 'TSIS-CORSIM', complete: 'CivilEngineering'},
      {id: 588, name: 'BusinessModel', complete: 'CivilEngineering'},
      {id: 589, name: 'Hydraulics', complete: 'CivilEngineering'},
      {id: 590, name: 'Renovation', complete: 'CivilEngineering'},
      {id: 591, name: 'CivilSiteDesign', complete: 'CivilEngineering'},
      {id: 592, name: 'FieldSurveys', complete: 'CivilEngineering'},
      {id: 593, name: 'SlopeStability', complete: 'CivilEngineering'},
      {id: 594, name: 'HighwayDesign', complete: 'CivilEngineering'},
      {
        id: 595,
        name: 'TroubleshootingroblemSolving}',
        complete: 'CivilEngineering'
      },
      {id: 596, name: 'Mathematics', complete: 'CivilEngineering'},
      {id: 597, name: 'HVAC', complete: 'CivilEngineering'},
      {id: 598, name: 'Wastewater', complete: 'CivilEngineering'},
      {id: 599, name: 'GoodDrivingRecord', complete: 'CivilEngineering'},
      {id: 600, name: 'SoilScience', complete: 'CivilEngineering'},
      {id: 601, name: 'DemandModeling', complete: 'CivilEngineering'},
      {id: 602, name: 'ProjectPlanning', complete: 'CivilEngineering'},
      {
        id: 603,
        name: 'ManufacturingEngineering',
        complete: 'CivilEngineering'
      },
      {id: 604, name: 'CommercialConstruction', complete: 'CivilEngineering'},
      {
        id: 605,
        name: 'EnvironmentalEngineering',
        complete: 'CivilEngineering'
      },
      {id: 606, name: 'Mapping', complete: 'CivilEngineering'},
      {id: 607, name: 'StructuralSystems', complete: 'CivilEngineering'},
      {
        id: 608,
        name: 'Maintenance/OperationsAndTransportation',
        complete: 'CivilEngineering'
      },
      {id: 609, name: 'AdobeIllustrator', complete: 'CivilEngineering'},
      {id: 610, name: 'PerformanceAppraisal', complete: 'CivilEngineering'},
      {id: 611, name: 'CostManagement', complete: 'CivilEngineering'},
      {id: 612, name: 'WaterDistribution', complete: 'CivilEngineering'},
      {
        id: 613,
        name: 'GlobalPositioningSystems',
        complete: 'CivilEngineering'
      },
      {id: 614, name: 'AutodeskRevit', complete: 'CivilEngineering'},
      {id: 615, name: 'GeometricDesign', complete: 'CivilEngineering'},
      {id: 616, name: 'ProjectCoordination', complete: 'CivilEngineering'},
      {id: 617, name: 'ProfessionalEngineer', complete: 'CivilEngineering'},
      {id: 618, name: 'Estimators', complete: 'CivilEngineering'},
      {id: 619, name: 'HydraulicEngineering', complete: 'CivilEngineering'},
      {id: 620, name: 'Ordinances', complete: 'CivilEngineering'},
      {id: 621, name: 'Seismology', complete: 'CivilEngineering'},
      {id: 622, name: 'TrafficFlow', complete: 'CivilEngineering'},
      {id: 623, name: 'GreenBuilding', complete: 'CivilEngineering'},
      {
        id: 624,
        name: 'ArchitecturalEngineering',
        complete: 'CivilEngineering'
      },
      {
        id: 625,
        name: 'ComputerSciences',
        complete: 'LogisticsandProcurement'
      },
      {id: 626, name: 'FoodSafety', complete: 'LogisticsandProcurement'},
      {
        id: 627,
        name: 'InternalControls',
        complete: 'LogisticsandProcurement'
      },
      {id: 628, name: 'Management', complete: 'LogisticsandProcurement'},
      {id: 629, name: 'RealEstate', complete: 'LogisticsandProcurement'},
      {id: 630, name: 'PMICertified', complete: 'LogisticsandProcurement'},
      {id: 631, name: 'FoodServices', complete: 'LogisticsandProcurement'},
      {
        id: 632,
        name: 'EnterpriseResourcePlanning',
        complete: 'LogisticsandProcurement'
      },
      {id: 633, name: 'Management', complete: 'LogisticsandProcurement'},
      {id: 634, name: 'DrugDevelopment', complete: 'LogisticsandProcurement'},
      {id: 635, name: 'Subcontracting', complete: 'LogisticsandProcurement'},
      {
        id: 636,
        name: 'BusinessRequirements',
        complete: 'LogisticsandProcurement'
      },
      {id: 637, name: 'LabourLaws', complete: 'LogisticsandProcurement'},
      {
        id: 638,
        name: 'ComputerSciences',
        complete: 'LogisticsandProcurement'
      },
      {id: 639, name: 'FoodSafety', complete: 'LogisticsandProcurement'},
      {
        id: 640,
        name: 'SoftwareDevelopment',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 641,
        name: 'InventoryManagement',
        complete: 'LogisticsandProcurement'
      },
      {
        id: 642,
        name: 'CardiopulmonaryResuscitation}',
        complete: 'LogisticsandProcurement'
      },
      {id: 643, name: 'CostReduction', complete: 'LogisticsandProcurement'},
      {
        id: 644,
        name: 'PropertyManagement',
        complete: 'LogisticsandProcurement'
      },
      {id: 645, name: 'Mitigation', complete: 'LogisticsandProcurement'},
      {
        id: 646,
        name: 'CustomerExperience',
        complete: 'LogisticsandProcurement'
      },
      {id: 647, name: 'Submittals', complete: 'LogisticsandProcurement'},
      {id: 648, name: 'Purchasing', complete: 'LogisticsandProcurement'},
      {id: 649, name: 'PublicPolicies', complete: 'LogisticsandProcurement'},
      {id: 650, name: 'BusinessProcess', complete: 'LogisticsandProcurement'},

      {id: 330, name: 'Sustainability', complete: 'Architecture'},
      {id: 331, name: 'BuiltEnvironment', complete: 'Architecture'},
      {id: 332, name: 'Biotechnology', complete: 'Architecture'},
      {id: 333, name: 'Horticulture', complete: 'Architecture'},
      {id: 334, name: 'ProfessionalEngineer', complete: 'Architecture'},
      {id: 335, name: 'PerformanceAppraisal', complete: 'Architecture'},
      {id: 336, name: 'PerformanceReview', complete: 'Architecture'},
      {id: 337, name: 'CriticalThinking', complete: 'Architecture'},
      {
        id: 338,
        name: 'CustomerRelationshipManagement',
        complete: 'Architecture'
      },
      {id: 339, name: 'ArcGIS', complete: 'Architecture'},
      {id: 340, name: 'Electronics', complete: 'Architecture'},
      {id: 341, name: 'RandomTesting', complete: 'Architecture'},
      {id: 342, name: 'GraphicCommunication', complete: 'Architecture'},
      {id: 343, name: 'MaterialSelection', complete: 'Architecture'},
      {id: 344, name: 'BuildingPerformance', complete: 'Architecture'},
      {id: 345, name: 'CivilSiteDesign', complete: 'Architecture'},
      {id: 346, name: 'Physics', complete: 'Architecture'},
      {id: 347, name: 'CurriculumDevelopment', complete: 'Architecture'},
      {id: 348, name: 'Illustration', complete: 'Architecture'},
      {id: 349, name: 'FloorPlanning', complete: 'Architecture'},
      {id: 350, name: 'CodeReview', complete: 'Architecture'},
      {id: 351, name: 'AutoCAD', complete: 'Architecture'},
      {id: 352, name: 'MarketEnvironment', complete: 'Architecture'},
      {id: 353, name: 'ArchitecturalDesign', complete: 'Architecture'},
      {id: 354, name: 'GraphicDesign', complete: 'Architecture'},
      {id: 355, name: 'Negotiation', complete: 'Architecture'},
      {id: 356, name: 'TechnicalDocumentation', complete: 'Architecture'},
      {id: 357, name: 'UrbanPlanning', complete: 'Architecture'},
      {id: 358, name: 'MarketingOperations', complete: 'Architecture'},
      {id: 359, name: 'Economics', complete: 'Architecture'},
      {id: 360, name: '3DComputerGraphicsSoftware', complete: 'Architecture'},
      {id: 361, name: 'LifeSafetyCodes', complete: 'Architecture'},
      {id: 362, name: 'Filing', complete: 'Architecture'},
      {id: 363, name: 'CivilEngineering', complete: 'Architecture'},
      {id: 364, name: 'Superstructure', complete: 'Architecture'},
      {id: 365, name: 'ElectricalSystems', complete: 'Architecture'},
      {id: 366, name: 'Conceptualization', complete: 'Architecture'},
      {id: 367, name: 'SpacePlanning', complete: 'Architecture'},
      {id: 368, name: 'DataCenters', complete: 'Architecture'},
      {id: 369, name: 'ErosionControl', complete: 'Architecture'},
      {
        id: 370,
        name: 'TroubleshootingroblemSolving}',
        complete: 'Architecture'
      },
      {id: 371, name: 'TakingMeetingMinutes', complete: 'Architecture'},
      {id: 372, name: 'Animations', complete: 'Architecture'},
      {id: 373, name: 'Prioritization', complete: 'Architecture'},
      {id: 374, name: 'ProjectManagementSoftware', complete: 'Architecture'},
      {
        id: 375,
        name: 'BiologicsLicenseApplicationsBLA}',
        complete: 'Architecture'
      },
      {id: 376, name: 'TestPlanning', complete: 'Architecture'},
      {id: 377, name: 'AuthorizationComputing}', complete: 'Architecture'},
      {id: 378, name: 'DesignThinking', complete: 'Architecture'},
      {id: 379, name: 'Teamwork', complete: 'Architecture'},
      {id: 380, name: 'DueDiligence', complete: 'Architecture'},
      {id: 381, name: 'Agriculture', complete: 'Architecture'},
      {id: 382, name: 'Geometry', complete: 'Architecture'},
      {id: 383, name: 'Bding', complete: 'Architecture'},
      {
        id: 384,
        name: 'DigitalModelingAndFabrication',
        complete: 'Architecture'
      },
      {id: 385, name: 'LinkAggregation', complete: 'Architecture'},
      {id: 386, name: 'InternationalBuildingCodes', complete: 'Architecture'},
      {id: 387, name: 'GreenInfrastructure', complete: 'Architecture'},
      {id: 388, name: 'Thermodynamics', complete: 'Architecture'},
      {id: 389, name: 'On-TimePerformance', complete: 'Architecture'},
      {id: 390, name: 'Maya', complete: 'Architecture'},
      {id: 391, name: 'ConceptArts', complete: 'Architecture'},
      {id: 392, name: 'GoodManufacturingPractices', complete: 'Architecture'},
      {id: 393, name: 'MicroStation', complete: 'Architecture'},
      {id: 394, name: 'StructuralAnalysis', complete: 'Architecture'},
      {id: 395, name: 'Renovation', complete: 'Architecture'},
      {id: 396, name: 'Computer-AedDesign', complete: 'Architecture'},
      {id: 397, name: 'ProjectManagementProcess', complete: 'Architecture'},
      {id: 398, name: 'Gardening', complete: 'Architecture'},
      {id: 399, name: 'ShipConstruction', complete: 'Architecture'},
      {id: 400, name: 'StructuralSystems', complete: 'Architecture'},
      {id: 401, name: 'AdobeColdFusion', complete: 'Architecture'},
      {id: 402, name: 'DocumentProduction', complete: 'Architecture'},
      {id: 403, name: 'StormwaterManagement', complete: 'Architecture'},
      {id: 404, name: 'VisualCommunications', complete: 'Architecture'},
      {id: 405, name: 'TechnicalSupport', complete: 'Architecture'},
      {id: 406, name: 'Calculations', complete: 'Architecture'},
      {id: 407, name: 'AdobeAcrobat', complete: 'Architecture'},
      {id: 408, name: 'LandscapeMaintenance', complete: 'Architecture'},
      {id: 409, name: 'FluMechanics', complete: 'Architecture'},
      {id: 410, name: 'ValDriversLicense', complete: 'Architecture'},
      {id: 411, name: 'ImageEditing', complete: 'Architecture'},
      {id: 412, name: 'CorporateDesign', complete: 'Architecture'},
      {id: 413, name: 'Mapping', complete: 'Architecture'},
      {id: 414, name: 'FiniteElementMethods', complete: 'Architecture'},
      {id: 415, name: 'CostEstimation', complete: 'Architecture'},
      {id: 416, name: 'InteriorArchitecture', complete: 'Architecture'},
      {id: 417, name: 'LifeSciences', complete: 'Architecture'},
      {id: 418, name: 'Erosions', complete: 'Architecture'},
      {id: 419, name: 'Hydrology', complete: 'Architecture'},
      {id: 420, name: 'Auditing', complete: 'Architecture'},
      {id: 421, name: 'LanguageTranslation	', complete: 'Architecture'},
      {id: 422, name: 'DocumentSet', complete: 'Architecture'},
      {id: 423, name: 'SoilScience', complete: 'Architecture'},
      {id: 424, name: 'Geology', complete: 'Architecture'},
      {id: 425, name: 'Procurement', complete: 'Architecture'},
      {id: 426, name: 'StrategicPlanning', complete: 'Architecture'},
      {id: 427, name: 'IndustryPractices', complete: 'Architecture'},
      {id: 428, name: 'LandscapePlanning', complete: 'Architecture'},
      {id: 429, name: 'EngineeringSupport', complete: 'Architecture'},
      {id: 430, name: 'Timelines', complete: 'Architecture'},
      {id: 431, name: 'Telecommunications', complete: 'Architecture'},
      {id: 432, name: 'ArchitecturalDevelopment', complete: 'Architecture'},
      {
        id: 433,
        name: 'AmericanInstituteOfCertifiedPlanners',
        complete: 'Architecture'
      },
      {id: 434, name: 'Chemistry', complete: 'Architecture'},
      {id: 435, name: 'EngineeringDesignProcess', complete: 'Architecture'},
      {
        id: 436,
        name: 'DesignElementsAndPrinciples',
        complete: 'Architecture'
      },
      {
        id: 437,
        name: 'ProfessionalDevelopmentPrograms',
        complete: 'Architecture'
      },
      {id: 438, name: 'SellingTechniques', complete: 'Architecture'},
      {id: 439, name: 'IndustrialDesign', complete: 'Architecture'},
      {id: 440, name: 'ProfessionalServices', complete: 'Architecture'},
      {id: 441, name: 'DesignStructureMatrix', complete: 'Architecture'},
      {id: 442, name: 'AcuteCare', complete: 'Architecture'},
      {id: 443, name: 'ElectricalEngineering', complete: 'Architecture'},
      {id: 444, name: 'Plumbing', complete: 'Architecture'},
      {
        id: 445,
        name: 'SystemsDevelopmentLifeCycle',
        complete: 'Architecture'
      },
      {id: 446, name: 'BusinessOperations', complete: 'Architecture'},
      {id: 447, name: 'FluDynamics', complete: 'Architecture'},
      {id: 448, name: 'FacilitiesEngineering', complete: 'Architecture'},
      {id: 449, name: 'PersuasiveCommunication', complete: 'Architecture'},
      {id: 450, name: 'TeamLeadership', complete: 'Architecture'},
      {
        id: 451,
        name: 'LicensedProfessionalEngineer',
        complete: 'Architecture'
      },
      {
        id: 452,
        name: 'CorrectiveAndPreventiveAction',
        complete: 'Architecture'
      },
      {id: 453, name: 'CustomerSatisfaction', complete: 'Architecture'},
      {id: 454, name: 'SocialWork', complete: 'Architecture'},
      {id: 455, name: 'Optics', complete: 'Architecture'},
      {id: 456, name: 'RealProperty', complete: 'Architecture'},
      {id: 457, name: 'Collections', complete: 'Architecture'},
      {id: 458, name: 'InterpersonalSkills', complete: 'Architecture'},
      {id: 459, name: 'IntegralCalculus', complete: 'Architecture'},
      {id: 460, name: 'Roofing', complete: 'Architecture'},
      {id: 461, name: 'Photography', complete: 'Architecture'},
      {id: 462, name: 'MicrosoftProject', complete: 'Architecture'},
      {id: 463, name: 'EngineeringPhysics', complete: 'Architecture'},
      {id: 464, name: 'FacilityManagement', complete: 'Architecture'},
      {id: 465, name: 'LaserCutting', complete: 'Architecture'},
      {id: 466, name: 'PrivateNetworks', complete: 'Architecture'},
      {id: 467, name: 'EngineeringDrawings', complete: 'Architecture'},
      {id: 468, name: 'DataProcessing', complete: 'Architecture'},
      {id: 469, name: 'BusinessStrategies', complete: 'Architecture'},
      {id: 470, name: 'ProjectFiles', complete: 'Architecture'},
      {id: 471, name: 'StructuralEngineering', complete: 'Architecture'},
      {id: 472, name: 'PolicyDevelopment', complete: 'Architecture'},
      {id: 473, name: 'HigherEducation', complete: 'Architecture'},
      {id: 474, name: 'Aviation', complete: 'Architecture'},
      {id: 475, name: 'SoilMechanics', complete: 'Architecture'},
      {id: 476, name: 'InformationManagement', complete: 'Architecture'},
      {id: 477, name: 'MarketingCollateral', complete: 'Architecture'},
      {id: 478, name: 'BusinessModel', complete: 'Architecture'},
      {id: 479, name: 'ExhibitDesign', complete: 'Architecture'},
      {id: 480, name: 'RequestForProposal', complete: 'Architecture'},
      {id: 481, name: 'ArchitecturalPlanning', complete: 'Architecture'},
      {id: 482, name: 'Microelectronics', complete: 'Architecture'},

      {
        id: 296,
        name: 'OrganizationalCommunications',
        complete: 'Architecture'
      },
      {id: 297, name: 'SiteAnalysis', complete: 'Architecture'},
      {id: 298, name: 'Hydraulics', complete: 'Architecture'},
      {id: 299, name: 'Writing', complete: 'Architecture'},
      {
        id: 300,
        name: 'Irrigation:LandscapingAndAgriculture}',
        complete: 'Architecture'
      },
      {id: 301, name: 'Propulsion', complete: 'Architecture'},
      {id: 302, name: 'FinancialManagement', complete: 'Architecture'},
      {id: 303, name: 'Rendering', complete: 'Architecture'},
      {id: 304, name: 'InformationModel', complete: 'Architecture'},
      {id: 305, name: 'ConstructionManagement', complete: 'Architecture'},
      {id: 306, name: 'Land-UsePlanning', complete: 'Architecture'},
      {id: 307, name: 'Shipyard', complete: 'Architecture'},
      {id: 308, name: 'Budgeting', complete: 'Architecture'},
      {id: 309, name: 'Sketching', complete: 'Architecture'},
      {id: 310, name: 'IntegratedDesign', complete: 'Architecture'},
      {
        id: 311,
        name: 'OrganizationalCommunications',
        complete: 'Architecture'
      },
      {id: 312, name: 'SiteAnalysis', complete: 'Architecture'},
      {id: 313, name: 'Hydraulics', complete: 'Architecture'},
      {id: 314, name: 'Writing', complete: 'Architecture'},
      {id: 315, name: 'SitePlanning', complete: 'Architecture'},
      {id: 316, name: 'SystemsDesign', complete: 'Architecture'},
      {id: 317, name: 'ProjectManagement', complete: 'Architecture'},
      {id: 318, name: 'Drawing', complete: 'Architecture'},
      {id: 319, name: 'Furnishing', complete: 'Architecture'},
      {id: 320, name: 'DevelopmentPlanning', complete: 'Architecture'},
      {id: 321, name: 'LandDevelopment', complete: 'Architecture'},
      {id: 322, name: 'ComputerSciences', complete: 'Architecture'},
      {id: 323, name: 'RegulatoryRequirements', complete: 'Architecture'},
      {id: 324, name: '3DModeling', complete: 'Architecture'},
      {id: 325, name: 'Creativity', complete: 'Architecture'},
      {id: 326, name: 'ArchitecturalDrawing', complete: 'Architecture'},
      {id: 327, name: 'UrbanDesign', complete: 'Architecture'},
      {id: 328, name: 'Shipbuilding', complete: 'Architecture'},
      {id: 329, name: 'SustainableDesign', complete: 'Architecture'}
    ];
  }
}
