import {
  Component,
  Input
  // EventEmitter,
  // Input,
  // Output,
  // SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {InstituteApiService} from '../../services/api/institute.api.service';
import {CryptoUtilities} from '../shared/utilities';
// import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material';
// import {Overlay} from '@angular/cdk/overlay';
@Component({
  selector: 'institute-component',
  template: require('./institute.component.html'),
  styles: [require('./institute.component.scss')]
})
export class InstituteComponent {
  constructor(
    private router: Router,
    private instituteApiService: InstituteApiService
  ) {}
  @Input('active') protected active: string;
  private instituteList: any[] = [];
  public allInstitute: any = [];
  private instituteId: string = '';
  public ngOnInit(): void {
    this.checkInstitutes();
    this.getAllInstitutes();
    // console.log(this.currentUser.id)
  }

  private checkInstitutes(): void {
    this.instituteApiService
      .promiseCheckInstitute()
      .then((userinstitute): any => {
        if (userinstitute) {
          let institutionId = this.cipher(userinstitute.institutionId);
          return this.router.navigate([
            '/institute-profile/' + institutionId + '/home'
          ]);
        }
      });
  }

  private getAllInstitutes(): void {
    this.instituteApiService.promiseGetAllInstitute().then((institute) => {
      this.allInstitute = institute;
    });
  }

  private cipher(id): string {
    return CryptoUtilities.cipher(id);
  }
  /**
   * Handle Submit
   */
  public handleSubmit(): Promise<boolean> | void {
    if (this.instituteId) {
      return this.router.navigate([
        '/institute-profile/' + this.instituteId + '/home'
      ]);
    }
  }
}
