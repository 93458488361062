import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService, TokenStore} from '../../../../../services';
import {UserModel} from '../../../models';
import {
  MessagesApiService,
  NotificationApiService
} from '../../../../../services/api';

@Component({
  selector: 'navbar-mobile-component',
  template: require('./mobile.component.html'),
  styles: [require('./mobile.component.scss')]
})
export class NavbarMobileComponent implements OnInit {
  constructor(
    private router: Router,
    private messagesApiService: MessagesApiService,
    private notificationApiService: NotificationApiService
  ) {}

  protected user: UserModel = UserService.getUser();
  protected unReadMessageCount: number = 0;
  protected unReadNotificationCount: number = 0;

  public ngOnInit(): void {}

  protected onSignOut(): void {
    TokenStore.expungeData();
    window.location.reload();
  }

  public ngAfterViewInit(): void {
    this.messagesApiService
      .promiseGetUnReadMessageCount()
      .then((response) => {
        if (response && response['data']) {
          this.unReadMessageCount = response['data'];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.notificationApiService
      .promiseGetUnReadNotificationCount()
      .then((response) => {
        if (response && response['data']) {
          this.unReadNotificationCount = response['data'];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
