import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  EventModel,
  OpportunityModel,
  ScholarshipModel
} from '../../app/shared/models';
import {ArticleModel} from '../../app/shared/models/article';

@Injectable()
export class DataShareService {
  private eventDetails$: BehaviorSubject<EventModel> = new BehaviorSubject(
    null
  );
  private articleDetails$: BehaviorSubject<ArticleModel> = new BehaviorSubject(
    null
  );
  private opportuninityDetails$: BehaviorSubject<ArticleModel> = new BehaviorSubject(
    null
  );
  private schlDetails$: BehaviorSubject<ScholarshipModel> = new BehaviorSubject(
    null
  );

  public getEventDetails(): Observable<EventModel> {
    return this.eventDetails$.asObservable();
  }

  public setEventDetails(data: EventModel): any {
    this.eventDetails$.next(data);
  }

  public getArticleDetails(): Observable<EventModel> {
    return this.articleDetails$.asObservable();
  }

  public setArticleDetails(data: EventModel): any {
    this.articleDetails$.next(data);
  }

  public getOpportuninityDetails(): Observable<OpportunityModel> {
    return this.opportuninityDetails$.asObservable();
  }

  public setOpportuninityDetails(data: OpportunityModel): any {
    this.opportuninityDetails$.next(data);
  }

  public getScholarship(): Observable<ScholarshipModel> {
    return this.schlDetails$.asObservable();
  }

  public setScholarship(data: ScholarshipModel): any {
    this.schlDetails$.next(data);
  }
}
