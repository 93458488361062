import { 
    InstituteModel,
    InstitutePostModel,
    InstituteStudentGroupModel
  } from './institute';
  
  export class InstituteFactory {
    public static create (data: any): InstituteModel {
      return <InstituteModel> (new InstituteModel ())
        .assimilate(data);
    }
  
    public static createMany (data: InstituteModel[]): InstituteModel[] {
      return data.map(
        instanceData => InstituteFactory.create(instanceData),
      );
    }
    
  public static createInstitutePost (data: any): InstitutePostModel {
    return <InstitutePostModel>new InstitutePostModel().assimilate(data);
  }
  
    public static createStudentGroup(data: any): InstituteStudentGroupModel {
      return <InstituteStudentGroupModel>(
        new InstituteStudentGroupModel().assimilate(data)
      );
    }
  
    public static createStudentGroupList(
      data: InstituteStudentGroupModel[]
    ): InstituteStudentGroupModel[] {
      return data.map((instanceData) =>
      InstituteFactory.createStudentGroup(instanceData)
      );
    }
 
    public static createManyInstitutePost(
      data: Array<InstitutePostModel>
    ): Array<InstitutePostModel> {
      return data.map((instanceData) =>
      InstituteFactory.createInstitutePost(instanceData)
      );
    }
  }
  