import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  MessageNotificationService,
  CheckProfileCompletionService
} from '../services';
import {routerTransition} from './animations';
import {MatDialog} from '@angular/material';
import {Overlay} from '@angular/cdk/overlay';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  template: require('./app.component.html'),
  styles: [require('./app.component.scss')],
  animations: [routerTransition]
})
export class AppComponent implements OnInit {
  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private overlay: Overlay,
    private checkProfileIncompletion: CheckProfileCompletionService
  ) {}

  protected loading: boolean;
  protected messageNotificationService: Array<any> = [];
  protected urlState: any;

  public ngOnInit(): void {
    MessageNotificationService.onShow.subscribe((messageNotification) => {
      this.messageNotificationService = [];
      for (let id of Object.keys(messageNotification.notifications)) {
        this.messageNotificationService.push(
          messageNotification.notifications[id]
        );
      }
    });

    // remove this
    // this.checkProfileIncompletion.getStatus().subscribe(value => {
    //   if (value.status === true && value.initialized !== true) {
    //     value.initialized = true;
    //     this.checkProfileIncompletion.setStatus(value);
    //     setTimeout(() => {
    //       this.openProfileCompleteDialog(value);
    //     });
    //   }
    // });
  }

  protected getState(outlet): void {
    this.urlState = outlet.activatedRouteData.state;
    return outlet.activatedRouteData.state;
  }

  protected getTitle(state, parent): any {
    let data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }

  // private openProfileCompleteDialog (value: ProfileCompleteModel): void {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.panelClass = 'complete-profile-modal-wrapper';
  //   dialogConfig.id = 'CompleteProfileDialogComponent';
  //   dialogConfig.disableClose = true;
  //   dialogConfig.scrollStrategy = this.overlay.scrollStrategies.block();
  //   dialogConfig.data = value;
  //   this.dialog.open(CompleteProfileDialogComponent, dialogConfig);
  // }
}
