import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpParams} from '@angular/common/http';
import {
  IResponse,
  CareerGuidanceQuestionnaireModel
} from '../../app/shared/models';

@Injectable()
export class CareerGuidanceQuestionnaireApiService extends ApiService {
  public options = {};
  public baseURI = 'user/career-guidance-questionnaire';
  public baseURIPlural = 'user/career-guidance-questionnaire';
  public usersURI = 'user/career-guidance-questionnaire';

  public promisePostCareerGuidanceQuestionnaire(
    data: CareerGuidanceQuestionnaireModel
  ): Promise<IResponse> {
    return this.promisePostModelData(`add`, data).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promisePutCareerGuidanceQuestionnaire(
    data: CareerGuidanceQuestionnaireModel
  ): Promise<IResponse> {
    return this.promisePutModelData(`update`, data).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promiseGetCareerGuidanceQuestionnaireData(): Promise<any> {
    return this.promiseGetAllResponseData('').then((response: IResponse) => {
      return response;
    });
  }
}
