import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpParams} from '@angular/common/http';
import {IResponse, UserPreferenceModel} from '../../app/shared/models';
import {UserFactory, PostFactory} from '../../app/shared/models/factory';

@Injectable()
export class UserPreferenceApiService extends ApiService {
  public options = {};
  public baseURI = 'user/user-preference';
  public baseURIPlural = 'user/user-preference';
  public usersURI = 'user/user-preference';

  public promisePostUserPreference(
    data: UserPreferenceModel
  ): Promise<IResponse> {
    return this.promisePostModelData(`add`, data).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promisePutUserPreference(
    data: UserPreferenceModel
  ): Promise<IResponse> {
    return this.promisePutModelData(`update`, data).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promiseGetUserPreferenceData(): Promise<any> {
    return this.promiseGetAllResponseData('').then((response: IResponse) => {
      return response;
    });
  }
}
