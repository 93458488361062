import { Model } from './model';

export class OpportunityModel extends Model {
    public id?: number;
    public title?: string;
    public about?: string;
    public howitswork?: string;
    public course_title?: string;
    public course_detail?: string;
    public career_interest?: string;
    public skills?: string;
    public instructor?: string;
    public enrolment_option?: string;
    public enrolment_deadline?: Date;
    public price?: string;
    public currency?: string;
    public source_link?: string;
    public instituteId?: number;
    public createAt?: Date;
    public updatedAt?: Date;

    public init (): void {
        this.setBlankDataStructure({
            id: undefined,
            title: undefined,
            instituteId: undefined,
            currency: '$'
        });
    }
}
