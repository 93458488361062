import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {
  CanActivateUserProfile,
  RedirectToOnboardingComponent,
  RedirectToHomeComponent,
  RedirectToIndexComponent,
  AuthGuard
} from './shared/can-activate';
import {InstituteComponent} from './institute/institute.component';
export const appRoutes: Routes = [
  {
    path: 'peers',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./peers/peers.module')['PeersModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {state: 'peers'}
  },
  {
    path: 'institute',
    component: InstituteComponent,
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile]
  },
  {
    path: 'institute-profile',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./institute-profile/institute-profile.module')['InstituteProfileModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'institute-profile'}
  },
  /* {
    path: 'campus',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./campus/campus.module')['CampusModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'campus'}
  }, */
  {
    path: 'community',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./community/community.module')['CommunityModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'community'}
  },
  {
    path: 'user',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./user/user.module')['UserModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [CanActivateUserProfile],
    data: {state: 'user'}
  },
  {
    path: 'profile',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./profile/profile.module')['ProfileModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'profile'}
  },
  {
    path: 'companies',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./company/company.module')['CompanyModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {state: 'company'}
  },
  {
    path: 'home',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./home/home.module')['HomeModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'home'
    }
  },
  /*  {
    path: 'leisure',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./leisure/leisure.module')['LeisureModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'leisure'}
  }, */
  {
    path: 'review-resume',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./review-resume/review-resume.module')['ReviewResumeModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'review-resume'}
  },
  {
    path: '',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./index/index.module')['IndexModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToHomeComponent],
    canActivate: [CanActivateUserProfile],
    data: {
      title: 'The Talent Discovery Network.'
    }
  },
  {
    path: 'sign-up',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./sign-up/sign-up.module')['SignUpModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToHomeComponent],
    canActivate: [CanActivateUserProfile],
    data: {
      state: 'sign-up',
      title: 'Sign up'
    }
  },
  {
    path: 'sign-in',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./sign-in/sign-in.module')['SignInModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToHomeComponent],
    canActivate: [CanActivateUserProfile],
    data: {
      state: 'sign-in',
      title: 'Sign in'
    }
  },
  {
    path: 'forgot-password',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./forgot-password/forgot-password.module')['ForgotPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToHomeComponent],
    canActivate: [CanActivateUserProfile],
    data: {
      state: 'forgot-password',
      title: 'Forgot Password'
    }
  },
  {
    path: 'reset-password',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./reset-password/reset-password.module')['ResetPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToHomeComponent],
    canActivate: [CanActivateUserProfile],
    data: {
      state: 'reset-password',
      title: 'Reset Password'
    }
  },
  {
    path: 'about-us',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./about-us/about-us.module')['AboutUsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'about-us',
      title: 'About Peersview'
    }
  },
  {
    path: 'employers',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./employers/employers.module')['EmployersModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'employers',
      title: 'Employers'
    }
  },
  {
    path: 'career',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./career/career.module')['CareerModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'career',
      title: 'Career Services'
    }
  },
  {
    path: 'contact-us',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./contact-us/contact-us.module')['ContactUsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'contact-us',
      title: 'Contact Us'
    }
  },
  {
    path: 'jobs',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./jobs-pub/jobs.module')['JobsPubModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'jobs-pub',
      title: 'Internships/Jobs'
    }
  },
  {
    path: 'support',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./help-center/help-center.module')['HelpCenterModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'help-center',
      title: 'Help Center'
    }
  },
  /* {
    path: 'digital-campus',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./digital-campus/digital-campus.module')['DigitalCampusModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'digital-campus',
      title: 'Peersview Digital Campus'
    }
  }, */
  {
    path: 'scholarship',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./scholarship/scholarship.module')['ScholarshipModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'scholarship',
      title: 'Peersview Scholarship'
    }
  },
  {
    path: 'job',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./job/job.module')['JobModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'Career Center',
      title: 'Career Center'
    }
  },
  {
    path: 'job-professional',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./job-professional/job.module')['JobProfessionalModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'premium-career-center',
      title: 'Premium Career Center'
    }
  },
  {
    path: 'blog',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./blog/blog.module')['BlogModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'blog',
      title: 'Peersview Blog'
    }
  },
  {
    path: 'advance-search',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./advance-search/advance-search.module')['AdvanceSearchModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {state: 'advance-search'}
  },
  {
    path: 'account-settings',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./account-settings/account-settings.module')['AccountSettingsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'account-settings'}
  },
  {
    path: 'terms-of-use-user',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./terms-of-use-user/terms-of-use-user.module')['TermsOfUseUserModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'terms-of-use-user',
      title: 'Service Terms | Peersview Digital Campus'
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./privacy-policy/privacy-policy.module')['PrivacyPolicyModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'privacy-policy',
      title: 'Privacy policy'
    }
  },
  {
    path: 'end-user-agreement',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./end-user-agreement/end-user-agreement.module')['EndUserAgreementModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'end-user-agreement',
      title: 'End User Agreement'
    }
  },
  {
    path: 'terms-of-service',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./terms-of-service/terms-of-service.module')['TermsOfServiceModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'terms-of-service',
      title: 'Terms of Service'
    }
  },
  {
    path: 'data-processing-agreement',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./data-processing-agreement/data-processing-agreement.module')['DataProcessingAgreementModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'data-processing-agreement',
      title: 'Data Processing Agreement'
    }
  },
  {
    path: 'notification',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./notification/notification.module')['NotificationModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [CanActivateUserProfile],
    data: {state: 'notification'}
  },
  {
    path: 'job-event-admin',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./job-event-admin/job-event-admin.module')['JobEventAdminModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {state: 'job-event-admin'}
  },
  /* {
    path: 'campus-admin',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./campus-admin/campus-admin.module')['CampusAdminModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'campus-admin'}
  }, */
  {
    path: 'terms-of-use',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./terms-of-use/terms-of-use.module')['TermsOfUseModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'terms-of-use',
      title: 'Terms of Use'
    }
  },
  {
    path: 'messages',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./messages/messages.module')['MessagesModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {state: 'messages'}
  },
  {
    path: 'create-ad',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./create-ad/create-ad.module')['CreateAdModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'create-ad',
      title: 'Create Ad'
    }
  },
  {
    path: 'career-evaluation',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./career-evaluation/career-evaluation.module')['CareerEvaluationModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'career-evaluation',
      title: 'Career Evaluation'
    }
  },
  {
    path: 'job-profile',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./job-profile/job-profile.module')['JobProfileModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      state: 'job-profile',
      title: 'Job Profile'
    }
  },
  {
    path: 'mentor',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./mentor/mentor.module')['MentorModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'mentor',
      title: 'Peersview Mentor'
    }
  },
  {
    path: 'institute-profile/mentor',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./mentor/mentor.module')['MentorModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    resolve: [RedirectToOnboardingComponent, RedirectToIndexComponent],
    canActivate: [AuthGuard, CanActivateUserProfile],
    data: {
      state: 'mentor',
      title: 'Peersview Mentor'
    }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
