import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
// import { EventModel, IResponse, InstituteSavedEventModel, EventDressCodesModel, EventTypeModel } from '../../app/shared/models';
// import { EventFactory, EventDressCodesFactory, EventTypeFactory } from '../../app/shared/models/factory';
import {HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {EventModel, IResponse} from '../../app/shared/models';
import {EventFactory} from '../../app/shared/models/factory';
@Injectable()
export class EventApiService extends ApiService {
  public eventformValue = new BehaviorSubject<any>([]);

  public options = {};
  public baseURI = 'instituteevent';
  public baseURIPlural = 'instituteevents';

  // public promiseGetAllEventDressCodes(): Promise<EventDressCodesModel[]> {
  // 	return this.promiseGetAllResponseData('dress-codes')
  // 		.then((response: IResponse) => {
  // 			return EventDressCodesFactory.createMany(response.data);
  // 		});
  // }

  // public promiseGetEventType(): Promise<EventTypeModel[]> {
  // 	return this.promiseGetAllResponseData('event-types')
  // 		.then((response: IResponse) => {
  // 			return EventTypeFactory.createMany(response.data);
  // 		});
  // }

  // public promiseGetEvents(limit?: number, offset?: number, city?: string): Promise<EventModel[]> {
  //     let params: HttpParams;

  //     params = new HttpParams()
  //         .set('limit', limit.toString())
  //         .set('offset', offset.toString());
  //     if (city && city !== '') {
  //         params = params.append('city', city);
  //     }
  //     this.options = {
  //         params: params
  //     };
  //     return this.promiseGetAllResponseData()
  //         .then((response: IResponse) => {
  //             return EventFactory.createManyEvent(response.data);
  //         });
  // }

  public promiseGetInstituteEvent(
    instituteId?: number,
    limit?: number,
    offset?: number
  ): Promise<EventModel[]> {
    let params: HttpParams;

    if (instituteId) {
      params = new HttpParams()
        .set('limit', limit.toString())
        .set('offset', offset.toString())
        .set('instituteId', instituteId.toString());
    } else {
      params = new HttpParams()
        .set('limit', limit.toString())
        .set('offset', offset.toString());
    }
    this.options = {
      params: params
    };
    return this.promiseGetAllResponseData(`institute-events`).then(
      (response: IResponse) => {
        return EventFactory.createManyEvent(response.data);
      }
    );
  }

  public promiseGetAllSearchedEvents(
    searchString: string,
    instituteId?: number,
    limit: number = 10,
    offset: number = 0
  ): Promise<EventModel[]> {
    // console.log('service call hui', searchString);
    let params = new HttpParams()
      .set('keyword', searchString.toString())
      .set('limit', limit.toString())
      .set('offset', offset.toString())
      .set('instituteId', instituteId.toString());
    this.options = {
      params: params
    };

    return this.promiseGetAllResponseData(`institute-events`).then(
      (response: IResponse) => {
        return EventFactory.createManyEvent(response.data);
      }
    );
  }

  // public promiseGetInstituteEvent(limit?: number, offset?: number): Promise<EventModel[]> {
  //     let params: HttpParams;
  //     {
  //         params = new HttpParams()
  //             .set('limit', limit.toString())
  //             .set('offset', offset.toString());
  //     }
  //     this.options = {
  //         params: params
  //     };
  //     return this.promiseGetAllResponseData(`institute-events`)
  //         .then((response: IResponse) => {
  //             return EventFactory.createManyEvent(response.data);
  //         });
  // }

  // public promiseGetEvent(eventId?: number): Promise<EventModel> {
  //     return this.promiseGetResponseData(`get/${eventId}`)
  //         .then((response: IResponse) => {
  //             return EventFactory.createEvent(response.data);
  //         });
  // }

  // public promisePostEvent(event: EventModel): Promise<IResponse> {
  //     return this.promisePostModelData(``, event)
  //         .then((response: IResponse) => {
  //             return response;
  //         });
  // }

  // public promiseSaveEvent(filter: InstituteSavedEventModel): Promise<IResponse> {
  //     return this.promisePostModelData(`save/filterdata`, filter)
  //         .then((response: IResponse) => {
  //             return response;
  //         });
  // }

  // public promiseRemoveEvent(eventId: number): Promise<IResponse> {
  //     return this.promiseRemoveData(`${eventId}`)
  //         .then((response: IResponse) => {
  //             return response;
  //         });
  // }

  // public promiseUpdateEvent(event: EventModel, eventId: number): Promise<IResponse> {
  //     return this.promisePostModelData(`${eventId}`, event)
  //         .then((response: IResponse) => {
  //             return response;
  //         });
  // }
}
