import {Component} from '@angular/core';

@Component({
  selector: 'index-footer-component',
  template: require('./footer.component.html'),
  styles: [require('./footer.component.scss')]
})
export class IndexFooterComponent {
  constructor() {}
}
