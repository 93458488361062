import {createCipher, createDecipher} from 'crypto';

export class CryptoUtilities {
  public static cipher(value: any): string {
    const cipher = createCipher('aes192', 'sample cipher here');
    let encrypted = cipher.update(value.toString(), 'utf8', 'hex');
    encrypted += cipher.final('hex');
    return encrypted;
  }
  public static decipher(value: any): string {
    const decipher = createDecipher('aes192', 'sample cipher here');
    let decrypted = decipher.update(value.toString(), 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  }
}
