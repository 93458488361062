import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '../../../models';
import {TokenStore, UserService} from '../../../../../services';
import {MatDialogConfig, MatDialog} from '@angular/material';
import {SharedCreateMessageComponent} from '../../../modals';
import {
  MessagesApiService,
  NotificationApiService
} from '../../../../../services/api';

@Component({
  selector: 'navbar-desktop-component',
  template: require('./desktop.component.html'),
  styles: [require('./desktop.component.scss')]
})
export class NavbarDesktopComponent {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private messagesApiService: MessagesApiService,
    private notificationApiService: NotificationApiService
  ) {}

  protected user: UserModel = UserService.getUser();
  protected keyword: string = null;
  protected unReadMessageCount: number = 0;
  protected unReadNotificationCount: number = 0;

  protected onSignOut(): void {
    TokenStore.expungeData();
    window.location.reload();
  }

  public ngAfterViewInit(): void {
    this.messagesApiService
      .promiseGetUnReadMessageCount()
      .then((response) => {
        if (response && response['data']) {
          this.unReadMessageCount = response['data'];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.GetNotificationCount();
  }

  protected GetNotificationCount(): void {
    // console.log('enter notification');
    this.notificationApiService
      .promiseGetUnReadNotificationCount()
      .then((response) => {
        // console.log('notification count', this.unReadNotificationCount);
        if (response && response['data']) {
          this.unReadNotificationCount = response['data'];
          // console.log('notification', this.unReadNotificationCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  protected onNewMessageClick(): void {
    let dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'share-post-modal';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'this.post';
    dialogConfig.id = 'SharePostModalComponent';
    this.dialog.open(SharedCreateMessageComponent, dialogConfig);
  }
}
