import { OpportunityModel } from './opportunity';

export class OpportunityFactory {
  public static createOpportunity (data: any): OpportunityModel {
    return <OpportunityModel> (new OpportunityModel ())
      .assimilate(data);
  }

  public static createManyOpportunity (data: Array<OpportunityModel>): Array<OpportunityModel> {
    if (data !== undefined) {
      return data.map(
        instanceData => OpportunityFactory.createOpportunity(instanceData),
      );
    } else {
      return null;
    }
  }
}
