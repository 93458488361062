import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UserApiService} from '../../../../services/api';
import {UserService} from '../../../../services/user.service';
import {UserModel} from '../../../shared/models/user';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-account-link',
  template: require('./account-link.component.html'),
  styles: [require('./account-link.component.css')]
})
export class AccountLinkComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private userApiService: UserApiService,
    private dialog: MatDialog
  ) {
    this.formData.user = UserService.getUser();
    this.modalParams = data;
    if (data.type === 'facebook') {
      this.formData.link = UserService.getUser().facebook_profile;
    } else if (data.type === 'twitter') {
      this.formData.link = UserService.getUser().twitter_profile;
    } else if (data.type === 'instagram') {
      this.formData.link = UserService.getUser().instagram_profile;
    } else if (data.type === 'linkedIn') {
      this.formData.link = UserService.getUser().snapchat_profile;
    }

    if (this.formData.link !== '') {
      this.buttonLabel = 'Update';
    }
  }
  public modalInstance: any;
  public accountType: string;
  public modalParams: any;
  public buttonLabel: string = 'Add';
  public formData = {
    user: {},
    type: '',
    link: ''
  };
  public ngOnInit(): void {}

  public updateSocialAccountLink(): void {
    this.modalInstance = this.dialog.getDialogById('AccountLinkModal');
    this.formData.type = this.modalInstance._containerInstance._config.data.type;
    this.userApiService
      .promiseUpdateSocialLinks(this.formData)
      .then((res) => {
        this.dialog.closeAll();
      })
      .catch((err) => {
        console.log('Something went wrong.');
      });
  }
}
