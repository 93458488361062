import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {SharedDirectiveModule} from './shared/directives/shared-directive.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
  MatDialogModule,
  MAT_DIALOG_DATA
} from '@angular/material';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule
} from '@angular/material';
import {AdsenseModule} from 'ng2-adsense';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxLinkifyjsModule} from 'ngx-linkifyjs';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  CampusApiService,
  CourseApiService,
  InterestApiService,
  PostApiService,
  UserApiService,
  AdvanceSearchService,
  MessagesApiService,
  CommunityApiService,
  NotificationApiService,
  SkillApiService,
  RelevantSkillApiService,
  JobApiService,
  ScholarshipApiService,
  CampusAdminApiService,
  IndustryApiService,
  BlogApiService,
  UserPreferenceApiService,
  CareerGuidanceQuestionnaireApiService,
  PracticeVideoInterviewApiService,
  SavedJobApiService,
  ManualJobApiService,
  CompanyApiService,
  CareerEvaluationApiService
} from '../services/api';
import {UtilitiesService, TitleService, MetaService} from '../services';
import {AuthInterceptor} from '../interceptors/authinterceptor';
import {RouterModule} from '@angular/router';
import 'tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

import {
  SharedSharePostModalComponent,
  SharedViewPostModalComponent,
  ReportPostModalComponent,
  SharedConfirmModalComponent,
  SharedImagePreviewComponent,
  SharedCreateMessageComponent,
  SharedPostDetailModalComponent,
  BecomeMentorModalComponent,
  SharedPostLikeDetailModalComponent,
  MentorModalComponent,
  CreateCommunityComponent,
  ComunityMobileAskQuestionMobileComponent,
  ReportPostCommunityModalComponent,
  SharedCommunityPostReplyComponent,
  SharedPostReplyCommentModalComponent,
  SharedResumeReviewModalComponent,
  CompleteProfileDialogComponent,
  JobFilterDialogComponent
} from './shared/modals';
import {InstituteComponent} from './institute/institute.component';
import {SharedModule} from './shared/components/shared.module';
import {
  CanActivateUserProfile,
  RedirectToOnboardingComponent,
  RedirectToHomeComponent,
  RedirectToIndexComponent,
  AuthGuard
} from './shared/can-activate';
import {SharedSetRatingsModalComponent} from './shared/components/set-ratings-modal/set-ratings.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReviewApiService} from '../services/api/review.api.service';
import {TokenStore} from '../services';
import {CareerGuidanceReview} from '../services/api/career-guidance-review.api.service';
import {AccountLinkComponent} from './shared/modals/account-link/account-link.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {InstituteApiService} from '../services/api/institute.api.service';
import {EventApiService} from '../services/api/event.api.service';
import {ArticleApiService} from '../services/api/article.api.service';
import {OpportunityApiService} from '../services/api/opportunity.api.service';
import {DataShareService} from '../services/api/share-data.service';
import {HomePostService} from '../services/api/home-post.api.service';
import {InstituteService} from '../services/institute.service';

declare var tinymce: any;
tinymce.init({});

@NgModule({
  imports: [
    NgbModule.forRoot(),
    BrowserModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    SharedDirectiveModule,
    NgxSpinnerModule,
    NgxLinkifyjsModule.forRoot({
      enableHash: false,
      enableMention: false
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-3739099434887146',
      adSlot: 8915141538
    })

    // CKEditorModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: []
    },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ArticleApiService,
    CanActivateUserProfile,
    AuthGuard,
    RedirectToOnboardingComponent,
    RedirectToHomeComponent,
    RedirectToIndexComponent,
    CampusApiService,
    CourseApiService,
    HomePostService,
    DataShareService,
    EventApiService,
    OpportunityApiService,
    InterestApiService,
    PostApiService,
    UserApiService,
    SkillApiService,
    RelevantSkillApiService,
    JobApiService,
    ManualJobApiService,
    SavedJobApiService,
    ScholarshipApiService,
    MessagesApiService,
    NotificationApiService,
    UtilitiesService,
    TitleService,
    MetaService,
    InstituteService,
    Title,
    AdvanceSearchService,
    UtilitiesService,
    CommunityApiService,
    CampusAdminApiService,
    IndustryApiService,
    BlogApiService,
    UserPreferenceApiService,
    CareerGuidanceQuestionnaireApiService,
    PracticeVideoInterviewApiService,
    CompanyApiService,
    ReviewApiService,
    CareerEvaluationApiService,
    InstituteApiService,
    TokenStore,
    CareerGuidanceReview
  ],
  declarations: [
    AppComponent,
    SharedPostDetailModalComponent,
    AccountLinkComponent,
    BecomeMentorModalComponent,
    // ProfileLeftSidebarUserInfoMessageDiaglogComponent
    SharedViewPostModalComponent,
    ReportPostModalComponent,
    SharedSharePostModalComponent,
    SharedConfirmModalComponent,
    SharedResumeReviewModalComponent,
    SharedCreateMessageComponent,
    SharedImagePreviewComponent,
    SharedPostLikeDetailModalComponent,
    MentorModalComponent,
    CreateCommunityComponent,
    ComunityMobileAskQuestionMobileComponent,
    ReportPostCommunityModalComponent,
    SharedCommunityPostReplyComponent,
    SharedPostReplyCommentModalComponent,
    CompleteProfileDialogComponent,
    InstituteComponent
  ],
  exports: [],
  entryComponents: [
    SharedSharePostModalComponent,
    SharedPostDetailModalComponent,
    AccountLinkComponent,
    BecomeMentorModalComponent,
    SharedViewPostModalComponent,
    ReportPostModalComponent,
    SharedConfirmModalComponent,
    SharedImagePreviewComponent,
    SharedCreateMessageComponent,
    SharedPostLikeDetailModalComponent,
    MentorModalComponent,
    SharedResumeReviewModalComponent,
    CreateCommunityComponent,
    ComunityMobileAskQuestionMobileComponent,
    ReportPostCommunityModalComponent,
    SharedCommunityPostReplyComponent,
    SharedPostReplyCommentModalComponent,
    SharedSetRatingsModalComponent,
    CompleteProfileDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private titleService: TitleService,
    private metaService: MetaService
  ) {
    this.titleService.init();
    this.metaService.init();
  }
}
