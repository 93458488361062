import {Component, Inject, OnInit, HostListener} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReviewApiService} from '../../../../services/api/review.api.service';
import {
  MessageNotificationService,
  NotificationTypes,
  CloudinaryUploadService
} from '../../../../services';

@Component({
  selector: 'shared-resume-review-modal-component',
  template: require('./resume-review-modal.component.html'),
  styles: [require('./resume-review-modal.component.scss')]
})
export class SharedResumeReviewModalComponent {
  constructor(
    private reviewApiService: ReviewApiService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) protected confirmActionData: any,
    private dialog: MatDialog,
    private cloudinaryUploadService: CloudinaryUploadService
  ) {}

  protected reviewForm: FormGroup;

  protected submitted = false;
  protected loading = false;
  protected success = '';
  protected error = '';
  protected attachment: File = null;
  protected attachmentName = '';

  protected dragAreaClass: string;
  protected onFileChange(event: any): void {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

  protected ngOnInit(): void {
    this.createForm();
    this.dragAreaClass = 'dragarea';
  }

  @HostListener('dragover', ['$event']) protected onDragOver(event: any): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) protected onDragEnter(
    event: any
  ): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) protected onDragEnd(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) protected onDragLeave(
    event: any
  ): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) protected onDrop(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  protected saveFiles(files: FileList): void {
    this.reviewForm.patchValue({attachment: ''});
    if (files.length > 1) {
      this.error = 'Only one file at time allow';
    } else if (
      files[0].type !== 'application/pdf' &&
      files[0].type !== 'application/msword' &&
      files[0].type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      this.error = 'This file format is not supported';
    } else {
      this.attachment = files[0];
      this.attachmentName = files[0].name;
      this.reviewForm.patchValue({attachment: this.attachmentName});
      this.error = '';
      // console.log(files[0].size, files[0].name, files[0].type);
    }
  }

  protected get f(): any {
    return this.reviewForm.controls;
  }

  protected createForm(): void {
    this.reviewForm = this.formBuilder.group({
      type: ['1'],
      company_name: [this.confirmActionData.company_name, Validators.required],
      job_post_url: [this.confirmActionData.job_post_url, Validators.required],
      job_description: [
        this.confirmActionData.job_description,
        Validators.required
      ],
      attachment: ['', Validators.required]
    });
  }

  protected attachmentUpload(fileInput: any): void {
    this.attachment = <File>fileInput.target.files[0];
  }

  protected onSubmit(): void {
    const controls = this.reviewForm.controls;
    this.submitted = true;
    // stop here if form is invalid
    if (this.reviewForm.invalid) {
      return;
    }
    this.loading = true;
    this.success = '';
    this.error = '';
    let folderName = 'resume';
    if (this.f.type.value === 2) {
      folderName = 'cover';
    }
    this.cloudinaryUploadService
      .uploadFileToCloudinary(this.attachment, folderName)
      .then((res: any) => {
        const formData = this.reviewForm.value;
        formData.attachment = res.secure_url;
        this.reviewApiService
          .promiseUploadReview(formData)
          .then(() => {
            this.onCancel();
            MessageNotificationService.show(
              {
                notification: {
                  id: 'add-review-success',
                  message:
                    'Please Check Your Email For The Result Of This Review.',
                  instruction: ''
                }
              },
              NotificationTypes.Success
            );
          })
          .catch((error) => {
            MessageNotificationService.show(
              {
                notification: {
                  id: 'add-review-error',
                  message: 'Can not add review.',
                  instruction: ''
                }
              },
              NotificationTypes.Error
            );
          });
      })
      .catch(() => {});
  }

  public chnageReviewType(reviewType): void {
    this.f.type.setValue(reviewType);
  }

  protected onCancel(): void {
    this.dialog.closeAll();
  }
}
