import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpParams} from '@angular/common/http';
import {
  InstituteModel,
  InstitutePostModel,
  InstituteStudentGroupModel
} from '../../app/shared/models/institute';
import {IResponse} from '../../app/shared/models';
import {InstituteFactory} from '../../app/shared/models/institute.factory';

@Injectable()
export class InstituteApiService extends ApiService {
  public options = {};
  public baseURI = 'institute';
  public baseURIPlural = 'institute';

  public promiseGetInstitute(instituteId: number): Promise<InstituteModel> {
    if (instituteId) {
      let params = new HttpParams().set('instituteId', instituteId.toString());
      this.options = {
        params: params
      };
    }

    return this.promiseGetResponseData('profile-front').then(
      (response: IResponse) => {
        return InstituteFactory.create(response.data);
      }
    );
  }

  public promiseCheckInstitute(): Promise<any> {
    return this.promiseGetAllResponseData('getuserinstitute').then(
      (response: IResponse) => {
        return response.data;
      }
    );
  }

  public promiseGetAllInstitute(): Promise<InstituteModel[]> {
    return this.promiseGetAllResponseData('getallinstitute').then(
      (response: IResponse) => {
        return InstituteFactory.createMany(response.data);
      }
    );
  }

  public promiseRegister(institute: InstituteModel): Promise<IResponse> {
    return this.promisePostModelData('register', institute).then(
      (responseData: IResponse) => {
        return responseData;
      }
    );
  }

  public promiseSignIn(institute: InstituteModel): Promise<InstituteModel> {
    return this.promisePostModelData('login', institute).then(
      (response: IResponse) => {
        return InstituteFactory.create(response.data);
      }
    );
  }

  public promiseUpdateProfilePicture(user: InstituteModel): Promise<IResponse> {
    return this.promisePutModelData('institute-logo', user).then(
      (responseData: IResponse) => {
        return responseData;
      }
    );
  }

  public promiseUpdateBanner(user: InstituteModel): Promise<IResponse> {
    return this.promisePutModelData('institute-banner', user).then(
      (responseData: IResponse) => {
        return responseData;
      }
    );
  }

  public promiseUpdateInstituteName(user: InstituteModel): Promise<any> {
    return this.promisePutModelData('institute-name', user).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promiseUpdateSupport(user: InstituteModel): Promise<any> {
    return this.promisePutModelData('support', user).then(
      (response: IResponse) => {
        return response;
      }
    );
  }

  public promiseCreateStudentGroup(
    instituteId: number,
    instituteStudentGroup: InstituteStudentGroupModel
  ): Promise<IResponse> {
    return this.promisePostModelData(
      `${instituteId}/student-group`,
      instituteStudentGroup
    ).then((responseData: IResponse) => {
      return responseData;
    });
  }

  public promiseGetAllStudentGroup(
    instituteId: number,
    isMyGroup = true,
    limit: number = 10,
    offset: number = 0,
    institutePrivacyId: number
  ): Promise<InstituteStudentGroupModel[]> {
    let params = new HttpParams()
      .set('isMyGroup', isMyGroup.toString())
      .set('limit', limit.toString())
      .set('offset', offset.toString())
      .set('institutePrivacyId', institutePrivacyId.toString());

    this.options = {
      params: params
    };

    return this.promiseGetAllResponseData(`${instituteId}/student-groups`).then(
      (response: IResponse) => {
        return InstituteFactory.createStudentGroupList(response.data);
      }
    );
  }

  public promiseGetGroupDetails(
    instituteId: number,
    groupId: number
  ): Promise<InstituteStudentGroupModel> {
    return this.promiseGetResponseData(`${instituteId}/${groupId}/groups`).then(
      (response: IResponse) => {
        return response.data;
      }
    );
  }

  public promiseGetAllPost(
    instituteId: number,
    groupId: number,
    limit: number = 10,
    offset: number = 0
  ): Promise<InstitutePostModel[]> {
    // console.log('instituteId', instituteId);

    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    this.options = {
      params: params
    };

    return this.promiseGetAllResponseData(
      `${instituteId}/${groupId}/posts`
    ).then((response: IResponse) => {
      return InstituteFactory.createManyInstitutePost(response.data);
    });
  }

  public promiseCreatePost(
    instituteId: number,
    groupId: number,
    institutePost: InstitutePostModel
  ): Promise<InstitutePostModel> {
    return this.promisePostModelData(
      `${instituteId}/${groupId}/posts`,
      institutePost
    ).then((response: IResponse) => {
      return InstituteFactory.createInstitutePost(response.data);
    });
  }

  public promiseGetAllJobs(
    instituteId: number,
    groupId: number,
    institutePost: InstitutePostModel
  ): Promise<InstitutePostModel> {
    return this.promisePostModelData(
      `${instituteId}/${groupId}/pos`,
      institutePost
    ).then((response: IResponse) => {
      return InstituteFactory.createInstitutePost(response.data);
    });
  }

  public promiseGetJsonForLinkPreview(url: string): Promise<IResponse> {
    return this.promiseGetJsonDataForLinkPreview(
      `link-preview/${encodeURI(url)}`
    ).then((res: IResponse) => {
      return res;
    });
  }

  public promiseGetAllIvents(
    instituteId: number,
    groupId: number
  ): Promise<InstitutePostModel> {
    return this.promisePostModelData(`${instituteId}/${groupId}/pos`).then(
      (response: IResponse) => {
        return InstituteFactory.createInstitutePost(response.data);
      }
    );
  }
}
