export class SpecialityService {
  public static getspecialty(): any {
    return [
      {
        id: 1,
        name: 'Accounting'
      },

      {
        id: 7,
        name: 'Biotech and Life sciences'
      },

      {
        id: 22,
        name: 'Healthcare'
      },

      {
        id: 24,
        name: 'Hotels & Accommodation'
      },

      {
        id: 35,
        name: 'Management consulting'
      },

      {
        id: 37,
        name: 'Medical devices'
      },

      {
        id: 46,
        name: 'Pharmaceuticals'
      },

      {
        id: 59,
        name: 'Veterinary'
      },

      {
        id: 63,
        name: 'E-commerce'
      },

      {
        id: 67,
        name: 'Business Development'
      },

      {
        id: 74,
        name: 'Dentistry'
      },
      {
        id: 75,
        name: 'Medical services'
      },

      {
        id: 81,
        name: 'Physiology'
      },
      {
        id: 82,
        name: 'Psychology'
      },
      {
        id: 83,
        name: 'Wellness'
      }
    ];
  }
}
