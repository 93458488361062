/**
 * This component is for user's followee
 * which the current login user follows
 */
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserApiService} from '../../../../services/api';
import {UserModel} from '../../../shared/models';
import {CryptoUtilities} from '../../../shared/utilities';
import {UserService} from '../../../../services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'shared-followee-component',
  template: require('./followee.component.html'),
  styles: [require('./followee.component.scss')]
})
export class SharedFolloweeComponent implements OnInit {
  constructor(
    private userApiService: UserApiService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.getUserFollowee();
  }

  @ViewChild('content') private content;
  @Input() protected followees: any;
  private modalReference = null;
  private user: UserModel = UserService.getOtherUser() || UserService.getUser();
  public followeeModal = false;

  public ngOnInit(): void {}

  private getUserFollowee(): void {
    this.userApiService
      .promiseGetFollowees(this.user.id)
      .then((followees: any) => {
        this.followees = followees;
        // console.log('followees', this.followees);
      })
      .catch(() => {});
  }

  protected onClickUserProfile(user): Promise<boolean> {
    let userId = CryptoUtilities.cipher(user.id);
    let currentLoginUser = UserService.getUser();

    if (user.id === currentLoginUser.id) {
      return this.router.navigate([`/profile`]);
    }

    return this.router.navigate([`/profile/${userId}`]);
  }

  public openFollowee(): void {
    this.followeeModal = true;
    // var modal = document.getElementById("myModal");
    // modal.style.display = "block";
    // console.log('followees', this.followees[0].follower);
  }

  public openModal(): void {
    // // console.log("id",id)
    this.modalReference = this.modalService.open(this.content, {
      backdrop: 'static',
      keyboard: false
    });
  }
  public closeModal(): void {
    this.modalReference.close();
  }
  public onClose(): void {
    this.followeeModal = false;
  }
}
