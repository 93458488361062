import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpParams} from '@angular/common/http';
import {IResponse} from '../../app/shared/models';

@Injectable()
export class ReviewApiService extends ApiService {
  public options = {};
  public baseURI = 'resume-review';
  public baseURIPlural = 'resume-review';
  public usersURI = 'resume-review';

  public promiseUploadReview(data: any): Promise<any> {
    return this.promisePostData('add', data).then((responseData: IResponse) => {
      return responseData;
    });
  }
}
