import { Model } from './model';

export class EventModel extends Model {
    public id?: number;
    public title?: string;
    public description?: string;
    public eventImage?: string;
    public startDate?: Date;
    public endDate?: Date;
    public ticketSalesEndDate?: Date;
    public venueAddress?: string;
    public ticketPrice?: number;
    public organizerContactDetails?: string;
    public eventURL?: string;
    public eventDressCodeId?: number;
    public eventTypeId?: number;
    public cityId?: number;
    public instituteId?: number;
    public createAt?: Date;
    public updatedAt?: Date;
    public city?: any;

    public init (): void {
        this.setBlankDataStructure({
            id: undefined,
            title: undefined,
            instituteId: undefined,
            company: undefined
        });
    }
}
