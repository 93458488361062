import { Model } from './model';

export class ArticleModel extends Model {
    public id?: number;
    public title?: string;
    public content?: string;
    public instituteId?: number;
    public createAt?: Date;
    public updatedAt?: Date;

    public init (): void {
        this.setBlankDataStructure({
            id: undefined,
            title: undefined,
            instituteId: undefined,
            content: undefined
        });
    }
}
