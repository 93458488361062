import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {IResponse } from '../../app/shared/models';
import { OpportunityFactory } from '../../app/shared/models/factory';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { OpportunityModel } from '../../app/shared/models/opportunity';

@Injectable()
export class OpportunityApiService extends ApiService {
    public opportunityformValue = new BehaviorSubject<any>([]);

    public options = {};
    public baseURI = 'opportunity';
    public baseURIPlural = 'opportunities';


    public promiseGetInstituteOpportunity(instituteId?: number, limit?: number, offset?: number): Promise<OpportunityModel[]> {
        let params: HttpParams;
        if (instituteId) {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString())
                .set('instituteId', instituteId.toString());
        } else {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString());
        }
        this.options = {
            params: params
        };
        return this.promiseGetAllResponseData(`institute-opportunities`)
            .then((response: IResponse) => {
                return OpportunityFactory.createManyOpportunity(response.data);
            });
    }

    // public promiseGetOpportunity(opportunityId?: number): Promise<OpportunityModel> {
    //     return this.promiseGetResponseData(`get/${opportunityId}`)
    //         .then((response: IResponse) => {
    //             return OpportunityFactory.createOpportunity(response.data);
    //         });
    // }

    // opportunities/institute-opportunities
    public promiseGetOpportunity(instituteId?: number, limit?: number, offset?: number): Promise<OpportunityModel[]> {
        let params: HttpParams;
        if (instituteId) {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString())
                .set('instituteId', instituteId.toString());
        } else {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString());
        }
        this.options = {
            params: params
        };
        return this.promiseGetResponseData(`institute-opportunities`)
            .then((response: IResponse) => {
                return OpportunityFactory.createManyOpportunity(response.data);
            });
    }

    public promisePostOpportunity(opportunity: OpportunityModel): Promise<IResponse> {
        return this.promisePostModelData(``, opportunity)
            .then((response: IResponse) => {
                return response;
            });
    }

    public promiseRemoveOpportunity(opportunityId: number): Promise<IResponse> {
        return this.promiseRemoveData(`${opportunityId}`)
            .then((response: IResponse) => {
                return response;
            });
    }

    public promiseUpdateOpportunity(opportunity: OpportunityModel, opportunityId: number): Promise<IResponse> {
        return this.promisePostModelData(`${opportunityId}`, opportunity)
            .then((response: IResponse) => {
                return response;
            });
    }
}
