import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ApiService} from '../api.service';
import {
  CampusModel,
  CampusPostModel,
  CampusPostReplyModel,
  CampusFreshersFeedModel,
  CampusFreshersFeedPostModel,
  CampusCourseFeedPostModel,
  CampusClassPostModel,
  CampusCourseModel,
  CampusClassModel,
  CampusStudentGroupModel,
  CampusMarketplaceModel,
  IResponse,
  HomePostModel
} from '../../app/shared/models';
import {CampusFactory, HomeFactory} from '../../app/shared/models/factory';

@Injectable()
export class HomePostService extends ApiService {
  public options = {};
  public baseURI = 'home-post';
  public baseURIPlural = 'campus';

  /**
   * Basically get the all the campuses
   */
 
  // public promiseCreatePost(
  //   instituteId: number,
  //   homePost: CampusPostModel
  // ): Promise<CampusPostModel> {
  //   return this.promisePostModelData(`${instituteId}/post`, homePost).then(
  //     (response: IResponse) => {
  //       return CampusFactory.createCampusPost(response.data);
  //     }
  //   );
  // }

  public promiseCreatePost(
    instituteId: number,
    homePost: HomePostModel
  ): Promise<HomePostModel> {
    return this.promisePostModelData(`${instituteId}/post`, homePost).then(
      (response: IResponse) => {
        return HomeFactory.createHomePost(response.data);
      }
    );
  }


  public promiseGetAllPost(
    instituteId: number,
    // groupId: number,
    limit: number = 10,
    offset: number = 0
  ): Promise<HomePostModel[]> {
    console.log('instituteId', instituteId);
    
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    this.options = {
      params: params
    };

     return this.promiseGetAllResponseData(`${instituteId}/postss`).then(
      (response: IResponse) => {
        return HomeFactory.createManyHomePost(response.data);
      }
    );
  }

}
