import { EventModel } from './event';

export class EventFactory {
  public static createEvent(data: any): EventModel {
    return <EventModel>(new EventModel())
      .assimilate(data);
  }

  public static createManyEvent(data: Array<EventModel>): Array<EventModel> {
    if (data !== undefined) {
      return data.map(
        instanceData => EventFactory.createEvent(instanceData),
      );
    } else {
      return null;
    }
  }
}
