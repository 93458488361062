import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ApiService} from '../api.service';
import {
  IResponse,
  DirectorySearchModel,
  UserModel
} from '../../app/shared/models';
import {UserFactory} from '../../app/shared/models/factory';

@Injectable()
export class DirectorySearchService extends ApiService {
  public options = {};
  public baseURI = 'directory-search';
  public baseURIPlural = 'directory-search';

  public promiseGetAllSearchedUsers(
    instituteId?: number,
    keyword?: string,
    year?: number,
    limit?: number,
    offset?: number
  ): Promise<UserModel[]> {
    let params = new HttpParams();
    if (keyword) {
      params = new HttpParams()
        .set('instituteId', instituteId.toString())
        .set('keyword', keyword.toString())
        .set('limit', limit.toString())
        .set('offset', offset.toString());
    } else if (year) {
      params = new HttpParams()
        .set('instituteId', instituteId.toString())
        .set('year', year.toString())
        .set('limit', limit.toString())
        .set('offset', offset.toString());
    } else {
      params = new HttpParams()
        .set('instituteId', instituteId.toString())
        .set('limit', limit.toString())
        .set('offset', offset.toString());
    }
    this.options = {
      params: params
    };

    return this.promiseGetAllResponseData(``).then((response: IResponse) => {
      return UserFactory.createMany(response.data);
    });
  }
}
