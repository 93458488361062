import {Model} from './model';

export class PracticeVideoInterviewModel extends Model {
  public id?: number;
  public helpNeed?: any[];
  public jobUrl?: any[];
  public aboutRequirements?: any[];
  public briefDetailAboutCompany?: any[];
  public tellUsAboutYourself?: any[];
  public jobDescription?: any[];
  public createAt?: Date;
  public updatedAt?: Date;

  public init(): void {
    this.setBlankDataStructure({
      id: undefined,
      helpNeed: [],
      jobUrl: [],
      aboutRequirements: [],
      briefDetailAboutCompany: [],
      tellUsAboutYourself: [],
      jobDescription: []
    });
  }
}
