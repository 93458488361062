import {Model} from './model';

export class UserPreferenceModel extends Model {
  public id?: number;
  public isFullTimeJob?: boolean;
  public isInternship?: boolean;
  public isPartTimeJob?: boolean;
  public isOnCampusJob?: boolean;
  public industries?: any[];
  public province?: any[];
  public isRecommendJob?: boolean;
  public isMoreJob?: boolean;
  public isRecommendOpportunities?: boolean;
  public createAt?: Date;
  public updatedAt?: Date;

  public init(): void {
    this.setBlankDataStructure({
      id: undefined,
      isFullTimeJob: 0,
      isInternship: 0,
      isPartTimeJob: 0,
      isOnCampusJob: 0,
      industries: [],
      province: [],
      isRecommendJob: 0,
      isMoreJob: 0,
      isRecommendOpportunities: 0
    });
  }
}
