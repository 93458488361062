import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {UserApiService} from '../../../services/api';
import {
  TokenStore,
  UserService,
  CheckProfileCompletionService
} from '../../../services';
import {UserModel, ProfileCompleteModel} from '../models';
import {CryptoUtilities} from '../utilities';
import {CONFIG} from '../../../config';

@Injectable()
export class CanActivateCheckInstitute implements CanActivate {
  constructor(
    private userApiService: UserApiService,
    private checkProfileIncompletion: CheckProfileCompletionService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot /* , state: RouterStateSnapshot */
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const user = UserService.getUser();

      let instituteId = parseInt(
        CryptoUtilities.decipher(route.paramMap.get('instituteId')),
        10
      );

      let userId = CryptoUtilities.cipher(user.id);
      if (user.institutionId && user.institutionId === instituteId) {
        return resolve(true);
      } else {
        if (user.institutionId) {
        } else {
          let apiUrl = CONFIG[CONFIG.environment].api;
          window.location.href =
            apiUrl +
            '/user/institute/login/' +
            route.paramMap.get('instituteId') +
            '/' +
            userId;
        }
      }

      this.userApiService
        .promiseGetUser()
        .then((userData: any) => {
          // console.log(userData);
          TokenStore.setAccessToken(userData.token);
          UserService.setUser(userData);

          // if (
          //   !userData.aboutMe ||
          //   !userData.userSkills ||
          //   userData.userSkills.length === 0 ||
          //   !userData.workExperiences ||
          //   userData.workExperiences.length === 0
          // ) {
          //   let model = new ProfileCompleteModel;
          //   model.status = true;
          //   model.aboutme = !userData.aboutMe;
          //   model.workExperience = !userData.workExperiences || userData.workExperiences.length === 0;
          //   model.skills = !userData.userSkills || userData.userSkills.length === 0;
          //   this.checkProfileIncompletion.setStatus(model);
          // }

          resolve(true);
        })
        .catch(() => {
          TokenStore.expungeData();
          window.location.reload();
          resolve(false);
        });
    });
  }
}
