import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {IndustryApiService} from '../../../../services/api';
import {IndustryModel} from '../../../shared/models';

@Component({
  selector: 'app-mentor-filter-modal',
  template: require('./mentor-filter-modal.component.html'),
  styles: [require('./mentor-filter-modal.component.scss')]
})
export class MentorFilterDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private dialog: MatDialog,
    private industryApiService: IndustryApiService
  ) {
    this.getIndustries();
  }
  private form: any = {};
  protected industry: string = '';
  private industries: IndustryModel[] = [];
  private selectedIndustries: IndustryModel[] = [];
  public ngOnInit(): void {}

  private getIndustries(): void {
    this.industryApiService
      .promiseGetAllIndustries()
      .then((industries: IndustryModel[]) => {
        this.industries = industries;
      });
  }
  protected selectIndustry(industry): void {
    let index = this.selectedIndustries.indexOf(industry);
    if (index > -1) {
      this.selectedIndustries.splice(index, 1);
    } else {
      this.selectedIndustries.push(industry);
    }
  }
  protected onCancel(): void {
    this.dialog.closeAll();
  }
  protected onSave(): void {
    let jobFilterModalComponent = this.dialog.getDialogById(
      'MentorFilterDialogComponent'
    );
    let filters: any = {};
    if (this.selectedIndustries.length > 0) {
      filters.industries = this.selectedIndustries.map(
        (industry) => industry.id
      );
    }
    jobFilterModalComponent.close(filters);
  }
}
