import {Model} from './model';

export class CareerGuidanceQuestionnaireModel extends Model {
  public id?: number;
  public isIntroductionToConnections: boolean;
  public isResumeReview: boolean;
  public isOpenDoorAtWorkPlace: boolean;
  public isIntership: boolean;
  public isMeetForOffice: boolean;
  public isAnswerToIndustrySpecificQuestions: boolean;
  public isCareerAdvice: boolean;
  public isInterestedInGradSchool: boolean;
  public isCareerCenterEvents: boolean;
  public isBuildMyNetwork: boolean;
  public isWantAJob: boolean;
  public isNotSureForHelp: boolean;
  public isWorking: boolean;
  public isGraduate: boolean;
  public isGapYear: boolean;
  public isVolunteering: boolean;
  public isFellowship: boolean;
  public isMilitaryService: boolean;
  public isNotSureForAfterGraduation: boolean;
  public createAt?: Date;
  public updatedAt?: Date;

  public init(): void {
    this.setBlankDataStructure({
      id: undefined,
      isIntroductionToConnections: 0,
      isResumeReview: 0,
      isOpenDoorAtWorkPlace: 0,
      isIntership: 0,
      isMeetForOffice: 0,
      isAnswerToIndustrySpecificQuestions: 0,
      isCareerAdvice: 0,
      isInterestedInGradSchool: 0,
      isCareerCenterEvents: 0,
      isBuildMyNetwork: 0,
      isWantAJob: 0,
      isNotSureForHelp: 0,
      isWorking: 0,
      isGraduate: 0,
      isGapYear: 0,
      isVolunteering: 0,
      isFellowship: 0,
      isMilitaryService: 0,
      isNotSureForAfterGraduation: 0
    });
  }
}
