import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetLocationService {
  constructor(private http: HttpClient) {}
  public getProvince(countryCode: String): Observable<any> {
    return this.http.get(`province/${countryCode}`);
  }
  public getProvinceCities(provinceId: Number = 1): Observable<any> {
    return this.http.get(`province-cities/${provinceId}`);
  }
}
