import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'mentor-detail-modal-component',
  template: require('./mentor-detail.component.html'),
  styles: [require('./mentor-detail.component.scss')]
})
export class MentorModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected mentorDetailData: any,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {}
}
