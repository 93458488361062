/**
 * This component is for the announcements
 */
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'announcement-component',
  template: require('./announcement.component.html'),
  styles: [require('./announcement.component.scss')]
})
export class SharedAnnouncementComponent {
  constructor() {}
}
