import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {IResponse } from '../../app/shared/models';
import { ArticleFactory } from '../../app/shared/models/factory';
import { HttpParams } from '@angular/common/http';
import { ArticleModel } from '../../app/shared/models/article';

@Injectable()
export class ArticleApiService extends ApiService {
    public options = {};
    public baseURI = 'article';
    public baseURIPlural = 'articles';

    public promiseGetInstituteArticle(instituteId?: number, limit?: number, offset?: number): Promise<ArticleModel[]> {
        let params: HttpParams;

        if (instituteId) {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString())
                .set('instituteId', instituteId.toString());
        } else {
            params = new HttpParams()
                .set('limit', limit.toString())
                .set('offset', offset.toString());
        }
        this.options = {
            params: params
        };
        return this.promiseGetAllResponseData(`institute-articles`)
            .then((response: IResponse) => {
                return ArticleFactory.createManyArticle(response.data);
            });
    }

    public promiseGetAllArticles(limit?: number, offset?: number): Promise<ArticleModel[]> {
        let params: HttpParams;
        params = new HttpParams()
            .set('limit', limit.toString())
            .set('offset', offset.toString());

        return this.promiseGetAllResponseData('', { params: params })
            .then((response: IResponse) => {
                return ArticleFactory.createManyArticle(response.data);
            });
    }

    public promiseGetArticles(): Promise<ArticleModel[]> {
        return this.promiseGetAllResponseData()
            .then((response: IResponse) => {
                return ArticleFactory.createManyArticle(response.data);
            });
    }

    public promiseSearchArticles(title: string): Promise<ArticleModel[]> {
        return this.promiseGetResponseData(`search?q=${title}`)
            .then((response: IResponse) => {
                return ArticleFactory.createManyArticle(response.data);
            });
    }

    public promiseGetRecentArticles(articleId?: number): Promise<ArticleModel[]> {
        return this.promiseGetResponseData(`recent/${articleId}`)
            .then((response: IResponse) => {
                return ArticleFactory.createManyArticle(response.data);
            });
    }

    public promiseGetArticle(articleId?: number): Promise<ArticleModel> {
        return this.promiseGetResponseData(`get/${articleId}`)
            .then((response: IResponse) => {
                return ArticleFactory.createArticle(response.data);
            });
    }

    public promisePostArticle(article: ArticleModel): Promise<IResponse> {
        return this.promisePostModelData(``, article)
            .then((response: IResponse) => {
                return response;
            });
    }

    public promiseRemoveArticle(articleId: number): Promise<IResponse> {
        return this.promiseRemoveData(`${articleId}`)
            .then((response: IResponse) => {
                return response;
            });
    }

    public promiseUpdateArticle(article: ArticleModel, articleId: number): Promise<IResponse> {
        return this.promisePostModelData(`${articleId}`, article)
            .then((response: IResponse) => {
                return response;
            });
    }

    // public saveComments(articleId: string, comment: string): Promise<any> {
    //     return this.promiseCommentModelData(`${articleId}`, comment)
    //         .then((response: any) => {
    //             return response;
    //         })
    // }
}
