import {Component, Input, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {JobModel, SavedJobModel} from '../../models';
import {SavedJobApiService} from '../../../../services/api';
import {CryptoUtilities} from '../../utilities';

@Component({
  selector: 'shared-job-card-component',
  template: require('./job.component.html'),
  styles: [require('./job.component.scss')]
})
export class SharedJobCardComponent {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private savedJobApiService: SavedJobApiService
  ) {}

  @Input() protected job: JobModel;
  public jobTypes: string[] = [
    'Full-Time',
    'Part-Time',
    'Internship',
    'Graduate Role',
    'Co-op'
  ];

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {}

  protected trimStory(message, maxCharacters): string {
    if (message && message.length > maxCharacters) {
      let trimmedString = message.substr(0, maxCharacters);
      return (trimmedString = trimmedString + '...');
    } else {
      return message;
    }
  }

  protected showJobDetail(job: JobModel): Promise<boolean> {
    let jobId = CryptoUtilities.cipher(job.id);

    return this.router.navigate([`/job/${jobId}`]);
  }

  protected onSave(job: JobModel): void {
    let data: SavedJobModel = new SavedJobModel();
    data.jobId = job.id;

    this.savedJobApiService.promisePostSavedJob(data).then((res) => {
      console.log(res);
      this.snackBar.open('Saved', '', {
        duration: 2000
      });
    });
  }
}
