import {
  InstituteModel,
  InstituteStudentGroupModel
} from '../app/shared/models/institute';

export class InstituteService {
  private static institute: InstituteModel;
  private static instituteGroup: InstituteStudentGroupModel;
  private static otherInstitute: InstituteModel;

  public static setInstitute(institute: InstituteModel): void {
    InstituteService.institute = institute;
  }

  public static getInstitute(): InstituteModel {
    return InstituteService.institute;
  }

  public static setOtherInstitute(institute: InstituteModel): void {
    InstituteService.otherInstitute = institute;
  }

  public static getOtherInstitute(): InstituteModel {
    return InstituteService.otherInstitute;
  }

  public static setInstituteGroup(
    instituteGroup: InstituteStudentGroupModel
  ): void {
    InstituteService.instituteGroup = instituteGroup;
  }

  public static getInstituteGroup(): InstituteStudentGroupModel {
    return InstituteService.instituteGroup;
  }
}
