
import { ArticleModel } from './article';

// import { ArticleModel } from "../../app/shared/models/article";

export class ArticleFactory {
  public static createArticle (data: any): ArticleModel {
    return <ArticleModel> (new ArticleModel ())
      .assimilate(data);
  }

  public static createManyArticle (data: Array<ArticleModel>): Array<ArticleModel> {
    return data.map(
      instanceData => ArticleFactory.createArticle(instanceData),
    );
  }
}