import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {
  UserApiService,
  CompanyApiService,
  IndustryApiService
} from '../../../../services/api';
import {
  UserModel,
  ProfileCompleteModel,
  CompanyModel,
  IndustryModel,
  AttachmentModel
} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {CountriesService} from '../../../../services';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CryptoUtilities} from '../../utilities';
import {CompanyEmitter} from '../../emitter';
import {CompanyFactory} from '../../models/company.factory';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-complete-profile-modal',
  template: require('./company-profile-modal.component.html'),
  styles: [require('./company-profile-modal.component.scss')]
})
export class CompanyProfileModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ProfileCompleteModel,
    private companyApiService: CompanyApiService,
    private industryApiService: IndustryApiService,
    private userApiService: UserApiService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    if (this.data !== null) {
      this.isEdit = true;
      this.companyId = parseFloat(CryptoUtilities.decipher(this.data));
      this.getCompany(this.companyId);
    }
    this.getIndustries();
  }

  private companyComposeForm: FormGroup;
  private form: CompanyModel = new CompanyModel();
  protected company: CompanyModel;
  private isEdit: boolean = false;
  protected countries: any[] = [];
  protected industries: IndustryModel[] = [];
  protected imageSelected: boolean = false;
  protected imageReSelected: boolean = false;
  protected isSubmitted: boolean = false;
  protected companys: CompanyModel[] = [];
  protected userInfo: any = undefined;
  protected logo: AttachmentModel;
  protected images: AttachmentModel[] = [];
  protected attachments: AttachmentModel[] = [];
  protected companyId: number;

  public ngOnInit(): void {
    this.userApiService.promiseGetUser().then((userInfo: UserModel) => {
      this.userInfo = userInfo;
    });
    this.countries = CountriesService.getCountries();
    this.companyComposeForm = this.fb.group({
      company: ['', Validators.required],
      company_type: [''],
      company_size: [''],
      no_of_employees: [''],
      company_contact: [''],
      country: ['', Validators.required],
      city: ['', Validators.required],
      recruit: [''],
      industry: [''],
      company_bio: ['', Validators.required],
      logo: ['']
    });
  }

  get f(): any {
    return this.companyComposeForm.controls;
  }

  private getIndustries(): void {
    this.industryApiService
      .promiseGetAllIndustries()
      .then((industries: IndustryModel[]) => {
        this.industries = industries;
      });
  }

  private getCompany(id: number): void {
    this.companyApiService
      .promiseGetCompany(id)
      .then((company: CompanyModel) => {
        this.form = company;
        this.attachments = this.form.companyAttachments;
        for (let attach of this.attachments) {
          if (attach.usage === 'logo') {
            this.logo = attach;
          } else if (attach.usage === 'image') {
            this.images.push(attach);
          }
        }

        this.companyComposeForm = this.fb.group({
          company: this.form.company,
          company_type: this.form.company_type,
          company_size: this.form.company_size,
          no_of_employees: this.form.no_of_employees,
          company_contact: this.form.company_contact,
          country: this.form.country,
          city: this.form.city,
          recruit: this.form.recruit,
          industry: this.form.industry,
          company_bio: this.form.company_bio,
          attachments: this.form.companyAttachments,
          logo: this.form.logo
        });
      });
    this.imageSelected = true;
  }

  protected onImageSelected(selected): void {
    this.imageSelected = selected;
  }

  protected onUploadComplete(attachments): void {
    this.createCompany(attachments);
  }

  private markFormGroupTouched(form: FormGroup): void {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();

      if ((control as any).controls) {
        this.markFormGroupTouched(control as FormGroup);
      }
    });
  }

  protected onDeleteItem(item: AttachmentModel): void {
    this.companyApiService
      .promiseDeleteCompanyAttachment(item.id)
      .then((res) => {
        this.getCompany(this.companyId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  protected onChangeCompany(item): void {
    const companyId = CryptoUtilities.cipher(item);
    this.router.navigate([`/companies/compose`, companyId]);
  }

  protected onCancel(): void {
    this.dialog.closeAll();
  }

  protected onSave(): void {
    this.markFormGroupTouched(this.companyComposeForm);
    this.isSubmitted = true;
    if (this.companyComposeForm.valid && this.imageSelected) {
      return CompanyEmitter.uploadImages().emit();
    }
  }

  protected createCompany(attachments): void {
    let data = CompanyFactory.createCompany(this.companyComposeForm.value);
    if (data.recruit) {
      data.recruit = 1;
    } else {
      data.recruit = 0;
    }
    for (let attach of attachments) {
      if (attach.usage === 'logo') {
        data.logo = attach.cloudinaryPublicId;
      }
    }
    data.userId = this.userInfo.id;

    data.companyAttachments = attachments;

    // console.log('1111111', this.isEdit);
    if (this.isEdit) {
      // console.log('in if', this.form.id);
      this.companyApiService
        .promiseUpdateCompany(data, this.form.id)
        .then((res) => {
          this.dialog.closeAll();
          this.companyApiService.refreshState();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // console.log('in else');
      this.companyApiService
        .promisePostCompany(data)
        .then((res) => {
          this.dialog.closeAll();
          this.companyApiService.refreshState();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
