import { Model } from './model';
import {PostReplyModel, PostPollModel} from './post';
import {UserModel} from './user';

export class InstituteModel extends Model {
  public id?: number;
  public institution_id: string;
  public token: string;
  public password: string;
  public attachments: String[];
  public confirmPassword?: string;
  public institution_name: string;
  public institution_name_old?: string;
  public institution_logo?: string;
  public institution_banner?: string;
  public support_title?: string;
  public support_content?: string;

  public init(): void { }
}

export class InstituteStudentGroupModel extends Model {
  public id?: number;
  public name: string;
  public description: string;
  public adminEmail: string;
  public isConfirm?: boolean;
  public instituteId?: number;
  public institutePrivacyId?: number = 1;
  public logo: string;
  public init(): void {}
}

export class InstitutePostModel extends Model {
  // public id?: number;
  public instituteId?: number;
  public institutionId?: number;
  public instituteStudentGroupId?: number;
  public groupId?: number;
  public createdAt: Date;
  public message: string;
  public likeCount: number;
  public isUserLike: number;
  public isUserPostLike: number;
  public pageviewCount: number;
  public institutePostReply: Array<PostReplyModel>;
  public postShare: InstitutePostModel;
  public postReply: Array<PostReplyModel>;
  public ratingCount: number;
  public roundedRating?: number;
  public attachments: String[];
  // use as a virtual holder for postPoll
  public institutePostPoll: PostPollModel;

  public init(): void {
    this.setBlankDataStructure({
      id: undefined,
      message: undefined,
      attachments: []
    });
  }
}

export class InstitutePostReplyModel extends Model {
  public id?: number;
  public comment: string;
  public user?: UserModel;
  public hideComment?: boolean;
  public postPollOptionId?: number;
  public createdAt?: Date;
  public attachments: String[];

  public init(): void {
    this.setBlankDataStructure({
      id: undefined,
      comment: undefined,
      user: undefined,
      hideComment: undefined, 
      postPollOptionId: undefined,
      createdAt: undefined,
      attachments: []
    });
  }

}
export class InstituteClassPostModel extends InstitutePostModel {
  // use as a virtual field for courseFeed
  public classId: number;

  public init(): void {
    super.init();
    this.setBlankDataStructure({
      classId: undefined
    });
  }
}