import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {IResponse} from '../../app/shared/models';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class CareerEvaluationApiService extends ApiService {
  public options = {};
  public baseURI = 'careerevaluation';
  public baseURIPlural = 'careerevaluations';

  public promiseGetAllCountryCareerEvaluation(): any {
    return this.promiseGetAllResponseData('career-evaluation').then(
      (response: IResponse) => {
        return response.data;
      }
    );
  }

  public promiseGetAllCountryCareerEvaluationResult(): any {
    return this.promiseGetAllResponseData('result').then(
      (response: IResponse) => {
        return response.data;
      }
    );
  }

  public promiseGetJobProfile(instituteId?: number, interestId?: number): any {
    let params: HttpParams;
    params = new HttpParams()
      .set('instituteID', instituteId.toString())
      .set('interestID', interestId.toString());
    this.options = {
      params: params
    };
    return this.promiseGetAllResponseData('job-profile').then(
      (response: IResponse) => {
        return response.data;
      }
    );
  }
}
