export * from './campus.factory';
export * from './post.factory';
export * from './course.factory';
export * from './article.factory';
export * from './user.factory';
export * from './interest.factory';
export * from './advance-search.factory';
export * from './messages.factory';
export * from './community.factory';
export * from './notification.factory';
export * from './job.factory';
export * from './saved-job.factory';
export * from './event.factory';
export * from './manual-job.factory';
export * from './scholarship.factory';
export * from './industry.factory';
export * from './blog.factory';
export * from './company.factory';
export * from './opportunity.factory';
export * from './home.factory';