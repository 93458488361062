export const STAGING = {
  api: 'https://peersview.herokuapp.com/api/v1/',
  socialProviders: {
    google: {
      clientId:
        '554830554730-9999uo5phtv3fo4fcctaq6vd8ggjgpcv.apps.googleusercontent.com'
    },
    linkedin: {
      clientId: '77y99ina3fva1b'
    },
    facebook: {
      clientId: '2014589065490082',
      apiVersion: 'v2.11'
    }
  },
  publicKey:
    'pk_test_51H3jc1Fi2vCKERegUrB3mXoaE70MSuzeTk4ol1Ba1Lpj4l6eYrCY3NhcHr4VpocOqhBBuqOKPqjdg4TEqZUAVMfe00ggaghiO4',
  payPalClientId:
    'AePyWhEUwPPmTuyFbvs8cwL7UwOK2AV1oMwI5EZV_iCUG58EhpIvX96ZM4vyHXRmj0iIpxkwBQJplmH3'
};
