import {Component, OnInit, Input, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/platform-browser';
import {TokenStore} from '../../../../services';

@Component({
  selector: 'shared-navbar-unauth-component',
  template: require('./navbar-unauth.component.html'),
  styles: [require('./navbar-unauth.component.scss')]
})
export class SharedNavbarUnauthComponent implements OnInit {
  constructor() {}

  @Input() protected logo: boolean;
  @Input() protected isLogoBlue: boolean = false;
  @Input() protected peersviewLogo: boolean;
  @Input() protected aboutUs: boolean;
  @Input() protected digitalCampus: boolean;
  @Input() protected employers: boolean;
  @Input() protected career: boolean;
  @Input() protected jobs: boolean;
  @Input() protected blog: boolean;
  @Input() protected press: boolean;
  @Input() protected signIn: boolean;
  @Input() protected signUp: boolean;
  @Input() protected aboutUsSecond: boolean;
  @Input() protected employersSecond: boolean;
  @Input() protected digitalCampusSecond: boolean;
  @Input() protected blogSecond: boolean;
  @Input() protected pressSecond: boolean;
  @Input() protected signInSecond: boolean;
  @Input() protected signUpSecond: boolean;
  @Input() protected companies: boolean;
  @Input() protected careerFair: boolean;
  private isToggle: boolean;
  public token = TokenStore.getAccessToken();

  public ngOnInit(): void {
    //   if (this.token) {
    //     this.signIn = false;
    //     this.signUp = false;
    //   }
    //   console.log('From UNAUTH NAvbar', this.token);
  }
}
