import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-dialog',
  template: require('./info-dialog.component.html'),
  styles: [require('./info-dialog.component.scss')]
})
export class InfoDialogComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
