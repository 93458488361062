import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'shared-sidebar-footer-component',
  template: require('./sidebar-footer.component.html'),
  styles: [require('./sidebar-footer.component.scss')]
})
export class SharedSidebarFooterComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
